import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, runInAction } from 'mobx';
import { Header, Label, Button } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import ReviewPublishRequest from './ReviewPublishRequest';
import SimpleTable from '../helpers/SimpleTable';
import ComponentSwitch from '../helpers/ComponentSwitch';
import ByWithProfile from '../helpers/ByWithProfile';

class PublishRequestsCard extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    runInAction(() => {
      // add any state changing initialization logic if needed
    });
  }

  getRequestType() {
    return this.props.type;
  }

  getRequest() {
    return this.props.requestId;
  }

  getPublishRequest() {
    return this.props.publishRequest;
  }

  render() {
    return (
      <>
        <Label attached="top left">{(this.getRequestType() === 'ami' ? 'AMI ': '')}Publish Request</Label>
        <div className="flex mb1">
          <Header as="h3" color="grey" className="mt0 flex-auto ellipsis">
            {this.getRequest()}
          </Header>
        </div>
        <SimpleTable
          headerRenderer={() => {
            return ['Requested By', 'Requested date', ''];
          }}
          rowRenderer={request => {
            return [
              <ByWithProfile
                user={this.props.usersStore.asUserObjectByNsUsername(request.createdBy.ns, request.createdBy.username)}
              />,
              request.createdAt,
              <>{this.renderActions(request)}</>,
            ];
          }}
          rowData={this.getPublishRequest()}
          keyMethod={request => request.username}
        />
      </>
    );
  }


  renderActions(request) {
    return (
      <ComponentSwitch
        tests={[
          () =>
            request.pending() && (
              <>
                <ReviewPublishRequest
                  request={request}
				  formType='approve'
				  onApprovePublish={request.approve}
		                  type={this.getRequestType()}
                /> 
                <ReviewPublishRequest
                  request={request}
				  formType='reject'
				  onRejectPublish={request.reject}
		                  type={this.getRequestType()}
                /> 
              </>
            ),
          () =>
            request.running() && (
              <Button floated="right" disabled>
                Running
              </Button>
            ),
          () =>
            request.completed() && (
              <Button floated="right" disabled>
                Complete
              </Button>
            ),
          () =>
            request.rejected() && (
              <Button floated="right" disabled>
                Rejected
              </Button>
            ),
        ]}
      />
    );
  }
}

export default inject('userDisplayName', 'usersStore')(withRouter(observer(PublishRequestsCard)));
