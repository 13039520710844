/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getSettings, updateSetting, refreshCustomEnvironmentConfigurations, 
	refreshSagemakerEnvironmentConfigurations } from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import { Setting } from './Setting';

// ==================================================================
// ProjectsStore
// ==================================================================
const SettingsStore = BaseStore.named('SettingsStore')
  .props({
    settings: types.optional(types.map(Setting), {}),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const settings = await getSettings();
        // We try to preserve existing projects data and merge the new data instead
        // We could have used self.projects.replace(), but it will do clear() then merge()
        self.runInAction(() => {
          consolidateToMap(self.settings, settings, (exiting, newItem) => {
            exiting.setSetting(newItem);
          });
        });
      },

      cleanup: () => {
        self.settings.clear();
        superCleanup();
      },

      async getSetting(id) {
        let settings = self.settings;
        if (settings) {
          settings = await getSettings();
        }
        const setting = settings.find(item => item.id === id);
        return setting;
      },

      async refreshCustomInstanceTypes() {
        await refreshCustomEnvironmentConfigurations();
      },
	   
      async refreshSagemakerInstanceTypes() {
	await refreshSagemakerEnvironmentConfigurations();
      },

      async update(setting) {
        await updateSetting(setting.id, setting);
      },

      async updateBatch(settingList) {
        await Promise.all(settingList.map(setting => this.update(setting)));
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.settings.size === 0;
    },

    get total() {
      return self.settings.size;
    },

    get list() {
      const result = [];
      self.settings.forEach(setting => result.push(setting));

      return _.reverse(_.sortBy(result, ['createdAt', 'id']));
    },
  }));

function registerModels(globals) {
  globals.settingsStore = SettingsStore.create({}, globals);
}

export { SettingsStore, registerModels };
