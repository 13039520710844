/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

// edit by 2359 - add requestPermissions, updatePermissions and revokePermissions
import {
  getStudyPermissions,
  updateStudyPermissions,
  requestStudyAccess,
  updateStudyUserPermissions,
  revokeStudyUserPermissions,
  changeOwner,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { StudyPermissions } from './StudyPermissions';

// ==================================================================
// StudyStore
// ==================================================================
const StudyPermissionsStore = BaseStore.named('StudyPermissionsStore')
  .props({
    studyId: types.identifier,
    studyPermissions: types.maybe(StudyPermissions),
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const newPermissions = await getStudyPermissions(self.studyId);
        if (!self.studyPermissions || !_.isEqual(self.studyPermissions, newPermissions)) {
          self.runInAction(() => {
            self.studyPermissions = newPermissions;
          });
        }
      },

      cleanup: () => {
        superCleanup();
      },

      requestPermissions: async (updateRequest, requestFile) => {
        // console.log(`Request: ${JSON.stringify(updateRequest)}`);
        const _permissionRequested = await requestStudyAccess(self.studyId, updateRequest, requestFile);
        // console.log(`PermissionRequested: ${JSON.stringify(permissionRequested)}`);
        // await self.load(); // cannot self load afer requesting access, as user will still not have accesss and load() will throw 'not found'
      },

      update: async (permissions, isPublic, selectedUsers) => {
        const updateRequest = { permissions, isPublic, usersToAdd: [], usersToRemove: [] };

        self.studyPermissions.userTypes.forEach(type => {
          const userToRequestFormat = user => ({ principalIdentifier: user, permissionLevel: type });

          // Set selected users as "usersToAdd" (API is idempotent)
          updateRequest.usersToAdd.push(...selectedUsers[type].map(userToRequestFormat));

          // Set removed users as "usersToRemove"
          updateRequest.usersToRemove.push(
            ..._.differenceWith(self.studyPermissions[`${type}Users`], selectedUsers[type], _.isEqual).map(
              userToRequestFormat,
            ),
          );
        });

        // Perform update and reload store
        await updateStudyPermissions(self.studyId, updateRequest);
        await self.load();
      },

      updatePermissions: async permissions => {
        // Perform update and reload store
        await updateStudyUserPermissions(self.studyId, { permissions });
        await self.load();
      },

      revokePermissions: async permissions => {
        // Perform update and reload store
        const { principalIdentifier, ..._rest } = permissions;
        await revokeStudyUserPermissions(self.studyId, { permissions: { principalIdentifier, studyId: self.studyId } });
        await self.load();
      },

      async updateOwner(updateRequest) {
        const result = await changeOwner(updateRequest);
        if (!_.isEqual(result.ownerWithNs, self.studyPermissions.ownerWithNs)) {
          self.runInAction(() => {
            self.studyId = updateRequest.studyId;
          });
          await self.load();
        }
      },
    };
  });

export { StudyPermissionsStore };
