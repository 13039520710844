/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { inject, observer } from 'mobx-react';
import { Component } from 'react';

// expected props
// - authenticationProviderConfigsStore (via injection)
class AddAuthenticationProvider extends Component {
  render() {
    return 'TODO: IMPLEMENT';
  }
}

export default inject('authenticationProviderConfigsStore')(observer(AddAuthenticationProvider));
