import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { listAmiPublishRequests } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { AmiPermission } from './AmiPermission';

// ==================================================================
// AmiPublishRequestsStore
// ==================================================================
const AmiPublishRequestsStore = BaseStore.named('AmiPublishRequestsStore')
  .props({
    publishRequests: types.array(AmiPermission),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      doLoad: async () => {
        const newPublishRequests = await listAmiPublishRequests();
        if (!self.publishRequests || !_.isEqual(self.publishRequests, newPublishRequests)) {
          self.runInAction(() => {
            self.publishRequests = newPublishRequests;
          });
        }
      },
      cleanup: () => {
        self.publishRequests.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.publishRequests.size === 0;
    },

    get total() {
      return self.publishRequests.size;
    },

    get list() {
      return self.publishRequests;
    },

    get listAsMap() {
      const mapping = {};
      self.publishRequests.forEach(req => {
        if (Object.prototype.hasOwnProperty.call(mapping, req.amiId)) {
          mapping[req.amiId].push(req);
        } else {
          mapping[req.amiId] = [req];
        }
      });
      return mapping;
    },

    hasPublishRequest(id) {
      return self.publishRequests.has(id);
    },

    getPublishRequest(id) {
      return self.publishRequests.get(id);
    },
  }));

function registerModels(globals) {
  globals.amiPublishRequestsStore = AmiPublishRequestsStore.create({}, globals);
}

export { AmiPublishRequestsStore, registerModels };
