/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import moment from 'moment';
import { getEnv, types } from 'mobx-state-tree';

import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';
import initializeAmplify, { cookieStorage as storage }from '../../init-amplify';
import { getFragmentParam, removeFragmentParams } from '../../helpers/utils';
import { setIdToken, forgetIdToken, forceResetPassword, updateUserLogin } from '../../helpers/api';

import localStorageKeys from '../constants/local-storage-keys';
import { boom } from '../../helpers/errors';
//import initializeAmplify from '../../init-amplify';
import { awsRegion } from '../../helpers/settings';

const isTokenExpired = token => {
  const now = Date.now();
  const decoded = jwtDecode(token);
  const expiresAt = _.get(decoded, 'exp', 0) * 1000;
  return expiresAt < now;
};

const REGULAR_USER_EXPIRY_DAYS = 365; // 12 months
const ADMIN_USER_EXPIRY_DAYS = 90; // 3 months
const PASSWORD_CHANGED_DATE_FIELD = 'custom:pwdChangedDate';

const isPasswordExpired = (lastModDate, isAdmin) => {
  const lastUpdDate = moment(lastModDate).startOf('day');
  const current = moment().startOf('day');
  const diff = Math.abs(lastUpdDate.diff(current, 'days', false)) + 1;
  // console.log("diff: " + diff);
  if (isAdmin) {
    return diff >= ADMIN_USER_EXPIRY_DAYS;
  }
  return diff >= REGULAR_USER_EXPIRY_DAYS;
};

function removeTokensFromUrl() {
  const newUrl = removeFragmentParams(document.location, [
    'id_token',
    'access_token',
    'token_type',
    'expires_in',
    'state',
  ]);
  window.history.replaceState({}, document.title, newUrl);
}

async function getIdTokenFromAmplify() {
  try {
    // The Auth.currentSession() line below throws "No current user" error when user has not signed in yet
    // Also the Auth.currentSession() works only in case of direct Cognito User Pool auth.
    // It throws exception in case of federated login via SAML or other social IdPs
    const currentSession = await Auth.currentSession();
    return _.get(currentSession, 'idToken.jwtToken');
  } catch (e) {
    return console.error(e);
  }
}

// ==================================================================
// Login model
// ==================================================================
const CognitoAuthentication = types
  .model('CognitoAuthentication', {
    processing: false,
    selectedAuthenticationProviderId: '',
  })
  .actions(self => ({
    runInAction(fn) {
      return fn();
    },

    // this method is called by the Cleaner
    cleanup() {
      if (self.selectedAuthenticationProvider) {
        // give selected authentication provider a chance to do its own cleanup
        self.selectedAuthenticationProvider.cleanup();
      }
      self.clearTokens();
    },

    clearTokens() {
      _.forEach(localStorageKeys, keyValue => storage.removeItem(keyValue));
    },

    setSelectedAuthenticationProviderId(authenticationProviderId) {
      self.selectedAuthenticationProviderId = authenticationProviderId;
      if (self.isCognitoUserPool) {
        // If the Cognito User Pool authentication provider is selected then initialize amplify library for using it with Cognito
        initializeAmplify({
          awsRegion,
          userPoolId: self.selectedAuthenticationProvider.userPoolId,
          userPoolWebClientId: self.selectedAuthenticationProvider.clientId,
        });
      }
    },

    useCognito() {
      if (self.selectedAuthenticationProvider) {
        return self.isCognitoUserPool;
      }
      return false;
    },

    async getActiveIdToken() {
      // The id token would be in URL in case of SAML redirection.
      // The name of the token param is "id_token" in that case (instead of "appIdToken"), if the token is
      // issued by Cognito.
      // Also the id_token is returned via URL fragment i.e, with # instead of query param something like
      // https://web.site.url/#id_token=blabla instead of
      // https://web.site.url?idToken=blabla
      // TODO: Make the retrieval of id token from query string param or fragment param (or any other mechanism)
      // dynamic based on the authentication provider. Without that, the following code will only work for
      // any auth providers that set id token either in local storage as "appIdToken" or deliver to us
      // via URL fragment parameter as "id_token".
      // This code will NOT work for auth providers issuing id token and delivering via any other mechanism.
      let idToken = getFragmentParam(document.location, 'id_token');
      if (idToken) {
        await self.saveIdToken(idToken);
        removeTokensFromUrl(); // we remove the idToken from the url for a good security measure
      }

      if (!idToken && self.isCognitoUserPool) {
        // attempt to get token from amplify only if the selected authentication provider is cognito
        idToken = await getIdTokenFromAmplify();
      }

      if (!idToken) {
        idToken = storage.getItem(localStorageKeys.appIdToken);
      }

      let activeIdToken;
      if (idToken && !isTokenExpired(idToken)) {
        activeIdToken = idToken;
      }

      // console.log("activeIdToken: " + activeIdToken);

      return activeIdToken;
    },

    async getActiveIdTokenAndDecodedToken() {
      const idToken = await self.getActiveIdToken();
      const decodedIdToken = idToken && jwtDecode(idToken);
      return {
        idToken,
        decodedIdToken,
      };
    },

    async saveIdToken(idToken) {
      storage.setItem(localStorageKeys.appIdToken, idToken);
      const decodedIdToken = idToken && jwtDecode(idToken);
      setIdToken(idToken, decodedIdToken);
    },

    async removeIdToken() {
      storage.removeItem(localStorageKeys.appIdToken);
      forgetIdToken();
      await self.logout();
    },

    async login({ username, password }) {
      if (self.isCognitoUserPool) {
        return Auth.signIn(username, password);
      }
      const result = await self.selectedAuthenticationProvider.login({
        username,
        password,
        authenticationProviderId: self.selectedAuthenticationProviderId,
      });
      const { idToken } = result || {};
      if (_.isEmpty(idToken)) {
        throw boom.incorrectImplementation(
          `There is a problem with the implementation of the server side code. The id token is not returned.`,
        );
      }

      await self.saveIdToken(idToken);

      const app = getEnv(self).app;
      await app.start();
      return result;
    },
    async logout() {
      if (self.isCognitoUserPool) {
        // If the Cognito User Pool authentication provider is selected then call amplify's Logout to logout from Cognito as well
        await Auth.signOut({ global: true }).catch(_err => {
          Auth.signOut();
        });
      }
      const cleaner = getEnv(self).cleaner;
      return cleaner.cleanup();
    },
    async setupTOTP(user) {
      return Auth.setupTOTP(user);
    },
    async validateTOTP(user, totpCode) {
      return Auth.verifyTotpToken(user, totpCode);
    },
    async confirmSignIn(user, totp, isNewUser) {
      const loggedUser = await Auth.confirmSignIn(user, totp, 'SOFTWARE_TOKEN_MFA');

      const idToken = loggedUser.signInUserSession.idToken.jwtToken || '';
      if (_.isEmpty(idToken)) {
        throw boom.incorrectImplementation(
          `There is a problem with the implementation of the server side code. The id token is not returned.`,
        );
      }
      const { attributes } = await Auth.currentAuthenticatedUser({ bypassCache: true });
      await self.saveIdToken(idToken);
      const app = getEnv(self).app;
      const userStore = getEnv(self).userStore;
      await userStore.load();
      const userEntry = userStore.user;
      // console.log("userEntry role: " + userEntry.userRole + " isAdmin: " + (_.has(userEntry, 'isAdmin') ? userEntry.isAdmin: '--'));

      if (isNewUser) {
        const _updated = await this.updatePwdChangedDate(user);
        // console.log("in confirmSignIn: after update pwd date: " + updated);
      }

      const username = loggedUser.username;
      const isPwdExpired = await self.checkPwdExpiry(username, attributes, userEntry);
      if (isPwdExpired) {
        await forceResetPassword(self.selectedAuthenticationProvider.userPoolId, username);
        await self.removeIdToken();
        throw boom.passwordExpired('Your password has expired. Please change your password!');
      } else {
	await updateUserLogin(userEntry);      
        await app.start();
      }
    },
    async nonMfaSignIn(user) {
      const idToken = user.signInUserSession.idToken.jwtToken || '';

      if (_.isEmpty(idToken)) {
        throw boom.incorrectImplementation(
          `There is a problem with the implementation of the server side code. The id token is not returned.`,
        );
      }

      await self.saveIdToken(idToken);

      const app = getEnv(self).app;
      await app.start();
    },
    async updatePwdChangedDate(user) {
      const attrs = {};
      attrs[PASSWORD_CHANGED_DATE_FIELD] = moment().format();
      return Auth.updateUserAttributes(user, attrs);
    },
    async resetPassword(user, newPassword) {
      return Auth.completeNewPassword(user, newPassword, { family_name: 'test', name: 'test' });
    },
    async federatedSignIn() {
      Auth.federatedSignIn({ provider: 'Google' });
    },
    async forgotPassword(username) {
      if (self.isCognitoUserPool) {
        return Auth.forgotPassword(username);
      }
      return null;
    },
    async forgotPasswordSubmit(username, verificationCode, newPassword) {
      if (self.isCognitoUserPool) {
        return Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
      }
      return null;
    },
    async checkPwdExpiry(username, cognitoData, userEntry) {
      // console.log("in checkPwdExpiry: username: " + username);
      if (self.isCognitoUserPool) {
        let isAdmin = false;
        if ((_.has(userEntry, 'isAdmin') && userEntry.isAdmin) || userEntry.userRole === 'admin') {
          isAdmin = true;
        }
        // console.log("in checkPwdExpiry::isAdminUser: " + isAdmin + " pwdChangedDate: " + cognitoData[PASSWORD_CHANGED_DATE_FIELD]);
        return isPasswordExpired(cognitoData[PASSWORD_CHANGED_DATE_FIELD], isAdmin);
      }
      return false;
    },
  }))
  .views(self => ({
    get isCognitoUserPool() {
      if (!self.selectedAuthenticationProvider) {
        // add chenjqp - bugfix to display appropriate error message when auth provider is not selected
        throw boom.badRequest('No authentication provider selected!');
      }
      return self.selectedAuthenticationProvider.type === 'cognito_user_pool';
    },
    get selectedAuthenticationProvider() {
      const authenticationProviderPublicConfigsStore = getEnv(self).authenticationProviderPublicConfigsStore;
      return authenticationProviderPublicConfigsStore.toAuthenticationProviderFromId(
        self.selectedAuthenticationProviderId,
      );
    },

    get shouldCollectUserNamePassword() {
      const selectedAuthenticationProvider = self.selectedAuthenticationProvider;
      return selectedAuthenticationProvider && selectedAuthenticationProvider.credentialHandlingType === 'submit';
    },
  }));

function registerModels(globals) {
  globals.cognitoAuthentication = CognitoAuthentication.create({}, globals);
}

export { CognitoAuthentication, registerModels };
