import { configure } from 'mobx';
import 'mobx-react/batchingForReactDom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeGlobals } from './globals';
import AppContainer from './AppContainer';

function App({ renderAppContainer, renderError, renderProgress }) {
  // Disabling service worker
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // Enable mobx strict mode, changes to state must be contained in actions
  configure({ enforceActions: 'always' });

  const globals = initializeGlobals();

  // Render page loading message
  renderProgress();

  // Trigger the app startup sequence
  (async () => {
    try {
      await globals.app.start();
      renderAppContainer(AppContainer, globals);
    } catch (err) {
      console.log(err);
      // TODO - check if the code = tokenExpired, then
      // - render a notification error
      // - call cleaner cleanup, this is IMPORTANT
      // - render the app and skip the rest of the renderError logic
      // - doing the above logic will help us have a smooth user experience
      //   when the token has expired. NOTE: this might not be applicable for the
      //   cases where the app requires midway before anything is displayed to the user
      renderError(err);
      try {
        globals.cleaner.cleanup();
      } catch (error) {
        // ignore
        console.log(error);
      }
    }
  })();
}

export default App;
