import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Grid } from 'semantic-ui-react';
import { gotoFn, withRouter } from '../../helpers/routing';
import Details from '../environments/marketplace/Details';

// const isSecure = true;
const envType = 'V';
const offering = { type: 'secure-vetting' };

class SecureOutputsSelection extends React.Component {
  handleSuccess = () => {
    const onSuccess = this.props.onSuccess || _.noop;
    onSuccess();
    this.props.outputsSelection.clear();
    const goto = gotoFn(this);
    goto('/workspaces/V');
  };

  handleBack = () => {
    const goto = gotoFn(this);
    goto('/secure-outputs');
  };

  render() {
    // const so = this.props.outputsSelection.list;
    // console.log("selected outputs: " + JSON.stringify(so));
    return (
      <div className="p3 animated fadeIn">
        <Grid className="mt0">
          <Grid.Column width={16}>
            <Segment raised className="mt3">
              <div className="mb3 flex">
                <Header as="h3" className="color-grey mt1 mb0 flex-auto">
                  <Icon name="file archive" className="align-top" />
                  <Header.Content className="left-align">Secure Outputs</Header.Content>
                </Header>
              </div>
              <Segment className="pr3 pl3 pb3 pt0">
                <Header as="h3" textAlign="left" className="mt0" color="grey">
                  Set up a SECURE vetting resource for me
                </Header>
                <Details
                  envType={envType}
                  offering={offering}
                  outputs={this.props.outputsSelection.list}
                  onSuccess={this.handleSuccess}
                  onBack={this.handleBack}
                  isSecure
                />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default inject('outputsSelection')(withRouter(observer(SecureOutputsSelection)));
