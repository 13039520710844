/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';
import TagSet from '../tag-sets/TagSet';
import { StudyFilesStore } from './StudyFilesStore';
import { StudyPermissionsStore } from './StudyPermissionsStore';
import { StudyPublishRequestsStore } from './StudyPublishRequestsStore';
import { StudyCollectionsStore } from './StudyCollectionsStore';
import { displayError } from '../../helpers/notification';
import { requestStudyAccess, deleteStudyData, archiveStudyData, getStudyCollections } from '../../helpers/api';

// ==================================================================
// Study
// ==================================================================
const CREATED = 'created';
const DELETED = 'deleted';
const ARCHIVED = 'archived';
// const encode = (username, ns) => `${ns}/${username}`;

// edited by 2359 - added isUpload and isMetaData
// edit 2359 - add initial support for data collections
const Study = types
  .model('Study', {
    id: types.identifier,
    rev: types.maybe(types.number),
    name: '',
    category: '',
    projectId: '',
    access: types.maybe(types.string),
    isUpload: types.maybeNull(types.boolean),
    isMetaData: types.maybeNull(types.boolean),
    stagingResources: types.optional(types.array(types.model({ arn: types.string })), []),
    resources: types.optional(types.array(types.model({ arn: types.string })), []),
    description: types.maybeNull(types.string),
    uploadLocationEnabled: false,
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    filesStore: types.maybe(StudyFilesStore),
    permissionsStore: types.maybe(StudyPermissionsStore),
    tags: types.optional(types.array(TagSet), []),
    status: types.maybe(types.enumeration('status', [CREATED, DELETED, ARCHIVED])),
    isSecure: types.maybe(types.boolean),
    isOutput: types.optional(types.boolean, false),
    isSharing: types.optional(types.boolean, false),
    inUse: types.optional(types.boolean, false),
    inPending: types.optional(types.boolean, false),
    available: types.optional(types.boolean, true),
    publishRequestsStore: types.maybe(StudyPublishRequestsStore),
    fsxStorageSize: types.optional(types.number, 1800),
    collections: types.optional(
      types.array(
        types.union(
          types.model({
            collectionName: types.string,
            collectionId: types.string,
            description: types.optional(types.string, ''),
          }),
          types.string,
        ),
      ),
      [],
    ),
    collectionsStore: types.maybe(StudyCollectionsStore),
  })
  .actions(self => ({
    setStudy(rawStudy) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawStudy);
    },

    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = StudyFilesStore.create({ studyId: self.id });
      }
      return self.filesStore;
    },

    getPermissionsStore() {
      if (!self.permissionsStore) {
        self.permissionsStore = StudyPermissionsStore.create({ studyId: self.id });
      }
      return self.permissionsStore;
    },

    getPublishRequestsStore() {
      if (!self.publishRequestsStore) {
        self.publishRequestsStore = StudyPublishRequestsStore.create({ studyId: self.id });
      }
      return self.publishRequestsStore;
    },

    getCollectionsStore(permission) {
      if (!self.collectionsStore) {
        self.collectionsStore = StudyCollectionsStore.create({ studyId: self.id, permission });
      }
      return self.collectionsStore;
    },

    addTag: tag => {
      self.tags.push(tag);
    },
    removeTagAtIndex: index => {
      self.tags.splice(index, 1);
    },
    updateAccess(access) {
      self.access = access;
    },
    async requestAccess() {
      try {
        await requestStudyAccess(self.id);
        self.updateAccess(Study.ACCESS_REQUESTED);
      } catch (e) {
        displayError(`Encountered a problem trying request access to Study '${self.name}'`, e);
      }
    },

    setStatus(status) {
      self.status = status;
    },

    setAvailable(available) {
      self.available = available;
    },

    async deleteData() {
      await deleteStudyData(self.id);
      self.setStatus(DELETED);
      if (self.filesStore)
        // API studies do not have filesStore
        self.filesStore.empty();
    },

    async archiveData() {
      await archiveStudyData(self.id);
      self.setStatus(ARCHIVED);
      self.filesStore.empty();
    },

    updateDataCollections(collections) {
      self.dataCollections = collections;
    },

    async getDataCollections() {
      try {
        const result = await getStudyCollections(self.id);
        this.updateDataCollections(result.collections);
      } catch (e) {
        // displayError(`Encountered a problem trying to get the collections of Study '${self.name}'`);
        displayError(e);
      }
    },
  }))

  .views(self => ({
    noAccess() {
      return !self.access || self.access === Study.NO_ACCESS;
    },
    accessRequested() {
      return self.access === Study.ACCESS_REQUESTED;
    },
    adminAccess() {
      return self.access === Study.ADMIN;
    },
    readAccess() {
      return self.access === Study.READ_ONLY;
    },
    uploadAvailable() {
      return self.uploadLocationEnabled && self.adminAccess() && self.dataAvailable();
    },
    dataAvailable() {
      return self.status !== DELETED && self.status !== ARCHIVED;
    },
    get archived() {
      return self.status === ARCHIVED;
    },
    get deleted() {
      return self.status === DELETED;
    },
    isAvailable() {
      return self.available;
    },
    isAccessible() {
      return (
        self.access &&
        (self.access === Study.ADMIN || self.access === Study.READ_ONLY || self.access === Study.READ_ONLY_COLLECTIONS)
      );
    },
    outputAvailable() {
      return !self.inUse && !self.inPending;
    },
    secureAccess() {
      return self.isSecure;
    },
    collectionAccess() {
      return self.access === Study.READ_ONLY_COLLECTIONS;
    },
  }));

Study.NO_ACCESS = 'noaccess';
Study.READ_ONLY = 'readonly';
Study.READ_ONLY_COLLECTIONS = 'readonlycollections';
Study.ADMIN = 'admin';
Study.ACCESS_REQUESTED = 'requested';

export { Study }; // eslint-disable-line import/prefer-default-export
