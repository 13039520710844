/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Container, Header, Icon, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { isStoreError, isStoreLoading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import SimpleTable from '../helpers/SimpleTable';
import { withRouter } from '../../helpers/routing';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';

class IndexesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getIndexesStore() {
    const store = this.props.indexesStore;
    store.load();
    return store;
  }

  getIndexes() {
    const store = this.getIndexesStore();
    return store.list;
  }

  renderMain() {
    const indexesData = this.getIndexes();
    // const pageSize = indexesData.length;
    // const pagination = indexesData.length > pageSize;
    return (
      <div>
        <SimpleTable
          headerRenderer={() => {
            return ['Cost Centre Name', 'AWS Account', 'Description'];
          }}
          rowRenderer={request => {
            return [
              request.id,
              this.props.awsAccountsStore.getNameForAccountId(request.awsAccountId),
              request.description,
            ];
          }}
          rowData={indexesData}
          keyMethod={request => request.id}
        />
        <br />
      </div>
    );
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleAddIndex = () => {
    this.goto('/indexes/add');
  };

  renderHeader() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="briefcase" className="align-top" />
          <Header.Content className="left-align">
            Cost Centres
            {this.renderTotal()}
          </Header.Content>
        </Header>
        <Button color="blue" size="medium" basic onClick={this.handleAddIndex}>
          Add Cost Centre
        </Button>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getIndexes().length}</Label>;
  }

  render() {
    const store = this.getIndexesStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderMain();
    }
    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {content}
      </Container>
    );
  }
}

export default inject('awsAccountsStore', 'indexesStore')(withRouter(observer(IndexesList)));
