/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal, Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getEditStudyForm } from '../../models/forms/EditStudy';
import { displayError } from '../../helpers/notification';
import { randomNum } from '../../helpers/utils';

import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';

// expected props
// - studiesStore
// - category
// - userStore (via injection)
// - projectsStore (via injection)
// - indexesStore (via injection)
class EditStudy extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.tagSet = observable({
      tags: [],
      addTag: ({ key, values }) => {
        runInAction(() => {
          this.tagSet.tags.push({ id: randomNum(), key, values });
        });
      },
      removeTagAtIndex: index => {
        runInAction(() => {
          this.tagSet.tags.splice(index, 1);
        });
      },
    });
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
      // this.storageType = undefined;
      // this.createUploadLocation = true;
      this.tagSet.tags = [];
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const studyValues = form.values();
      // const studyId = this.props.study.id;
      const rev = this.props.study.rev;

      // Edit study, clear form, and close modal
      const study = await this.props.studiesStore.editStudy(this.props.study.id, {
        ...studyValues,
        rev,
      });

      form.clear();
      this.cleanModal();
      if (this.props.onEdit && typeof this.props.onEdit === 'function') {
        this.props.onEdit(study);
      }
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Edit Study
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderEditStudyForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    return (
      <SemanticDropdown.Item
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Edit study
      </SemanticDropdown.Item>
    );
    /*
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Edit Study
      </Button>
    );
	*/
  }

  renderEditStudyForm() {
    const form = getEditStudyForm({
      id: { value: this.props.study.id },
      name: { value: this.props.study.name },
      description: { value: this.props.study.description },
    });

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('id')} />
              <Input field={form.$('name')} />
              <TextArea field={form.$('description')} />

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Edit Study
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(EditStudy));
