import { createForm } from '../../helpers/form';

const publishRequestAdminFields = {
  // General fields
  comments: {
    label: 'Comments',
    rules: 'required|string|between:1,3000',
  },
};

const getPublishRequestAdminForm = () => {
  return createForm(publishRequestAdminFields);
};

export { getPublishRequestAdminForm }; // eslint-disable-line import/prefer-default-export
