/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Progress, Header, Segment, Button, Container, Label } from 'semantic-ui-react';

import { isStoreLoading, isStoreReloading } from '../../../../models/BaseStore';

class Carryout extends React.Component {
  render() {
    const store = this.props.carryoutStore;
    const isBusy = isStoreLoading(store) || isStoreReloading(store);

    return isBusy ? this.renderProgress() : this.renderInstructions();
  }

  renderInstructions() {
    const num = value => (
      <Label circular className="mr2">
        {value}
      </Label>
    );

    return (
      <div className="animated fadeIn">
        <Button
          size="small"
          color="blue"
          floated="right"
          className="mt1 mr1"
          content="Download Token"
          icon="cloud download"
          labelPosition="right"
        />
        <Button
          size="small"
          color="blue"
          floated="right"
          className="mt1 mr2"
          content="Download Manifest File"
          icon="cloud download"
          labelPosition="right"
        />
        <Header as="h3" attached="top" color="grey">
          Usage Instructions
        </Header>
        <Segment attached>
          <div className="flex">
            <div className="pl2">
              <Container fluid text textAlign="left">
                <div className="flex mt2">
                  <div>{num(1)}</div>
                  <div className="flex-auto">
                    Download the manifest file and the token file. The token file should be considered a secret, it
                    should not be shared with others, and it should be protected.
                  </div>
                </div>
                <div className="flex mt2">
                  <div>{num(2)}</div>
                  <div className="flex-auto">
                    In your compute instance, such as your own EC2 instance, install <b>fusera</b> (an open source
                    project by MITRE).
                    <pre>$ fusera --ngc &lt;token file&gt; --acc-file &lt;manifest file&gt; fusemnt</pre>
                  </div>
                </div>
                <div className="flex mt2">
                  <div>{num(3)}</div>
                  <div className="flex-auto">
                    You can access the files as if they are local to your file system.
                    <pre>
                      $ cd fusemnt <br />$ ls
                    </pre>
                  </div>
                </div>
              </Container>
            </div>
            <div className="flex-auto">&nbsp;</div>
          </div>
          <div className="float-right">
            <Button
              className="mr1"
              attached="right"
              size="small"
              content="Send instructions via email"
              icon="mail outline"
              labelPosition="right"
            />
            <Button
              attached="right"
              size="small"
              content="Copy instructions to clipboard"
              icon="copy"
              labelPosition="right"
            />
          </div>
          <div style={{ clear: 'both' }} />
        </Segment>
      </div>
    );
  }

  renderProgress() {
    return (
      <Progress percent={100} active color="blue">
        <span className="color-grey">Preparing access information</span>
      </Progress>
    );
  }
}

export default inject('carryoutStore')(observer(Carryout));
