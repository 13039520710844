/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Secure Output Permissions
// ==================================================================
const SecureOutputPermissions = types
  .model('SecureOutputPermissions', {
    id: types.identifier,
    pendingAdmins: types.array(UserIdentifier),
    approvedAdmins: types.array(UserIdentifier),
    rejectedAdmins: types.array(UserIdentifier),
    createdAt: '',
    createdBy: UserIdentifier,
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
  })
  .views(_self => ({
    get userTypes() {
      return ['admin', 'readonly'];
    },
  }));

export { SecureOutputPermissions };
