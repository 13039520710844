/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';
import TagSet from '../tag-sets/TagSet';
import { SecureOutputPermissionsStore } from './SecureOutputPermissionsStore';
import { SecureOutputEgressTasksStore } from './SecureOutputEgressTasksStore';
import { SecureOutputFilesStore } from './SecureOutputFilesStore';

// ==================================================================
// Secure Output
// ==================================================================
const PENDING_OWNER = 'pendingOwner';
const OWNER_APPROVED = 'ownerApproved';
const OWNER_REJECTED = 'ownerRejected';
const PENDING_ME = 'pendingMe';
const MY_APPROVED = 'myApproved';
const MY_REJECTED = 'myRejected';

const SecureOutput = types
  .model('SecureOutput', {
    id: types.identifier,
    parentStudy: types.array(types.string),
    rev: types.maybe(types.number),
    name: '',
    projectId: '',
    inUse: types.maybeNull(types.boolean),
    readyForEgress: types.maybeNull(types.boolean),
    egressRequested: types.maybeNull(types.boolean),
    dataDeleted: types.maybeNull(types.string),
    approved: types.maybeNull(types.boolean),
    rejected: types.maybeNull(types.boolean),
    requested: types.maybeNull(types.boolean),
    created: types.maybeNull(types.boolean),
    description: types.maybeNull(types.string),
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    permissionsStore: types.maybe(SecureOutputPermissionsStore),
    egressTasksStore: types.maybe(SecureOutputEgressTasksStore),
    tags: types.optional(types.array(TagSet), []),
    status: types.maybe(
      types.enumeration('status', [
        PENDING_OWNER,
        OWNER_APPROVED,
        OWNER_REJECTED,
        PENDING_ME,
        MY_APPROVED,
        MY_REJECTED,
      ]),
    ),
    inReview: types.array(types.string),
    pending: types.maybeNull(types.boolean),
    filesStore: types.maybe(SecureOutputFilesStore),
  })
  .actions(self => ({
    setSecureOutput(rawSecureOutput) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawSecureOutput);
    },

    getPermissionsStore() {
      if (!self.permissionsStore) {
        self.permissionsStore = SecureOutputPermissionsStore.create({ secureOutputId: self.id });
      }
      return self.permissionsStore;
    },

    getEgressTasksStore() {
      if (!self.egressTasksStore) {
        self.egressTasksStore = SecureOutputEgressTasksStore.create({ secureOutputId: self.id });
      }
      return self.egressTasksStore;
    },
    updateStatus(status) {
      self.status = status;
    },
    setStatus(status) {
      self.status = status;
    },

    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = SecureOutputFilesStore.create({ secureOutputId: self.id });
      }
      return self.filesStore;
    },
  }))

  .views(self => ({
    isInUse() {
      if (self.inUse === null) {
        return false;
      }
      return self.inUse;
    },
    isReadyForEgress() {
      if (self.readyForEgress === null) {
        return false;
      }
      return self.readyForEgress;
    },
    isEgressRequested() {
      if (self.egressRequested === null) {
        return false;
      }
      return self.egressRequested;
    },
    isDataDeleted() {
      if (self.dataDeleted === 'false') {
        return false;
      }
      return true;
    },
    isPendingMyApproval() {
      return self.status === 'pendingMe';
    },
    isApprovedByMe() {
      return self.status === 'myApproved';
    },
    isRejectedByMe() {
      return self.status === 'myRejected';
    },
    isOwnerApproved() {
      return self.status === 'ownerApproved';
    },
    isPendingOwner() {
      return self.status === 'pendingOwner' && self.readyForEgress;
    },
    isOwnerRejected() {
      return self.status === 'ownerRejected';
    },
    noAccess() {
      return self.isInUse() || self.isDataDeleted() || self.isOwnerRejected() || self.isRejectedByMe();
    },
    isInReview() {
      return typeof self.inReview !== 'undefined' ? self.inReview.length > 0 : false;
    },
    isPending() {
      if (self.pending === null) {
        return false;
      }
      return self.pending;
    },
  }));

SecureOutput.PENDING_OWNER = 'pendingOwner';
SecureOutput.OWNER_APPROVED = 'ownerApproved';
SecureOutput.OWNER_REJECTED = 'ownerRejected';
SecureOutput.PENDING_ME = 'pendingMe';
SecureOutput.MY_APPROVED = 'myApproved';
SecureOutput.MY_REJECTED = 'myRejected';

export { SecureOutput }; // eslint-disable-line import/prefer-default-export
