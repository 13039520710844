import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Label, Checkbox } from 'semantic-ui-react';

class StudyCollectionsList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.study = props.study;
    this.isAdmin = props.isAdmin;
  }

  handleCollectionSelection = collection => {
    this.props.handlePrefixSelection(this.study, collection);
  };

  render() {
    const collections = this.study.collections;

    return (
      <div>
        {collections.length > 0 ? (
          <h5>Collections:</h5> &&
          collections.map(prefix => (
            <Label>
              {!this.isAdmin && (
                <Checkbox
                  className="mr1"
                  onChange={() => this.handleCollectionSelection(prefix.collectionId)}
                  checked={this.props.filesSelection.hasPrefix(this.study.id, prefix.collectionId)}
                />
              )}
              {prefix.collectionName}
            </Label>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default inject('filesSelection')(observer(StudyCollectionsList));
