/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { inject } from 'mobx-react';
import { action } from 'mobx';
import { parse } from 'csv-parse';
import { Button, Header, Modal, Grid, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { displayError, displaySuccess } from '../../helpers/notification';

// added by 2359
// expected props
// - studyId
class UploadApiDataFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      isProcessing: false,
      modalOpen: false,
    };
  }

  handleFormSubmission = async () => {
    const { apiData, error } = this.state;
    if (error) {
      displayError(error);
      return;
    }
    if (!apiData.length) {
      displayError('Please upload a valid csv file.');
      return;
    }
    this.setState({ isProcessing: true });
    const { id, rev } = this.props.study;
    const rawApiData = JSON.stringify(apiData);
    try {
      // add catch for error from API
      const result = await this.props.studiesStore.updateStudyApiData({ id, rev, apiData: rawApiData });
      this.setState({ isProcessing: false });
      if (result) {
        this.setState({ modalOpen: false, apiData: [] });
        displaySuccess('File updated successfully.', 'Success!');
      } else {
        displayError('Something went wrong, please try again.', 'Error!');
      }
    } catch (err) {
      displayError(err);
    }
  };

  handleFiles = e => {
    this.setState({ error: false, apiData: [] });
    const reader = new FileReader();
    reader.onload = () => {
      // Use reader.result
      const data = reader.result;
      const apiData = [];
      try {
        parse(data, { delimiter: ',' }, (err, output) => {
          if (err) {
            this.setState({ error: err });
            displayError(err);
            return;
          }
          output.forEach(item => {
            if (
              /^[a-zA-Z0-9]+$/.test(item[0]) &&
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#,?&//=]*)/.test(
                item[1],
              )
            ) {
              apiData.push({ page: item[0], url: item[1] });
            } else {
              this.setState({ error: 'There is an error in syntax of the csv file.' });
              displayError('There is an error in syntax of the csv file.');
            }
          });
          this.setState({ apiData });
        });
      } catch (err) {
        displayError(err);
        this.setState({ error: err });
      }
    };
    if (/(.*?)\.(csv)$/.test(e.target.files[0].name)) {
      reader.readAsText(e.target.files[0]);
    } else {
      this.setState({ error: 'Please uplad a valid csv file.' });
      displayError('Please uplad a valid csv file.');
    }
  };

  render() {
    return (
      <Modal
        size="small"
        closeIcon
        trigger={this.renderTrigger()}
        open={this.state.modalOpen}
        onClose={action(() => {
          this.setState({ modalOpen: false, apiData: [] });
        })}
      >
        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="small" />
          </Dimmer>
          <div className="mt2 animated fadeIn">
            <Header as="h3" icon textAlign="left" className="mt3 ml3" color="grey">
              Upload API File
            </Header>
            <div className="mx3 animated fadeIn">
              <Grid>
                <Grid.Column width={10}>
                  <Grid.Row>
                    Note: Newly uploaded csv files will not effect any change on active workspaces. Workspaces launched
                    henceforth will reflect the updated csv data.
                  </Grid.Row>
                  <Grid.Row />
                  <Grid.Row>
                    <input accept=".csv" onChange={e => this.handleFiles(e)} type="file" />
                  </Grid.Row>
                  <Grid.Row />
                  {this.state.error && <Grid.Row className="error">&nbsp;&nbsp;{this.state.error}</Grid.Row>}
                </Grid.Column>
              </Grid>
              <Button
                onClick={this.handleFormSubmission}
                className="ml2 mb3"
                floated="right"
                color="blue"
                icon
                disabled={this.state.processing}
                type="submit"
              >
                Upload
              </Button>
            </div>
          </div>
        </Dimmer.Dimmable>
      </Modal>
    );
  }

  renderTrigger() {
    return (
      <Dropdown.Item
        onClick={() => {
          this.setState({ modalOpen: true });
        }}
      >
        Upload API Files
      </Dropdown.Item>
    );
  }
}

export default inject('studiesStore')(UploadApiDataFiles);
