/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Dimmer, Loader, Label, Button, Tab, Grid } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import { displaySuccess } from '../../helpers/notification';
import ErrorBox from '../helpers/ErrorBox';

// expected props
// - environmentsStore (via injection)
// - location (from react router)
class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      handleChange: action,
    });

    this.state = {
      maxWorkerNodes: 0,
      isProcessing: true,
    };
  }

  componentDidMount() {
    this.getSetting();
  }

  getSetting = async settingId => {
    const store = this.getStore();
    await store.load();
    const settings = [];
    const setting = _.find(store.settings.toJSON(), item => item.id === settingId);
    if (setting) this.setState({ [settingId]: setting.value, isProcessing: false });
    else if (settings.length === 0) {
      this.setState({ isProcessing: false });
    }
  };

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  onUpdateSetting = async () => {
    this.setState({ isProcessing: true });
    const store = this.getStore();
    const newSettings = [];
    _.map(_.keys(this.state), settingId => {
      if (settingId !== 'isProcessing') {
        const setting = _.find(store.settings.toJSON(), item => item.id === settingId);
        const newSetting = { ...setting, value: this.state[settingId] };
        newSettings.push(newSetting);
      }
    });
    await store.updateBatch(newSettings);
    this.setState({ isProcessing: false });
    displaySuccess('Success', 'Settings updated!');
  };

  handleChange = (settingId, e) => {
    this.setState({ [settingId]: e.target.value });
  };

  refreshCustomInstanceTypes = async () => {
    this.setState({ isProcessing: true });
    const store = this.getStore();
    await store.refreshCustomInstanceTypes();
    this.setState({ isProcessing: false });
    displaySuccess('Success', 'Custom instance types have been refreshed.');
  };

  refreshSagemakerInstanceTypes = async () => {
    this.setState({ isProcessing: true });
    const store = this.getStore();
    await store.refreshSagemakerInstanceTypes();
    this.setState({ isProcessing: false });
    displaySuccess('Success', 'Sagemaker instance types have been refreshed.');
  };	

  getStore() {
    return this.props.settingsStore;
  }

  render() {
    const isAdmin = this.props.userStore.user.isAdmin;
    if (isAdmin) {
      return this.renderSettings();
    }
    return <ErrorBox error="You are not authorized to perform this operation" />;
  }

  renderSettings() {
    const store = this.getStore();
    const list = store.settings;

    const categories = [{ label: 'Workspace', data: list ? list.toJSON() : {} }];
    const dataPanes = categories.map(category => {
      return {
        menuItem: category.label,
        render: () => {
          if (category.label === 'Workspace') {
            return (
              <Tab.Pane key={category.label}>
                {_.map(category.data, setting => {
                  return (
                    <div key={setting.name}>
                      <Label className="m2">{`${setting.name}: `}</Label>
                      <input
                        key={setting.id}
                        className="m2"
                        onChange={e => this.handleChange(setting.id, e)}
                        value={this.state[setting.id] || setting.value}
                      />
                    </div>
                  );
                })}
                <div>
                  <Label className="m2">Refresh Custom Instance Types</Label>
                  <Button icon color="green" onClick={this.refreshCustomInstanceTypes}>
                    <Icon name="refresh" />
                  </Button>
                </div>
		<div>
                  <Label className="m2">Refresh Sagemaker Instance Types</Label>
                  <Button icon color="green" onClick={this.refreshSagemakerInstanceTypes}>
                    <Icon name="refresh" />
                  </Button>
                </div>
                {list && (
                  <Button className="m2" color="blue" onClick={this.onUpdateSetting}>
                    Save
                  </Button>
                )}
                {!list && <div>There are no settings configured yet.</div>}
              </Tab.Pane>
            );
          }
          return (
            <Tab.Pane key={category.label}>
              <div>
                <Label className="m2">HTTP REST API Script</Label>
                <input type="file" className="m2" />
                <textarea type="textarea" className="m2" rows="15" cols="100" />
                <Button color="blue" className="m2">
                  Save
                </Button>
              </div>
            </Tab.Pane>
          );
        },
      };
    });

    return (
      <div className="p3 animated fadeIn">
        <Grid className="mt0">
          <Grid.Column width={16}>
            <div className="mt3">
              {this.renderTitle()}
              <div style={{ clear: 'both' }} />
            </div>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                Settings
              </Header>
              <Dimmer.Dimmable dimmed={this.state.isProcessing}>
                <Dimmer active={this.state.isProcessing} inverted>
                  <Loader size="big" />
                </Dimmer>
                <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
              </Dimmer.Dimmable>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No settings
        </Header>
      </Segment>
    );
  } */

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align">Administrator Settings</Header.Content>
        </Header>
      </div>
    );
  }

  /* renderMain() {
    const store = this.getStore();
    const list = store.settings;
    return <div>Admin Setting!</div>;
  } */
}

export default inject('userStore', 'settingsStore')(withRouter(observer(SettingsPage)));
