import React from 'react';
import { Button, Header as BaseHeader, Modal, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import { getEnvironmentExportAmiForm } from '../../models/forms/EnvironmentExportAmiForm';
import { displayError, displaySuccess } from '../../helpers/notification';

import TextArea from '../helpers/fields/TextArea';
import Input from '../helpers/fields/Input';
import Form from '../helpers/fields/Form';

class EnvironmentExportAmi extends React.Component {
  constructor(props) {
    super(props);  
	this.modalOpen = false;
    makeObservable(this, {
	  modalOpen: observable
    });		
	this.state = { isProcessing: false };
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  getStore() {
    return this.props.environmentsStore;
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
	  const amiValues = form.values();
      const store = this.getStore();
      await store.exportEnvironment(this.props.environment.id, amiValues);
      await store.doLoad();
      displaySuccess('Exporting AMI triggered successfully.', 'Success!');
      runInAction(() => {
        this.setState({ isProcessing: false });
      });

	  form.clear();
      this.cleanModal();
    } catch (error) {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Export as AMI
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderExportAmiForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      color: 'blue'
    };
    return (
	    <Label
          {...props}
          onClick={this.onClickAction}
        >
          Export as AMI
        </Label>

    );
  }

  onClickAction = event => {
    event.preventDefault();
    event.stopPropagation();

	runInAction(() => {
		this.modalOpen = true;
	})
  }

  renderExportAmiForm() {
    const form = getEnvironmentExportAmiForm();

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('name')} />
              <TextArea field={form.$('description')} />

              <Button className="mb3" floated="right" color="blue" icon disabled={processing} onClick={onSubmit}>
                Export as AMI
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }

}

export default inject('userStore')(observer(EnvironmentExportAmi));
