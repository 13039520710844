/**
 * Added chenjqp
 */

import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import InfoPopup from '../helpers/InfoPopup';

class SecureOutputsAbout extends React.Component {
  render() {
    const secureOutputHelpContent = (
      <Grid centered>
        <Grid.Column textAlign="center">
          <Header as="h4">What&apos;s this?</Header>
          <p>A secure output holds all derived data from secure studies.</p>
          <Header as="h4">How does it work?</Header>
          <p>
            The created/selected secure output during workspace creation will be mounted in your secure workspace with
            write access enabled. You can perform all your analysis within the secure workspace and write your output
            files into the mount point. Once the associated secure workspace is terminated, you may request to freeze
            and egress data from the secure output by clicking the &apos;Freeze and egress&apos; button. Owners of
            studies from which your data is derived from will be notified to vet the data in your secure output for
            sensitive information, and approve/reject your egress request. In the event you own all the secure studies,
            you will be able to immediately egress the data.
          </p>
          <Header as="h4">Egress request was approved, what&apos;s next?</Header>
          <p>
            You may copy the secure output data into your study. If you do not have an existing study, please create one
            in the &apos;Studies&apos; tab. From your study, you can choose to continue analysis by creating workspaces
            with the study.
          </p>
          <Header as="h4">What happens if I leave my secure output idle?</Header>
          <p>If a secure output has been left idle for 30 days, data will be automatically deleted.</p>
        </Grid.Column>
      </Grid>
    );

    return <InfoPopup message={secureOutputHelpContent} />;
  }
}

export default inject('userStore')(observer(SecureOutputsAbout));
