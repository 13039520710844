/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook selection
import _ from 'lodash';
import { types, applySnapshot } from 'mobx-state-tree';

const Notebook = types.model('Notebook', {
  id: types.identifier,
  name: '',
});

const NotebooksSelection = types
  .model('NotebooksSelection', {
    notebooks: types.optional(types.map(Notebook), {}),
  })
  .actions(self => ({
    setNotebook(file) {
      self.notebooks.set(file.id, file);
    },
    deleteNotebook(id) {
      self.notebooks.delete(id);
    },
    clear() {
      self.notebooks.clear();
    },
    setNotebooks(filesMapSnapshot) {
      applySnapshot(self.notebooks, filesMapSnapshot);
    },
  }))
  .views(self => ({
    hasNotebook(id) {
      return self.notebooks.has(id);
    },
    get empty() {
      return self.notebooks.size === 0;
    },
    get count() {
      return self.notebooks.size;
    },
    get notebooksCount() {
      const studyIdMap = {};
      self.notebooks.forEach(entry => {
        studyIdMap[entry.id] = true;
      });

      return _.size(studyIdMap);
    },
    get notebookNames() {
      const names = [];
      self.notebooks.forEach(entry => {
        names.push(entry.id);
      });

      return names;
    },
  }));

function registerModels(globals) {
  globals.notebooksSelection = NotebooksSelection.create({}, globals);
}

export { NotebooksSelection, registerModels };
