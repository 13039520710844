/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types, applySnapshot } from 'mobx-state-tree';

// TODO: Improve file model
// const File2 = types.model('File2', {
//   name: '',
//   size: types.optional(types.number, 0),
// });

// TODO this should have been named 'Run'
// edit 2359 - add initial support for data collections
const File = types.model('File', {
  id: types.identifier,
  name: '',
  description: '',
  accessStatus: '',
  category: '',
  isSecure: false,
  prefix: types.optional(types.array(types.string), []),
});

// TODO this should have been named 'RunsSelection'
// edit 2359 - add initial support for data collections
const FilesSelection = types
  .model('FilesSelection', {
    files: types.optional(types.map(File), {}),
  })
  .actions(self => ({
    setFile(file) {
      self.files.set(file.id, file);
    },
    deleteFile(id) {
      self.files.delete(id);
    },
    setPrefix(studyId, prefix) {
      let selectedfile;
      self.files.forEach(file => {
        if (file.id === studyId) {
          selectedfile = file;
        }
      });
      if (selectedfile) {
        selectedfile.prefix.push(prefix);
        self.files.set(selectedfile.id, selectedfile);
      }
    },
    deletePrefix(studyId, prefix) {
      let selectedfile;
      self.files.forEach(file => {
        if (file.id === studyId) {
          selectedfile = file;
        }
      });
      selectedfile.prefix = selectedfile.prefix.filter(prefixStr => prefixStr !== prefix);

      // if there are no prefixes left for the study, remove the study from selection
      if (selectedfile.prefix.length === 0) {
        self.files.delete(studyId);
      } else {
        self.files.set(selectedfile.id, selectedfile);
      }
    },
    clear() {
      self.files.clear();
    },
    setFiles(filesMapSnapshot) {
      applySnapshot(self.files, filesMapSnapshot);
    },
  }))
  .views(self => ({
    hasFile(id) {
      return self.files.has(id);
    },
    get empty() {
      return self.files.size === 0;
    },
    get count() {
      return self.files.size;
    },
    get studiesCount() {
      const studyIdMap = {};
      self.files.forEach(entry => {
        studyIdMap[entry.studyId] = true;
      });

      return _.size(studyIdMap);
    },
    hasSecureStudies() {
      let hasSecure = false;
      self.files.forEach(entry => {
        if (entry.isSecure && entry.isSecure === true) {
          hasSecure = true;
        }
      });
      return hasSecure;
    },
    hasApiStudies() {
      let hasApiStudies = false;
      self.files.forEach(entry => {
        if (entry.category === 'API Data') {
          hasApiStudies = true;
        }
      });
      return hasApiStudies;
    },
    studiesCountByStatus: state => {
      const studyIdMap = {};
      self.files.forEach(entry => {
        if (entry.accessStatus === state) studyIdMap[entry.studyId] = true;
      });

      return _.size(studyIdMap);
    },
    studiesCountByNotStatus: state => {
      const studyIdMap = {};
      self.files.forEach(entry => {
        if (entry.accessStatus !== state) studyIdMap[entry.studyId] = true;
      });

      return _.size(studyIdMap);
    },
    get fileNames() {
      const names = [];
      self.files.forEach(entry => {
        names.push(entry.id);
      });

      return names;
    },
    groupByStudy: () => {
      const studyIdMap = {};
      self.files.forEach(entry => {
        const values = studyIdMap[entry.studyId];
        if (_.isArray(values)) {
          values.push(entry);
        } else {
          studyIdMap[entry.studyId] = [entry];
        }
      });

      return studyIdMap;
    },
    groupNotApprovedByStudy: () => {
      const studyIdMap = {};
      self.files.forEach(entry => {
        if (entry.accessStatus === 'approved') return;
        const values = studyIdMap[entry.studyId];
        if (_.isArray(values)) {
          values.push(entry);
        } else {
          studyIdMap[entry.studyId] = [entry];
        }
      });

      return studyIdMap;
    },
    countByStatus: state => {
      let counter = 0;
      self.files.forEach(file => {
        if (file.accessStatus === state) counter += 1;
      });

      return counter;
    },
    hasPrefix(id, prefix) {
      if (!self.files.has(id)) {
        return false;
      }
      let selectedfile;
      self.files.forEach(file => {
        if (file.id === id) {
          selectedfile = file;
        }
      });
      if (selectedfile) {
        return selectedfile.prefix.toJSON().includes(prefix);
      }
      return false;
    },
    hasAnyPrefix(id) {
      let hasAnyPrefix = false;
      self.files.forEach(file => {
        if (file.id === id) {
          hasAnyPrefix = Object.prototype.hasOwnProperty.call(file, 'prefix');
        }
      });
      return hasAnyPrefix;
    },
    getFiles: () => {
      const names = [];
      self.files.forEach(entry => {
        names.push(entry);
      });

      return names;
    },
  }));

function registerModels(globals) {
  globals.filesSelection = FilesSelection.create({}, globals);
}

export { FilesSelection, registerModels };
