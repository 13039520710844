/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook entities
import { types, applySnapshot } from 'mobx-state-tree';
import { displayError } from '../../helpers/notification';
import { NotebookPermissionsStore } from './NotebookPermissionsStore';
import UserIdentifier from '../users/UserIdentifier';
import { requestNotebookAccess } from '../../helpers/api';
// ==================================================================
// Notebook
// ==================================================================

const Notebook = types
  .model('Notebook', {
    id: types.identifier,
    workspaceId: types.maybe(types.string),
    name: types.maybeNull(types.string),
    access: types.maybe(types.string),
    projectId: '',
    uploadLocationEnabled: types.maybeNull(types.boolean),
    createdAt: '',
    createdBy: types.maybeNull(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.maybeNull(UserIdentifier, {}),
    resources: types.maybeNull(types.array(types.model({ arn: types.string })), []),
    permissionsStore: types.maybe(NotebookPermissionsStore),
  })
  .actions(self => ({
    setNotebook(rawNotebook) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawNotebook);
    },
    getPermissionsStore() {
      if (!self.permissionsStore) {
        self.permissionsStore = NotebookPermissionsStore.create({ notebookId: self.id });
      }
      return self.permissionsStore;
    },
    updateAccess(access) {
      self.access = access;
    },
    async requestAccess() {
      try {
        await requestNotebookAccess(self.id);
        self.updateAccess(Notebook.ACCESS_REQUESTED);
      } catch (e) {
        displayError(`Encountered a problem trying request access to Study '${self.name}'`, e);
      }
    },
  }))
  .views(self => ({
    noAccess() {
      return !self.access || self.access === Notebook.NO_ACCESS;
    },
    accessRequested() {
      return self.access === Notebook.ACCESS_REQUESTED;
    },
    adminAccess() {
      return self.access === Notebook.ADMIN;
    },
    readAccess() {
      return self.access === Notebook.READ_ONLY;
    },
  }));

Notebook.NO_ACCESS = 'noaccess';
Notebook.READ_ONLY = 'readonly';
Notebook.ADMIN = 'admin';
Notebook.ACCESS_REQUESTED = 'requested';

export { Notebook }; // eslint-disable-line import/prefer-default-export
