import React from 'react';
import { inject, observer } from 'mobx-react';
import { runInAction, observable } from 'mobx';
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';
import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - study
// - studiesStore
// - userStudiesStore
// - userStore (via injection)
class EditOutputStudy extends React.Component {
  constructor(props) {
    super(props);
    this.componentStore = observable({
      showEditModal: false,
      showLoader: false,
      isStudyOwner: false,	    
    });
	  
    this.currUser = this.props.userStore.user;
  }

  componentDidMount() {
    const studiesStore = this.props.userStudiesStore;
    runInAction(async() => {
      const isOwner = await studiesStore.isStudyOwner(this.props.study.id, this.currUser.username, this.currUser.ns);	    
      this.componentStore.isStudyOwner = isOwner;
    });
  }

  render() {
    const study = this.props.study;
    const canShow = study.isOutput && this.componentStore.isStudyOwner;
	  
    return (
      <>
       { canShow && study.outputAvailable() && !study.isSharing && (
	<>
	  <Dimmer active={this.componentStore.showLoader}>
            <Loader />
          </Dimmer>
          <Dropdown.Item
            onClick={() => {
              runInAction(() => {
                this.componentStore.showEditModal = true;
              });
            }}
           >
             Freeze & Share Study
           </Dropdown.Item>

           {this.renderConfirmationModal()}
	</>       
       )}
      </>
    );

  }

  renderConfirmationModal() {
    const study = this.props.study;
    return (
      <ConfirmationModal
        open={this.componentStore.showEditModal}
        header="Update Output Study Sharing"
        message="The output study can only used as input study (read-only). Are you sure you want to freeze and enable sharing?"
        onConfirm={() => {
          this.toggleSharing(study);
        }}
        onCancel={() => {
          runInAction(() => {
            this.componentStore.showEditModal = false;
          });
        }}
      />
    );
  }

  async toggleSharing(study) {
    runInAction(() => {
      this.componentStore.showEditModal = false;
      this.componentStore.showLoader = true;
    });
    try {
	    
       const _updatedStudy = await this.props.studiesStore.editStudy(study.id, {
         isSharing: !study.isSharing,
         rev: study.rev,
         id: study.id,
       });
       displaySuccess('The study had been enabled for sharing.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });

  }
}

export default inject('userStore')(observer(EditOutputStudy));
