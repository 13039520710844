/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - listing of notebooks
import React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Segment, Label, Header } from 'semantic-ui-react';

import { niceNumber } from '../../helpers/utils';
import NotebookRow from './NotebookRow';

class NotebooksTab extends React.Component {
  constructor(props) {
    super(props);
    // allStudiesStores: observable,
    // useStudiesStore: action,
    makeObservable(this, {});
  }

  componentDidMount() {
    this.props.store.load();
  }

  render() {
    const content = this.renderContent();

    return content;
  }

  /* renderPagination(store) {
    const pages = store.numOfPages;
    if (pages < 2) return null;
    return (
      <Pagination
        className="mt1"
        activePage={store.activePage}
        totalPages={pages}
        siblingRange={0}
        boundaryRange={1}
        firstItem={null}
        lastItem={null}
        onPageChange={this.handlePageChange}
      />
    );
  } */

  renderContent() {
    return (
      <>
        {this.renderTotal({ count: this.props.store.total, label: 'Total Notebooks:' })}
        {this.props.store.list.map(notebook => (
          <Segment clearing raised key={notebook.id} className="mb3">
            <NotebookRow notebook={notebook} notebooksStore={this.props.store} />
          </Segment>
        ))}
      </>
    );
  }

  renderTotal({ count, label }) {
    const niceCount = niceNumber(count);

    return (
      <Header as="h2" color="grey" className="mt2 mb0 mr3">
        {label}{' '}
        <Label circular size="huge">
          {niceCount}
        </Label>
      </Header>
    );
  }
}

export default observer(NotebooksTab);
