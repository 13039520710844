import _ from 'lodash';
import { observer } from 'mobx-react';
import { Input } from 'semantic-ui-react';
import c from 'classnames';

import Header from './Header';
import Description from './Description';
import ErrorPointer from './ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - fluid (via props), default to true
// - disabled (via props), default to false
// - autoFocus (via props), default to false
const Component = observer(
  ({ field, fluid = true, disabled = false, className = 'mb4', autoFocus = false, onChange }) => {
    const { error = '', sync } = field;
    const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
    const isDisabled = field.disabled || disabled;
    const disabledClass = isDisabled ? 'disabled' : '';
    const errorClass = hasError ? 'error' : '';
    const attrs = {
      fluid,
      disabled: isDisabled,
      error: hasError,
      ..._.omit(field.bind(), ['label', 'onChange']),
      autoFocus,
      type: 'file',
      onChange: (e, _data) => {
        sync(e);
        if (onChange) onChange(e);
      },
    };

    return (
      <div className={c(className, errorClass, disabledClass)}>
        <Header field={field} />
        <Description field={field} />
        <Input type="file" className="field" {...attrs} />
        <ErrorPointer field={field} />
      </div>
    );
  },
);

export default Component;
