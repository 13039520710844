/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Button, Label, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';

// edited by 2359 - changed to allow remove tag only if isEditable
const TagsEditorLine = observer(({ tag, onEdit, onRemove, _admin, isEditable }) => {
  return (
    <Table.Row>
      <Table.Cell>{tag.key}</Table.Cell>
      <Table.Cell>
        {tag.values.map((v, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key,no-shadow
            <Label key={i}>{v}</Label>
          );
        })}
      </Table.Cell>
      {isEditable && (
        <Table.Cell>
          <Button
            onClick={e => {
              e.preventDefault();
              if (typeof onEdit === 'function') {
                onEdit(tag, tag.key, tag.values);
              }
            }}
          >
            Edit
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              if (typeof onRemove === 'function') {
                onRemove(tag);
              }
            }}
          >
            Remove
          </Button>
        </Table.Cell>
      )}
    </Table.Row>
  );
});
export default TagsEditorLine;
