import React from 'react';
import { Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';

import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - study
class UpgradeStudy extends React.Component {
  constructor(props) {
    super(props);
    this.permissionsStore = props.study.getPermissionsStore();
    this.publishRequestStore = props.study.getPublishRequestsStore();
    this.componentStore = observable({
      showUpgradeModal: false,
      showLoader: false,
    });
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    swallowError(this.publishRequestStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  async handleUpgradeStudy() {
    runInAction(() => {
      this.componentStore.showUpgradeModal = false;
      this.componentStore.showLoader = true;
    });
    try {
      // submit upgrade request
      await this.props.studiesStore.upgradeToSecure(this.props.study.id);

      displaySuccess('The study and data associated has been marked for upgrade.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  renderModal() {
    const message = (
      <Modal.Description>
        <p>
          This will mark the study to be upgraded. Please note that:
          <ol>
            <li>All existing users will have their access permissions revoked</li>
            <li>All existing workspaces will have access to the study revoked</li>
            <li>All existing upload access for the study will be revoked</li>
          </ol>
          The study will not be available until the upgrading process is complete. Are you sure you want to proceed?
        </p>
      </Modal.Description>
    );
    return (
      <ConfirmationModal
        open={this.componentStore.showUpgradeModal}
        header="Upgrade Study"
        message={message}
        onConfirm={() => {
          this.handleUpgradeStudy();
        }}
        onCancel={() => {
          runInAction(() => {
            this.componentStore.showUpgradeModal = false;
          });
        }}
      />
    );
  }

  render() {
    if (!this.publishRequestStore.hasOngoing()) {
      return (
        <>
          <Dimmer active={this.componentStore.showLoader}>
            <Loader />
          </Dimmer>
          <Dropdown.Item
            onClick={() => {
              runInAction(() => {
                this.componentStore.showUpgradeModal = true;
              });
            }}
          >
            Upgrade Study
          </Dropdown.Item>

          {this.renderModal()}
        </>
      );
    }
    return null;
  }
}

export default observer(UpgradeStudy);
