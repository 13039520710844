/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { renderAppContainer, renderError, renderProgress } from './render-utils';
import App from './App';

import 'typeface-lato';
import './css/basscss-important.css';
import './css/semantic.min.css';
import './css/animate.css';
import 'toastr/build/toastr.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './css/index.css';

App({
  renderAppContainer,
  renderError,
  renderProgress,
});
