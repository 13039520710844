/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer } from 'mobx-react';

// edit 2359 - add support for EMR-hail
class EnvironmentConnectButton extends React.Component {
  getUrl = async environment => {
    switch (environment.instanceInfo.type) {
      case 'sagemaker': {
        const { AuthorizedUrl } = await environment.getEnvironmentNotebookUrl();
        return `${AuthorizedUrl}&view=lab`;
      }
      case 'emr':
        return environment.instanceInfo.JupyterUrl;
      case 'emr-hail':
        if (this.props.isSpark) {
          return environment.instanceInfo.SparkUrl;
        }
        return environment.instanceInfo.ZeppelinUrl;
      default:
        return '';
    }
  };

  handleConnectClick = async event => {
    event.preventDefault();
    event.stopPropagation();
    const newTab = window.open('about:blank', '_blank');

    const environment = this.props.environment;

    const url = await this.getUrl(environment);
    // Change to the notebook
    newTab.location = url;
    environment.setFetchingUrl(false);
  };

  render() {
    const { as: As, ...props } = this.props;
    return <As onClick={this.handleConnectClick} {...props} />;
  }
}

export default observer(EnvironmentConnectButton);
