/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */
import _ from 'lodash';
import React from 'react';
import { runInAction, observable, makeObservable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Table, Button, Dimmer, Loader, Segment, Pagination, Label, Checkbox } from 'semantic-ui-react';

import { formatBytes, swallowError, niceNumber } from '../../helpers/utils';
import { displayError, displaySuccess } from '../../helpers/notification';
import { isStoreError, isStoreLoading, isStoreReloading } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';
import ConfirmationModal from '../helpers/ConfirmationModal';
import DataCollectionModal from './DataCollectionModal';

// expected props
// - study
class StudyFilesTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      loading: computed,
      results: computed,
      parentPrefix: computed,
      handlePaginationChange: action,
      handlePrefix: action,
      handleTagChange: action,
    });

    // this.filesStore = props.filesStore;
    this.study = props.study;
    this.filesStore = props.study.getFilesStore();
    this.studyCollectionsStore = props.study.getCollectionsStore();
    this.stores = [this.filesStore, this.studyCollectionsStore];
    this.componentStore = observable({
      showDeleteModal: false,
      showArchiveModal: false,
      showLoader: false,
      showBatchTagModal: false,
    });
  }

  componentDidMount() {
    this.filesStore.load({ page: 1, reload: true });
    this.studyCollectionsStore.load();
    // this.filesStore.startHeartbeat();
  }

  componentWillUnmount() {
    // this.filesStore.stopHeartbeat();
  }

  get loading() {
    return isStoreLoading(this.stores) || isStoreReloading(this.stores);
  }

  get results() {
    return this.filesStore.visibleRecords || [];
  }

  get parentPrefix() {
    return this.filesStore.parentPrefix;
  }

  getFilesStore() {
    return this.filesStore;
  }

  getCollectionsStore() {
    return this.studyCollectionsStore;
  }

  handlePaginationChange = (e, { activePage }) => {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading
    const studyId = this.filesStore.studyId;
    const selectedPrefix = this.filesStore.selectedPrefix;
    swallowError(
      this.filesStore.load({
        page: activePage,
        reload: false, // don't reload
        studyId,
        selectedPrefix,
      }),
    );
  };

  handlePrefix(prefix, parentCollections) {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading

    const studyId = this.filesStore.studyId;
    const selectedPrefix = typeof prefix !== 'undefined' ? prefix : this.filesStore.selectedPrefix;
    swallowError(
      this.filesStore.load({
        page: 1,
        reload: true,
        studyId,
        selectedPrefix,
        parentCollections,
      }),
    );
  }

  async handleDeleteData() {
    runInAction(() => {
      this.componentStore.showDeleteModal = false;
      this.componentStore.showLoader = true;
    });
    try {
      await this.props.study.deleteData();
      displaySuccess('The data associated with this study has been marked for deletion.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  async handleArchiveData() {
    runInAction(() => {
      this.componentStore.showArchiveModal = false;
      this.componentStore.showLoader = true;
    });
    try {
      await this.props.study.archiveData();
      displaySuccess('The data associated with this study has been marked for archive.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  async handleTagChange(e, data, collectionId, fullPath) {
    // console.log(`Full path: ${fullPath}`);
    runInAction(() => {
      this.componentStore.showLoader = true;
    });
    try {
      if (data.checked) {
        await this.studyCollectionsStore.addToCollection(collectionId, fullPath, 'dir');
      } else {
        await this.studyCollectionsStore.removeFromCollection(collectionId, fullPath, 'dir');
      }
      await this.filesStore.load({ reload: true });
      displaySuccess('Collection updated successfully!');
    } catch (err) {
      displayError(err);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  // Arrow function to pass the function to DataCollectionModal
  handleBatchUpdate = async (fullPaths, collectionIds) => {
    try {
      await this.studyCollectionsStore.addBatchToCollections(fullPaths, collectionIds, 'dir');

      const studyId = this.filesStore.studyId;
      const selectedPrefix = this.filesStore.selectedPrefix;
      displaySuccess('Tags added successfully!');
      swallowError(
        this.filesStore.load({
          page: 1,
          reload: true,
          studyId,
          selectedPrefix,
        }),
      );
    } catch (err) {
      displayError(err);
    }
  };

  /* handleSearchSubmit = form => {
    const { queryType, query } = form.values();
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading
    const studyId = this.filesStore.studyId;
    const selectedPrefix = '';
    swallowError(
      this.filesStore.load({
        page: 1,
        reload: true,
        studyId,
        selectedPrefix,
        queryType,
        query,
      }),
    );
  }; */

  getDisplay() {
    if (isStoreError(this.filesStore)) {
      return <ErrorBox error={this.filesStore.error} />;
    }
    if (isStoreLoading(this.filesStore)) {
      return <BasicProgressPlaceholder segmentCount={1} />;
    }
    if (this.props.study.deleted) {
      return (
        <Segment textAlign="center" className="mt4" secondary>
          The data associated with this study has been deleted.
        </Segment>
      );
    }
    if (this.props.study.archived) {
      return (
        <Segment textAlign="center" className="mt4" secondary>
          The data associated with this study has been archived.
        </Segment>
      );
    }
    return this.renderTable();
  }

  render() {
    return (
      <>
        {/* <Dimmer active={this.componentStore.showLoader}>
          <Loader />
        </Dimmer> */}
        <Header as="h2" className="ml1" floated="left">
          Files
        </Header>
        {this.getDisplay()}
        {this.renderConfirmationModals()}
      </>
    );
  }

  renderPagination() {
    const currentPage = this.filesStore.currentPage;
    const totalPages = this.filesStore.totalPages;
    if (totalPages < 2) return <div className="mb2" />;

    return (
      <Pagination
        activePage={currentPage}
        onPageChange={this.handlePaginationChange}
        totalPages={totalPages}
        floated="right"
      />
    );
  }

  renderTotal() {
    const count = this.filesStore.total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderTable() {
    const isAdminAccess = this.props.study.adminAccess();
    return (
      <div className="mt4">
        <Segment.Group>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
          <Segment clearing>
            {this.renderTotal()}
            {/* this.renderSearchBar() */}
            {/* this.renderBreadcrumb() */}
            <div className="flex justify-end">
              {isAdminAccess &&
                ((this.study.isOutput && this.study.isSharing) || !this.study.isOutput) &&
                this.renderSelectionButtons()}
              {this.renderBackButton()}
            </div>
            {/* this.renderContent() */}
            {this.renderContent()}
          </Segment>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  /* renderSearchBar() {
    const form = getSearchStudyFilesForm({
      query: { value: this.filesStore.query },
      queryType: { value: this.filesStore.queryType },
    });
    const queryTypes = getOptionsFromRules(form.$('queryType').rules);
    return (
      <>
        <Form form={form} onSuccess={this.handleSearchSubmit} onError={() => console.log('Error!')}>
          {({ _onSubmit }) => {
            return (
              <>
                <Input field={form.$('query')} />
                <DropdownHelper field={form.$('queryType')} selection options={queryTypes} />
                <Button type="submit">Search</Button>
              </>
            );
          }}
        </Form>
        <Button
          onClick={() => {
            this.filesStore.clearSearch();
            swallowError(
              this.filesStore.load({
                page: 1,
                reload: true,
                studyId: this.filesStore.studyId,
                selectedPrefix: '',
              }),
            );
          }}
          disabled={!this.filesStore.isSearchOn}
        >
          Clear Search
        </Button>
      </>
    );
  } */

  /* renderBreadcrumb() {
    const isSearchOn = this.filesStore.isSearchOn;
    const selectedPrefix = this.filesStore.selectedPrefix;
    const levels = selectedPrefix.split('/');
    // To get rid of the last empty string in levels array
    levels.pop();
    return (
      <Breadcrumb>
        {isSearchOn && (
          <>
            <Breadcrumb.Section>Search Result</Breadcrumb.Section>
            <Breadcrumb.Divider />
          </>
        )}
        {levels.map((level, i, { length }) => {
          if (i + 1 === length) {
            return <Breadcrumb.Section active>{level}</Breadcrumb.Section>;
          }
          return (
            <>
              <Breadcrumb.Section>{level}</Breadcrumb.Section>
              <Breadcrumb.Divider />
            </>
          );
        })}
      </Breadcrumb>
    );
  } */

  renderBackButton() {
    const studyId = this.getFilesStore().studyId;
    if (this.parentPrefix !== studyId) {
      let backParent = '';
      const parts = this.parentPrefix.split('/');
      if (parts.length > 1) {
        parts.pop(parts.length);
        backParent = _.join(parts, '/');
      } else {
        backParent = '';
      }
      backParent += backParent !== '' ? '/' : '';
      return (
        <div>
          <Button
            floated="right"
            size="mini"
            compact
            color="blue"
            className="mb2"
            onClick={() => {
              this.handlePrefix(backParent, this.filesStore.parentCollections.slice(0, -1));
            }}
          >
            Back
          </Button>
        </div>
      );
    }
    return null;
  }

  renderSelectionButtons() {
    if (this.filesStore.isEmpty || !this.props.study.adminAccess()) {
      return null;
    }
    return (
      <div>
        {/* The button to view and add tag to the selected files */}
        <DataCollectionModal
          files={this.filesStore.selectedFiles}
          batchUpdate={this.handleBatchUpdate}
          clearAll={this.filesStore.unselectAllFiles}
          collections={this.getCollectionsStore().list}
        />
        <Button size="mini" compact color="blue" onClick={this.filesStore.unselectAllFiles}>
          Unselect All
        </Button>
      </div>
    );
  }

  renderContent() {
    if (this.filesStore.isEmpty) {
      return <div>No files uploaded</div>;
    }

    const collections = this.getCollectionsStore().list;
    const result = this.results;
    const filesStore = this.getFilesStore();
    const isAdminAccess = this.props.study.adminAccess();
    const renderRow = file => {
      if (file.fileType === 'file') {
        return (
          <Table.Row key={file.fileName}>
            {isAdminAccess && ((this.study.isOutput && this.study.isSharing) || !this.study.isOutput) && <Table.Cell />}
            <Table.Cell>{file.fileName}</Table.Cell>
            <Table.Cell>{formatBytes(file.size)}</Table.Cell>
            <Table.Cell>{file.lastModified.toISOString()}</Table.Cell>
            {_.map(collections, collection => (
              <Table.Cell>
                <Checkbox
                  slider
                  checked={
                    this.filesStore.parentCollections &&
                    this.filesStore.parentCollections.some(parent => parent.includes(collection.collectionName))
                  }
                  disabled
                />
              </Table.Cell>
            ))}
          </Table.Row>
        );
      }
      return (
        <Table.Row key={file.fileName}>
          {isAdminAccess && ((this.study.isOutput && this.study.isSharing) || !this.study.isOutput) && (
            <Table.Cell>
              <Checkbox
                checked={_.some(this.filesStore.selectedFiles, selectedFile => selectedFile.fullPath === file.fullPath)}
                onChange={() => this.filesStore.updateSelectedFiles(file)}
              />
            </Table.Cell>
          )}
          <Table.Cell>
            {' '}
            <a
              className="cursor-pointer"
              onClick={() => {
                const parts = this.parentPrefix.split('/');
                if (parts[0] === filesStore.studyId) {
                  parts.pop(0);
                }
                const fwdParent = _.join(parts, '/');
                const finalPrefix = fwdParent !== '' ? `${fwdParent}/${file.fileName}` : file.fileName;
                const newParentCollections =
                  this.filesStore.parentCollections && this.filesStore.parentCollections.length > 0
                    ? this.filesStore.parentCollections.slice(0)
                    : [['-']];
                newParentCollections.push(file.collections.length > 0 ? file.collections.slice(0) : ['-']);

                this.handlePrefix(finalPrefix, newParentCollections);
              }}
            >
              {file.fileName}
            </a>
          </Table.Cell>
          <Table.Cell>{this.filesStore.isSearchOn && file.fullPath}</Table.Cell>
          <Table.Cell />
          {_.map(collections, collection => (
            <Table.Cell>
              <Checkbox
                slider
                checked={
                  file.collections.includes(collection.collectionName) ||
                  (this.filesStore.parentCollections &&
                    this.filesStore.parentCollections.some(parent => parent.includes(collection.collectionName)))
                }
                disabled={
                  !isAdminAccess ||
                  !((this.study.isOutput && this.study.isSharing) || !this.study.isOutput) ||
                  this.filesStore.parentCollections.some(parent => parent.includes(collection.collectionName))
                }
                onChange={(e, data) => this.handleTagChange(e, data, collection.collectionId, file.fullPath)}
              />
            </Table.Cell>
          ))}
        </Table.Row>
      );
    };

    return (
      <Dimmer.Dimmable dimmed={this.componentStore.showLoader}>
        <Dimmer active={this.componentStore.showLoader}>
          <Loader />
        </Dimmer>
        <Table striped>
          <Table.Header>
            <Table.Row>
              {isAdminAccess && ((this.study.isOutput && this.study.isSharing) || !this.study.isOutput) && (
                <Table.HeaderCell rowSpan="2" />
              )}
              <Table.HeaderCell rowSpan="2">Name</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Size</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Last Modified</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" colSpan={collections.length}>
                Collections
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {_.map(collections, collection => (
                <Table.HeaderCell>{collection.collectionName}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>{_.map(result, file => renderRow(file))}</Table.Body>
        </Table>
      </Dimmer.Dimmable>
    );
  }

  /* renderActionButtons() {
    return (
      this.props.study.adminAccess() &&
      !this.filesStore.isEmpty && (
        <>
          <Button
            floated="right"
            size="mini"
            compact
            color="blue"
            onClick={() => {
              runInAction(() => {
                this.componentStore.showArchiveModal = true;
              });
            }}
          >
            Archive Data
          </Button>
          <Button
            floated="right"
            size="mini"
            compact
            color="blue"
            onClick={() => {
              runInAction(() => {
                this.componentStore.showDeleteModal = true;
              });
            }}
          >
            Delete Data
          </Button>
        </>
      )
    );
  } */

  renderConfirmationModals() {
    return (
      <>
        <ConfirmationModal
          open={this.componentStore.showDeleteModal}
          header="Delete Study Data"
          message="Are you sure you want to delete all of the data associated with this study?"
          onConfirm={() => {
            this.handleDeleteData();
          }}
          onCancel={() => {
            runInAction(() => {
              this.componentStore.showDeleteModal = false;
            });
          }}
        />
        <ConfirmationModal
          open={this.componentStore.showArchiveModal}
          header="Archive Study Data"
          message="Are you sure you want to archive all of the data associated with this study?"
          onConfirm={() => {
            this.handleArchiveData();
          }}
          onCancel={() => {
            runInAction(() => {
              this.componentStore.showArchiveModal = false;
            });
          }}
        />
      </>
    );
  }
}

export default observer(StudyFilesTable);
