/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Grid, Header, Tab } from 'semantic-ui-react';

import { withRouter } from '../../helpers/routing';
import EnvironmentsList from './EnvironmentsList';
import { EnvironmentsStore } from '../../models/environments/EnvironmentsStore';

const envTypes = {
  R: 'Research Workspace',
  V: 'Vetting Workspace',
};

const envTabs = {
  R: 0,
  V: 1,
};

export { envTypes, envTabs };

class EnvironmentPage extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.stores = {
      'Research Workspace': EnvironmentsStore.create({ envType: 'R' }),
      'Vetting Workspace': EnvironmentsStore.create({ envType: 'V' }),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const envtypes = [
      { label: 'Research Workspace', allowCreate: true, envType: envTypes.R },
      { label: 'Vetting Workspace', envType: envTypes.V },
    ];
    const dataPanes = envtypes.map(envtype => {
      const store = this.stores[envtype.label];
      return {
        menuItem: envtype.label,
        render: () => (
          <Tab.Pane key={envtype.label}>
            <EnvironmentsList store={store} allowCreate={envtype.allowCreate} envType={envtype.envType} />
          </Tab.Pane>
        ),
      };
    });
    return (
      <div className="p3 animated fadeIn">
        {/* <div dangerouslySetInnerHTML={{__html:store.csv}} /> */}

        <Grid className="mt0">
          <Grid.Column width={16}>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                Environments
              </Header>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default inject('userStore')(withRouter(observer(EnvironmentPage)));
