/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const updateUserConfigFormFields = {
  username: {
    label: 'Username',
    explain: ' ',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'First name of this user',
    explain: ' ',
    rules: 'required|string|between:1,100',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Last name of this user',
    explain: ' ',
    rules: 'required|string|between:1,100',
  },
  email: {
    label: 'Email',
    placeholder: 'email address',
    explain: ' ',
    rules: 'required|email|string',
  },
  instituteName: {
    label: 'Institute Name',
    placeholder: 'Genome Institute of Singapore',
	explain: ' ',
    rules: 'required|string|between:1,300',
  },  
  designation: {
    label: 'Designation',
    placeholder: 'Bioinformatics Specialist',
	explain: ' ',
    rules: 'required|string|between:1,150',
  },
  identityProviderName: {
    label: 'Identity Provider Name',
    explain: ' ',
  },
  projectId: {
    label: 'Project',
    explain: ' ',
  },
  userRole: {
    label: 'User Role',
    explain: ' ',
  },
  applyReason: {
    label: 'Reason for Applying',
    explain: ' ',
  },
  status: {
    label: 'User Status',
    explain: ' ',
  },
};

function getUpdateUserConfigFormFields() {
  return updateUserConfigFormFields;
}

function getUpdateUserConfigForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: updateUserConfigFormFields }, { plugins });
}

export { getUpdateUserConfigFormFields, getUpdateUserConfigForm };
