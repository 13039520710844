/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Segment, Label, Header, Pagination, Checkbox, Grid, Accordion, Icon } from 'semantic-ui-react';

import { niceNumber } from '../../helpers/utils';
import { isStoreLoading, isStoreError } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';
import CreateStudy from './CreateStudy';
import StudyRow from './StudyRow';

// expected props
// store (StudiesStore)
// category ('Organization' | 'Open Data)
// allowCreate
// edited by leeas - add uploadDirStore
class StudiesTab extends React.Component {
  showDeleted = false;
  showFilterSettings = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      showFilterSettings: observable,
      showDeleted: observable,
    });

    this.handleShowDeletedToggle = this.handleShowDeletedToggle.bind(this);
    this.handleShowFilterSettings = this.handleShowFilterSettings.bind(this);
  }

  componentDidMount() {
    this.props.store.load();
    this.props.uploadDirStore.load(this.props.uploadDirStore.unameWithNs);
  }

  handleShowDeletedToggle(_event) {
    runInAction(() => {
      this.showDeleted = !this.showDeleted;
    });
  }

  handleShowFilterSettings(_event) {
    runInAction(() => {
      this.showFilterSettings = !this.showFilterSettings;
    });
  }

  render() {
    let content;
    if (isStoreError(this.props.store)) {
      content = <ErrorBox error={this.props.store.error} />;
    } else if (isStoreLoading(this.props.store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderContent();
    }

    return content;
  }

  renderPagination(store) {
    const pages = store.numOfPages;
    if (pages < 2) return null;
    return (
      <Pagination
        className="mt1"
        activePage={store.activePage}
        totalPages={pages}
        siblingRange={0}
        boundaryRange={1}
        firstItem={null}
        lastItem={null}
        onPageChange={this.handlePageChange}
      />
    );
  }

  renderContent() {
    let list = this.props.store.list;
    if (!this.showDeleted) {
      list = list.filter(study => study.status !== 'deleted');
    }

    return (
      <>
        {this.props.allowCreate && (
          <CreateStudy
            category={this.props.category}
            studiesStore={this.props.store}
            disableSecure={!this.props.allowCreateSecure}
          />
        )}
        {this.renderTotal({ count: list.length, label: 'Total Studies:' })}
        {this.renderPagination(this.props.store)}
        <Accordion>
          <Accordion.Title active={this.showFilterSettings} onClick={this.handleShowFilterSettings}>
            <Icon name="dropdown" />
            Filter settings
          </Accordion.Title>
          <Accordion.Content active={this.showFilterSettings}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>Show deleted studies: </Grid.Column>
                <Grid.Column width={10}>
                  <Checkbox slider checked={this.showDeleted} onChange={this.handleShowDeletedToggle} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>
        <hr />
        {list.map(study => (
          <Segment clearing raised key={study.id} className="mb3">
            <StudyRow
              study={study}
              noPermission={this.props.noPermission}
              uploadDirStore={this.props.uploadDirStore}
              studiesStore={this.props.store}
            />
          </Segment>
        ))}
      </>
    );
  }

  renderTotal({ count, label }) {
    const niceCount = niceNumber(count);

    return (
      <Header as="h2" color="grey" className="mt2 mb0 mr3">
        {label}{' '}
        <Label circular size="huge">
          {niceCount}
        </Label>
      </Header>
    );
  }
}

export default observer(StudiesTab);
