// Idea from https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/storage/CookieStorage.ts
// and https://github.com/ryanwalters/amplify-auth-next-storage/blob/master/next-storage.js

import JsCookie from 'js-cookie';
import { makeObservable, action } from 'mobx';

class CookieStorage {
  constructor({ domain, path = '/', expires = 365, secure = true }) {
    if (!domain) {
      throw new Error('The domain of CookieStorage can not be undefined.');
    }

    this.domain = domain;
    this.path = path;
    this.expires = expires;
    this.secure = secure;

    makeObservable(this, {
      setItem: action, 
      getItem: action,	    
      removeItem: action,
      clear: action
    });

  }

  setItem(key, value) {
    JsCookie.set(key, value, this._getData());	  
  }	

  getItem(key) {
    const item = JsCookie.get(key);
    const val = item ?? null;
    return val;
  }

  removeItem(key) {  
    JsCookie.remove(key, this._getData());
  }
  
  clear() { 
    const cookie = JsCookie.get();
    Object.keys(cookie).map(key => this.removeItem(key));
    return {};	  
  }

  _getData() {
    return {
	path: this.path,
	expires: this.expires,
	domain: this.domain,
	secure: this.secure
    };
  }
}

export default CookieStorage;
