/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getSecureOutputEgressTasks, secureOutputEgressToStudy } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { SecureOutputEgressTask } from './SecureOutputEgressTask';

// ==================================================================
// StudyStore
// ==================================================================
const SecureOutputEgressTasksStore = BaseStore.named('SecureOutputEgressTasksStore')
  .props({
    secureOutputId: types.identifier,
    secureOutputEgressTasks: types.array(types.maybe(SecureOutputEgressTask)),
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const tasks = await getSecureOutputEgressTasks(self.secureOutputId);
        if (!self.secureOutputEgressTasks || !_.isEqual(self.secureOutputEgressTasks, tasks)) {
          self.runInAction(() => {
            self.secureOutputEgressTasks = tasks;
          });
        }
      },
      addTask(task) {
        const id = task.taskArn;

        if (!self.secureOutputEgressTasks) {
          self.runInAction(() => {
            self.secureOutputEgressTasks = [];
          });
        }

        const index = self.secureOutputEgressTasks.findIndex(egresstask => egresstask.taskArn === id);

        self.runInAction(() => {
          if (index < 0) {
            self.secureOutputEgressTasks.push(task);
          } else {
            self.secureOutputEgressTasks[index] = task;
          }
        });
        return self.secureOutputEgressTasks.find(egresstask => egresstask.taskArn === id);
      },
      async egressToStudy(secureOutput, secureOutputPrefix, destinationStudyId, destinationPrefix) {
        return secureOutputEgressToStudy(secureOutput, secureOutputPrefix, destinationStudyId, destinationPrefix);
      },
      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({
    get list() {
      return self.secureOutputEgressTasks;
    },
  }));

export { SecureOutputEgressTasksStore };
