/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, Form, Grid, Header, Image, Segment, Label, Input, Select } from 'semantic-ui-react';
import QRCode from 'qrcode.react';

import { displayError, displaySuccess } from '../helpers/notification';
import { branding } from '../helpers/settings';
import dataLakeImage from '../images/data-lake.gif';

// From https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/app/Layouts/LoginLayout.js
class Authenticate extends React.Component {
  user = null;
  username = '';
  password = '';
  loading = false;
  authenticationProviderError = '';
  usernameError = '';
  passwordError = '';
  authStage = 'SignedOut';
  secretKey = ''; // for QR code generation to associate software token
  totpSetup = ''; // for verification of the software token
  totpSignIn = ''; // for sign in TOTP
  resetPassword = ''; // for password reset during initial login
  resetPasswordConfirmation = ''; // for confirming reset password during initial login
  totpSetupError = '';
  totpSignInError = '';
  resetPasswordError = '';
  resetPasswordConfirmationError = '';
  forgotPasswordCode = ''; // for forgot password verification code

  constructor(props) {
    super(props);
    // secretKeyError: observable,
    makeObservable(this, {
      username: observable,
      password: observable,
      loading: observable,
      authenticationProviderError: observable,
      usernameError: observable,
      passwordError: observable,
      authStage: observable,
      secretKey: observable,
      totpSetup: observable,
      totpSignIn: observable,
      resetPassword: observable,
      resetPasswordConfirmation: observable,
      totpSetupError: observable,
      totpSignInError: observable,
      resetPasswordError: observable,
      resetPasswordConfirmationError: observable,
      forgotPasswordCode: observable,
    });

    runInAction(() => {
      this.expiredPassword = false;
      this.newUser = false;
    });
  }

  clearForm = action(() => {
    this.username = '';
    this.password = '';
  });

  handleAuthenticationProviderChange = action((_event, { value }) => {
    this.props.cognitoAuthentication.setSelectedAuthenticationProviderId(value);
  });

  handleChange = name =>
    action(event => {
      this[name] = event.target.value;
      if (name === 'username') this.usernameError = '';
      if (name === 'password') this.passwordError = '';
      // if (name === 'secretKey') this.secretKeyError = '';
      if (name === 'totpSetup') this.totpSetupError = '';
      if (name === 'totpSignIn') this.totpSignInError = '';
      if (name === 'resetPassword') this.resetPasswordError = '';
      if (name === 'resetPasswordConfirmation') this.resetPasswordConfirmationError = '';
    });

  handleLogin = action(event => {
    event.preventDefault();
    event.stopPropagation();

    this.authenticationProviderError = '';
    this.usernameError = '';
    this.passwordError = '';
    this.expiredPassword = false;
    const username = _.trim(this.username) || '';
    const password = this.password || '';

    let error = false;

    // const collectUserNamePassword = this.props.cognitoAuthentication.shouldCollectUserNamePassword;
    // Validate username and password fields only if the selected authentication provider requires
    // username and password to be submitted.
    // For example, in case of SAML we do not collect username/password and in that case,
    // we won't validate username/password. It will be the responsibility of the Identity Provider
    // Do we need to collect username/password or not is specified by the authentication provider configuration
    // via "credentialHandlingType" field.
    // if (collectUserNamePassword) {
    if (_.isEmpty(username)) {
      this.usernameError = 'username is required';
      error = true;
    }

    if (!_.isEmpty(username) && username.length < 3) {
      this.usernameError = 'username must be at least 3 characters long';
      error = true;
    }

    if (_.isEmpty(password)) {
      this.passwordError = 'password is required';
      error = true;
    }
    if (!_.isEmpty(password) && password.length < 4) {
      this.passwordError = 'password must be at least 4 characters long';
      error = true;
    }
    // }

    if (error) return;

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;

    authentication
      .login({ username, password })
      .then(user => this.handleChallenge(user))
      .catch(_err => {
        this.clearForm();
        displayError('Either the password is incorrect or the user does not exist');
      })
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  });

  handlePasswordReset = action(event => {
    event.preventDefault();
    event.stopPropagation();

    let error = false;

    if (_.isEmpty(this.resetPassword)) {
      this.resetPasswordError = 'No password entered!';
      error = true;
    }

    if (this.resetPassword !== this.resetPasswordConfirmation) {
      this.resetPasswordConfirmationError = "Passwords don't match!";
      error = true;
    }

    if (error) return;

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;
    this.newUser = true;

    authentication
      .resetPassword(this.user, this.resetPassword)
      .then(user => this.handleChallenge(user))
      .catch(err => displayError(err.message))
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  });

  handleTotpSetup = action(event => {
    event.preventDefault();
    event.stopPropagation();

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;

    authentication
      .validateTOTP(this.user, this.totpSetup)
      .then(user => this.handleChallenge(user))
      .catch(err => displayError(err.message))
      .finally(
        action(() => {
          this.authStage = 'SignedOut';
          this.loading = false;
        }),
      );
  });

  handleTotpSignIn = action(event => {
    event.preventDefault();
    event.stopPropagation();

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;

    // console.log("in TotpSignIn " + this.user.username);
    authentication
      .confirmSignIn(this.user, this.totpSignIn, this.newUser)
      .catch(err => {
        displayError(err.message);
        if (err.code === 'passwordExpired') {
          runInAction(() => {
            this.username = this.user.username;
            this.expiredPassword = true;
          });
          this.handleForgotPassword(event);
        }
      })
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  });

  handleChallenge = action(user => {
    this.user = user;

    const authentication = this.props.cognitoAuthentication;
    // console.log("challengeName: " + user.challengeName);
    if (this.expiredPassword) {
      // console.log("inside expiredPass");
      this.authStage = 'PasswordResetRequest';
      return;
    }

    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      this.authStage = 'ConfirmSignIn';
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.authStage = 'NewPassword';
    } else if (user.challengeName === 'MFA_SETUP') {
      // if MFA is to be setup, we need to first get the secret key for the user
      authentication
        .setupTOTP(user)
        .then(
          action(result => {
            this.secretKey = result;
          }),
        )
        .catch(err => displayError(err.message))
        .finally(
          action(() => {
            this.authStage = 'TOTPSetup';
            this.loading = false;
          }),
        );
    } else if (!user.challengeName) {
      if (this.newUser) {
        this.authStage = 'ConfirmSignIn';
      } else {
        authentication.nonMfaSignIn(user);
      }
    }
  });

  /* handleFederatedSignIn = action(event => {
    event.preventDefault();
    event.stopPropagation();

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;

    authentication
      .federatedSignIn()
      .catch(err => displayError(err.message))
      .finally(
        action(() => {
          this.loading = false;
        }),
      );
  }); */

  getAuthenticationProviderOptions = () => {
    const authenticationProviderPublicConfigsStore = this.props.authenticationProviderPublicConfigsStore;
    return authenticationProviderPublicConfigsStore.authenticationProviderOptions;
  };

  renderIdProviderChoice() {
    const authenticationProviderOptions = this.getAuthenticationProviderOptions();
    const selectedAuthenticationProviderId = this.props.cognitoAuthentication.selectedAuthenticationProviderId;

    // Display authenticationProviderOptions only if there are more than one to choose from
    // if there is only one authentication provider available then use that
    // if (authenticationProviderOptions && authenticationProviderOptions.length > 1) {
    return (
      <Form.Field error={!!this.usernameError} required>
        <Select
          placeholder="Select Authentication Provider"
          options={authenticationProviderOptions}
          defaultValue={selectedAuthenticationProviderId}
          onChange={this.handleAuthenticationProviderChange}
        />
        {this.authenticationProviderError && (
          <Label basic color="red" pointing className="float-left mb2">
            {this.authenticationProviderError}
          </Label>
        )}
      </Form.Field>
    );
    // }
    // return '';
  }

  render() {
    return (
      <div className="login-form animated fadeIn">
        <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            {this.authStage === 'SignedOut' && this.renderSignIn()}
            {this.authStage === 'NewPassword' && this.renderPasswordReset()}
            {this.authStage === 'TOTPSetup' && this.renderTotpSetup()}
            {this.authStage === 'ConfirmSignIn' && this.renderTotpSignIn()}
            {this.authStage === 'PasswordResetRequest' && this.renderForgotPasswordForm()}
            {this.authStage === 'PasswordResetCode' && this.renderForgotPasswordCodeForm()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderSignIn() {
    const error = !!(this.usernameError || this.passwordError || this.authenticationProviderError);
    // const isCognitoUserPool = this.props.cognitoAuthentication.useCognito();
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>

          {this.renderIdProviderChoice()}

          <Form.Field error={!!this.usernameError} required>
            <Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              value={this.username}
              onChange={this.handleChange('username')}
            />
            {this.usernameError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.usernameError}
              </Label>
            )}
          </Form.Field>

          <Form.Field error={!!this.passwordError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              value={this.password}
              type="password"
              onChange={this.handleChange('password')}
            />
            {this.passwordError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.passwordError}
              </Label>
            )}
          </Form.Field>

          <Button type="submit" basic fluid size="large" onClick={this.handleLogin}>
            Login
          </Button>
          <hr />
          {this.renderForgotPassword()}
          <br />
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScSGWH29899Nq-6vZq1KiE9D-9cdUZn1gdZQFWjE3AQlen0zw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Request account
          </a>
          <br />
          <a href="mailto:raptor_admin@gis.a-star.edu.sg" target="_blank" rel="noreferrer">
            Contact us
          </a>
          {/* isCognitoUserPool && (
                    <Button type="submit" basic fluid size="large" onClick={this.handleFederatedSignIn}>
                  Google
                </Button>
                ) */}
        </Segment>
      </Form>
    );
  }

  handleForgotPassword = action(event => {
    event.preventDefault();
    event.stopPropagation();

    this.authStage = 'PasswordResetRequest';
    this.loading = false;
  });

  handleForgotPasswordSubmit = action(event => {
    event.preventDefault();
    event.stopPropagation();

    const authentication = this.props.cognitoAuthentication;
    if (!authentication.isCognitoUserPool) {
      displayError('Password reset not available for this authentication provider!');
      action(() => {
        this.authStage = 'SignedOut';
        this.loading = false;
      });
      return;
    }
    this.loading = true;

    authentication
      .forgotPassword(this.username)
      .then(
        action(() => {
          this.authStage = 'PasswordResetCode';
          this.loading = false;
        }),
      )
      .catch(
        action(_err => {
          displayError('Unable to reset password! Please contact administrators!');
          this.clearForm();
          this.authStage = 'SignedOut';
          this.loading = false;
        }),
      );
  });

  handleForgotPasswordCodeSubmit = action(event => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.forgotPasswordCode) {
      this.forgotPasswordError = 'No verifiation code specified!';
      displayError(this.forgotPasswordError);
      return;
    }

    if (this.resetPassword !== this.resetPasswordConfirmation) {
      this.resetPasswordConfirmationError = "Passwords don't match!";
      displayError(this.resetPasswordConfirmationError);
      return;
    }

    const authentication = this.props.cognitoAuthentication;
    this.loading = true;

    authentication
      .forgotPasswordSubmit(this.username, this.forgotPasswordCode, this.resetPassword)
      .then(
        action(() => {
          this.authStage = 'SignedOut';
          this.loading = false;
          this.clearForm();
          displaySuccess('Password successfully reset!');
        }),
      )
      .catch(
        action(err => {
          this.loading = false;
          displayError(`Unable to reset password: ${err.message}`);
        }),
      );
  });

  renderForgotPassword() {
    return (
      <a href="" onClick={this.handleForgotPassword}>
        Forgot/Reset password
      </a>
    );
  }

  renderForgotPasswordForm() {
    const error = !!(this.resetPasswordConfirmationError || this.resetPasswordError);
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>
          Please select your authentication details:
          {this.renderIdProviderChoice()}
          <Form.Field error={!!this.usernameError} required>
            <Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              value={this.username}
              onChange={this.handleChange('username')}
            />
            {this.usernameError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.usernameError}
              </Label>
            )}
          </Form.Field>
          An email containing a confirmation code will be sent to the registered address for the user.
          <Button type="submit" basic fluid size="large" onClick={this.handleForgotPasswordSubmit}>
            Send code
          </Button>
        </Segment>
      </Form>
    );
  }

  renderForgotPasswordCodeForm() {
    const error = !!(this.resetPasswordConfirmationError || this.resetPasswordError);
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>
          Please note that passwords need to conform to the following:
          <ul>
            <li>Minimum 15 characters</li>
            <li>Require numbers</li>
            <li>Require special characters</li>
            <li>Require uppercase letters</li>
            <li>Require lowercase letters</li>
          </ul>
          <Form.Field error={!!this.forgotPasswordError} required>
            <Input
              fluid
              placeholder="Verification code"
              value={this.forgotPasswordCode}
              onChange={this.handleChange('forgotPasswordCode')}
            />
            {this.forgotPasswordError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.forgotPasswordError}
              </Label>
            )}
          </Form.Field>
          <Form.Field error={!!this.resetPasswordError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              value={this.resetPassword}
              type="password"
              onChange={this.handleChange('resetPassword')}
            />
            {this.resetPasswordError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.resetPasswordError}
              </Label>
            )}
          </Form.Field>
          <Form.Field error={!!this.resetPasswordConfirmationError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm Password"
              value={this.resetPasswordConfirmation}
              type="password"
              onChange={this.handleChange('resetPasswordConfirmation')}
            />
            {this.resetPasswordConfirmationError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.resetPasswordConfirmationError}
              </Label>
            )}
          </Form.Field>
          <Button type="submit" basic fluid size="large" onClick={this.handleForgotPasswordCodeSubmit}>
            Change Password
          </Button>
        </Segment>
      </Form>
    );
  }

  renderPasswordReset() {
    const error = !!(this.resetPasswordConfirmationError || this.resetPasswordError);
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>
          It appears you are logging in for the first time! You are required to reset your password:
          <Segment>
            <p>
              The new password must follow the following requirements:
              <ul>
                <li>Password complexity: Contains upper and lower case alphanumeric with symbols</li>
                <li>Password length: Minimum 15</li>
              </ul>
            </p>
          </Segment>
          <Form.Field error={!!this.resetPasswordError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              value={this.resetPassword}
              type="password"
              onChange={this.handleChange('resetPassword')}
            />
            {this.resetPasswordError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.resetPasswordError}
              </Label>
            )}
          </Form.Field>
          <Form.Field error={!!this.resetPasswordConfirmationError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm Password"
              value={this.resetPasswordConfirmation}
              type="password"
              onChange={this.handleChange('resetPasswordConfirmation')}
            />
            {this.resetPasswordConfirmationError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.resetPasswordConfirmationError}
              </Label>
            )}
          </Form.Field>
          <Button type="submit" basic fluid size="large" onClick={this.handlePasswordReset}>
            Reset password
          </Button>
        </Segment>
      </Form>
    );
  }

  renderTotpSetup() {
    const qrvalue = `otpauth://totp/AWSCognito:${this.username}?secret=${this.secretKey}&issuer=RAPTOR`;
    const error = !!this.totpSetupError;
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>
          <QRCode value={qrvalue} />
          <br />
          You have yet to set up your TOTP! Please scan the QR code in your Google authenticator application and enter
          the code generated.
          <Form.Field error={!!this.totpSetupError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Verification TOTP token"
              value={this.totpSetup}
              type="password"
              onChange={this.handleChange('totpSetup')}
            />
            {this.totpSetupError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.totpSetupError}
              </Label>
            )}
          </Form.Field>
          <Button type="submit" basic fluid size="large" onClick={this.handleTotpSetup}>
            Setup TOTP
          </Button>
        </Segment>
      </Form>
    );
  }

  renderTotpSignIn() {
    const error = !!this.totpSignInError;
    return (
      <Form
        error={error}
        size="large"
        loading={this.loading}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Segment stacked>
          <Image centered src={dataLakeImage} />
          <Header as="h3" textAlign="center">
            {branding.login.title}
            <Header.Subheader>{branding.login.subtitle}</Header.Subheader>
          </Header>
          Please enter the TOTP code from your authenticator application:
          <Form.Field error={!!this.codeError} required>
            <Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="TOTP SIGN IN"
              value={this.totpSignIn}
              type="password"
              onChange={this.handleChange('totpSignIn')}
            />
            {this.totpSignInError && (
              <Label basic color="red" pointing className="float-left mb2">
                {this.totpSignInError}
              </Label>
            )}
          </Form.Field>
          <Button type="submit" basic fluid size="large" onClick={this.handleTotpSignIn}>
            Confirm
          </Button>
        </Segment>
      </Form>
    );
  }
}

export default inject('cognitoAuthentication', 'authenticationProviderPublicConfigsStore')(observer(Authenticate));
