/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Transition } from 'react-transition-group';

// eslint-disable-next-line react/prefer-stateless-function
export default class TwoSlidingSections extends React.Component {
  render() {
    const section = parseInt(this.props.section, 10);
    const children = React.Children.toArray(this.props.children) || [];
    const section0 = children.length > 0 ? children[0] : null;
    const section1 = children.length > 1 ? children[1] : null;

    return (
      <div style={{ overflow: 'hidden', padding: '2px', width: '100%' }} className={this.props.className}>
        <div style={{ width: '200%' }} className="flex items-stretch">
          <Transition in={section === 0} timeout={1000} mountOnEnter unmountOnExit>
            {state => {
              let name = '';
              switch (state) {
                case 'entering':
                  name = 'slideInLeft';
                  break;
                case 'exiting':
                  name = 'slideOutLeft';
                  break;
                default:
                  break;
              }
              return <div className={`animated ${name} w-50`}>{section0}</div>;
            }}
          </Transition>
          <Transition in={section === 1} timeout={1000} mountOnEnter unmountOnExit>
            {state => {
              let name = '';
              switch (state) {
                case 'entering':
                  name = 'slideOutLeft';
                  break;
                case 'exiting':
                  name = 'slideInLeft';
                  break;
                default:
                  break;
              }
              return <div className={`animated ${name} w-50`}>{section1}</div>;
            }}
          </Transition>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
