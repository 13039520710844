import _ from 'lodash';
import { observer } from 'mobx-react';
import { Checkbox, Table } from 'semantic-ui-react';
import c from 'classnames';

import Header from '../helpers/fields/Header';
import Description from '../helpers/fields/Description';
import ErrorPointer from '../helpers/fields/ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - options (via props), an array of [ {text, value}, {text, value}, ...]
// - onChange (via props), (optional) if provided, it will be given (value, field)
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - selection (via props), default to false
// - fluid (via props), default to false
// - disabled (via props), default to false
// - clearable (via props), default to false
// - multiple (via props), default to false
// - search (via props), default to false
const Component = observer(
  ({
    field,
    selection = false,
    fluid = false,
    disabled = false,
    clearable = false,
    multiple = false,
    search = false,
    className = 'mb4',
    options = [],
    onChange,
  }) => {
    const { _id, _value, sync, placeholder, error = '', _extra = {} } = field;
    const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
    // const mergeOptions = [...((extra && extra.options) || []), ...options];
    const isDisabled = field.disabled || disabled;
    const disabledClass = isDisabled ? 'disabled' : '';
    const errorClass = hasError ? 'error' : '';
    const attrs = {
      onChange: (e, data = {}) => {
        let current = field.value.slice();
        if (current.includes(e.target.value)) {
          current = current.filter(curr => curr !== e.target.value);
        } else {
          current.push(e.target.value);
        }
        sync(current);
        field.validate({ showErrors: true });
        if (onChange) onChange(data.value, field);
      },
      placeholder,
      selection,
      clearable,
      multiple,
      search,
      fluid,
      disabled: isDisabled,
      error: hasError,
    };

    return (
      <div className={c(className, errorClass, disabledClass)}>
        <Header field={field} />
        <Description field={field} />
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {options.map(option => (
              <Table.Row key={option.id}>
                <Table.Cell>
                  <Checkbox
                    id={option.value}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                    {...attrs}
                  />
                </Table.Cell>
                <Table.Cell>{option.text}</Table.Cell>
                <Table.Cell>{option.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <ErrorPointer field={field} />
      </div>
    );
  },
);

export default Component;
