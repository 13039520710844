/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, action, runInAction, observable } from 'mobx';
import { Header, Icon, Segment, Button } from 'semantic-ui-react';

import { gotoFn, withRouter } from '../../../helpers/routing';
import { swallowError } from '../../../helpers/utils';
import { isStoreEmpty, isStoreNotEmpty } from '../../../models/BaseStore';
import Stores from '../../../models/Stores';
import ErrorBox from '../../helpers/ErrorBox';
import WorkflowCommonDraftCard from '../../workflow-common/drafts/WorkflowCommonDraftCard';
import ProgressPlaceHolder from '../../workflow-common/ProgressPlaceholder';
import CreateDraftWizard from './CreateWorkflowDraft';
import WorkflowTemplateCardTabs from '../../workflow-templates/WorkflowTemplateCardTabs';

// expected props
// - workflowDraftsStore (via injection)
// - stepTemplatesStore (via injection)
// - location (from react router)
class WorkflowDraftsList extends React.Component {
  showCreateDraftWizard = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      handleCreateDraftClick: action,
      handleCreateDraftCancel: action,
      handleEditDraft: action,
      showCreateDraftWizard: observable,
    });

    runInAction(() => {
      this.stores = new Stores([this.getStore(), this.props.stepTemplatesStore]);
    });
  }

  componentDidMount() {
    this.getStores().load();
    const store = this.getStore();
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  getStores() {
    return this.stores;
  }

  getStore() {
    return this.props.workflowDraftsStore;
  }

  handleCreateDraftClick() {
    this.showCreateDraftWizard = true;
  }

  handleCreateDraftCancel() {
    this.showCreateDraftWizard = false;
  }

  handleEditDraft = async draft => {
    const goto = gotoFn(this);
    goto(`/workflows/drafts/edit/${draft.id}`);
  };

  render() {
    const stores = this.getStores();
    const store = this.getStore();
    let content = null;

    if (stores.hasError) {
      content = <ErrorBox error={stores.error} className="p0" />;
    } else if (stores.loading) {
      content = <ProgressPlaceHolder />;
    } else if (stores.ready && isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (stores.ready && isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <div className="mb4">
        {this.renderTitle()}
        {this.renderWizard()}
        {content}
      </div>
    );
  }

  renderEmpty() {
    const show = this.showCreateDraftWizard;
    if (show) return null;
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="edit outline" />
          No workflow drafts
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    const disabled = this.showCreateDraftWizard;
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="edit outline" className="align-top" />
          <Header.Content className="left-align">Workflow Drafts</Header.Content>
        </Header>
        <div>
          <Button basic color="blue" disabled={disabled} onClick={() => this.handleCreateDraftClick()}>
            Create Draft
          </Button>
        </div>
      </div>
    );
  }

  renderWizard() {
    const show = this.showCreateDraftWizard;
    if (!show) return null;
    return <CreateDraftWizard onCancel={() => this.handleCreateDraftCancel()} />;
  }

  renderMain() {
    const store = this.getStore();
    const list = store.list;

    return (
      <div>
        {_.map(list, draft => (
          <Segment className="mb2" clearing key={draft.id}>
            <WorkflowCommonDraftCard
              draft={draft}
              draftsStore={store}
              onEdit={this.handleEditDraft}
              className="pt0 pl2 pr2 pb2"
            >
              {({ uiState, version }) => <WorkflowTemplateCardTabs template={version} uiState={uiState} />}
            </WorkflowCommonDraftCard>
          </Segment>
        ))}
      </div>
    );
  }
}

export default inject('workflowDraftsStore', 'stepTemplatesStore')(withRouter(observer(WorkflowDraftsList)));
