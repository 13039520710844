// added by leeas

/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';
import { getTime } from 'date-fns';
import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Study Upload Dir
// ==================================================================
const StudyUploadDir = types
  .model('StudyUploadDir', {
    unameWithNs: types.identifier,
    studyId: types.maybe(types.string),
    uploaddir: types.maybe(types.string),
    validtill: types.maybe(types.string),
    validttl: types.maybe(types.number),
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    rev: types.maybe(types.number),
    isUserStudyAdmin: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setStudyUploadDirSet(rawUploadDirSet) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawUploadDirSet);
    },
  }))
  .views(_self => ({
    get dirLocked() {
      const currTime = getTime(new Date()) / 1000;
      return _self.validttl && _self.validttl > currTime;
    },
  }));

export default StudyUploadDir;
