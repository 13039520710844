/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';

import AccessRequest from './AccessRequest';

// ==================================================================
// AccessRequest
// ==================================================================
const AccessRequests = types
  .model('AccessRequests', {
    id: types.identifier,
    requests: types.array(AccessRequest),
    collections: types.array(
      types.model({
        collectionName: types.string,
        collectionId: types.string,
        description: types.optional(types.string, ''),
      }),
    ),
  })
  .actions(self => ({
    setAccessRequest(rawAccessRequest) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawAccessRequest);
    },
  }))
  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // add view methods here
  }));

export default AccessRequests;
