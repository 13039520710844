/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';
import _ from 'lodash';
import { removeNulls } from '../../helpers/utils';

const User = types
  .model('User', {
    firstName: '',
    lastName: '',
    isAdmin: types.optional(types.boolean, false),
    username: '',
    ns: '',
    email: '',
    userType: '',
    authenticationProviderId: '', // Id of the authentication provider this user is authenticated against (such as internal, cognito auth provider id etc)
    identityProviderName: '', // Name of the identity provider this user belongs to (such as Identity Provider Id in cognito user pool in case of Federation etc)
    status: 'active',
    rev: 0,
    userRole: '',
    projectId: types.array(types.string, []),
    applyReason: '',
    instituteName: '',
    designation: '',
    acceptUserAgreement: types.optional(types.boolean, false),
    lastLogin: types.maybe(types.string, 'NIL'), 	  
  })
  .actions(self => ({
    runInAction(fn) {
      return fn();
    },
    setUser(rawUser) {
      removeNulls(rawUser);
      self.firstName = rawUser.firstName || self.firstName || '';
      self.lastName = rawUser.lastName || self.lastName || '';
      self.isAdmin = rawUser.isAdmin || self.isAdmin;
      self.isExternalUser = rawUser.isExternalUser || self.isExternalUser;
      self.username = rawUser.username || self.username;
      self.ns = rawUser.ns || self.ns;
      self.email = rawUser.email || self.email;
      self.instituteName = rawUser.instituteName || self.instituteName;
      self.designation = rawUser.designation || self.designation;
      self.authenticationProviderId = rawUser.authenticationProviderId || self.authenticationProviderId;
      self.identityProviderName = rawUser.identityProviderName || self.identityProviderName;
      self.status = rawUser.status || self.status || 'active';
      self.createdBy = rawUser.createdBy || self.createdBy;
      self.rev = rawUser.rev || self.rev || 0;
      self.userRole = rawUser.userRole || self.userRole;
      self.projectId = rawUser.projectId || self.projectId || [];
      self.encryptedCreds = rawUser.encryptedCreds || self.encryptedCreds;
      self.applyReason = rawUser.applyReason || self.applyReason || '';
      self.acceptUserAgreement = rawUser.acceptUserAgreement || self.acceptUserAgreement;	    
      // we don't update the other fields because they are being populated by a separate store
    },
  }))
  .views(self => ({
    get displayName() {
      return `${self.firstName}`;
    },

    get displayFullName() {
      return `${self.firstName} ${self.lastName}`;
    },

    get longDisplayName() {
      if (self.unknown) {
        return `${self.username}??`;
      }
      const fullName = `${self.firstName} ${self.lastName}`;
      if (self.email) {
        return `${fullName} (${self.email})`;
      }
      return fullName;
    },

    get unknown() {
      return !self.firstName && !self.lastName;
    },

    get isRootUser() {
      return _.toLower(self.userType) === 'root';
    },

    get isActive() {
      return _.toLower(self.status) === 'active';
    },

    get isSystem() {
      const identifier = self.identifier;
      return identifier.username === '_system_';
    },

    isSame({ username, ns }) {
      return self.username === username && self.ns === ns;
    },

    get id() {
      return self.identifierStr;
    },

    get identifier() {
      return { username: self.username, ns: self.ns };
    },

    get identifierStr() {
      return JSON.stringify(self.identifier);
    },

    get encodedIdentifierStr() {
      return `${self.ns}/${self.username}`;
    },

    get hasAcceptedAgreement() {
      return self.acceptUserAgreement === true;
    },

  }));

function getIdentifierObjFromId(identifierStr) {
  return JSON.parse(identifierStr);
}

export { User, getIdentifierObjFromId };
