import { Button, Modal } from 'semantic-ui-react';

function EnvironmentPrompt({ form, isPromptOpen, onPromptDeny, onPromptAccept }) {
  return (
    <Modal open={isPromptOpen} form={form}>
      <Modal.Header>Caution!</Modal.Header>
      <Modal.Content>
        <p>Invalid collections have been found, do you still want to proceed?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onPromptDeny()}>Cancel</Button>
        <Button positive onClick={() => onPromptAccept()}>
          Proceed
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EnvironmentPrompt;
