/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { getEnv, types } from 'mobx-state-tree';
import {
  getAmis,
  importAmi,
  getEnvs,
  getEnvironments,
  stopEnvironment,
  startEnvironment,
  deleteEnvironment,
  exportEnvironment,
  createEnvironment,
  createSecureEnvironment,
  createEnvironmentFromAmi,
  getEnvironmentCost,
  updateAmiPermissions,
  getPresignedBootstrapUploadRequests,
  updateEnvironmentSecurity,
  changeInstanceType,
} from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import { Environment } from './Environment';
import { AmiPermission } from './AmiPermission';
import { EnvironmentStore } from './EnvironmentStore';

// ==================================================================
// EnvironmentsStore
// ==================================================================
// edited by 2359 - added handling of AMI and ability to start, stop or export instance
// edit 2359 - add initial support for custom bootstrap script, edit chenjqp - add functionality to update cidr
const EnvironmentsStore = BaseStore.named('EnvironmentsStore')
  .props({
    amis: types.optional(types.map(AmiPermission), {}),
    environments: types.optional(types.map(Environment), {}),
    environmentStores: types.optional(types.map(EnvironmentStore), {}),
    envType: types.optional(types.string, 'R'),
    tickPeriod: 30 * 1000, // 30 seconds
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let environments;
        if (self.envType) {
          environments = await getEnvs(self.envType);
        } else {
          environments = await getEnvironments();
        }
        const amis = await getAmis();
        /*
        try {
          const costPromises = environments.map(env => {
            return getEnvironmentCost(env.id, 1);
          });

          const costInfo = await Promise.all(costPromises);

          for (let i = 0; i < environments.length; i++) {
            environments[i].costs = costInfo[i];
          }
        } catch (error) {
          displayWarning('Error encountered retrieving cost data', error);
        }
		*/

        self.runInAction(() => {
          consolidateToMap(self.environments, environments, (exiting, newItem) => {
            exiting.setEnvironment(newItem);
          });
          consolidateToMap(self.amis, amis, (exiting, newItem) => {
            exiting.setAmi(newItem);
          });
        });
      },

      addEnvironment(rawEnvironment) {
        const id = rawEnvironment.id;
        const previous = self.environments.get(id);

        if (!previous) {
          self.environments.put(rawEnvironment);
        } else {
          previous.setEnvironment(rawEnvironment);
        }
      },

      getEnvironmentStore: environmentId => {
        let entry = self.environmentStores.get(environmentId);
        if (!entry) {
          // Lazily create the store
          self.environmentStores.set(environmentId, EnvironmentStore.create({ environmentId }));
          entry = self.environmentStores.get(environmentId);
        }

        return entry;
      },

      markAsTerminating: id => {
        const previous = self.environments.get(id);
        if (previous) {
          previous.markAsTerminating();
        }
      },

      markAsStopping: id => {
        const previous = self.environments.get(id);
        if (previous) {
          previous.markAsStopping();
        }
      },

      markAsStarting: id => {
        const previous = self.environments.get(id);
        if (previous) {
          previous.markAsStarting();
        }
      },

      setComputeType(type) {
        self.selectedCompute = type;
      },

      setSelectedAmi(amiId) {
        self.selectedAmi = amiId;
      },

      getSelectedAmi() {
        return self.selectedAmi;
      },

      getSelectedCompute() {
        return self.selectedCompute;
      },

      async getAmis() {
        const result = [];
        const amis = await getAmis();
        self.runInAction(() => {
          consolidateToMap(self.amis, amis, (existing, newItem) => {
            existing.setAmi(newItem);
          });
        });
        self.amis.forEach(ami => result.push(ami));
        return _.reverse(_.sortBy(result, ['createdAt']));
      },

      async setEnvType(envType) {
        self.envType = envType;
        await self.load();
      },

      async startEnvironment(environment) {
        const uiEventBus = getEnv(self).uiEventBus;
        await startEnvironment(environment.id);
        await uiEventBus.fireEvent('environmentStarted', environment);
      },

      async stopEnvironment(environment) {
        const uiEventBus = getEnv(self).uiEventBus;
        await stopEnvironment(environment.id);
        await uiEventBus.fireEvent('environmentStopped', environment);
      },

      async deleteEnvironment(environment) {
        const uiEventBus = getEnv(self).uiEventBus;
        await deleteEnvironment(environment.id);
        await uiEventBus.fireEvent('environmentDeleted', environment);
      },

      async exportEnvironment(environmentId, body) {
        await exportEnvironment(environmentId, body);
        return self.getEnvironment(environmentId);
      },

      async createEnvironment(environment) {
        const result = await createEnvironment(environment);
        self.addEnvironment(result);
        return self.getEnvironment(result.id);
      },

      async createSecureEnvironment(environment) {
        const result = await createSecureEnvironment(environment);
        self.addEnvironment(result);
        return self.getEnvironment(result.id);
      },

      async importAmi(ami) {
        await importAmi(ami);
      },

      async updateAmiPermissions(amiId, permissions) {
        const result = await updateAmiPermissions(amiId, permissions);
        return result;
      },

      async createEnvironmentFromAmi(environment) {
        const result = await createEnvironmentFromAmi(environment);
        self.addEnvironment(result);
        return self.getEnvironment(result.id);
      },

      async uploadBootstrapFiles(files, id) {
        // console.log('files: ', files);
        const result = await getPresignedBootstrapUploadRequests(id, files);
        return result;
      },

      async updateSecurity(environmentId, { cidr }) {
        // port to be put back if need
        const result = await updateEnvironmentSecurity(environmentId, {
          id: environmentId,
          instanceInfo: { cidr },
        });
        self.addEnvironment(result);
        return self.getEnvironment(result.id);
      },
      
      async getCost(environmentId, days) {
        const cost = await getEnvironmentCost(environmentId, days);
        return cost;
      },
        
      async editInstance(environmentId, newSize) {
        const result = await changeInstanceType(environmentId, newSize);
        return result;
      },

      cleanup: () => {
        self.environments.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.environments.size === 0;
    },

    get total() {
      return self.environments.size;
    },

    get list() {
      const result = [];
      self.environments.forEach(environment => result.push(environment));

      return _.reverse(_.sortBy(result, ['createdAt', 'name']));
    },

    get amilist() {
      const result = [];
      self.amis.forEach(ami => result.push(ami));
      return _.reverse(_.sortBy(result, ['createdAt']));
    },

    getEnvironment(id) {
      return self.environments.get(id);
    },
  }));

function registerModels(globals) {
  globals.environmentsStore = EnvironmentsStore.create({}, globals);
}

export { EnvironmentsStore, registerModels };
