/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { Button, Grid, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { parse } from 'csv-parse'; // add 2359 - add api study type

import { getCreateStudyForm } from '../../models/forms/CreateStudy';
import { getAwsAccount, getIndex, getProject } from '../../helpers/api';
import { displayError } from '../../helpers/notification';
import { getOptionsFromRules, randomNum } from '../../helpers/utils';

import Dropdown from '../helpers/fields/DropDown';
import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';
import TagsEditor from '../helpers/tag-set-two/TagsEditor';
import Toggle from '../helpers/fields/Toggle';

import SecureStudiesAbout from './SecureStudiesAbout';

// expected props
// - studiesStore
// - category
// - userStore (via injection)
// - projectsStore (via injection)
// - indexesStore (via injection)
// edited by 2359 - added isPublic
// edit 2359 - add api study type
class CreateStudy extends React.Component {
  modalOpen = false;
  storageType = undefined;
  createUploadLocation = true;
  publicDisabled = false;
  secureDisabled = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
      storageType: observable,
      createUploadLocation: observable,
      publicDisabled: observable,
      secureDisabled: observable,
    });
    this.tagSet = observable({
      tags: [],
      addTag: ({ key, values }) => {
        runInAction(() => {
          this.tagSet.tags.push({ id: randomNum(), key, values });
        });
      },
      removeTagAtIndex: index => {
        runInAction(() => {
          this.tagSet.tags.splice(index, 1);
        });
      },
    });
    // this.error = false;
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      fsxSizeListInGb: [],
    };
  }

  componentDidMount() {
    runInAction(() => {
      this.secureDisabled = this.props.disableSecure ? this.props.disableSecure : false;
    });
    if (this.props.isOutputStudy) {
      this.getFsxSizeSetting();
    }
  }

  async getFsxSizeSetting() {
    const fsxSizeSetting = await this.getSettingsStore.getSetting('fsxSizeInGb');
    const arr = fsxSizeSetting.value.split(',');
    const fsxSizeListInGb = [];
    Object.values(arr).forEach(size => {
      fsxSizeListInGb.push({ key: size, value: size, text: `${size}GB` });
    });
    this.setState({ fsxSizeListInGb });
  }

  get getSettingsStore() {
    return this.props.settingsStore;
  }

  // add 2359 - add api study type
  downloadSampleCsv = () => {
    const rows = [
      ['page1', 'https://www.ebi.ac.uk/metagenomics/api/v1/biomes?page=1'],
      ['page2', 'https://www.ebi.ac.uk/metagenomics/api/v1/biomes?page=2'],
      ['page3', 'https://www.ebi.ac.uk/metagenomics/api/v1/biomes?page=3'],
      ['page4', 'https://www.ebi.ac.uk/metagenomics/api/v1/biomes?page=4'],
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
      this.storageType = undefined;
      this.createUploadLocation = true;
      this.tagSet.tags = [];
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  // edit 2359 - add api study type
  handleFormSubmission = async form => {
    try {
      const studyValues = form.values();
      let isError = false;

      // Determine derived values based on storage type
      switch (studyValues.storageType) {
        case 'S3':
          studyValues.uploadLocationEnabled = this.createUploadLocation;
          if (!studyValues.uploadLocationEnabled) {
            studyValues.resources = [{ arn: studyValues.s3Path.replace(/^s3:\/\//, 'arn:aws:s3:::') }];
          }
          break;
        case 'RDS': {
          const indexId = (await getProject(studyValues.projectId)).indexId;
          const accountId = (await getIndex(indexId)).awsAccountId;
          const accountNum = (await getAwsAccount(accountId)).accountId;

          studyValues.resources = [
            {
              arn: `arn:aws:rds-db:${studyValues.rdsRegion}:${accountNum}:dbuser:${studyValues.rdsInstanceResourceId}/${studyValues.rdsUsername}`,
            },
          ];
          break;
        }
        default:
      }

      if (studyValues.storageType !== 'REST API Endpoint') {
        delete studyValues.apiData;
      } else {
        studyValues.category = 'API Data';
        studyValues.isPublic = true;
      }

      if (!this.props.isOutputStudy || studyValues.storageType !== 'S3') {
        delete studyValues.fsxStorageSize;
      }

      // Delete unneeded values
      ['s3StorageLocation', 's3Path', 'rdsRegion', 'rdsInstanceResourceId', 'rdsUsername'].forEach(key => {
        delete studyValues[key];
      });

      // Bugfix: check tags for lowercase ONLY
      _.forEach(this.tagSet.tags, t => {
        if (!t.key || (t.key && t.key.length === 0)) {
          displayError('Please remove empty tags!');
          isError = true;
          return false;
        }

        const letters = /^[0-9a-z-_]+$/;
        if (!letters.test(t.key)) {
          displayError('Tag names have to be lowercase!');
          isError = true;
          return false;
        }
      });

      if (!isError) {
        // Create study, clear form, and close modal
        const inputStudy = {
          ...studyValues,
          isPublic: studyValues.isPublic ? 'true' : 'false',
          isSecure: studyValues.isSecure,
          isOutput: !!this.props.isOutputStudy,
          category: studyValues.category || this.props.category,
          tags: this.tagSet.tags
            .map(t => ({ key: t.key, values: t.values }))
            .filter(t => t.key.length > 0 && t.values.length > 0),
        };

        if (this.props.isOutputStudy) {
          // since this is used as output study, disable sharing
          Object.assign(inputStudy, { isSharing: false });
          if (studyValues.storageType === 'S3') {
            const intFsxStorageSize = parseInt(studyValues.fsxStorageSize.replace('GB', ''), 10);
            Object.assign(inputStudy, { fsxStorageSize: intFsxStorageSize });
          }
        }
        const study = await this.props.studiesStore.createStudy(inputStudy);

        form.clear();
        this.cleanModal();
        if (this.props.onCreate && typeof this.props.onCreate === 'function') {
          this.props.onCreate(study);
        }
      }
    } catch (error) {
      displayError(error);
    }
  };

  // add 2359 - add api study type
  handleFiles = (e, form) => {
    const reader = new FileReader();
    form.$('apiData').value = '';
    reader.onload = () => {
      // Use reader.result
      const data = reader.result;
      // console.log('data: ', data);
      const apiData = [];
      try {
        parse(data, { delimiter: ',' }, (err, output) => {
          if (err) {
            displayError(err);
            return;
          }
          output.forEach(item => {
            if (
              /^[a-zA-Z0-9]+$/.test(item[0]) &&
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#,?&//=]*)/.test(
                item[1],
              )
            ) {
              apiData.push({ page: item[0], url: item[1] });
            } else {
              displayError('There is an error in syntax of the csv file.');
            }
          });
          if (apiData.length) {
            form.$('apiData').value = JSON.stringify(apiData);
          }
        });
      } catch (err) {
        displayError(err);
      }
    };
    if (/(.*?)\.(csv)$/.test(e.target.files[0].name)) {
      reader.readAsText(e.target.files[0]);
    } else {
      displayError('Please uplad a csv file.');
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Create Study
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderCreateStudyForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
        type="button"
      >
        Create Study
      </Button>
    );
  }

  // edit 2359 - add api study type
  renderCreateStudyForm() {
    const form = getCreateStudyForm();
    const projectIds = this.props.userStore.projectIdDropdown;
    let storageTypes = getOptionsFromRules(form.$('storageType').rules);
    form.$('isPublic').value = this.props.isSecure ? !this.props.isSecure : false;
    form.$('isSecure').value = this.props.isSecure ? this.props.isSecure : false;
    if (this.props.isOutputStudy) {
      form.$('isPublic').value = false;
      storageTypes = storageTypes.filter(type => type.value === 'S3');
    }
    const fsxStorageSize = form.$('fsxStorageSize');

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          const storageTypeOnChange = action((_event, data) => {
            this.storageType = data.value;
            if (this.props.isOutputStudy && this.storageType === 'S3') {
              fsxStorageSize.set('rules', 'required');
            }

            if (this.storageType === 'REST API Endpoint') {
              form.$('isPublic').value = true;
              form.$('isSecure').value = false;
              this.publicDisabled = true;
              this.secureDisabled = true;
            } else if (this.props.isOutputStudy) {
              // disable public study for output study
              form.$('isPublic').value = false;
              this.secureDisabled = false;
              this.publicDisabled = true;
            } else if (form.$('isSecure').value === true) {
              this.secureDisabled = false;
              this.publicDisabled = true;
            } else if (form.$('isPublic').value === true) {
              this.secureDisabled = true;
              this.publicDisabled = false;
            } else {
              this.secureDisabled = false;
              this.publicDisabled = false;
            }
          });

          const secureOnChange = action(data => {
            if (data === true) {
              form.$('isPublic').value = false;
              this.publicDisabled = true;
            } else {
              this.publicDisabled = false;
            }
          });

          const publicOnChange = action(data => {
            if (data === true) {
              form.$('isSecure').value = false;
              this.secureDisabled = true;
            } else {
              this.secureDisabled = false;
            }
          });

          return (
            <>
              <Input field={form.$('id')} />
              <Input field={form.$('name')} />
              <div className="flex">
                <SecureStudiesAbout />
                <Toggle
                  field={form.$('isSecure')}
                  onChange={secureOnChange}
                  disabled={this.props.disableSecure || this.secureDisabled}
                />
              </div>
              <Toggle field={form.$('isPublic')} onChange={publicOnChange} disabled={this.publicDisabled} />
              <TextArea field={form.$('description')} />
              <Dropdown field={form.$('projectId')} options={projectIds} fluid selection />
              <Dropdown
                field={form.$('storageType')}
                options={storageTypes}
                onChange={storageTypeOnChange}
                fluid
                selection
              />

              {this.storageType === 'REST API Endpoint' && this.renderAPIEndpoint(form)}
              {this.props.isOutputStudy && this.storageType === 'S3' && this.renderFsxStorageSize(form)}

              <TagsEditor target={this.tagSet} />

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Create Study
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }

  // edit 2359 - add api study type
  renderAPIEndpoint = form => {
    return (
      <Grid className="mb2">
        <Grid.Column width={8}>
          <Grid.Row>
            <input onChange={e => this.handleFiles(e, form)} type="file" />
          </Grid.Row>
          <Grid.Row>
            <br />
            <a href="#" onClick={this.downloadSampleCsv}>
              &nbsp;&nbsp;&nbsp;Download sample file
            </a>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  };

  renderFsxStorageSize(form) {
    const fsxStorageSize = form.$('fsxStorageSize');
    return (
      <Dropdown
        field={fsxStorageSize}
        options={this.state.fsxSizeListInGb}
        fluid
        placeholder="please select storage size"
        selection
      />
    );
  }

}

export default inject('userStore')(observer(CreateStudy));
