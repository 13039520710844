/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

// ==================================================================
// AuditLogEvent
// ==================================================================
const AuditLogEvent = types
  .model('AuditLogEvent', {
    action: types.optional(types.string, ''),
    actor: types.optional(types.string, ''),
    message: types.optional(types.string, ''),
    payload: types.optional(types.frozen(), {}),
    timestamp: types.integer,
  })
  .views(self => ({
    get getISODate() {
      if (typeof self.timestamp === 'undefined') {
        return 'unknown';
      }
      return new Date(self.timestamp).toISOString();
    },
  }));

export default AuditLogEvent;
