/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types, getEnv } from 'mobx-state-tree';

// A convenient model that returns the display name or long display name given a user identifier
const UserDisplayName = types.model('UserDisplayName', {}).views(self => ({
  // identifier: can be an instance of the UserIdentifier, or a string or undefined
  getDisplayName(identifier) {
    // TODO deal with _systems_
    let userStore;

    if (_.isString(identifier)) return identifier;
    if (_.isUndefined(identifier)) {
      userStore = getEnv(self).userStore;
      if (userStore.user) return userStore.displayName;
      return 'Unknown';
    }

    const usersStore = getEnv(self).usersStore;
    const user = usersStore.asUserObject(identifier);

    if (_.isUndefined(user)) return 'unknown';
    return user.displayName || 'unknown';
  },

  getDisplayFullName(identifier) {
    // TODO deal with _systems_
    let userStore;

    if (_.isString(identifier)) return identifier;
    if (_.isUndefined(identifier)) {
      userStore = getEnv(self).userStore;
      if (userStore.user) return userStore.displayFullName;
      return 'Unknown';
    }

    const usersStore = getEnv(self).usersStore;
    const user = usersStore.asUserObject(identifier);

    if (_.isUndefined(user)) return 'unknown';
    return user.displayFullName || 'unknown';
  },

  // identifier: can be an instance of the UserIdentifier, or a string or undefined
  getLongDisplayName(identifier) {
    // TODO deal with _systems_
    let userStore;

    if (_.isString(identifier)) return identifier;
    if (_.isUndefined(identifier)) {
      userStore = getEnv(self).userStore;
      if (userStore.user) return userStore.longDisplayName;
      return 'Unknown';
    }

    const usersStore = getEnv(self).usersStore;
    const user = usersStore.asUserObject(identifier);

    if (_.isUndefined(user)) return 'unknown';
    return user.longDisplayName || 'unknown';
  },

  // identifier: can be an instance of the UserIdentifier, or a string or undefined
  isSystem(identifier) {
    let userStore;

    if (_.isString(identifier)) return identifier === '_system_';
    if (_.isUndefined(identifier)) {
      userStore = getEnv(self).userStore;
      if (userStore.user) return userStore.user.isSystem;
      return false;
    }
    const username = _.get(identifier, 'username', '');

    return username === '_system_';
  },
}));

function registerModels(globals) {
  globals.userDisplayName = UserDisplayName.create({}, globals);
}

export { UserDisplayName, registerModels };
