/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  Button,
  Dimmer,
  Header,
  List,
  Loader,
  Dropdown,
  Segment,
  Modal,
  Menu,
  Icon,
  Radio,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';

import { getUpdateUserConfigFormFields } from '../../models/forms/UpdateUserConfig';
import { displayError } from '../../helpers/notification';
import validate from '../../models/forms/Validate';
import Stores from '../../models/Stores';
import { swallowError } from '../../helpers/utils';

class UserConfigure extends React.Component {
  formProcessing = false;
  validationErrors = new Map();

  constructor(props) {
    super(props);
    makeObservable(this, {
      formProcessing: observable,
      validationErrors: observable,
    });

    this.initState();
    runInAction(() => {
      this.updateUser = {};
      this.stores = new Stores([
        this.props.userStore,
        this.props.usersStore,
        this.props.userRolesStore,
        this.props.authenticationProviderConfigsStore,
        this.props.awsAccountsStore,
        this.props.projectsStore,
      ]);
    });
    // this.form = getUpdateUserConfigForm();
    this.getUpdateUserConfigFormFields = getUpdateUserConfigFormFields();
    this.currentUser = this.props.user;
    this.adminMode = this.props.adminMode;
    this.isRoot = this.props.user.username === 'root';
  }

  initState(set) {
    const state = {
      view: 'detail',
      identityProviderName: '',
      status: 'active',
      projectId: [],
      userRole: '',
      modalOpen: false,
    };

    if (set) {
      this.setState(state);
    } else {
      this.state = state;
    }
  }

  /* goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });
    this.props.history.push(link);
  } */

  componentWillUnmount() {
    this.cleanUp();
    this.initState();
    this.props.usersStore.startHeartbeat();
  }

  getStores() {
    return this.stores;
  }

  componentDidMount() {
    swallowError(this.getStores().load());
  }

  componentDidUpdate(prevProps) {
    runInAction(() => {
      if (prevProps.user !== this.props.user) {
        this.currentUser = this.props.user;
        this.adminMode = this.props.adminMode;
        this.isRoot = this.props.user.username === 'root';
      }
    });
  }

  cleanUp() {
    this.setState({
      modalOpen: false,
    });
  }

  handleOpen = () => {
    this.props.usersStore.stopHeartbeat();
    this.setState({
      modalOpen: true,
      projectId: this.props.user.projectId,
      userRole: this.props.user.userRole,
      identityProviderName: this.props.user.identityProviderName,
    });
  };

  handleClose = () => {
    this.initState(true);
    this.cleanUp();
  };

  renderDetailView() {
    const nonRootContent = this.isRoot ? null : (
      <>
        <div className="mb1" />
        {this.renderField('userRole')}
        <div className="mb1" />
        {this.renderField('identityProviderName')}
        <div className="mb1" />
        {this.renderField('projectId', null, (value = []) => value.join(', '))}
        <div className="mb1" />
        {this.currentUser.status === 'pending' && this.renderField('applyReason')}
        <div className="mb1" />
        {this.renderField('status')}
      </>
    );
    return (
      <Segment basic className="ui fluid form">
        {this.renderField('username')}
        <div className="mb1" />
        {this.renderField('firstName')}
        <div className="mb1" />
        {this.renderField('lastName')}
        <div className="mb1" />
        {this.renderField('email')}
        <div className="mb1" />
        {this.renderField('instituteName')}
        <div className="mb1" />
        {this.renderField('designation')}
        {nonRootContent}
        {this.renderButtons()}
        <div className="mb4" />
      </Segment>
    );
  }

  renderTrigger() {
    let content = null;
    if (this.props.adminMode) {
      content = (
        <Button size="mini" compact color="blue" onClick={this.handleOpen}>
          Detail
        </Button>
      );
    } else {
      content = (
        <Menu.Item onClick={this.handleOpen}>
          <Icon name="user" /> {this.props.userStore.user.displayName}
        </Menu.Item>
      );
    }
    return content;
  }

  render() {
    let content = null;
    if (this.state.view === 'detail') {
      content = this.renderDetailView();
    } else if (this.state.view === 'edit') {
      content = this.renderUpdateUserConfigForm();
    }
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.state.modalOpen} onClose={this.handleClose}>
        <div className="mt2 animated fadeIn">
          <Header as="h3" icon textAlign="center" className="mt3" color="grey">
            User Detail
          </Header>
          <div className="mt3 ml3 mr3 animated fadeIn">{content}</div>
        </div>
      </Modal>
    );
  }

  renderUpdateUserConfigForm() {
    const processing = this.formProcessing;
    const fields = this.getUpdateUserConfigFormFields;
    const toEditableInput = (attributeName, type = 'text') => {
      const handleChange = action(event => {
        event.preventDefault();
        this.updateUser[attributeName] = event.target.value;
      });
      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.currentUser[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
          />
        </div>
      );
    };
    const nonRootContent = this.isRoot ? null : (
      <>
        <div className="mb2" />
        {this.adminMode && this.renderField('userRole')}
        {this.adminMode && this.renderUserRoleSelection()}
        <div className="mb2" />
        {this.adminMode && this.renderField('identityProviderName')}
        {this.adminMode && this.renderIdentityProviderNameSelection()}
        <div className="mb2" />
        {this.adminMode && this.props.userRolesStore.getUserType(this.state.userRole) === 'INTERNAL' ? (
          <div>
            {this.renderField('projectId')}
            {this.renderProjectIdSelection()}
          </div>
        ) : (
          ''
        )}
        <div className="mb2" />
        {this.adminMode && this.renderField('status')}
        {this.adminMode && this.renderStatusSelection()}
      </>
    );

    return (
      <Segment basic className="ui fluid form">
        <Dimmer active={processing} inverted>
          <Loader inverted>Updating</Loader>
        </Dimmer>
        {this.renderField('firstName', toEditableInput('firstName'))}
        <div className="mb2" />
        {this.renderField('lastName', toEditableInput('lastName'))}
        <div className="mb2" />
        {this.renderField('email', toEditableInput('email', 'email'))}
        <div className="mb2" />
        {this.renderField('instituteName', toEditableInput('instituteName'))}
        <div className="mb2" />
        {this.renderField('designation', toEditableInput('designation'))}
        {nonRootContent}
        {this.renderButtons()}
        <div className="mb4" />
      </Segment>
    );
  }

  handleClickEditButton = () => {
    this.setState({ view: 'edit' });
  };

  handleClickCancelButton = () => {
    if (this.state.view === 'edit') {
      this.setState({ view: 'detail' });
    } else {
      this.handleClose();
    }
  };

  handleStatusChange = (e, { value }) => this.setState({ status: value });

  handleUserRoleSelection = (e, { value }) => {
    this.setState({ userRole: value });
  };

  handleProjectId = (e, { value }) => this.setState({ projectId: value });

  handleIdentityProviderName = (e, { value }) => {
    this.setState({
      identityProviderName: value,
    });
  };

  renderUserRoleSelection() {
    const userRoleOption = this.props.userRolesStore.dropdownOptions;
    return (
      <Dropdown
        options={userRoleOption}
        defaultValue={this.state.userRole}
        placeholder="Please select user role"
        fluid
        selection
        onChange={this.handleUserRoleSelection}
      />
    );
  }

  renderProjectIdSelection() {
    const projects = this.props.projectsStore.dropdownOptions;
    return (
      <Dropdown
        options={projects}
        fluid
        multiple
        selection
        onChange={this.handleProjectId}
        defaultValue={this.state.projectId}
      />
    );
  }

  renderStatusSelection() {
    return (
      <Form>
        <Form.Field>
          <Radio
            label="Active"
            name="radioGroup"
            value="active"
            checked={this.state.status === 'active'}
            onChange={this.handleStatusChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Inactive"
            name="radioGroup"
            value="inactive"
            checked={this.state.status === 'inactive'}
            onChange={this.handleStatusChange}
          />
        </Form.Field>
      </Form>
    );
  }

  renderIdentityProviderNameSelection() {
    const identityProviderOption = this.props.authenticationProviderConfigsStore.list.reduce(
      (providerNameOptions, provider) => [
        ...providerNameOptions,
        ...(provider.config.federatedIdentityProviders || []).map(federatedIdp => ({
          key: federatedIdp.name,
          value: federatedIdp.name,
          text: federatedIdp.name,
        })),
      ],
      [],
    );

    return (
      <Dropdown
        selection
        fluid
        options={identityProviderOption}
        onChange={this.handleIdentityProviderName}
        value={this.currentUser.identityProviderName}
      />
    );
  }

  handleClickSubmitButton = action(async () => {
    if (_.isEmpty(this.updateUser.firstName)) {
      this.updateUser.firstName = this.currentUser.firstName;
    }
    if (_.isEmpty(this.updateUser.lastName)) {
      this.updateUser.lastName = this.currentUser.lastName;
    }
    if (_.isEmpty(this.updateUser.email)) {
      this.updateUser.email = this.currentUser.email;
    }
    if (_.isEmpty(this.updateUser.designation)) {
      this.updateUser.designation = this.currentUser.designation;
    }
    if (_.isEmpty(this.updateUser.instituteName)) {
      this.updateUser.instituteName = this.currentUser.instituteName;
    }
    runInAction(() => {
      this.formProcessing = true;
    });

    try {
      // Perform client side validations first
      const validationResult = await validate(this.updateUser, this.getUpdateUserConfigFormFields);
      // if there are any client side validation errors then do not attempt to make API call
      if (validationResult.fails()) {
        runInAction(() => {
          this.validationErrors = validationResult.errors;
          this.formProcessing = false;
        });
      } else {
        // There are no client side validation errors so ask the store to add user (which will make API call to server to add the user)
        const deletedUser = _.clone(this.currentUser);
        if (!_.isEmpty(this.updateUser.firstName)) {
          this.currentUser.firstName = this.updateUser.firstName;
        }
        if (!_.isEmpty(this.updateUser.lastName)) {
          this.currentUser.lastName = this.updateUser.lastName;
        }
        if (!_.isEmpty(this.updateUser.email)) {
          this.currentUser.email = this.updateUser.email;
        }
        if (!_.isEmpty(this.updateUser.designation)) {
          this.currentUser.designation = this.updateUser.designation;
        }
        if (!_.isEmpty(this.updateUser.instituteName)) {
          this.currentUser.instituteName = this.updateUser.instituteName;
        }

        if (this.adminMode) {
          if (!this.isRoot) {
            this.currentUser.userRole = this.state.userRole;
            this.currentUser.status = this.state.status;
            this.currentUser.projectId = this.state.projectId;
            this.currentUser.isAdmin = this.state.userRole === 'admin';
          }
        }
        if (
          this.currentUser.identityProviderName === this.state.identityProviderName ||
          _.isEmpty(this.state.identityProviderName)
        ) {
          // idp no change, update user
          try {
            if (this.adminMode && !_.isEmpty(this.state.identityProviderName)) {
              this.currentUser.identityProviderName = this.state.identityProviderName;
            }
            await this.props.usersStore.updateUser(this.currentUser);
            await this.props.userStore.load();
            runInAction(() => {
              this.formProcessing = false;
            });
          } catch (err) {
            runInAction(() => {
              this.formProcessing = false;
            });
            displayError(err);
          }
        } else {
          try {
            if (this.adminMode) {
              this.currentUser.identityProviderName = this.state.identityProviderName;
            }
            await this.getStore().addUser(this.currentUser);
            await this.getStore().deleteUser(deletedUser);
            await this.getStore().load();
            runInAction(() => {
              this.formProcessing = false;
            });
          } catch (err) {
            runInAction(() => {
              this.formProcessing = false;
            });
            displayError(err);
          }
        }
      }
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
    if (this.props.userStore.username === this.props.user.username) {
      // if this is the same user, update the user store
      this.props.userStore.load();
    }
	this.setState({ view: 'detail' });
  });

  handleClickDeleteButton = action(async () => {
    runInAction(() => {
      this.formProcessing = true;
    });
    try {
      await this.getStore().deleteUser(this.currentUser);
      runInAction(() => {
        this.formProcessing = false;
      });
      this.cleanUp();
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
  });

  handleClickApproveButton = action(async () => {
    runInAction(() => {
      this.formProcessing = true;
    });
    try {
      if (this.adminMode) {
        this.currentUser.status = 'active';
      }
      await this.props.usersStore.updateUser(this.currentUser);
      await this.props.userStore.load();
      runInAction(() => {
        this.formProcessing = false;
      });
      this.cleanUp();
    } catch (err) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(err);
    }
    this.cleanUp();
  });

  handleClickDeactivateButton = action(async () => {
    runInAction(() => {
      this.formProcessing = true;
    });
    try {
      if (this.adminMode) {
        this.currentUser.status = 'inactive';
      }
      await this.props.usersStore.updateUser(this.currentUser);
      await this.props.userStore.load();
      runInAction(() => {
        this.formProcessing = false;
      });
      this.cleanUp();
    } catch (err) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(err);
    }
    this.cleanUp();
  });

  renderButtons() {
    const processing = this.formProcessing;

    const makeButton = ({ label = '', color = 'blue', floated = 'left', disabled = false, ...props }) => {
      return (
        <Button color={color} floated={floated} disabled={processing || disabled} className="ml2" {...props}>
          {label}
        </Button>
      );
    };

    const editButton =
      this.state.view === 'detail' && (this.props.user.status === 'active' || this.props.user.status === 'inactive')
        ? makeButton({ label: 'Edit', onClick: this.handleClickEditButton })
        : '';

    const saveButton =
      this.state.view === 'edit' ? makeButton({ label: 'Save', onClick: this.handleClickSubmitButton }) : '';

    const deleteButton =
      // TODO: deletion actions should be confirmed by user first
      this.state.view === 'detail'
        ? makeButton({
            label: 'Delete',
            floated: 'right',
            color: 'red',
            disabled: this.isRoot,
            onClick: this.handleClickDeleteButton,
          })
        : '';

    const activeButton =
      (this.props.user.status === 'pending' || this.props.user.status === 'inactive') && this.state.view === 'detail'
        ? makeButton({
            label: 'Activate User',
            floated: 'right',
            color: 'blue',
            onClick: this.handleClickApproveButton,
          })
        : '';

    const deactiveButton =
      (this.props.user.status === 'active' || this.props.user.status === 'pending') && this.state.view === 'detail'
        ? makeButton({
            label: 'Deactivate User',
            floated: 'right',
            disabled: this.isRoot,
            onClick: this.handleClickDeactivateButton,
          })
        : '';

    const cancelButton = makeButton({
      label: 'Cancel',
      floated: 'right',
      color: 'grey',
      onClick: this.handleClickCancelButton,
    });

    return this.adminMode ? (
      <div className="mt3 mb3">
        <Modal.Actions>
          {cancelButton}
          {deactiveButton}
          {activeButton}
          {deleteButton}
          {saveButton}
          {editButton}
        </Modal.Actions>
      </div>
    ) : (
      <div className="mt3 mb3">
        <Modal.Actions>
          {cancelButton}
          {saveButton}
          {editButton}
        </Modal.Actions>
      </div>
    );
  }

  renderField(name, component, contentRenderer) {
    const fields = this.getUpdateUserConfigFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    let content = this.currentUser[name];
    if (contentRenderer && typeof contentRenderer === 'function') {
      content = contentRenderer(content);
    }
    content = _.isEmpty(content) ? 'N/A' : content;

    const fieldErrors = this.validationErrors.get(name);
    const hasError = !_.isEmpty(fieldErrors);
    return (
      <div>
        <Header className="mr3 mt0" as="h4" color="grey">
          {label}
        </Header>
        {hasExplain && (
          <div className="mb2">
            {explain} <span>{this.state.view === 'detail' ? content : ''}</span>
          </div>
        )}
        <div className={`ui field input block m0 ${hasError ? 'error' : ''}`}>{component}</div>
        {hasError && (
          <div className="ui pointing red basic label">
            <List>
              {_.map(fieldErrors, fieldError => (
                <List.Item key={name}>
                  <List.Content>{fieldError}</List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }

  getStore() {
    return this.props.usersStore;
  }
}

export default inject('authenticationProviderConfigsStore')(observer(UserConfigure));
