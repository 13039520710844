/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
// add 2359 - add support for EMR-hail notebook permission entities
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import {
  getNotebookPermissions,
  requestNotebookAccess,
  updateNotebookUserPermissions,
  revokeNotebookUserPermissions,
  updateNotebookPermissions,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { NotebookPermissions } from './NotebookPermissions';

// ==================================================================
// NotebookStore
// ==================================================================
const NotebookPermissionsStore = BaseStore.named('NotebookPermissionsStore')
  .props({
    notebookId: types.identifier,
    notebookPermissions: types.maybe(NotebookPermissions),
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const newPermissions = await getNotebookPermissions(self.notebookId);
        if (!self.notebookPermissions || !_.isEqual(self.notebookPermissions, newPermissions)) {
          self.runInAction(() => {
            self.notebookPermissions = newPermissions;
          });
        }
      },

      cleanup: () => {
        superCleanup();
      },

      requestPermissions: async updateRequest => {
        await requestNotebookAccess(self.notebookId, updateRequest);
      },

      updatePermissions: async permissions => {
        // Perform update and reload store
        await updateNotebookUserPermissions(self.notebookId, { permissions });
        await self.load();
      },

      revokePermissions: async permissions => {
        // Perform update and reload store
        const { principalIdentifier, ..._rest } = permissions;
        await revokeNotebookUserPermissions(self.notebookId, {
          permissions: { principalIdentifier, notebookId: self.notebookId },
        });
        await self.load();
      },

      update: async (permissions, selectedUsers) => {
        const updateRequest = { permissions, usersToAdd: [], usersToRemove: [] };

        self.notebookPermissions.userTypes.forEach(type => {
          const userToRequestFormat = user => ({ principalIdentifier: user, permissionLevel: type });

          // Set selected users as "usersToAdd" (API is idempotent)
          updateRequest.usersToAdd.push(...selectedUsers[type].map(userToRequestFormat));

          // Set removed users as "usersToRemove"
          updateRequest.usersToRemove.push(
            ..._.differenceWith(self.notebookPermissions[`${type}Users`], selectedUsers[type], _.isEqual).map(
              userToRequestFormat,
            ),
          );
        });

        // Perform update and reload store
        await updateNotebookPermissions(self.notebookId, updateRequest);
        await self.load();
      },
    };
  });

export { NotebookPermissionsStore };
