/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';

import { Card, Button, Image } from 'semantic-ui-react';

// import { toUTCDate } from '../../../helpers/utils';

class SummaryCard extends React.Component {
  handleDetails = () => {
    this.props.onViewDetails(this.props.offering);
  };

  render() {
    const offering = this.props.offering;
    if (!offering) return null;

    return this.renderCard(offering);
  }

  renderCard(offering) {
    const isDisabled = (offering && _.has(offering, 'disabledReason') && offering.disabledReason !== '');
	  
    return (
      <Card raised>
        <Card.Content>
          <Card.Header className="color-grey">
            <Image src={offering.icon} floated="right" style={{ maxHeight: '20px', maxWidth: '20px' }} />
            {offering.name} {offering.disabledReason}
          </Card.Header>
          <Card.Meta>{offering.sub}</Card.Meta>
          <Card.Description>
            <div className="mb3">{React.createElement(offering.description)}</div>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
	    { !isDisabled &&   
          <Button basic color="green" fluid onClick={this.handleDetails}>
            Configure
          </Button> }
        </Card.Content>
      </Card>
    );
  }

  /* renderDate(date) {
    if (_.isEmpty(date)) return 'N/A';
    return <TimeAgo date={toUTCDate(date)} />;
  } */
}

// export default inject('dacRequestsStore', 'pendingSelection')(observer(SummaryCard));
export default observer(SummaryCard);
