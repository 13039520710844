/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types, getEnv, applySnapshot } from 'mobx-state-tree';

import { BaseStore } from '../BaseStore';

const CarryoutStore = BaseStore.named('CarryoutStore')
  .props({
    files: types.optional(types.array(types.string), []),
    token: '',
  })
  .actions(self => ({
    async doLoad() {
      const filesSelection = getEnv(self).filesSelection;
      applySnapshot(self.files, filesSelection.fileNames);
      const random = _.times(100, () => _.random(65, 90));
      self.token = String.fromCharCode(...random).toLowerCase();
    },
    clear: () => {
      self.files.clear();
    },
  }))
  .views(self => ({
    get empty() {
      return _.size(self.files) === 0;
    },
  }));

function registerModels(globals) {
  globals.carryoutStore = CarryoutStore.create({}, globals);
}

export { CarryoutStore, registerModels };
