/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// import dvr from 'mobx-react-form/lib/validators/DVR';
// import Validator from 'validatorjs';
// import MobxReactForm from 'mobx-react-form';

import { createForm } from '../../helpers/form';

// edit 2359 - add support for parallel cluster which needs private subnet
const addAwsAccountFormFields = {
  name: {
    label: 'Account Name',
    placeholder: 'Type the name of this account',
    rules: 'required|string|between:1,100',
  },
  accountId: {
    label: 'AWS Account ID',
    placeholder: 'Type the 12-digit AWS account ID',
    rules: 'required|string|size:12|regex:/^[0-9]{12}$/',
  },
  roleArn: {
    label: 'Role Arn',
    placeholder: 'Type Role ARN for launching resources into this AWS account',
    rules: 'required|string|between:10,300',
  },
  externalId: {
    label: 'External ID',
    placeholder: 'Type external ID for this AWS account',
    rules: 'required|string|between:1,300',
  },
  description: {
    label: 'Description',
    placeholder: 'Type description for this AWS account',
    rules: 'required|string|between:0,2048',
  },
  vpcId: {
    label: 'VPC ID',
    placeholder: 'Type the ID of the VPC where EMR clusters will be launched',
    rules: 'required|string|size:21|regex:/^vpc-0[a-f0-9]{16}$/',
  },
  subnetId: {
    label: 'Subnet ID',
    placeholder: 'Type the ID of the subnet where the EMR clusters will be launched',
    rules: 'required|string|size:24|regex:/^subnet-0[a-f0-9]{16}$/',
  },
  privateSubnetId: {
    label: 'Private Subnet ID',
    placeholder: 'Type the ID of the subnet',
    rules: 'required|string|size:24|regex:/^subnet-0[a-f0-9]{16}$/',
  },
  encryptionKey: {
    label: 'KMS Encryption Key parameer store path',
    placeholder: 'Type the KMS Encryption Key parameter store path to use for this AWS account',
    rules: 'required|string|between:1,300',
  },
  ssbWorkspaceVpcId: {
    label: 'Secure Sandbox Workspace VPC ID',
    placeholder: 'Type the ID of the VPC where secure sandbox workspaces will be launched',
    rules: 'required|string|size:21|regex:/^vpc-0[a-f0-9]{16}$/',
  },
  ssbWorkspaceSubnetId: {
    label: 'Secure Sandbox Workspace Subnet ID',
    placeholder: 'Type the ID of the subnet where secure sandbox workspaces will be launched',
    rules: 'required|string|size:24|regex:/^subnet-0[a-f0-9]{16}$/',
  },
  ssbBastionVpcId: {
    label: 'Secure Sandbox Bastion VPC ID',
    placeholder: 'Type the ID of the VPC where secure sandbox bastions will be launched',
    rules: 'required|string|size:21|regex:/^vpc-0[a-f0-9]{16}$/',
  },
  ssbBastionSubnetId: {
    label: 'Secure Sandbox Bastion Subnet ID',
    placeholder: 'Type the ID of the subnet where secure sandbox bastions will be launched',
    rules: 'required|string|size:24|regex:/^subnet-0[a-f0-9]{16}$/',
  },
  ssbEncryptionKey: {
    label: 'KMS Encryption Key parameter store path for secure sandbox',
    placeholder:
      'Type the KMS Encryption Key parameter store path to use for this AWS account for secure sandbox resources',
    rules: 'required|string|between:1,300',
  },
  ssbWorkspaceCfnVpcEndpointIp: {
    label: 'Cloudformation VPC endpoint (Workspace)',
    placeholder: 'Private IP for cloudformation services in SSB (Workspace VPC)',
    rules: 'required|ipv4|between:1,100',
  },
  ssbWorkspaceKmsVpcEndpointIp: {
    label: 'KMS VPC endpoint (Workspace)',
    placeholder: 'Private IP for KMS services in SSB (Workspace VPC)',
    rules: 'required|ipv4|between:1,100',
  },
  ssbWorkspaceS3VpcEndpointPrefixList: {
    label: 'S3 VPC endpoint prefix list (Workspace)',
    placeholder: 'Prefix list for S3 services in SSB (Workspace VPC)',
    rules: 'required|string|between:1,100|regex:/^pl-[a-z0-9]{8}$/',
  },
  ssbBastionCfnVpcEndpointIp: {
    label: 'Cloudformation VPC endpoint (Bastion)',
    placeholder: 'Private IP for cloudformation services in SSB (Bastion VPC)',
    rules: 'required|ipv4|between:1,100',
  },
  ssbBastionSsmVpcEndpointIp: {
    label: 'SSM VPC endpoint (Bastion)',
    placeholder: 'Private IP for SSM services in SSB (Bastion VPC)',
    rules: 'required|ipv4|between:1,100',
  },
  ssbBastionKmsVpcEndpointIp: {
    label: 'KMS VPC endpoint (Bastion)',
    placeholder: 'Private IP for KMS services in SSB (Bastion VPC)',
    rules: 'required|ipv4|between:1,100',
  },
};

function getAddAwsAccountForm() {
  return createForm([addAwsAccountFormFields]);
}

function getAddAwsAccountFormFields() {
  return addAwsAccountFormFields;
}

export { getAddAwsAccountFormFields, getAddAwsAccountForm };

/*
function getAddAwsAccountFormFields() {
  return addAwsAccountFormFields;
}

function getAddAwsAccountForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addAwsAccountFormFields }, { plugins });
}

export { getAddAwsAccountFormFields, getAddAwsAccountForm };
*/
