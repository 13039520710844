/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react';

class EnvironmentStatusIcon extends React.Component {
  getEnvironment() {
    return this.props.environment;
  }

  render() {
    const status = this.getEnvironment().status;
    if (status === 'COMPLETED') {
      return (
        <Label basic size="mini" color="green">
          Ready
        </Label>
      );
    }
    if (status === 'TERMINATED') {
      return (
        <Label basic size="mini" color="red">
          Terminated
        </Label>
      );
    }
    if (status === 'FAILED') {
      return (
        <Label basic size="mini" color="red">
          Error
        </Label>
      );
    }
    // start added by 2359
    if (status === 'STOPPING_FAILED') {
      return (
        <Label basic size="mini" color="red">
          STOPPING FAILED
        </Label>
      );
    }
    if (status === 'STARTING_FAILED') {
      return (
        <Label basic size="mini" color="red">
          STARTING FAILED
        </Label>
      );
    }
    if (status === 'STOPPED') {
      return (
        <Label basic size="mini" color="red">
          STOPPED
        </Label>
      );
    }
    if (status === 'STOPPING') {
      return (
        <div>
          <Label basic size="mini">
            Stopping
            <Icon loading name="spinner" size="large" color="orange" className="ml1 mr1" />
          </Label>
        </div>
      );
    }
    // end added by 2359
    if (status === 'TERMINATING') {
      return (
        <div>
          <Label basic size="mini">
            Terminating
            <Icon loading name="spinner" size="large" color="red" className="ml1 mr1" />
          </Label>
        </div>
      );
    }
    if (status === 'TERMINATING_FAILED') {
      return (
        <div>
          <Label basic size="mini">
            Terminating failed
            <Icon loading name="spinner" size="large" color="red" className="ml1 mr1" />
          </Label>
        </div>
      );
    }

    return (
      <Label basic size="mini">
        Starting
        <Icon loading name="spinner" size="large" color="yellow" className="ml1 mr1" />
      </Label>
    );
  }
}

export default observer(EnvironmentStatusIcon);
