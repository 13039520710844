/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

const AuthenticationProviderTypeConfig = types.model('AuthenticationProviderTypeConfig', {}).actions(_self => ({
  cleanup() {
    // No-op for now
  },
}));

export default AuthenticationProviderTypeConfig;
