/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { consolidateToMap } from '../../helpers/utils';
import { getUserRoles, addUserRole } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { UserRole } from './UserRole';

// ==================================================================
// UserRolesStore
// ==================================================================
const UserRolesStore = BaseStore.named('UserRolesStore')
  .props({
    userRoles: types.optional(types.map(UserRole), {}),
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const userRoles = (await getUserRoles()) || [];
        self.runInAction(() => {
          consolidateToMap(self.userRoles, userRoles, (exiting, newItem) => {
            exiting.setUserRole(newItem);
          });
        });
      },

      cleanup: () => {
        superCleanup();
      },

      addUserRole: async userRole => {
        const addedUserRole = await addUserRole(userRole);
        self.runInAction(() => {
          // Added newly created user to users map
          const addedUserRoleModel = UserRole.create(addedUserRole);
          self.userRoles.set(addedUserRoleModel.id, addedUserRoleModel);
        });
      },

      getUserType: userRole => {
        let userType = '';
        self.userRoles.forEach(item => {
          if (_.toLower(item.id) === _.toLower(userRole)) {
            userType = item.userType;
          }
        });
        return userType;
      },
    };
  })

  .views(self => ({
    get list() {
      const result = [];
      // converting map self.users to result array
      self.userRoles.forEach(userRole => result.push(userRole));
      return result;
    },
    get dropdownOptions() {
      const result = [];
      // converting map self.users to result array
      self.userRoles.forEach(userRole => {
        const role = {};
        role.key = userRole.id;
        role.value = userRole.id;
        role.text = userRole.id;
        result.push(role);
      });
      return result;
    },
  }));

function registerModels(globals) {
  globals.userRolesStore = UserRolesStore.create({}, globals);
}

export { UserRolesStore, registerModels };
