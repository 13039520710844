/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook access requests
import { types, applySnapshot } from 'mobx-state-tree';

import NotebookAccessRequest from './NotebookAccessRequest';

// ==================================================================
// NotebookAccessRequest
// ==================================================================
const NotebookAccessRequests = types
  .model('NotebookAccessRequests', {
    id: types.identifier,
    requests: types.array(NotebookAccessRequest),
  })
  .actions(self => ({
    setAccessRequest(rawAccessRequest) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawAccessRequest);
    },
  }))
  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // add view methods here
  }));

export default NotebookAccessRequests;
