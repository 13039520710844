/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { displayError, displaySuccess } from '../../helpers/notification';
import { createForm } from '../../helpers/form';

import Form from '../helpers/fields/Form';

class DeleteData extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.secureOutput = props.secureOutput;
    this.store = props.secureOutputsStore;
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = _form => {
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async _form => {
    try {
      await this.store.deleteData(this.secureOutput.id);
      this.cleanModal();

      displaySuccess('Secure output data deleted.');
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    const form = createForm({});
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Secure Output Data Delete
          </BaseHeader>
          <div className="mx3 animated fadeIn">
            <Form
              form={form}
              onCancel={this.handleFormCancel}
              onSuccess={this.handleFormSubmission}
              onError={this.handleFormError}
            >
              {({ processing, _onSubmit, onCancel }) => {
                return (
                  <>
                    Confirm deletion?
                    <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                      Delete data
                    </Button>
                    <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                      Cancel
                    </Button>
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Delete data
      </Button>
    );
  }
}

export default inject('userStore')(observer(DeleteData));
