/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import {
  getSecureOutputPermissions,
  requestSecureOutputEgress,
  approveSecureOutputEgress,
  rejectSecureOutputEgress,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { SecureOutputPermissions } from './SecureOutputPermissions';

// ==================================================================
// StudyStore
// ==================================================================
const SecureOutputPermissionsStore = BaseStore.named('SecureOutputPermissionsStore')
  .props({
    secureOutputId: types.identifier,
    secureOutputPermissions: types.maybe(SecureOutputPermissions),
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const newPermissions = await getSecureOutputPermissions(self.secureOutputId);
        if (!self.secureOutputPermissions || !_.isEqual(self.secureOutputPermissions, newPermissions)) {
          self.runInAction(() => {
            self.secureOutputPermissions = newPermissions;
          });
        }
      },

      cleanup: () => {
        superCleanup();
      },

      requestEgress: async secureOutput => {
        await requestSecureOutputEgress(secureOutput);
        await self.load();
      },

      approveEgress: async secureOutput => {
        await approveSecureOutputEgress(secureOutput);
        // await self.load();
      },

      rejectEgress: async (secureOutput, adminComments) => {
        await rejectSecureOutputEgress(secureOutput, adminComments);
        // await self.load();
      },
    };
  });

export { SecureOutputPermissionsStore };
