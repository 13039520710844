/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getEnv, types } from 'mobx-state-tree';

import { getDownloadKeys, createNewDownloadKey, revokeDownloadKey } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import DownloadKey from './DownloadKey';
import UserIdentifier from '../users/UserIdentifier';

const DownloadKeysStore = BaseStore.named('DownloadKeysStore')
  .props({
    userIdentifierStr: types.identifier,
    userIdentifier: UserIdentifier,
    downloadKeys: types.optional(types.map(DownloadKey), {}),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      async doLoad() {
        const username = self.userIdentifier.username;
        const ns = self.userIdentifier.ns;
        // do not pass username or ns params when loading api keys for current user
        const downloadKeys = await getDownloadKeys(!self.isStoreForCurrentUser() && { username, ns });
        self.runInAction(() => {
          const map = {};
          downloadKeys.forEach(downloadKey => {
            const downloadKeyModel = DownloadKey.create(downloadKey);
            map[downloadKeyModel.id] = downloadKeyModel;
          });
          self.downloadKeys.replace(map);
        });
      },
      async createNewDownloadKey(name, files) {
        const username = self.userIdentifier.username;
        const ns = self.userIdentifier.ns;
        const downloadKey = await createNewDownloadKey(name, files, !self.isStoreForCurrentUser() && { username, ns });
        self.runInAction(() => {
          // The put call below will automatically use the id from ApiKey
          // (as it is marked "types.identifier") and add that as a key in the map and
          // store the object as value against it
          self.downloadKeys.put(DownloadKey.create(downloadKey));
        });
        return downloadKey;
      },
      async revokeDownloadKey(downloadKeyId) {
        const username = self.userIdentifier.username;
        const ns = self.userIdentifier.ns;
        const downloadKey = await revokeDownloadKey(downloadKeyId, { username, ns });
        self.runInAction(() => {
          self.downloadKeys.put(DownloadKey.create(downloadKey));
        });
      },
      cleanup: () => {
        self.user = undefined;
        superCleanup();
      },
    };
  })
  .views(self => ({
    get empty() {
      return self.downloadKeys.size === 0;
    },
    get list() {
      const result = [];
      // converting map self.apiKeys to result array
      self.downloadKeys.forEach(downloadKey => result.push(downloadKey));
      return result;
    },
    isStoreForCurrentUser: () => {
      const username = self.userIdentifier.username;
      const ns = self.userIdentifier.ns;

      const userStore = getEnv(self).userStore;
      const currentUser = userStore.user;
      return currentUser.username === username && currentUser.ns === ns;
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use UserStore.apiKeysStore or UsersStore.getApiKeysStore(userIdentifier)
export default DownloadKeysStore;
