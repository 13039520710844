import React from 'react';
import { Modal, Dimmer, Loader, Dropdown, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';

import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - study
class RequestPublishStudy extends React.Component {
  constructor(props) {
    super(props);
    this.store = props.study.getPublishRequestsStore();
    this.componentStore = observable({
      showModal: false,
      showLoader: false,
    });
  }

  componentDidMount() {
    swallowError(this.store.load());
    this.store.startHeartbeat();
  }

  componentWillUnmount() {
    this.store.stopHeartbeat();
  }

  async handleRequestPublishStudy() {
    runInAction(() => {
      this.componentStore.showModal = false;
      this.componentStore.showLoader = true;
    });
    try {
      // submit upgrade request
      await this.store.requestPublish(this.props.study.id);

      displaySuccess('A publish request has been submitted for admin approval.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  renderModal() {
    const message = (
      <Modal.Description>
        <p>
          A publish request will be submitted to the RAPTOR administrators to push data from staging into the production
          bucket.
        </p>

        <Label basic color="red">
          <p>
            Important notice:
            <ul>
              <li>
                Files uploaded to the staging bucket will OVERWRITE existing files in the study at the same path upon
                data publish.
              </li>
              <li>
                Users performing self-uploads are to ensure there are no impartial or ongoing uploads prior to
                requesting for publish.
              </li>
            </ul>
          </p>
        </Label>

        <p>Are you sure you want to proceed?</p>
      </Modal.Description>
    );
    return (
      <ConfirmationModal
        open={this.componentStore.showModal}
        header="Request Publish Study"
        message={message}
        onConfirm={() => {
          this.handleRequestPublishStudy();
        }}
        onCancel={() => {
          runInAction(() => {
            this.componentStore.showModal = false;
          });
        }}
      />
    );
  }

  render() {
    if (!this.store.hasOngoing()) {
      return (
        <>
          <Dimmer active={this.componentStore.showLoader}>
            <Loader />
          </Dimmer>
          <Dropdown.Item
            onClick={() => {
              runInAction(() => {
                this.componentStore.showModal = true;
              });
            }}
          >
            Request Staging Data Publish
          </Dropdown.Item>
          {this.renderModal()}
        </>
      );
    }
    return <></>;
  }
}

export default observer(RequestPublishStudy);
