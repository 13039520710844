import _ from 'lodash';
import React from 'react';
import { makeObservable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Container } from 'semantic-ui-react';

import Stores from '../../models/Stores';
import { withRouter } from '../../helpers/routing';
import { swallowError } from '../../helpers/utils';
import { isStoreLoading, isStoreEmpty, isStoreNotEmpty, isStoreError } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import PublishRequestsCard from './PublishRequestsCard';

class PublishRequestsList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    runInAction(() => {
      this.store = new Stores([this.props.publishRequestsStore, this.props.amiPublishRequestsStore]);
    });
  }

  componentDidMount() {
    swallowError(this.store.load());
  }

  render() {
    const store = this.store;
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreEmpty(this.props.publishRequestsStore) && isStoreEmpty(this.props.amiPublishRequestsStore)) {
      content = this.renderEmpty();
    } else if (isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No Pending Publish Requests
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="list alternate outline" className="align-top" />
          <Header.Content className="left-align">Publish Requests</Header.Content>
        </Header>
      </div>
    );
  }

  // added leeas - add support for AMI publish request handling
  renderMain() {
    const publishRequests = this.props.publishRequestsStore.listAsMap;
    const amiPublishRequests = this.props.amiPublishRequestsStore.listAsMap;	  
    const studies = Object.keys(publishRequests);
    const amis = Object.keys(amiPublishRequests);	  

    return (
      <div>
        { studies.length > 0 &&  _.map(studies, study => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={study} data-instance={study}>
            <PublishRequestsCard requestId={study} publishRequest={publishRequests[study]} type="study" />
          </Segment>
        ))}
	{ amis.length > 0 &&  _.map(amis, ami => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={ami} data-instance={ami}>
            <PublishRequestsCard requestId={ami} publishRequest={amiPublishRequests[ami]} type="ami" />
          </Segment>
        ))}    
      </div>
    );
  }
}

export default inject('publishRequestsStore', 'amiPublishRequestsStore')(withRouter(observer(PublishRequestsList)));
