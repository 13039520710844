/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Button, Dropdown, Input, Table } from 'semantic-ui-react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

const TagsEditorLine = observer(({ tagSet, onRemove, index }) => {
  const options = tagSet.values.map(value => {
    return { key: value, text: value, value };
  });

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          placeholder="Name"
          defaultValue={tagSet.key}
          fluid
          onChange={(e, { value }) => {
            runInAction(() => {
              tagSet.key = value;
            });
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          placeholder="Values"
          fluid
          multiple
          selection
          allowAdditions
          search
          value={tagSet.values}
          options={options}
          onChange={(e, { value }) => {
            runInAction(() => {
              tagSet.values = value;
            });
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          onClick={e => {
            e.preventDefault();
            onRemove(index);
          }}
        >
          Remove
        </Button>
      </Table.Cell>
    </Table.Row>
  );
});
export default TagsEditorLine;
