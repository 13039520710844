import React from 'react';
import { Modal, Dimmer, Loader, Button, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { swallowError } from '../../helpers/utils';
import { displayError, displaySuccess } from '../../helpers/notification';

// expected props
// - userStore
// - usersStore
class UserAgreement extends React.Component {
  constructor(props) { 
    super(props);
    this.componentStore = observable({
      hasAccepted: false,	    
      showAgreementModal: false,
      showLoader: false,
    });
    this.state = observable({
      disableButton: true
    });
  }

  componentDidMount() {
    swallowError(this.props.usersStore.load());
    this.props.usersStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.props.usersStore.stopHeartbeat();
  }

  getStore() {
    return this.props.usersStore;
  }

  updateHasAccepted() {
    runInAction(() => {
      this.componentStore.hasAccepted = true;
      this.componentStore.showAgreementModal = false;	    
    });
  }
	  

  setShowAgreementState(state) {
    runInAction(() => {
      this.componentStore.showAgreementModal = state;
    });
  }

  async handleCancel() {
    if (this.componentStore.hasAccepted === false) {
	this.setShowAgreementState(true);
    } else {
	this.setShowAgreementState(false);
    }
  }
    	  
  async handleAcceptAgreement() {
    runInAction(() => {
      this.componentStore.showAgreementModal = false;
      this.componentStore.showLoader = true;	    
    });
    try {
      // update user
      const updateUser = this.props.userStore.cloneUser;
      updateUser.acceptUserAgreement = true;	    
      await this.getStore().accedeUserAgreement(updateUser);
      await this.props.userStore.load();  
      this.updateHasAccepted();	    
      displaySuccess('User had accepted the User Agreement', 'Success');
	    
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  renderModal() {
    const handleScroll = (e) => {
        const isBottom = (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50);
	if (isBottom) { console.log('bottom is reached'); }    
        this.setState({ disableButton: (!isBottom) });
    };

    const message = (
      <Modal.Description>
        <div className="header">User Agreement - RAPTOR USER</div>    
        <div className="scrolling content" onScroll={handleScroll}>
         <div className="ui segments">
          <div className="ui segment">
	    <p>Last updated 29 May 2023</p> 
            <p><b>Introduction</b></p>
	    <p>This is the RAPTOR User Agreement of the Genome Institute of Singapore (GIS), which is applicable to the sharing and usage of data on the RAPTOR platform. Adherence to this Agreement ensures data privacy and security in a reasonable way and helps meet the requirements and standards set by global and local laws, regulations, and policies.</p>
          </div>
          <div className="ui segment">
            <p><b>Intended Audience</b></p>
	    <p>The intended audience for this document includes all individuals who are using the RAPTOR platform, regardless of the form of use. This includes using RAPTOR to host, access and analyse data. It is important to understand and adhere to the principles outlined in this agreement to ensure responsible sharing and usage of data while protecting the privacy and security of those whose data are being shared and analysed.</p>
          </div>
          <div className="ui segment">
           <p><b>Definitions</b></p>
	   <p><ol type="a">
	    <li>Agreement: This User Agreement document</li>
	    <li>Institute: Genome Institute of Singapore</li>
	    <li>Platform: RAPTOR website</li>
	    <li>Administrators: RAPTOR administrators</li>
	    <li>Stakeholders: All individuals who are using the Platform, regardless of form of use</li>
            <li>Data: data hosted on the Platform</li>
            <li>Workspace: Compute resources provisioned and managed by the Platform for users of the Data and/or owner of the Data to perform authorized work</li>
            <li>Requestor: user who requested for access to Data</li>
            <li>Data Access Committee (DAC):  Data&apos;s administrators and/or owner</li>
            <li>HIM-DM: HealthTech Instruction Manual - Data Management</li>
            <li>Study: a logical collection of Data, with the definition of the types as follows:</li>
	    <ol type="1">
              <li>Public Study: Data is not access controlled, anyone with an account on the Platform is able to access the Data.</li>
              <li>Private Study: Data is access controlled, users with an account on the Platform can request for access.</li>
              <li>Private-Secure Study: Data is access controlled, users with an account on the Platform can request for access. Additional security implementations in place for egress protection.</li>
	    </ol>
	   </ol></p> 
          </div>
          <div className="ui segment">
           <p><b>Contact</b></p>
	   <p>For clarifications on the Agreement, please contact us at raptor_admin@gis.a-star.edu.sg.</p> 
          </div>
          <div className="ui segment">
           <p><b>Personal Integrity</b></p>
	   <p>Personal integrity refers to the principle and practice of ensuring the accuracy, completeness and reliability of personal data throughout its lifecycle, and ensures accountability during Platform use.</p>
	   <p><ol type="a">
	     <li>Do not use another user&apos;s identity when accessing the Platform and/or Workspace.</li>
             <li>Do not trick or mislead the Administrators, DAC members, and/or other stakeholders when responding to questions and request for clarifications.</li>
             <li>Do not impersonate the Institute, Institute employees, another stakeholder or any other person or entity for any purpose at any time when using the Platform, Workspaces and/or Data.</li>
             <li>Ensure that contact information registered with the Platform is up to date.</li>
	    </ol></p>
          </div>
	  <div className="ui segment">
	    <p><b>Personal Security</b></p>
	    <p>Personal security aims to prevent identity theft, fraud, unauthorized data disclosure, and other forms of privacy violations that may lead to harm or misuse of personal information.</p>
	    <p><ol type="a">
	      <li>Always maintain confidentiality of credentials used for the Platform, Workspace and Data.</li>   
	      <li>Change your Platform password at least once a year.</li>
              <li>Change your Platform and/or Workspace credentials and contact the Administrators immediately if someone has stolen, or it is suspected that someone has gained unauthorized access to your credentials.</li>
              <li>Do not leave your device unattended while logged in to the Platform and/or Workspace, or accessing Data.</li>
              <li>Always logoff the Platform and/or Workspace when access is no longer required.</li>
              <li>Ensure that the internet browser of the access device is updated regularly for security fixes and patches before accessing the Platform.</li>
	    </ol></p>
          </div>
	  <div className="ui segment">
	    <p><b>Platform Security</b></p>
	    <p>The goal of Platform security is to establish a secure foundation for data processing by addressing potential vulnerabilities, protecting against external threats, and ensuring the overall resilience and reliability of the Platform.</p>
	    <p><ol type="a">
	      <li>Do not introduce any viruses, Trojan horses, adware, spyware, worms, logic bombs or other material that is malicious into the Platform.</li>
              <li>Do not circumvent any security-related features that prevent or restrict the use of any functionalities on the Platform.</li>
   	      <li>Do not participate in any activity that interferes with or disrupts the Platform (or the servers and networks which are connected to the Platform).</li>
              <li>Do not participate in any automated use of the Platform, such as using robots, spiders or other automatic device, process, or means to access the Platform for any purpose.</li>
	      <li>Do not install software that allows a third-party to discover, interfere with or gain unauthorized access to the Platform.</li>
  	      <li>Discontinue use of the Platform if it shows signs of being infected by a virus or other malware and report the incident to the Administrators.</li>
              <li>Report all security incidents or suspected incidents to the Administrators.</li>
            </ol></p>
	  </div>
	  <div className="ui segment">
	    <p><b>Workspace Security</b></p>
	    <p>Workspace security refers to the measures and practices implemented to ensure the resilience and reliability of work environments. It encompasses safeguards to minimize the risk of environment compromise, vulnerability exploitation, and other security incidents within the workspace.</p>
	    <p><ol type="a">
	      <li>Do not introduce any viruses, Trojan horses, adware, spyware, worms, logic bombs or other material that is malicious into the Workspace.</li>
              <li>Do not circumvent any security-related features that prevent or restrict the use of any functionalities on the Workspace.</li>
              <li>Do not participate in any activity that interferes with or disrupts the Workspaces (or the servers and networks which are connected to the Workspace).</li>
              <li>Always comply with copyright and licenses of proprietary third-party software when installing into the Workspace.</li>
    	      <li>Do not install software that allows a third-party to discover, interfere with or gain unauthorized access to the Workspaces.</li>
              <li>Do not enable password authentication for Linux-based Workspaces.</li>
              <li>Do not share Workspaces.</li>
              <li>Perform updates and/or migrations within the time frame specified upon receiving security bulletins pertaining to known application, operating system, and software vulnerabilities, or EOL announcements.</li>
              <li>Discontinue use of and terminate any Workspaces that show signs of being infected by a virus or other malware and report the incident to the Administrators.</li>
              <li>Report all security incidents or suspected incidents to the Administrators.</li>
	    </ol></p>
           </div>
	   <div className="ui segment">
            <p><b>Data Security</b></p>
	    <p>Data security refers to the measures implemented to ensure the protection of Data. It encompasses safeguards to minimize the risk of unauthorized access, data breach and disclosure, and other security incidents related to the Data.</p>
	    <p><ol type="a">
	      <li>Do not circumvent any security-related features that prevent or restrict the access and/or use of any Data. For example, if copy and paste functionalities are not allowed, do not take screenshots or photos of the Data.</li>
              <li>Ensure that Data that requires egress protection is protected by the security features implemented by the correct Study type (for e.g. clinical data classified as high sensitivity under HIM-DM, or data that needs to be protected against export out of the Platform must be contained within a Private-Secure Study with egress protection).</li>
            </ol></p>
           </div>
	   <div className="ui segment">
	    <p><b>Data Classification</b></p>
	    <p>This process refers to categorizing data based on its sensitivity, criticality, and the level of protection required. It is important to consider the potential harm that could result from disclosure of Data, such as emotional distress or discrimination of one whose Data was disclosed, and loss of reputation of organizations, groups and individuals who handled the Data.</p>
	    <p><ol type="a">
	      <li>Ensure that the Data has undergone documented security and sensitivity classification.</li>
              <li>Ensure that the Data has been authorized to be transferred to and/or hosted on the Platform and any documentation pertaining to the authorization has been duly completed before uploading your Data.</li>
 	      <li>Ensure that special restrictions and security requirements enforced by existing security policies (such as HIM-DM) that apply to your Data can be always adhered to by the Platform and/or Workspaces before uploading your Data. Administrators must be contacted for clarity if there are doubts.</li>
	    </ol></p>
	   </div>
           <div className="ui segment">
	    <p><b>Controlled Access</b></p>
	    <p>This is the practice of restricting and managing the access to sensitive or confidential data. It involves implementing security measures and controls to ensure that only authorized individuals are granted access to specific data sets or resources, while unauthorized users are prevented from accessing or viewing the information.</p>
	    <p><ol type="a">
	      <li>Ensure that an active member of RAPTOR is appointed as the Data owner. Any changes to the Data owner should be communicated to the Administrators. Data found not having an active Data owner will be archived and deleted after 6 months.</li>
              <li>Ensure that a DAC with active members is appointed for controlling access to the Data. These stakeholders should be appropriately trained to fulfil their duties.</li> 
              <li>Ensure that all access requests, egress requests to Data are vetted and verified. Seek clarification from Administrators if Requestor identity verification is needed.</li>
               <li>Ensure that authorized scope of use for your Data is defined and communicated to the Requestor.</li>
               <li>Ensure that administrative actions on access parameters for a Requestor are performed upon notification of changes to the Requestor status, Data sharing permissions or any such changes that affect the access parameters of the Requestor. For instance, revoking a Requestor’s permission to your Data if the Requestor is no longer working on the project.</li>
  	       <li>Regularly assess all users with access to Data to ensure access is valid.</li>
               <li>Ensure that an access request is submitted when access is required to Data that has not yet been granted.</li>      
	       <li>Do not access, process and/or modify Data for which access has not been granted, or access has expired or revoked.</li>
               <li>Do not retrieve Data for, or copy and distribute Data to other users.</li>
               <li>Report to the Administrators if access granted to Data is believed to be erroneous or accidental, and not use the Data until investigations are complete.</li>
               <li>Safeguard against unauthorized access, disclosure, and use of Data. For example, you must not allow other users to take photos of the Data.</li>
	     </ol></p>
           </div>
	   <div className="ui segment">
            <p><b>Data privacy</b></p>
            <p>Data privacy refers to the protection and management of personal or sensitive information to ensure that it is handled in a manner that respects individuals&apos; privacy rights.</p>
	    <p><ol type="a">
              <li>Do not make any unauthorized use of information of other users obtained from the Platform, such as sending unsolicited emails.</li>
	       <li>Do not attempt to re-identify individuals included in the Data. If identifiable Data is found, contact Administrators immediately.</li>
	       <li>Do not use the Data outside of the authorized scope set by the Data owner and/or DAC.</li>
               <li>Do not exfiltrate as-is or derived Data that has egress protection in place (for e.g. clinical data classified as high sensitivity under HIM-DM, or data that needs to be protected against export out of the Platform) under Private-Secure Studies without approval from DAC.</li>
	     </ol></p>	 		  
           </div>
           <div className="ui segment">
             <p><b>Breach of Agreement</b></p>
             <p><ol type="a">
		  <li>Suspension
	              <p>The Institute may suspend your right to use the Platform, Workspaces and/or Data at any time if we determine:</p>
		      <p><ol type="1">
		        <li>You pose a security risk to the Platform, Workspaces and/or Data.</li>
                        <li>You could adversely impact the Platform, Workspaces and/or Data.</li>
                        <li>You could be fraudulent or in breach of the Agreement.</li>
		      </ol></p></li>
		  <li>Termination
	              <p>The Institute may terminate your right to use the Platform, Workspaces and/or Data at any time.</p></li>
                  <li>Reporting a breach
	              <p><ol type="1">
		        <li>If you suspect that any Data or User is in breach of this Agreement, please refer to the contact details in Introduction to let us know which Data or User is in breach and why.</li>
 	                <li>We will reasonably determine whether Data or User breaches this Agreement.</li>
		        <li>The consequences for violating our Agreement will vary depending on the severity of the breach.</li>
		      </ol></p></li>
              </ol></p>
           </div>     
           <div className="ui segment">
             <p>I have read the above User Agreement.</p>
	     <p>I acknowledge and agree that my access to the Platform, Workspaces and/or Data is covered by, and subject to, the Agreement. Further, I acknowledge that the Institute retains the right to terminate or suspend my access rights to the Platform, Workspaces and/or Data at any time, without notice.</p> 
	   </div>	  
         </div>
        </div>  
      </Modal.Description>
    );
    return (
      <Modal open={this.componentStore.showAgreementModal} size="tiny" onClose={() => { this.handleCancel(); }} closeOnDimmerClick>
        <Header content="User Agreement" />
        <Modal.Content>{message}</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { this.handleCancel(); }}>Cancel</Button>
          <Button color="blue" disabled={this.state.disableButton} onClick={() => { this.handleAcceptAgreement(); }}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const currUser = this.props.userStore.user;
    //console.log("currUser: " + currUser.username + " hasAcceptedAgreement: " + currUser.hasAcceptedAgreement);	  
    if (!currUser.hasAcceptedAgreement) {
	this.setShowAgreementState(true);    
	 //console.log("showAgreementModal: " + this.componentStore.showAgreementModal);   
        return (
          <>
            <Dimmer active={this.componentStore.showLoader}>
              <Loader />
            </Dimmer>
            
            {this.renderModal()}
          </>
        );
    } 
    return null;
  }
}

export default observer(UserAgreement);
