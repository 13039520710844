/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Table, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import TagsEditorLine from './TagsEditorLine';

const TagsEditor = observer(({ target }) => {
  function renderTags() {
    if (!target.tags || target.tags.length === 0) {
      return null;
    }
    return target.tags.map((tagSet, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key,no-shadow
        <TagsEditorLine key={tagSet.id} index={index} tagSet={tagSet} onRemove={ind => target.removeTagAtIndex(ind)} />
      );
    });
  }

  return (
    <Table>
      <Table.Body>
        {renderTags()}
        <Table.Row>
          <Table.Cell colSpan={3}>
            <Button
              onClick={e => {
                e.preventDefault();
                target.addTag({ key: '', values: [] });
              }}
            >
              Add Tags
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
});

export default TagsEditor;
