import { createForm } from '../../helpers/form';

const createCollectionFields = {
  // General fields
  collectionName: {
    label: 'Name',
    placeholder: 'A name for the collection',
    rules: 'required|string|between:1,100|regex:/^[A-Za-z0-9-_]+$/',
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the collection',
    rules: 'required|string|between:1,3000',
  },
};

const getCreateCollectionForm = () => {
  return createForm(createCollectionFields);
};

export { getCreateCollectionForm }; // eslint-disable-line import/prefer-default-export
