/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { barOptions } from './graph-options';

Chart.register(...registerables);

function BarGraph({ className, data, title, width = 250, height = 120 }) {
  return (
    <div className={className}>
      <div className="fs-9 center mt1 mb1">{title}</div>
      <div>
        <Bar className={className} data={data} width={width} height={height} options={barOptions} />
      </div>
    </div>
  );
}

export default BarGraph;
