/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import c from 'classnames';

import Header from './Header';
import Description from './Description';
import ErrorPointer from './ErrorPointer';

// expected props
// - form (via props)
// - field (via props), this is the mobx form field object
// - show (via props), can be 'headerOnly', 'toggleOnly', 'both' (default to 'both')
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - disabled (via props), default to false
// - size (via props), default to large
// edited by 2359 - changed mb4 to mb0
const Component = observer(
  ({ field, disabled = false, show = 'both', className = 'mb0', size = 'large', onChange }) => {
    const { id, value, sync, error = '', extra = {} } = field;
    const { yesLabel = 'Yes', noLabel = 'No' } = extra || {};
    const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
    const isDisabled = field.disabled || disabled;
    const disabledClass = isDisabled ? 'disabled' : '';
    const errorClass = hasError ? 'error' : '';
    const yesSelected = (_.isBoolean(value) && value === true) || value === 'true';
    const cursor = isDisabled ? 'op-3' : 'cursor-pointer';
    const handleClick = toAssign => event => {
      event.preventDefault();
      event.stopPropagation();
      if (isDisabled) return;
      sync(toAssign);
      field.validate({ showErrors: true });
      if (onChange) {
        onChange(toAssign);
      }
    };

    const yesAttributes = {
      name: 'toggle on',
      color: hasError ? 'red' : 'blue',
      size,
      className: 'mr1',
      disabled: isDisabled,
    };
    const noAttributes = {
      name: 'toggle off',
      color: hasError ? 'red' : 'grey',
      size,
      className: 'mr1',
      disabled: isDisabled,
    };

    const headerOrHeaderAndToggle = show === 'both' || show === 'headerOnly';
    const headerOnly = show === 'headerOnly';
    const toggleOnly = show === 'toggleOnly';

    const toggleButton = (
      <div className={c(hasError ? 'color-red' : '')}>
        {yesSelected && (
          <span id={id} className={c(cursor)} onClick={handleClick(false)}>
            <Icon {...yesAttributes} />
            {yesLabel}
          </span>
        )}
        {!yesSelected && (
          <span id={id} className={c('op-65', cursor)} onClick={handleClick(true)}>
            <Icon {...noAttributes} />
            {noLabel}
          </span>
        )}
      </div>
    );

    return (
      <div className={c(className, errorClass, disabledClass)}>
        {headerOrHeaderAndToggle && (
          <>
            <div className="flex flex-wrap mb1">
              <Header field={field} className="mt0 mb0 mr2" />
              {!headerOnly && toggleButton}
            </div>
            <Description field={field} />
            <ErrorPointer field={field} />
          </>
        )}
        {toggleOnly && toggleButton}
      </div>
    );
  },
);

export default Component;
