/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

// ==================================================================
// Sagemaker Instance Types
// ==================================================================
const SagemakerInstanceType = types
  .model('SagemakerInstanceType', {
    memory: types.string,
    vCpu: types.integer,
    instanceType: types.string,
    pricePerUnit: types.string,
    unit: types.string,	  
  })
  .actions(self => ({
    setSagemakerInstanceType(rawInstance) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic

      // Preserve the value of the fetchingUrl
      const fetchingUrl = self.fetchingUrl;
      applySnapshot(self, rawInstance);
      self.fetchingUrl = fetchingUrl;
    },
  }));

export { SagemakerInstanceType };
