

import React from 'react';
import { Button, Header as BaseHeader, Modal, Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getEditAmiForm } from '../../models/forms/EditAmi';
import { displayError } from '../../helpers/notification';

import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';

class EditAmi extends React.Component {
  modalOpen = false;	
	
  constructor(props) {
    super(props);
    makeObservable(this, {
	  modalOpen: observable
    });	
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const amiValues = form.values();
      
      // Edit study, clear form, and close modal
      await this.props.ami.updateAmi(amiValues);

      form.clear();
      this.cleanModal();

    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Edit AMI
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderEditAmiForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
	return (<SemanticDropdown.Item onClick={action(() => {
          this.modalOpen = true;
        })}>Edit AMI</SemanticDropdown.Item>);
  }

  renderEditAmiForm() {
      
    const form = getEditAmiForm({
        name: {value: this.props.ami.name}, 
        description: {value: this.props.ami.description}
    });

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {

          return (
            <>
              <Input field={form.$('name')}/>
              <TextArea field={form.$('description')}/>
              
              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Edit AMI
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }


}

export default inject('userStore', 'environmentsStore')(observer(EditAmi));
