/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';

import { Icon } from 'semantic-ui-react';

import { gotoFn, withRouter } from '../../../helpers/routing';
import CreateEnvironmentForm from '../CreateEnvironmentForm';

// edit 2359 - add support for EMR notebook selection
class Details extends React.Component {
  handleBack = form => {
    if (form && form.clear) {
      form.clear();
    }
    this.props.onBack();
  };

  handleFormSuccess = () => {
    const onSuccess = this.props.onSuccess || _.noop;
    onSuccess();
    const goto = gotoFn(this);
    const envType = this.props.envType;
    // console.log("envtype: " + envType);
    goto(`/workspaces/${envType}`);
  };

  // edit 2359 - add support for EMR notebook selection
  render() {
    const { offering, files, secureFiles, outputs, isSecure, notebooks } = this.props;

    return offering ? (
      <div>
        {this.renderButton()}
        <div className="mt3">
          <CreateEnvironmentForm
            offering={offering}
            onCancel={this.handleBack}
            onSuccess={this.handleFormSuccess}
            files={files}
            amiId={this.props.amiId}
            envType={this.props.envType}
            isSecure={isSecure}
            secureFiles={secureFiles}
            outputs={outputs}
            notebooks={notebooks}
          />
        </div>
      </div>
    ) : (
      this.renderButton()
    );
  }

  renderButton() {
    return (
      <div onClick={this.handleBack} className="cursor-pointer">
        <Icon name="left chevron" color="grey" />
        Back
      </div>
    );
  }
}

export default withRouter(Details);
