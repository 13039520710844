import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

import { swallowError } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreNew } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';

class StudyPublishRequestsTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.store = props.study.getPublishRequestsStore();
    // this.study = props.study;
  }

  componentDidMount() {
    swallowError(this.store.load());
    this.store.startHeartbeat();
  }

  componentWillUnmount() {
    this.store.stopHeartbeat();
  }

  render() {
    let content;
    if (isStoreError(this.store)) {
      content = <ErrorBox error={this.store.error} />;
    } else if (isStoreLoading(this.store) || isStoreNew(this.store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    const requests = this.store.requests;

    const renderRow = request => {
      return (
        <Table.Row key={request.id}>
          <Table.Cell>{request.id}</Table.Cell>
          <Table.Cell>{request.status}</Table.Cell>
          <Table.Cell>{request.comments}</Table.Cell>
          <Table.Cell>{request.createdAt}</Table.Cell>
          <Table.Cell>{request.updatedAt}</Table.Cell>
        </Table.Row>
      );
    };

    return (
      <Dimmer.Dimmable dimmed={this.isProcessing}>
        <Dimmer active={this.isProcessing} inverted>
          <Loader size="big" />
        </Dimmer>

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Publish Request</Table.HeaderCell>
              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell width={2}>Admin comments</Table.HeaderCell>
              <Table.HeaderCell width={2}>Date created</Table.HeaderCell>
              <Table.HeaderCell width={2}>Date updated</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{_.map(requests, request => renderRow(request))}</Table.Body>
        </Table>
      </Dimmer.Dimmable>
    );
  }
}

export default observer(StudyPublishRequestsTable);
