/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getCreateSecureOutputForm } from '../../models/forms/CreateSecureOutputForm';
import { getCreateSmSecureOutputForm } from '../../models/forms/CreateSmSecureOutputForm';
import { displayError } from '../../helpers/notification';

import Dropdown from '../helpers/fields/DropDown';
import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';

// expected props
// - secureOutputStore
// - userStore (via injection)
// - projectsStore (via injection)
// - indexesStore (via injection)
class CreateSecureOutput extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    // this.projectToIndex = {};
    // this.indexToAccount = {};
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      fsxSizeListInGb: [],
    };

    this.hideStorage = (typeof this.props.isSagemaker === 'undefined' ? false: this.props.isSagemaker);	    
  }

  componentDidMount() {
    this.getFsxSizeSetting();
  }

  async getFsxSizeSetting() {
    const fsxSizeSetting = await this.getSettingsStore.getSetting('fsxSizeInGb');
    const arr = fsxSizeSetting.value.split(',');
    const fsxSizeListInGb = [];
    Object.values(arr).forEach(size => {
      fsxSizeListInGb.push({ key: size, value: size, text: `${size}GB` });
    });
    this.setState({ fsxSizeListInGb });
  }

  get getSettingsStore() {
    return this.props.settingsStore;
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const secureOutputValues = form.values();

      // Create secure output, clear form, and close modal
      const inputSecureOutput = {
        ...secureOutputValues,
        parentStudy: this.props.parentStudies,
      };

      if (this.hideStorage) {	 
	  if (_.has(inputSecureOutput, 'fsxStorageSize')) {
	      delete inputSecureOutput.fsxStorageSize;
	  }
      } else {
          const intFsxStorageSize = parseInt(secureOutputValues.fsxStorageSize.replace('GB', ''), 10);
          Object.assign(inputSecureOutput, { fsxStorageSize: intFsxStorageSize });
      }

      const secureOutput = await this.props.secureOutputsStore.createSecureOutput(inputSecureOutput);
      form.clear();
      this.cleanModal();
      if (this.props.onCreate && typeof this.props.onCreate === 'function') {
        this.props.onCreate(secureOutput);
      }
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Create Secure Output
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderCreateSecureOutputForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
        type="button"
      >
        Create Secure Output
      </Button>
    );
  }

  renderCreateSecureOutputForm() {
    let form = getCreateSecureOutputForm();	  
    if (this.hideStorage) {
        form = getCreateSmSecureOutputForm();
    }
    const projectIds = this.props.userStore.projectIdDropdown;

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('id')} />
              <Input field={form.$('name')} />
              <TextArea field={form.$('description')} />
              <Dropdown field={form.$('projectId')} options={projectIds} fluid selection />
              { (!this.hideStorage) && <Dropdown
                field={form.$('fsxStorageSize')}
                options={this.state.fsxSizeListInGb}
                fluid
                placeholder="please select storage size"
                selection
              /> }

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Create Secure Output
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(CreateSecureOutput));
