import { createForm } from '../../helpers/form';

const rejectSecureOutputEgressFields = {
  // General fields
  adminComments: {
    label: 'Admin/Owner Comments',
    value: '',
    rules: 'required|string|between:1,3000',
  },
};

const getRejectSecureOutputEgressForm = () => {
  return createForm(rejectSecureOutputEgressFields);
};

export { getRejectSecureOutputEgressForm }; // eslint-disable-line import/prefer-default-export
