import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { listStudyPublishRequests } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import PublishRequest from './PublishRequest';

// ==================================================================
// PublishRequestsStore
// ==================================================================
const PublishRequestsStore = BaseStore.named('PublishRequestsStore')
  .props({
    publishRequests: types.array(PublishRequest),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      doLoad: async () => {
        const newPublishRequests = await listStudyPublishRequests();
        if (!self.publishRequests || !_.isEqual(self.publishRequests, newPublishRequests)) {
          self.runInAction(() => {
            self.publishRequests = newPublishRequests;
          });
        }
      },
      cleanup: () => {
        self.publishRequests.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.publishRequests.size === 0;
    },

    get total() {
      return self.publishRequests.size;
    },

    get list() {
      return self.publishRequests;
    },

    get listAsMap() {
      const mapping = {};
      self.publishRequests.forEach(req => {
        if (Object.prototype.hasOwnProperty.call(mapping, req.studyId)) {
          mapping[req.studyId].push(req);
        } else {
          mapping[req.studyId] = [req];
        }
      });
      return mapping;
    },

    hasPublishRequest(id) {
      return self.publishRequests.has(id);
    },

    getPublishRequest(id) {
      return self.publishRequests.get(id);
    },
  }));

function registerModels(globals) {
  globals.publishRequestsStore = PublishRequestsStore.create({}, globals);
}

export { PublishRequestsStore, registerModels };
