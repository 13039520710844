/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getUser } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { User } from './User';

const UserStore = BaseStore.named('UserStore')
  .props({
    user: types.maybe(User),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const user = await getUser();
        self.runInAction(() => {
          self.user = User.create(user);
        });
      },
      cleanup: () => {
        self.user = undefined;
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return _.isEmpty(self.user);
    },

    get projectIdDropdown() {
      const result = [];
      // converting map self.users to result array
      self.user.projectId.forEach(project => {
        const res = {};
        res.key = project;
        res.value = project;
        res.text = project;
        result.push(res);
      });
      return result;
    },

    get cloneUser() {
      let result = {};
      const {
        username,
        authenticationProviderId,
        identityProviderName,
        firstName,
        lastName,
        email,
	instituteName,
	designation,
        isAdmin,
        status,
        userRole,
        rev,
        projectId,
	acceptUserAgreement,      
      } = self.user;
      result = {
        username,
        authenticationProviderId,
        identityProviderName,
        firstName,
        lastName,
        email,
	instituteName,
	designation,
        isAdmin,
        status,
        rev,
        userRole,
        projectId,
	acceptUserAgreement,      
      };
      return result;
    },
  }));

function registerModels(globals) {
  globals.userStore = UserStore.create({}, globals);
}

export { UserStore, registerModels };
