/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';

const requestAccessFields = {
  // General fields
  isRead: {
    label: 'Read',
    value: true,
    rules: 'boolean',
  },
  isUpload: {
    label: 'Upload File',
    value: false,
    rules: 'boolean',
  },
  isMetaData: {
    label: 'Edit Metadata',
    value: false,
    rules: 'boolean',
  },
  expiry: {
    label: 'Expiry Date',
    value: '',
    rules: 'string',
  },
  requestorComments: {
    label: 'Requestor Comments',
    value: '',
    rules: 'string|between:1,3000',
  },
  adminComments: {
    label: 'Admin/Owner Comments',
    value: '',
    rules: 'string|between:1,3000',
  },
  permissionType: {
    label: 'Permission Type',
    value: '',
    rules: ['required', { in: ['study', 'collection'] }],
  },
  collectionIds: {
    label: 'Collections',
    value: [],
    placeholder: 'The list of collections which will be granted permission for',
  },
  requestFile: {
    label: 'Request File',
    extra: {
      explain: 'Please upload any signed authorization forms for the use of this study (if applicable).',
    },
    value: '',
  },
  uuid: {
    label: 'Request uuid',
    value: '',
  },
};

const getRequestAccessForm = () => {
  return createForm(requestAccessFields);
};

export { getRequestAccessForm }; // eslint-disable-line import/prefer-default-export
