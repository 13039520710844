/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

import ConfigurationEditor from '../configuration/ConfigurationEditor';

const AuthenticationProviderConfigEditor = types
  .model('AuthenticationProviderPublicConfig', {
    id: types.identifier,
    configEditor: types.optional(ConfigurationEditor, {}),
  })
  .actions(self => ({
    setConfigEditor(configEditor) {
      self.configEditor = configEditor;
    },
  }))
  .views(_self => ({}));

export default AuthenticationProviderConfigEditor;
