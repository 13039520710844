/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Study Permissions
// ==================================================================
// edited by leeas - added ownerWithNs
// edited by 2359 - add requestedPermissions, permissions, expiry and isPublic
// edited by leeas - add uploadUsers
const StudyPermissions = types
  .model('StudyPermissions', {
    id: types.identifier,
    adminUsers: types.array(UserIdentifier),
    readonlyUsers: types.array(UserIdentifier),
    accessRequestedUsers: types.array(UserIdentifier),
    requestedPermissions: types.array(
      types.model({
        principalIdentifier: UserIdentifier,
        isRead: types.boolean,
        isUpload: types.boolean,
        isMetaData: types.boolean,
        expiry: types.maybeNull(types.string),
        collectionIds: types.maybeNull(types.array(types.string)),
      }),
    ),
    permissions: types.array(
      types.model({
        principalIdentifier: UserIdentifier,
        isRead: types.boolean,
        isUpload: types.boolean,
        isMetaData: types.boolean,
        expiry: types.maybeNull(types.string),
        requestorComments: types.maybeNull(types.string),
        adminComments: types.maybeNull(types.string),
        collectionIds: types.maybeNull(types.array(types.string)),
      }),
    ),
    uploadUsers: types.array(UserIdentifier),
    createdAt: '',
    createdBy: UserIdentifier,
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    expiry: types.maybeNull(types.string),
    isPublic: types.string,
    ownerWithNs: types.optional(types.string, 'N/A'),
  })
  .views(_self => ({
    get userTypes() {
      return ['admin', 'readonly'];
    },
  }));

export { StudyPermissions };
