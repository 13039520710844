import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable } from 'mobx';
import { Popup, Icon } from 'semantic-ui-react';
import c from 'classnames';
import { withRouter } from '../../helpers/routing';

class ByWithProfile extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  get user() {
    return this.props.user;
  }

  get userDisplayNameService() {
    return this.props.userDisplayName;
  }

  render() {
    const user = this.user;
    const displayNameService = this.userDisplayNameService;
    const isSystem = displayNameService.isSystem(user);
    return isSystem ? (
      ''
    ) : (
      <span className={c(this.props.className)}>
        {this.props.by ? 'by' : ''} {displayNameService.getDisplayName(user)} {this.renderProfile()}
      </span>
    );
  }

  renderProfile() {
    const user = this.props.usersStore.asUserObject(this.user);
    const displayNameService = this.userDisplayNameService;
    return (
      <Popup hoverable trigger={<Icon name="user circle" />} wide>
        <Icon name="user circle" />: {displayNameService.getDisplayFullName(user)} <br />
        <Icon name="building" />: {user.instituteName ? user.instituteName : 'N/A'}
        <br />
        <Icon name="address card" />: {user.designation ? user.designation : 'N/A'}
        <br />
      </Popup>
    );
  }
}

export default inject('userDisplayName', 'usersStore')(withRouter(observer(ByWithProfile)));
