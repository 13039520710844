import _ from 'lodash';
import { Icon, Label, Popup } from 'semantic-ui-react';
import UserConfigure from './UserConfigure';
import SimpleTable from '../helpers/SimpleTable';

function generatePopup(props, row) {
  const user = row;
  return (
    <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      <span>
        <Popup
          content="View User Detail"
          trigger={
            <UserConfigure
              user={_.clone(user)}
              adminMode
              userStore={props.userStore}
              usersStore={props.usersStore}
              userRolesStore={props.userRolesStore}
              awsAccountsStore={props.awsAccountsStore}
              projectsStore={props.projectsStore}
            />
          }
        />
      </span>
    </div>
  );
}

function generateLabel(row) {
  return row.status === 'active' ? (
    <span>
      <Label>
        <Icon color="green" name="check circle" />
        Active
      </Label>
    </span>
  ) : row.status === 'inactive' ? (
    <span>
      <Label>
        <Icon color="red" name="circle" />
        Inactive
      </Label>
    </span>
  ) : (
    <span>
      <Label>
        <Icon color="orange" name="exclamation circle" />
        Pending
      </Label>
    </span>
  );
}

export default function UsersListTable({ props, usersList }) {
  return (
    <SimpleTable
      headerRenderer={() => {
        return ['Name', 'Email', 'Identity Provider', 'Role', 'Project', 'Status', ''];
      }}
      rowRenderer={request => {
        return [
          request.username,
          request.email,
          request.identityProviderName,
          request.userRole,
          request.projectId ? request.projectId.join(', ') : '<<none>>',
          generateLabel(request),
          generatePopup(props, request),
        ];
      }}
      rowData={usersList}
      keyMethod={request => request.username}
    />
  );
}
