/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { createForm } from '../../helpers/form';

const workflowStepConfigOverrideFields = step => {
  const rows = step.configOverrideSummaryRows || [];
  return _.map(rows, ({ name, title, allowed = false }) => ({
    name,
    label: title,
    value: allowed,
    default: allowed,
  }));
};

function getWorkflowStepConfigOverrideForm(step) {
  const fields = workflowStepConfigOverrideFields(step);
  return createForm(fields);
}

export default getWorkflowStepConfigOverrideForm;
