/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { inject } from 'mobx-react';
import { runInAction, observable, makeObservable, action } from 'mobx';
import { Header, Checkbox, Tab, Grid, Label, Table } from 'semantic-ui-react';
import Dotdotdot from 'react-dotdotdot';

import TimeAgo from 'react-timeago';
import { isStoreLoading, isStoreNotEmpty, isStoreError } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import RowBase from './RowBase';
import ComponentSwitch from '../helpers/ComponentSwitch';
import { swallowError } from '../../helpers/utils';
import RequestEgress from './RequestEgress';
import ApproveEgress from './ApproveEgress';
import RejectEgress from './RejectEgress';
import EgressToStudy from './EgressToStudy';
import DeleteData from './DeleteData';
import SecureOutputFilesTable from './SecureOutputFilesTable';
import ByWithProfile from '../helpers/ByWithProfile';

// const isSecure = true;
class SecureOutputRow extends RowBase {
  column = null;
  data = null;
  direction = null;

  constructor(props) {
    super(props);
    makeObservable(this, {
      column: observable,
      data: observable,
      direction: observable,
      handleSort: action,
    });

    runInAction(() => {
      this.store = this.props.secureOutput.getEgressTasksStore();
    });
  }

  componentDidMount() {
    swallowError(this.store.load());
    runInAction(() => {
      this.data = this.store.list;
    });
  }

  handleSort(clickedColumn) {
    if (this.column !== clickedColumn) {
      runInAction(() => {
        this.column = clickedColumn;
        this.data = _.sortBy(this.data, [clickedColumn]);
        this.direction = 'ascending';
      });

      return;
    }

    runInAction(() => {
      this.data = this.data.reverse();
      this.direction = this.direction === 'ascending' ? 'descending' : 'ascending';
    });
  }

  handleOutputSelection = secureOutput => {
    const selection = this.props.outputsSelection;
    if (selection.hasOutput(secureOutput.id)) {
      selection.deleteOutput(secureOutput.id);
    } else {
      const { id, name, description, status } = secureOutput;
      if (status === 'pendingMe') {
        selection.setOutput({ id, name, description });
      }
    }
  };

  renderEgressTasks() {
    return (
      <Table sortable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={this.column === 'secureOutputPrefix' ? this.direction : null}
              onClick={() => this.handleSort('secureOutputPrefix')}
            >
              Secure Output Prefix
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={this.column === 'destinationStudyId' ? this.direction : null}
              onClick={() => this.handleSort('destinationStudyId')}
            >
              Destination Study ID
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={this.column === 'destinationStudyPrefix' ? this.direction : null}
              onClick={() => this.handleSort('destinationStudyPrefix')}
            >
              Destination Study Prefix
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={this.column === 'status' ? this.direction : null}
              onClick={() => this.handleSort('status')}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={this.column === 'createdAt' ? this.direction : null}
              onClick={() => this.handleSort('createdAt')}
            >
              Created At
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={this.column === 'updatedAt' ? this.direction : null}
              onClick={() => this.handleSort('updatedAt')}
            >
              Last Updated
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {this.data &&
            this.data.map(egress => {
              return (
                <Table.Row>
                  <Table.Cell>{egress.secureOutputPrefix}</Table.Cell>
                  <Table.Cell>{egress.destinationStudyId}</Table.Cell>
                  <Table.Cell>{egress.destinationStudyPrefix}</Table.Cell>
                  <Table.Cell>{egress.status}</Table.Cell>
                  <Table.Cell>{egress.createdAt}</Table.Cell>
                  <Table.Cell>{egress.updatedAt}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  }

  getTabPanes(secureOutput) {
    const store = this.store;
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreNotEmpty(store)) {
      content = this.renderEgressTasks(secureOutput);
    } else {
      content = null;
    }

    const panes = [
      {
        menuItem: 'Description',
        render: () => (
          <Tab.Pane basic attached={false}>
            {this.renderDescriptionPane()}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Secure Output Details',
        render: () => (
          <Tab.Pane basic attached={false}>
            <div className="ml1">
              <Header as="h2">Attributes</Header>

              <Table striped>
                <Table.Row>
                  <Table.Cell>Requested by</Table.Cell>
                  <Table.Cell>{secureOutput.createdBy.username}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Created date</Table.Cell>
                  <Table.Cell>{secureOutput.createdAt}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated date</Table.Cell>
                  <Table.Cell>{secureOutput.updatedAt}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Project ID</Table.Cell>
                  <Table.Cell>{secureOutput.projectId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Studies</Table.Cell>
                  <Table.Cell>
                    {secureOutput.parentStudy.map(parent => {
                      return (
                        <div>
                          {parent}
                          <br />
                        </div>
                      );
                    })}
                  </Table.Cell>
                </Table.Row>
              </Table>
              {this.props.category === 'my-secure-output' && (
                <SecureOutputFilesTable secureOutput={this.props.secureOutput} />
              )}
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Egress requested',
        render: () => (
          <Tab.Pane basic attached={false}>
            <div className="ml1">
              <Header as="h2">Egress destinations</Header>

              <div className="ml2">{content}</div>
              <br />
            </div>
          </Tab.Pane>
        ),
      },
    ];

    // Add the Permissions tab if this is an Organization level study
    if (secureOutput.category === 'pending') {
      panes.push({
        menuItem: 'Permissions',
        render: () => <Tab.Pane basic attached={false} />,
      });
    }

    return panes;
  }

  getData() {
    return this.props.secureOutput;
  }

  renderDescriptionPane() {
    const data = this.getData();
    return <Dotdotdot clamp={3}>{data.description}</Dotdotdot>;
  }

  renderHeader(secureOutput) {
    const selection = this.props.outputsSelection;
    const isSelected = selection.hasOutput(secureOutput.id);
    const by = () => (
      <span className="ml1">
        <ByWithProfile by user={secureOutput.createdBy} />{' '}
      </span>
    );
    return (
      <Header as="h2" color="grey" className="mt0">
        <Grid>
          <Grid.Column width={12} onClick={() => this.handleOutputSelection(secureOutput)}>
            <table>
              <tr>
                <td>{!this.props.noPermission && <Checkbox className="mr1" checked={isSelected} />}</td>
                <td>
                  {secureOutput.name} <span className="pl2 font-size-small-9">(ID: {secureOutput.id})</span>
                  <Header.Subheader className="fs-9">
                    created <TimeAgo date={secureOutput.createdAt} /> {by()}
                  </Header.Subheader>
                </td>
              </tr>
            </table>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {this.renderActions(secureOutput)}
          </Grid.Column>
        </Grid>
      </Header>
    );
  }

  renderLabel(text, color) {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'small',
      compact: true,
      color,
    };
    return <Label {...props}>{text}</Label>;
  }

  renderActions(secureOutput) {
    return (
      <div>
        <ComponentSwitch
          tests={[
            // (REQUESTOR & OWNER) DATA IS DELETED
            () => secureOutput.isDataDeleted() && this.renderLabel('Data Deleted', 'grey'),
            // (REQUESTOR) IN REVIEW
            () =>
              secureOutput.isInReview() &&
              !secureOutput.isPendingMyApproval() &&
              this.renderLabel('In Review', 'orange'),
            // (REQUESTOR) IN USE
            () => secureOutput.isInUse() && this.renderLabel('In Use', 'grey'),
            // (REQUESTOR) PENDING WORKSPACE STARTUP
            () => secureOutput.isPending() && this.renderLabel('Pending', 'orange'),
            // (REQUESTOR) APPROVED
            () =>
              secureOutput.isReadyForEgress() &&
              secureOutput.isOwnerApproved() && (
                <>
                  <DeleteData secureOutput={secureOutput} secureOutputsStore={this.props.secureOutputsStore} />
                  <EgressToStudy secureOutput={secureOutput} secureOutputsStore={this.props.secureOutputsStore} />
                </>
              ),
            // (REQUESTOR) REJECTED
            () =>
              secureOutput.isReadyForEgress() &&
              secureOutput.isOwnerRejected() && (
                <>
                  {this.renderLabel('Rejected', 'red')}
                  <DeleteData secureOutput={secureOutput} secureOutputsStore={this.props.secureOutputsStore} />
                </>
              ),
            // (REQUESTOR) NOT IN USE, PENDING EGRESS REQUEST
            () =>
              !secureOutput.isInUse() &&
              !secureOutput.isPending() &&
              secureOutput.isReadyForEgress() &&
              !secureOutput.isEgressRequested() &&
              secureOutput.isPendingOwner() && (
                <>
                  <RequestEgress secureOutput={secureOutput} />
                  <DeleteData secureOutput={secureOutput} secureOutputsStore={this.props.secureOutputsStore} />
                </>
              ),
            // (REQUESTOR) PENDING APPROVAL
            () =>
              secureOutput.isReadyForEgress() &&
              secureOutput.isEgressRequested() &&
              secureOutput.isPendingOwner() &&
              this.renderLabel('Pending', 'orange'),
            // (OWNER) PENDING MY ACTION
            () =>
              secureOutput.isEgressRequested() &&
              secureOutput.isPendingMyApproval() && (
                <>
                  {secureOutput.isInReview() && this.renderLabel('In Review', 'orange')}
                  <ApproveEgress secureOutput={secureOutput} />
                  <RejectEgress secureOutput={secureOutput} />
                </>
              ),
            // (OWNER) APPROVED BY ME
            () =>
              secureOutput.isEgressRequested() &&
              secureOutput.isApprovedByMe() &&
              this.renderLabel('Approved', 'green'),
            // (OWNER) REJECTED BY ME
            () =>
              secureOutput.isEgressRequested() && secureOutput.isRejectedByMe() && this.renderLabel('Rejected', 'red'),
          ]}
          fallback={null}
        />
      </div>
    );
  }
}

// RowBase is already an observer
export default inject('outputsSelection', 'tagSetsStore')(SecureOutputRow);
