/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import StudiesTable from '../../studies/StudiesTable';

class StudySelector extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  render() {
    return <StudiesTable handleNext={this.props.handleNext} />;
  }
}

export default inject('amiWizard', 'filesSelection', 'carryoutStore')(observer(StudySelector));
