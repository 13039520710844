/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, runInAction } from 'mobx';
import TimeAgo from 'react-timeago';
import { Header, Label, Button } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import ComponentSwitch from '../helpers/ComponentSwitch';
import ReviewAccessRequest from './ReviewAccessRequest';
import SimpleTable from '../helpers/SimpleTable';
import ByWithProfile from '../helpers/ByWithProfile';
import ReviewNotebookAccessRequest from './ReviewNotebookAccessRequest';

// expected props
// - accessRequest - a AccessRequest model instance (via props)
// - userDisplayName (via injection)
// - location (from react router)
// edit 2359 - add support for EMR-hail notebook permissions handling
class AccessRequestsCard extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    runInAction(() => {
      // add any state changing initialization logic if needed
    });
  }

  getAccessRequest() {
    return this.props.accessRequests;
  }

  getCollections() {
    return this.props.collections;
  }

  getUserDisplayNameService() {
    return this.props.userDisplayName;
  }

  render() {
    const { id, name, createdAt, createdBy } = this.getAccessRequest().study;
    const displayNameService = this.getUserDisplayNameService();
    const isSystem = displayNameService.isSystem(createdBy);
    const by = () =>
      isSystem ? (
        ''
      ) : (
        <span className="ml1">
          <ByWithProfile by user={createdBy} />{' '}
        </span>
      );
    return (
      <>
        <Label attached="top left">
          {this.getAccessRequest().type === 'study' ? 'Study' : 'Notebook'} Access Request
        </Label>
        <div className="flex mb1">
          <Header as="h3" color="grey" className="mt0 flex-auto ellipsis">
            {name} (ID: {id})
            <Header.Subheader className="fs-9">
              created <TimeAgo date={createdAt} /> {by()}
            </Header.Subheader>
          </Header>
        </div>
        <SimpleTable
          headerRenderer={() => {
            return ['Requester', ''];
          }}
          rowRenderer={request => {
            return [
              <ByWithProfile user={this.props.usersStore.asUserObjectByNsUsername(request.ns, request.username)} />,
              <>{this.renderActions(request)}</>,
            ];
          }}
          rowData={this.props.accessRequests.requests}
          keyMethod={request => request.username}
        />
      </>
    );
  }

  // edited by 2359 - changed grant button to ReviewAccessRequest
  // edit 2359 - add support for EMR-hail notebook permissions handling
  // edit chenjqp - remove isUpload and isMetadata for reviewing access request to secure studies
  renderActions(request) {
    const ar = this.getAccessRequest();
    const study = this.getAccessRequest().study;
    const collections = this.getCollections();

    return (
      <ComponentSwitch
        tests={[
          () =>
            request.requested() && (
              <>
                {ar.type === 'study' ?
				<ReviewAccessRequest
                  studyId={study.id}
                  accessRequest={request}
				  collections={collections}
                  onGrantAccess={request.grant}
				  onDenyAccess={request.deny}
				  isSecure={study.isSecure}
                  isRequest
				  reviewType='approve'
                />
				: <><ReviewNotebookAccessRequest
                studyId={study.id}
                accessRequest={request}
                onGrantAccess={request.deny}
                isRequest
				reviewType='reject'
               /><ReviewNotebookAccessRequest
                studyId={study.id}
                accessRequest={request}
                onGrantAccess={request.grant}
                isRequest
				reviewType='approve'
               />
				</>
                }
              </>
            ),
          () =>
            request.processing() && (
              <Button floated="right" loading>
                Processing
              </Button>
            ),
          () =>
            request.granted() && (
              <Button floated="right" disabled>
                Granted
              </Button>
            ),
          () =>
            request.denied() && (
              <Button floated="right" disabled>
                Denied
              </Button>
            ),
        ]}
      />
    );
  }
}

export default inject('userDisplayName', 'usersStore')(withRouter(observer(AccessRequestsCard)));
