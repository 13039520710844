import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

import { swallowError } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreNew } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';
import CreateCollection from './CreateCollection';

class StudyCollectionsTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.store = props.study.getCollectionsStore();
    this.study = props.study;
  }

  componentDidMount() {
    swallowError(this.store.load());
    this.store.startHeartbeat();
  }

  componentWillUnmount() {
    this.store.stopHeartbeat();
  }

  render() {
    let content;
    if (isStoreError(this.store)) {
      content = <ErrorBox error={this.store.error} />;
    } else if (isStoreLoading(this.store) || isStoreNew(this.store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    const collections = this.store.list;

    const renderRow = collection => {
      return (
        <Table.Row key={collection.id}>
          <Table.Cell>{collection.collectionName}</Table.Cell>
          <Table.Cell>
            <div style={{ 'white-space': 'pre-line' }}>{collection.description}</div>
          </Table.Cell>
        </Table.Row>
      );
    };

    return (
      <Dimmer.Dimmable dimmed={this.isProcessing}>
        <Dimmer active={this.isProcessing} inverted>
          <Loader size="big" />
        </Dimmer>

        <CreateCollection study={this.study} />

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{_.map(collections, collection => renderRow(collection))}</Table.Body>
        </Table>
      </Dimmer.Dimmable>
    );
  }
}

export default observer(StudyCollectionsTable);
