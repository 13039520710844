/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { action, makeObservable, runInAction } from 'mobx';
import TimeAgo from 'react-timeago';
import { Header, Label, Radio, Dropdown, Icon, Table, Button, Dimmer, Loader } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import { displayError, displaySuccess } from '../../helpers/notification';
import ByWithProfile from '../helpers/ByWithProfile';
import ConfirmationModal from '../helpers/ConfirmationModal';
import EditAmi from './EditAmi';

// expected props
// - accessRequest - a AccessRequest model instance (via props)
// - userDisplayName (via injection)
// - location (from react router)
class AmiCard extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.usersStore = this.props.usersStore;
    this.state = {
      editModeOn: false,
      selectedUserIds: [],
      isProcessing: false,
	  openDisableConfirm: false,
	  openEnableConfirm: false,
	  openDeleteConfirm: false,
	  openPublishConfirm: false,  
    };
    runInAction(() => {
      // add any state changing initialization logic if needed
    });
  }

  componentDidMount() {
    this.getSelectedUserIds();
  }

  getSelectedUserIds = () => {
    const users = this.props.ami.permissions.slice();
    if (users.length) {
      const selectedUserIds = users.map(user => JSON.stringify(user));
      this.setState({ selectedUserIds });
    }
  };

  enableEditMode = () => {
    this.setState({ editModeOn: true });
  };

  getUserDisplayNameService() {
    return this.props.userDisplayName;
  }

  resetForm = () => {
    this.setState({ editModeOn: false, isProcessing: false });
  };

  submitUpdate = async () => {
    runInAction(() => {
      this.setState({ isProcessing: true });
    });
    // Perform update
    try {
      const data = this.state.selectedUserIds.map(user => JSON.parse(user));
      await this.props.updateAmiPermissions(this.props.ami.id, data);
      displaySuccess('Update Succeeded');
      this.resetForm();
    } catch (error) {
      displayError('Update Failed', error);
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    }
  };

  renderUsersDropdown(_userType) {
    const dropdownOnChange = action((_event, data) => {
      this.setState({ selectedUserIds: data.value });
    });
    return (
      <Dropdown
        selection
        fluid
        multiple
        search
        options={this.usersStore.asDropDownOptions()}
        value={this.state.selectedUserIds}
        placeholder="Select users"
        onChange={dropdownOnChange}
      />
    );
  }

  async handleDisableClick(){
    runInAction(() => {
      this.setState({ openDisableConfirm: true });
    });
  }

  async handleDisableConfirm(){
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      await this.props.ami.disableAmi();
	  this.props.onDisable(this.props.ami.amiId);
      displaySuccess('AMI disabled');
    } catch (error) {
      displayError('Disable Failed', error);
    } finally {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });	
	}

	runInAction(() => {
		this.setState({openDisableConfirm: false});
	})
  }

  async handleEnableClick(){
    runInAction(() => {
      this.setState({ openEnableConfirm: true });
    });
  }

  async handleEnableConfirm(){
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      await this.props.ami.enableAmi();
      displaySuccess('AMI enabled');
    } catch (error) {
      displayError('Enable Failed', error);
    } finally {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });	
	}

	runInAction(() => {
		this.setState({openEnableConfirm: false});
	})
  }

  async handleDeleteClick(){
    runInAction(() => {
      this.setState({ openDeleteConfirm: true });
    });
  }

  async handleDeleteConfirm(){
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      await this.props.ami.deleteAmi();
	  this.props.onDelete(this.props.ami.amiId);
      displaySuccess('AMI deleted');
    } catch (error) {
      displayError('Delete Failed', error);
    } finally {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });	
	}

	runInAction(() => {
		this.setState({openDeleteConfirm: false});
	})
  }

  async handlePublishClick(){
    runInAction(() => {
      this.setState({ openPublishConfirm: true });
    });
  }
	
  async handlePublishConfirm(){
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      await this.props.ami.publishAmi();
          this.props.onPublish(this.props.ami.amiId);
      displaySuccess('Publish AMI Request submitted');
    } catch (error) {
      displayError('Failed to submit Publish AMI Request', error);
    } finally {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    }

    runInAction(() => {
      this.setState({openPublishConfirm: false});
    })
  }

  render() {
    const displayNameService = this.getUserDisplayNameService();
    const { amiId, amiName, name, description, createdBy, createdAt, status, disabled, deleted, amiStatus } = this.props.ami;
	const isOwner = createdBy.username === this.props.userStore.user.username && createdBy.ns === this.props.userStore.user.ns;
	const isAdmin = this.props.userStore.user.isAdmin;
    const isSystem = displayNameService.isSystem(createdBy);
    const by = () =>
      isSystem ? (
        ''
      ) : (
        <span className="ml1">
          <ByWithProfile by user={this.props.usersStore.asUserObjectByNsUsername(createdBy.ns, createdBy.username)} />{' '}
        </span>
      );
    return (
      <>
        <Label attached="top left">AMI</Label>
        <div className="flex mb1">
          <Radio className="mr1" disabled={deleted || disabled || amiStatus !== 'Approved'} checked={this.props.selected === amiId} onChange={this.props.onSelect} />
          <Header as="h3" color="grey" className="mt0 flex-auto ellipsis">
            AMI Name: {name || amiName}
            <br />
            AMI ID: {amiId}
            <br />
			Description: {description}
            <Header.Subheader className="fs-9">
              created <TimeAgo date={createdAt} /> {by()}
            </Header.Subheader>
          </Header>
          <br />
          <div className="flex-auto mb1 mr4 right-align">
	                { (amiStatus !== 'Approved' && !disabled && !deleted ? 
			   <Label basic size="mini" color={amiStatus === 'Draft' ? 'grey': (amiStatus === 'Pending' ? 'orange': 'red')}>
			       { amiStatus }
		           </Label> :		
	                       ( disabled || deleted ) ? 
			   	  <Label basic size="mini" color='red'>
				      {disabled ? 'Disabled' : 'Deleted'}
			  	  </Label> :
			          <Label basic size="mini" color={status === 'Available' ? 'green' : 'blue'}>
			              {amiStatus === 'Approved' ? status : 'Unknown'}
			          </Label>
			      )
			}
	                { (isOwner || isAdmin) && !deleted && (status === 'Available' && amiStatus !== 'Approved') &&
		           <Dropdown floating labeled button>
                                   <Dropdown.Menu>
                                        <Dropdown.Header icon='cog' content='Actions'/>
                                        <Dropdown.Divider />
                                        <EditAmi ami={this.props.ami}/>
					{ amiStatus !== 'Pending' && amiStatus !== 'Rejected' && <Dropdown.Item onClick={() => this.handlePublishClick()}>Publish</Dropdown.Item> }
                                        <Dropdown.Item onClick={() => this.handleDeleteClick()}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                           </Dropdown> }
			{ (isOwner || isAdmin) && !deleted && (status === 'Available' && amiStatus === 'Approved') &&
			<Dropdown floating labeled button>
				<Dropdown.Menu>
					<Dropdown.Header icon='cog' content='Actions'/>
					<Dropdown.Divider />
					<EditAmi ami={this.props.ami}/>
					{ !disabled ? 
						<Dropdown.Item onClick={() => this.handleDisableClick()}>Disable</Dropdown.Item> :
						<Dropdown.Item onClick={() => this.handleEnableClick()}>Enable</Dropdown.Item>
					}
					<Dropdown.Item onClick={() => this.handleDeleteClick()}>Delete</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown> }			
          </div>
        </div>

        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>
          {this.props.isAdmin && !deleted && !disabled && amiStatus === 'Approved' && (
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Permissions</Table.HeaderCell>
                  <Table.HeaderCell>
                    Users
                    {this.props.isAdmin && !this.state.editModeOn && (
                      <Icon name="pencil" className="ml1 cursor-pointer" color="grey" onClick={this.enableEditMode} />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={3} />
                  <Table.Cell>
                    {this.state.editModeOn && this.renderUsersDropdown()}
                    {!this.state.editModeOn &&
                      this.state.selectedUserIds.map(user => {
                        return <Label>{JSON.parse(user).username}</Label>;
                      })}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
        </Dimmer.Dimmable>
        {this.state.editModeOn && (
          <>
            <Button
              floated="right"
              disabled={this.state.isProcessing}
              onClick={this.submitUpdate}
              size="mini"
              color="blue"
              icon
            >
              Submit
            </Button>

            <Button floated="right" disabled={this.state.isProcessing} onClick={this.resetForm} size="mini">
              Cancel
            </Button>
          </>
        )}
		
		<ConfirmationModal 
			open={this.state.openDisableConfirm} 
			header='Confirm Disable AMI' 
			message='Are you sure you want to disable the AMI? It can no longer be used to spin up Workspaces.' 
			onCancel={() => {runInAction(() => {
		      this.setState({ openDisableConfirm: false });
		    });}} 
			onConfirm={() => {this.handleDisableConfirm()}} />
			
		<ConfirmationModal 
			open={this.state.openEnableConfirm} 
			header='Confirm Enable AMI' 
			message='Are you sure you want to enable the AMI? ' 
			onCancel={() => {runInAction(() => {
		      this.setState({ openEnableConfirm: false });
		    });}} 
			onConfirm={() => {this.handleEnableConfirm()}} />
			
		<ConfirmationModal 
			open={this.state.openDeleteConfirm} 
			header='Confirm Delete AMI' 
			message='Are you sure you want to delete the AMI? This is an irreversible action!' 
			onCancel={() => {runInAction(() => {
		      this.setState({ openDeleteConfirm: false });
		    });}} 
			onConfirm={() => {this.handleDeleteConfirm()}} />			

	        <ConfirmationModal
                        open={this.state.openPublishConfirm}
                        header='Confirm Publish AMI'
                        message='Are you sure you want to publish the AMI? It can take at least 1 week for vetting and approval.'
                        onCancel={() => {runInAction(() => {
                      this.setState({ openPublishConfirm: false });
                    });}}
                        onConfirm={() => {this.handlePublishConfirm()}} />

		
      </>
    );
  }
}

export default inject('userDisplayName', 'usersStore', 'userStore', 'environmentsStore')(withRouter(observer(AmiCard)));
