import _ from 'lodash';
import { observer } from 'mobx-react';
import { Checkbox } from 'semantic-ui-react';
import c from 'classnames';

import Header from './Header';
import ErrorPointer from './ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - options (via props), an array of [ {text, value}, {text, value}, ...]
// - onChange (via props), (optional) if provided, it will be given (value, field)
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - fluid (via props), default to false
// - disabled (via props), default to false
const Component = observer(({ field, fluid = false, disabled = false, className = 'mb4', options = [], onChange }) => {
  const { _id, _value, sync, _placeholder, error = '', _extra = {} } = field;
  const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
  const isDisabled = field.disabled || disabled;
  const disabledClass = isDisabled ? 'disabled' : '';
  const errorClass = hasError ? 'error' : '';
  const attrs = {
    onChange: (e, data = {}) => {
      sync(data.value);
      field.validate({ showErrors: true });
      if (onChange) onChange(data.value, field);
    },
    fluid,
    disabled: isDisabled,
    error: hasError,
    className: 'mr2',
  };

  return (
    <div className={c(className, errorClass, disabledClass)}>
      <div className="flex flex-wrap mb1">
        <Header field={field} className="mt0 mb0 mr2" />
        {options.map(option => (
          <Checkbox radio label={option.text} value={option.text} checked={field.value === option.value} {...attrs} />
        ))}
        <ErrorPointer field={field} />
      </div>
    </div>
  );
});

export default Component;
