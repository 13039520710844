import _ from 'lodash';
import React from 'react';
import { observable, makeObservable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Table, Button, Dimmer, Loader, Segment, Pagination, Label } from 'semantic-ui-react';

import { formatBytes, swallowError, niceNumber } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreReloading } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';

// expected props
// - secureOutput
class EnvironmentOutputFilesTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      loading: computed,
      results: computed,
      parentPrefix: computed,
      handlePaginationChange: action,
      handlePrefix: action,
    });

    this.environment = props.environment;
    this.filesStore = props.environment.getFilesStore();
    this.stores = [this.filesStore];
    this.componentStore = observable({
      showLoader: false,
    });
  }

  componentDidMount() {
    this.filesStore.load({ page: 1, reload: true });
  }

  get loading() {
    return isStoreLoading(this.stores) || isStoreReloading(this.stores);
  }

  get results() {
    return this.filesStore.visibleRecords || [];
  }

  get parentPrefix() {
    return this.filesStore.parentPrefix;
  }

  getFilesStore() {
    return this.filesStore;
  }

  handlePaginationChange = (e, { activePage }) => {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading
    const environmentId = this.filesStore.environmentId;
    const selectedPrefix = this.filesStore.selectedPrefix;
    swallowError(
      this.filesStore.load({
        page: activePage,
        reload: false, // don't reload
        environmentId,
        selectedPrefix,
      }),
    );
  };

  handlePrefix(prefix) {
    if (this.loading) return; // we ignore page selection when the file store is loading/reloading

    const environmentId = this.filesStore.environmentId;
    const selectedPrefix = typeof prefix !== 'undefined' ? prefix : this.filesStore.selectedPrefix;
    swallowError(
      this.filesStore.load({
        page: 1,
        reload: true,
        environmentId,
        selectedPrefix,
      }),
    );
  }

  getDisplay() {
    let displayComponent;
    if (
      this.environment.instanceInfo.secureOutput ||
      (this.environment.instanceInfo.outputFiles && this.environment.instanceInfo.outputFiles.length > 0)
    ) {
      if (isStoreError(this.filesStore)) {
        displayComponent = <ErrorBox error={this.filesStore.error} />;
      } else if (isStoreLoading(this.filesStore)) {
        displayComponent = <BasicProgressPlaceholder segmentCount={1} />;
      } else {
        displayComponent = this.renderTable();
      }

      return (
        <>
          <Label basic color="red">
            Please note that the export of FSx data only pushes the files into a staging folder and not the actual
            output study/secure output folder. Syncing to the actual secure output will only be performed upon workspace
            termination.
          </Label>
          {displayComponent}
        </>
      );
    }
    return <>No outputs configured for this workspace!</>;
  }

  render() {
    return this.getDisplay();
  }

  renderPagination() {
    const currentPage = this.filesStore.currentPage;
    const totalPages = this.filesStore.totalPages;
    if (totalPages < 2) return <div className="mb2" />;

    return (
      <Pagination
        activePage={currentPage}
        onPageChange={this.handlePaginationChange}
        totalPages={totalPages}
        floated="right"
      />
    );
  }

  renderTotal() {
    const count = this.filesStore.total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderTable() {
    return (
      <div className="mt4">
        <Segment.Group>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
          <Segment clearing>
            {this.renderTotal()}
            <div className="flex justify-end">{this.renderBackButton()}</div>
            {this.renderContent()}
          </Segment>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  renderBackButton() {
    // const environmentId = this.getFilesStore().environmentId;
    if (this.parentPrefix !== '') {
      let backParent = '';
      const parts = this.parentPrefix.split('/');
      if (parts.length > 1) {
        parts.pop(parts.length);
        backParent = _.join(parts, '/');
      } else {
        backParent = '';
      }
      backParent += backParent !== '' ? '/' : '';
      return (
        <div>
          <Button
            floated="right"
            size="mini"
            compact
            color="blue"
            className="mb2"
            onClick={() => {
              this.handlePrefix(backParent);
            }}
          >
            Back
          </Button>
        </div>
      );
    }
    return null;
  }

  renderContent() {
    if (
      !(
        this.environment.instanceInfo.secureOutput ||
        (this.environment.instanceInfo.outputFiles && this.environment.instanceInfo.outputFiles.length > 0)
      )
    ) {
      return <div>No output!</div>;
    }
    if (this.filesStore.isEmpty) {
      return <div>No files uploaded</div>;
    }

    const result = this.results;
    const filesStore = this.getFilesStore();
    const renderRow = file => {
      if (file.fileType === 'file') {
        return (
          <Table.Row key={file.fileName}>
            <Table.Cell>{file.fileName}</Table.Cell>
            <Table.Cell>{formatBytes(file.size)}</Table.Cell>
            <Table.Cell>{file.lastModified.toISOString()}</Table.Cell>
          </Table.Row>
        );
      }
      return (
        <Table.Row key={file.fileName}>
          <Table.Cell>
            {' '}
            <a
              className="cursor-pointer"
              onClick={() => {
                const parts = this.parentPrefix.split('/');
                if (parts[0] === filesStore.environmentId) {
                  parts.pop(0);
                }
                const fwdParent = _.join(parts, '/');
                const finalPrefix = fwdParent !== '' ? `${fwdParent}/${file.fileName}` : file.fileName;

                this.handlePrefix(finalPrefix);
              }}
            >
              {file.fileName}
            </a>
          </Table.Cell>
          <Table.Cell />
          <Table.Cell />
        </Table.Row>
      );
    };

    return (
      <Dimmer.Dimmable dimmed={this.componentStore.showLoader}>
        <Dimmer active={this.componentStore.showLoader}>
          <Loader />
        </Dimmer>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2">Name</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Size</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Last Modified</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{_.map(result, file => renderRow(file))}</Table.Body>
        </Table>
      </Dimmer.Dimmable>
    );
  }
}

export default observer(EnvironmentOutputFilesTable);
