import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { getStudyCollections } from '../../helpers/api';

const DisplayCollectionPane = inject('environmentsStore')(
  observer(({ environment }) => {
    const [studies, setStudies] = React.useState([]);

    React.useEffect(() => {
      const fetchCollection = async () => {
        const studyCols = [];
        try {
          const files = environment.instanceInfo.files ? environment.instanceInfo.files : [];
          await Promise.all(
            files.map(async studyId => {
              const datacols = _.has(environment.instanceInfo, 'studyCollections')
                ? _.filter(environment.instanceInfo.studyCollections, collection => {
                    return collection.studyId === studyId;
                  })
                : [];
              if (datacols.length && datacols[0].collectionIds.length) {
                const result = await getStudyCollections(studyId);
                const collectionIds = datacols[0].collectionIds;
                const cols = collectionIds.map(colId => {
                  const findCol = _.has(result, 'collections')
                    ? _.find(result.collections, collection => {
                        return collection.collectionId === colId;
                      })
                    : {};
                  return findCol.collectionName ?? null;
                });
                studyCols.push({ studyId, cols });
              } else {
                studyCols.push({ studyId, cols: [] });
              }
            }),
          );
          setStudies(studyCols);
        } catch (err) {
          console.log(err);
        }
      };
      fetchCollection();
    }, []);

    const renderCollection = row => {
      if (row.cols.length > 0) {
        return (
          <>
            <br />
            Collection(s) selected:
            <ul>
              {row.cols.map(colName => (
                <li>{colName}</li>
              ))}
            </ul>
          </>
        );
      }
      return null;
    };
    return (
      <ul>
        {studies.length > 0 ? (
          studies.map(col => (
            <li>
              {col.studyId}
              {renderCollection(col)}
            </li>
          ))
        ) : (
          <li>NA</li>
        )}
      </ul>
    );
  }),
);

export default DisplayCollectionPane;
