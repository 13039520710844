/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook access requests

import { types } from 'mobx-state-tree';

import { getNotebookAccessRequests } from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import NotebookAccessRequests from './NotebookAccessRequests';

// ==================================================================
// NotebookAccessRequestsStore
// ==================================================================
const NotebookAccessRequestsStore = BaseStore.named('NotebookAccessRequestsStore')
  .props({
    accessRequests: types.optional(types.map(NotebookAccessRequests), {}),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      doLoad: async () => {
        const accessRequests = await getNotebookAccessRequests();
        self.runInAction(() => {
          consolidateToMap(self.accessRequests, accessRequests, (existing, newItem) => {
            existing.setAccessRequest(newItem);
          });
        });
      },
      cleanup: () => {
        self.accessRequests.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.accessRequests.size === 0;
    },

    get total() {
      return self.accessRequests.size;
    },

    get list() {
      const result = [];
      self.accessRequests.forEach(notebook => result.push(notebook));
      return result;
    },

    hasAccessRequest(id) {
      return self.accessRequests.has(id);
    },

    getAccessRequest(id) {
      return self.accessRequests.get(id);
    },
  }));

function registerModels(globals) {
  globals.notebookAccessRequestsStore = NotebookAccessRequestsStore.create({}, globals);
}

export { NotebookAccessRequestsStore, registerModels };
