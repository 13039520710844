/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { Button, Dimmer, Header, List, Loader, Radio, Segment, Tab, Grid, Form, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

import { isStoreError, isStoreLoading, isStoreReady } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import { getAddUserFormFields } from '../../models/forms/AddUserForm';
import { displayError } from '../../helpers/notification';
import { withRouter } from '../../helpers/routing';
import { swallowError } from '../../helpers/utils';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import validate from '../../models/forms/Validate';

const INTERNAL = 'internal';

class AddUser extends React.Component {
  formProcessing = false;
  validationErrors = new Map();
  user = {};

  constructor(props) {
    super(props);
    makeObservable(this, {
      formProcessing: observable,
      user: observable,
      validationErrors: observable,
      renderIdentityProviderNameSelection: action,
    });

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      role: 'guest',
      status: 'active',
      identityProviderName: '',
      identityProviderTitle: '',
      // identityProviderOption: [],
      projectId: [],
      userRole: '',
    };

    // this.form = getAddUserForm();
    this.addUserFormFields = getAddUserFormFields();
  }

  componentDidMount() {
    const store = this.props.authenticationProviderConfigsStore;
    swallowError(store.load());
  }

  render() {
    const store = this.props.authenticationProviderConfigsStore;
    let content = null;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else if (isStoreReady(store)) {
      content = (
        <div className="mt2 animated fadeIn">
          <Header as="h2" icon textAlign="center" className="mt3" color="grey">
            Add User
          </Header>
          <div className="mt3 ml3 mr3 animated fadeIn">{this.renderTabs()}</div>
        </div>
      );
    }

    return content;
  }

  renderTabs() {
    const panes = [
      {
        menuItem: 'Add Single User',
        render: () => (
          <Tab.Pane basic attached={false}>
            <div className="mt3 ml3 mr3 animated fadeIn">{this.renderAddUserForm()}</div>
          </Tab.Pane>
        ),
      },
      /*      {
        menuItem: 'Add Multiple Users',
        render: () => (
          <Tab.Pane basic attached={false}>
            <DragnDrop identityProviderOption={this.identityProviderOption} />
          </Tab.Pane>
        ),
      },
*/
    ];

    return (
      <Grid>
        <Grid.Column>
          <Tab
            onTabChange={this.handleChangeTab}
            defaultActiveIndex={0}
            className="mt2"
            menu={{ secondary: true, pointing: true }}
            panes={panes}
          />
        </Grid.Column>
      </Grid>
    );
  }

  // eslint-disable-next-line react/no-unused-state
  // handleRoleChange = (e, { value }) => this.setState({ role: value });

  handleStatusChange = (e, { value }) => this.setState({ status: value });

  handleIdentityProviderName = (e, { value }) => {
    this.setState({
      identityProviderName: value,
      identityProviderTitle: _.find(this.identityProviderOption, { value }).text,
    });
  };

  handleProjectId = (e, { value }) => this.setState({ projectId: value });

  renderProjectIdSelection() {
    const projects = this.props.projectsStore.dropdownOptions;
    return <Dropdown options={projects} fluid multiple selection onChange={this.handleProjectId} />;
  }

  renderIdentityProviderNameSelection() {
    const identityProviders = this.props.authenticationProviderConfigsStore.list;
    const identityProviderOption = [];
    Object.keys(identityProviders).forEach(ind => {
      const config = identityProviders[ind].config;
      const title = config.title;
      const id = config.federatedIdentityProviders ? config.federatedIdentityProviders[0].name : 'internal';
      const key = config.id;
      const obj = { key, text: title, value: id };
      identityProviderOption.push(obj);
    });
    this.identityProviderOption = identityProviderOption;

    return (
      <Dropdown options={this.identityProviderOption} fluid selection onChange={this.handleIdentityProviderName} />
    );
  }

  renderStatusSelection() {
    return (
      <Form>
        <Form.Field>
          <Radio
            label="Active"
            name="radioGroup"
            value="active"
            checked={this.state.status === 'active'}
            onChange={this.handleStatusChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Inactive"
            name="radioGroup"
            value="inactive"
            checked={this.state.status === 'inactive'}
            onChange={this.handleStatusChange}
          />
        </Form.Field>
      </Form>
    );
  }

  renderUserRoleSelection() {
    const userRoleOption = this.props.userRolesStore.dropdownOptions;
    return (
      <Dropdown
        options={userRoleOption}
        placeholder="Please select user role"
        fluid
        selection
        onChange={this.handleUserRoleSelection}
      />
    );
  }

  handleUserRoleSelection = (e, { value }) => this.setState({ userRole: value });

  renderAddUserForm() {
    const processing = this.formProcessing;
    const fields = this.addUserFormFields;
    const toEditableInput = (attributeName, type = 'text') => {
      const handleChange = action(event => {
        event.preventDefault();
        this.user[attributeName] = event.target.value;
      });
      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.user[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
          />
        </div>
      );
    };

    const internalUser = this.state.identityProviderName === INTERNAL;

    return (
      <Segment basic className="ui fluid form">
        <Dimmer active={processing} inverted>
          <Loader inverted>Checking</Loader>
        </Dimmer>
        {this.renderField('identityProviderName')}
        {this.renderIdentityProviderNameSelection()}
        <div className="mb4" />
        {this.renderField('username', toEditableInput('username', 'text'))}
        <div className="mb4" />
        {internalUser ? (
          <>
            {this.renderField('password', toEditableInput('password', 'password'))}
            <div className="mb4" />
            {this.renderField('email', toEditableInput('email', 'email'))}
            <div className="mb4" />
          </>
        ) : null}

        {this.renderField('email', toEditableInput('email', 'email'))}
        <div className="mb4" />
        {this.renderField('firstName', toEditableInput('firstName', 'text'))}
        <div className="mb4" />
        {this.renderField('lastName', toEditableInput('lastName', 'text'))}
        <div className="mb4" />    
        {this.renderField('instituteName', toEditableInput('instituteName', 'text'))}
        <div className="mb4" />   
        {this.renderField('designation', toEditableInput('designation', 'text'))}
        <div className="mb4" />   		
        {this.renderField('userRole')}
        {this.renderUserRoleSelection()}
        {this.props.userRolesStore.getUserType(this.state.userRole) === 'INTERNAL' ? (
          <div>
            <div className="mb4" />
            {this.renderField('projectId')}
            {this.renderProjectIdSelection()}
          </div>
        ) : (
          ''
        )}
        <div className="mb4" />
        {this.renderField('status')}
        {this.renderStatusSelection()}
        <div className="mb4" />
        {this.renderButtons()}
      </Segment>
    );
  }

  renderButtons() {
    const processing = this.formProcessing;
    return (
      <div className="mt3">
        <Button floated="right" color="blue" icon disabled={processing} className="ml2" onClick={this.handleSubmit}>
          Add User
        </Button>
        <Button floated="right" disabled={processing} onClick={this.handleCancel}>
          Cancel
        </Button>
      </div>
    );
  }

  renderField(name, component) {
    const fields = this.addUserFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    const fieldErrors = this.validationErrors.get(name);
    const hasError = !_.isEmpty(fieldErrors);

    return (
      <div>
        <Header className="mr3 mt0" as="h2" color="grey">
          {label}
        </Header>
        {hasExplain && <div className="mb2">{explain}</div>}
        <div className={`ui big field input block m0 ${hasError ? 'error' : ''}`}>{component}</div>
        {hasError && (
          <div className="ui pointing red basic label">
            <List>
              {_.map(fieldErrors, fieldError => (
                <List.Item key={name}>
                  <List.Content>{fieldError}</List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleCancel = action(event => {
    event.preventDefault();
    event.stopPropagation();
    this.formProcessing = false;
    this.goto('/users');
  });

  handleSubmit = action(async () => {
    this.formProcessing = true;
    try {
      // Perform client side validations first
      const validationResult = await validate(this.user, this.addUserFormFields);
      // if there are any client side validation errors then do not attempt to make API call
      if (validationResult.fails()) {
        runInAction(() => {
          this.validationErrors = validationResult.errors;
          this.formProcessing = false;
          displayError(JSON.stringify(validationResult.errors));
        });
      } else {
        // There are no client side validation errors so ask the store to add user (which will make API call to server to add the user)
        this.user.userRole = this.state.userRole;
        this.user.status = this.state.status;
        this.user.identityProviderName = this.state.identityProviderName;
        this.user.projectId = this.state.projectId;
        this.user.authenticationProviderId = await this.getAuthenticationProviderId();
        await this.getStore().addUser(this.user);
        runInAction(() => {
          this.formProcessing = false;
        });
        this.goto('/users');
      }
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
  });

  async getAuthenticationProviderId() {
    const authStore = this.props.authenticationProviderConfigsStore;
    let authList = authStore.list;
    if (authList.length === 0) {
      await authStore.doLoad();
      authList = authStore.list;
    }
    let authId = null;
    _.forEach(authList, auth => {
      if (auth.config.title === this.state.identityProviderTitle) {
        authId = auth.id;
      }
    });
    return authId;
  }

  getStore() {
    return this.props.usersStore;
  }
}

export default inject(
  'userStore',
  'usersStore',
  'userRolesStore',
  'awsAccountsStore',
  'projectsStore',
  'authenticationProviderConfigsStore',
)(withRouter(observer(AddUser)));
