/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';
import isCidr from 'is-cidr';

const dvrRules = {
  cidr: {
    function: value => {
      const result = isCidr(value);
      return result === 4 || result === 6;
    },
    message: 'The :attribute is not in the CIDR format.',
  },
  singleCidr: {
    function: value => {
      const result = isCidr(value);
      return (result === 4 || result === 6) && value.endsWith('/32');
    },
    message: 'The :attribute is not in the CIDR (single IP) format: <ip>/32',
  },
  ipv4: {
    function: val => {
      if (typeof val !== 'string') return false;

      // regex to check that each octet is valid
      const er = /^[0-9]+$/;
      // ipv4 octets are delimited by dot
      const octets = val.split('.');
      // check 1: ipv4 address should contains 4 octets
      if (octets.length !== 4) return false;

      for (let i = 0; i < octets.length; i++) {
        const element = octets[i];
        // check 2: each octet should be integer bigger than 0
        if (!er.test(element)) return false;

        // check 3: each octet value should be less than 256
        const octetValue = parseInt(element, 10);
        if (octetValue >= 256) return false;
      }

      // if all checks passed, we know it's valid IPv4 address!
      return true;
    },
    message: 'The :attribute is not in IPv4 format',
  },
};

const formPlugins = Object.freeze({
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }) => {
      Object.keys(dvrRules).forEach(key => validator.register(key, dvrRules[key].function, dvrRules[key].message));
    },
  }),
});

const formOptions = Object.freeze({
  showErrorsOnReset: false,
});

function createForm(fields, pluginsParam, optionsParam) {
  const plugins = pluginsParam || formPlugins;
  const options = optionsParam || formOptions;
  return new MobxReactForm({ fields }, { plugins, options });
}

/**
 * Creates a MobxReactForm specific to the field identified by the specified fieldName from the given fields.
 * @param fieldName Name of the field to create MobxReactForm for
 * @param fields An array of MobxReactForm fields OR an object containing the form fields.
 * See MobxReactForm documentation about fields https://foxhound87.github.io/mobx-react-form/docs/fields/ for more details.
 *
 * @param value Optional value for the field
 * @param pluginsParam Optional plugin parameters for the MobxReactForm
 * @param optionsParam Optional options parameters for the MobxReactForm
 */
function createSingleFieldForm(fieldName, fields, value, pluginsParam, optionsParam) {
  // An array of MobxReactForm fields OR an object containing the form fields
  // Find field with the given fieldName from the fields
  // In case of Array: It has shape [ {fieldName1:field1}, {fieldName2:field2} ]
  // In case of Object: It has shape { fieldName1:field1, fieldName2:field2 }
  const fieldsObj = _.isArray(fields) ? _.find(fields, field => _.keys(field)[0] === fieldName) : fields;
  const fieldOfInterest = _.get(fieldsObj, fieldName);

  if (!fieldOfInterest) {
    throw new Error(`Field not found. Can not create form for field ${fieldName}.`);
  }
  const fieldWithValue = _.assign({}, { value }, fieldOfInterest);
  const fieldsToUse = { [fieldName]: fieldWithValue };
  return createForm(fieldsToUse, pluginsParam, optionsParam);
}

export { formPlugins, formOptions, createForm, createSingleFieldForm };
