/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Container } from 'semantic-ui-react';

import Stores from '../../models/Stores';
import { StudiesStore } from '../../models/studies/StudiesStore';
import { withRouter } from '../../helpers/routing';
import { swallowError } from '../../helpers/utils';
import { isStoreLoading, isStoreEmpty, isStoreNotEmpty, isStoreError } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import AccessRequestsCard from './AccessRequestsCard';
import { NotebooksStore } from '../../models/notebooks/NotebooksStore';

// expected props
// - accessRequestsStore (via injection)
// - location (from react router)
// edit 2359 - add support for EMR-hail notebook permissions handling
class AccessRequestsList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.studiesStore = StudiesStore.create({ loadMethod: 'getMyStudies' });
    this.notebookStore = NotebooksStore.create({ loadMethod: 'getMyNotebooks' });
    runInAction(() => {
      this.store = new Stores([
        this.props.accessRequestsStore,
        this.props.notebookAccessRequestsStore,
        this.studiesStore,
        this.notebookStore,
      ]);
    });
  }

  componentDidMount() {
    swallowError(this.store.load());
  }

  render() {
    const store = this.store;
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreEmpty(this.props.accessRequestsStore) && isStoreEmpty(this.props.notebookAccessRequestsStore)) {
      content = this.renderEmpty();
    } else if (isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No Pending Access Requests
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="list alternate outline" className="align-top" />
          <Header.Content className="left-align">Access Requests</Header.Content>
        </Header>
      </div>
    );
  }

  // edit 2359 - add support for EMR-hail notebook permissions handling
  renderMain() {
    let accessRequests = this.props.accessRequestsStore.list;
    let notebookreq = this.props.notebookAccessRequestsStore.list;
    accessRequests = accessRequests
      .map(ar => {
        const study = this.studiesStore.getStudy(ar.id);
        return {
          ...ar,
          study,
          type: 'study',
        };
        // TODO: In most cases the study will be present, but we should handle the cases where it is not.
      })
      .filter(ar => !!ar.study);
    notebookreq = notebookreq
      .map(ar => {
        const notebook = this.notebookStore.getNotebook(ar.id);
        return {
          ...ar,
          study: notebook,
          type: 'notebook',
        };
      })
      .filter(ar => !!ar.study);
    return (
      <div>
        {_.map([...accessRequests, ...notebookreq], item => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={item.id} data-instance={item.id}>
            <AccessRequestsCard accessRequests={item} collections={item.collections} />
          </Segment>
        ))}
      </div>
    );
  }
}

export default inject('accessRequestsStore', 'notebookAccessRequestsStore')(withRouter(observer(AccessRequestsList)));
