// added by leeas

import _ from 'lodash';
import React from 'react';
import { makeObservable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Table, Label, Progress, Segment, Button, Grid, Icon, Container } from 'semantic-ui-react';
import { isAfter } from 'date-fns';
import ByWithProfile from '../helpers/ByWithProfile';
import {
  isStoreLoading,
  isStoreReady,
  isStoreEmpty,
  isStoreNotEmpty,
  isStoreError,
  isStoreReloading,
} from '../../models/BaseStore';
import { withRouter } from '../../helpers/routing';
import { swallowError, niceNumber } from '../../helpers/utils';
import ErrorBox from '../helpers/ErrorBox';
import { displayError, displaySuccess } from '../../helpers/notification';
import { getSearchUploadDirReqForm } from '../../models/forms/SearchUploadDirReqForm';
import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';

const DELIMIT = '/';

// expected props
// - userStore (via injection)
// - studyUploadDirStore (via injection)
class UploadDirReqPage extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      loading: computed,
      results: computed,
      handleSearchFormSubmission: action,
    });

    const currUser = this.props.userStore.user;
    this.unameWithNs = _.join([currUser.ns, currUser.username], DELIMIT);
    // console.log("in UploadDirReqPage: unameWithNs: " + this.unameWithNs);
    this.uploadDirStore = this.props.studyUploadDirStore;
    this.ns = currUser.ns;
    this.form = getSearchUploadDirReqForm();
    this.today = new Date();
  }

  componentDidMount() {
    swallowError(this.getUploadDirStore().load(this.unameWithNs));
  }

  get loading() {
    const uploadDirStore = this.getUploadDirStore();
    return isStoreLoading(uploadDirStore) || isStoreReloading(uploadDirStore);
  }

  get results() {
    return this.getUploadDirStore().list;
  }

  getCurrUser() {
    return this.props.userStore.user;
  }

  async getStudy(studyId) {
    try {
      const study = await this.props.studiesStore.isStudyAdmin(studyId, this.getCurrUser().username);
      return study;
    } catch (error) {
      console.log(`Error getting ${studyId} ${JSON.stringify(error)}`);
      displayError('Cannot find study!');
      return false;
    }
  }

  getUploadDirStore() {
    return this.uploadDirStore;
  }

  /* handleFormError = () => {
    // We don't need to do anything here
  }; */

  handleSearchFormSubmission = async form => {
    if (this.loading) return;

    const values = form.values();
    const username = values.username;
    const studyId = values.studyId;
    const isCurrUserAdmin = this.getCurrUser().isAdmin;

    if (username != null && username.length > 0 && !isCurrUserAdmin) {
      alert('You are only allowed to search by Study Id!');
    } else {
      const uploadDirStore = this.getUploadDirStore();
      if (username !== null && (studyId === null || studyId.length === 0)) {
        const userToFind = _.join([this.ns, username], DELIMIT);
        swallowError(uploadDirStore.load(userToFind));
      } else {
        const isStudyAdmin = await this.getStudy(studyId);
        console.log(`in search: study: ${studyId} ${isStudyAdmin}`);
        if (!isCurrUserAdmin && !isStudyAdmin) {
          alert('You are only allowed to search for your own Study Id!');
        } else {
          const userToFind = username ? _.join([this.ns, username], DELIMIT) : null;
          swallowError(uploadDirStore.searchByStudy(studyId, userToFind, this.getCurrUser().username));
        }
      }
    }
  };

  handleRevokeReq = async request => {
    try {
      const studyAdminUnameWithNs = request.isUserStudyAdmin ? this.unameWithNs : null;
      await this.getUploadDirStore().revokeRequest(
        request.unameWithNs,
        request.uploaddir,
        request.studyId,
        studyAdminUnameWithNs,
      );
      displaySuccess('Upload Access Revoked.');
      this.form.clear();
    } catch (error) {
      displayError('Revoke Failed', error);
    }
  };

  render() {
    const store = this.getUploadDirStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (this.loading || isStoreLoading(store)) {
      content = this.renderMain();
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="eye" className="align-top" />
          <Header.Content className="left-align">Upload Access</Header.Content>
        </Header>
      </div>
    );
  }

  renderMain() {
    return (
      <div>
        {this.renderSearchForm()}
        <Segment.Group>
          <Segment clearing>
            {this.renderTotal()}
            {this.renderMainResult()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  renderTotal() {
    const count = this.getUploadDirStore().total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderMainResult() {
    const uploadDirStore = this.getUploadDirStore();
    const loading = this.loading;
    let content = null;
    let progressBar = null;

    if (loading) {
      progressBar = <Progress percent={uploadDirStore.progress} indicating />;
    }

    if (isStoreError(uploadDirStore)) {
      content = <ErrorBox error={uploadDirStore.error} />;
    } else if (loading) {
      content = this.renderResult();
    } else if (isStoreReady(uploadDirStore) && isStoreEmpty(uploadDirStore)) {
      content = this.renderEmpty();
    } else if (isStoreReady(uploadDirStore) && isStoreNotEmpty(uploadDirStore)) {
      content = this.renderResult();
    } else {
      content = null;
    }

    return (
      <div className="animated fadeIn mt3">
        {progressBar}
        {content}
      </div>
    );
  }

  renderEmpty() {
    return <div>No upload access requests</div>;
  }

  renderSearchForm() {
    const form = this.form;
    const username = form.$('username');
    const studyId = form.$('studyId');

    return (
      <Segment clearing className="mb1">
        <Form form={form} onSuccess={this.handleSearchFormSubmission}>
          {({ loading }) => (
            <>
              <Grid columns="two">
                <Grid.Column key="username">
                  <Input field={username} disabled={loading} className="mb1" />
                </Grid.Column>
              </Grid>
              <Grid columns="two">
                <Grid.Column key="studyId">
                  <Input field={studyId} disabled={loading} className="mb1" />
                </Grid.Column>
              </Grid>
              <div>
                <Button floated="right" color="blue" icon disabled={loading} type="submit">
                  Search Upload Access
                </Button>
              </div>
            </>
          )}
        </Form>
      </Segment>
    );
  }

  getButton(request, username) {
    const validtill = new Date(request.validtill);
    const isActive = isAfter(validtill, this.today);
    console.log(`isStudyAdmin: ${request.isUserStudyAdmin}`);
    const canRevoke = this.getCurrUser().isAdmin || request.isUserStudyAdmin || request.unameWithNs === username;
    if (isActive) {
      return (
        <Button
          onClick={() => {
            this.handleRevokeReq(request);
          }}
          floated="right"
          disabled={!canRevoke}
        >
          Revoke
        </Button>
      );
    }
    return (
      <Button floated="right" disabled>
        Revoked
      </Button>
    );
  }

  renderActions(request, username) {
    return this.getButton(request, username);
  }

  renderResult() {
    const result = this.results;
    if (_.isEmpty(result)) {
      return this.renderEmpty();
    }

    const username = this.getUploadDirStore().unameWithNs;
    const renderRow = (row, index) => {
      return (
        <Table.Row key={index} verticalAlign="top">
          <Table.Cell textAlign="center" collapsing>
            {row.studyId}
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing style={{ whiteSpace: 'normal' }}>
            {row.validtill}
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing style={{ whiteSpace: 'normal' }}>
            <ByWithProfile
              user={this.props.usersStore.asUserObjectByNsUsername(row.createdBy.ns, row.createdBy.username)}
            />
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing style={{ whiteSpace: 'normal' }}>
            {row.createdAt}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {this.renderActions(row, username)}
          </Table.Cell>
        </Table.Row>
      );
    };

    return (
      <Table striped stackable selectable celled size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" collapsing>
              Study
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" collapsing>
              Validity Date
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" collapsing>
              Requestor
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" collapsing>
              Requested Date
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{_.map(result, (row, index) => renderRow(row, index))}</Table.Body>
      </Table>
    );
  }
}

export default inject(
  'userStore',
  'studiesStore',
  'usersStore',
  'studyUploadDirStore',
)(withRouter(observer(UploadDirReqPage)));
