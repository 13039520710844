/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';
import { BaseStore } from '../BaseStore';
import { getTagSet, addTag, editTag, removeTag } from '../../helpers/api';
import Tag from './Tag';

// ==================================================================
// TagSet
// ==================================================================

const TagSet = BaseStore.named('TagSet')
  .props({
    id: types.identifier,
    tags: types.optional(types.array(Tag), []),
  })
  .actions(self => ({
    doLoad: async () => {
      const data = await getTagSet(self.id);
      applySnapshot(self, data);
      return data;
    },
    addTag: async ({ key, values }) => {
      const data = await addTag(self.id, key, values);
      self.runInAction(() => {
        self.tags.push(data);
      });
      return data;
    },
    editTag: async ({ id, key, values, rev }) => {
      const data = await editTag(id, key, values, rev);
      self.runInAction(() => {
        self.tags = self.tags.filter(tag => !(tag.id === id && tag.key === key)); // remove first
        self.tags.push(data); // add back in
      });
    },
    removeTag: async ({ id, key }) => {
      await removeTag(id, key);
      self.runInAction(() => {
        self.tags = self.tags.filter(tag => !(tag.id === id && tag.key === key));
      });
    },
  }));
export default TagSet;
