import React from 'react';
import { Button, Header as BaseHeader, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getPublishRequestAdminForm } from '../../models/forms/PublishRequestAdminForm';
import Form from '../helpers/fields/Form';
import TextArea from '../helpers/fields/TextArea';

class ReviewPublishRequest extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.publishRequest = props.request;
    this.type = props.type;
    this.formType = props.formType;
    this.title = (this.formType === 'approve' ? 'Approve' : 'Reject') + (this.type === 'ami' ? ' AMI ': ' ');    	  
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      isProcessing: false,
    };
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  // handleRevokePermission = async () => {};

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const requestValues = form.values();
      const updateRequest = { ...this.publishRequest, ...requestValues };

      if (this.formType === 'approve') {
        await this.props.onApprovePublish(this.publishRequest.id, updateRequest);
      } else {
        await this.props.onRejectPublish(this.publishRequest.id, updateRequest);
      }

      form.clear();
      this.cleanModal();
    } catch (error) {
      // displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>
          <div className="mt2 animated fadeIn">
            <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
              {this.title}Publish Request
            </BaseHeader>
            <div className="mx3 animated fadeIn">{this.renderReviewPublishRequestForm()}</div>
          </div>
        </Dimmer.Dimmable>
      </Modal>
    );
  }

  renderTrigger() {
    const buttonColor = this.formType === 'approve' ? 'green' : 'red';
    const props = this.props.buttonProps || {
      floated: 'right',
      color: buttonColor,
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        {this.title} Request
      </Button>
    );
  }

  renderReviewPublishRequestForm() {
    const form = getPublishRequestAdminForm();	  
    const fieldName = (this.type === 'ami' ? 'Ami': 'Study'); 
    const requestId = (this.type === 'ami' ? this.publishRequest.amiId: this.publishRequest.studyId);
    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, onSubmit, onCancel }) => {
          return (
            <>
              <BaseHeader>{fieldName}: {requestId}</BaseHeader>
              <TextArea field={form.$('comments')} />
              <Button
                onClick={onSubmit}
                className="ml2 mb3"
                floated="right"
                color="blue"
                icon
                disabled={processing}
                type="submit"
              >
                Submit
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(ReviewPublishRequest));
