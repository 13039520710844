/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { observable, action, runInAction, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Progress, Header, Segment, Button, Container, Label, Input } from 'semantic-ui-react';

import { isStoreLoading, isStoreReloading } from '../../../../models/BaseStore';
import { displayError } from '../../../../helpers/notification';

class Carryout extends React.Component {
  loading = false;
  setName = '';

  constructor(props) {
    super(props);
    // error: observable,
    makeObservable(this, {
      handleCreateDownloadKey: action,
      loading: observable,
      setName: observable,
      handleChange: action,
    });
  }

  getStore() {
    return this.props.userDownloadKeysStore.getCurrentUserDownloadKeysStore();
  }

  handleCreateDownloadKey = async () => {
    if (this.setName === '') {
      alert('Please input study set name!');
    } else {
      this.loading = true;
      // const files = this.props.carryoutStore.files;
      this.getStore()
        .createNewDownloadKey(this.setName, this.props.carryoutStore.files)
        .then(downloadKey => {
          runInAction(() => {
            this.createDownloadZip(downloadKey);
            this.loading = false;
            this.generated = true;
          });
        })
        .catch(err => {
          runInAction(() => {
            // this.error = parseError(err);
            this.loading = false;
            displayError(err);
          });
        });
    }
  };

  handleChange = (event, data) => {
    this.setName = data.value;
  };

  createDownloadZip(downloadKey) {
    const zip = new JSZip();
    this.addCredentialFile(zip, downloadKey);
    this.addCyberduckReadme(zip);
    this.addCyberduckProfiles(zip, downloadKey);
    this.addMountReadme(zip);
    this.addMountCommands(zip, downloadKey);
    this.addExpiryReminder(zip, downloadKey);
    this.addStudyList(zip, downloadKey);
    zip.generateAsync({ type: 'blob' }).then(function fn(content) {
      FileSaver.saveAs(content, `${downloadKey.name}.zip`);
    });
  }

  addCyberduckReadme(zip) {
    zip.file('CYBERDUCK_README.TXT', this.generateCyberduckReadme());
  }

  addMountReadme(zip) {
    zip.file('GOOFYS_README.TXT', this.generateMountReadme());
  }

  addMountCommands(zip, downloadKey) {
    const mountFolder = zip.folder('goofys');
    mountFolder.file('mount.sh', this.generateMountCommands(downloadKey));
  }

  addCredentialFile(zip, downloadKey) {
    zip.file('credentials', this.generateCredentialFile(downloadKey));
  }

  addExpiryReminder(zip, downloadKey) {
    zip.file(`EXPIRES_${downloadKey.expiryDate}`, '');
  }

  addStudyList(zip, downloadKey) {
    zip.file('study_list.txt', this.generateStudyList(downloadKey));
  }

  generateCredentialFile(downloadKey) {
    const credential =
      `[raptor_user_read_access]\n` +
      `aws_access_key_id=${downloadKey.accessKey}\n` +
      `aws_secret_access_key=${downloadKey.secretKey}`;
    return credential;
  }

  addCyberduckProfiles(zip, downloadKey) {
    const cyberduck = zip.folder('cyberduck');
    _.map(downloadKey.files, file => {
      _.map(file.resources, resource => {
        const profile = this.generateCyberduckProfile(file.id, resource);
        cyberduck.file(`${file.id}.cyberduckprofile`, profile);
      });
    });
  }

  generateCyberduckProfile(studyId, resource) {
    return (
      `<?xml version="1.0" encoding="UTF-8"?> \n` +
      `<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd"> \n` +
      `<plist version="1.0"> \n` +
      `<dict> \n` +
      `<key>Protocol</key> \n` +
      `<string>s3</string> \n` +
      `<key>Vendor</key> \n` +
      `<string>s3-aws-https</string> \n` +
      `<key>Scheme</key> \n` +
      `<string>https</string> \n` +
      `<key>UUID</key> \n` +
      `<string>${studyId} </string> \n` +
      `<key>Description</key> \n` +
      `<string>${studyId}</string> \n` +
      `<key>Default Port</key> \n` +
      `<string>443</string> \n` +
      `<key>Default Path</key> \n` +
      `<string>${resource.replace('arn:aws:s3:::', '')}</string> \n` +
      `<key>Default Nickname</key> \n` +
      `<string>${studyId}</string> \n` +
      `<key>Default Hostname</key> \n` +
      `<string>s3-ap-southeast-1.amazonaws.com</string> \n` +
      `</dict> \n` +
      `</plist> \n`
    );
  }

  generateMountCommands(downloadKey) {
    let mountCommands = '';
    _.map(downloadKey.files, file => {
      _.map(file.resources, resource => {
        mountCommands += `mkdir /mnt/${file.id}\n`;
        mountCommands += `goofys -o allow_other --file-mode=0777 --dir-mode=0777 --profile raptor_user_read_access ${resource
          .replace('arn:aws:s3:::', '')
          .replace('/', ':')} /mnt/${file.id}\n`;
      });
    });
    return mountCommands;
  }

  generateCyberduckReadme() {
    const readme =
      'Downloading data with Cyberduck \n' +
      '1. Download and install Cyberduck from https://cyberduck.io/download/\n' +
      "2. Navigate into the 'cyberduck' folder in the manifest.zip.\n" +
      '3. Double-click on the <study_id>.cyberduckprofile to open Cyberduck to the study data repository.\n' +
      "4. When prompted, enter the access key and secret key found in the file 'credentials' in the manifest zip.\n";
    return readme;
  }

  generateMountReadme() {
    const readme =
      'Mounting data onto Linux machine (root user)\n' +
      '1. Download and install Goofys (https://github.com/kahing/goofys#installation). \n' +
      '2. Set up your environment with the following paths: GOPATH \n' +
      '3. Copy the `credentials` file in the manifest.zip into your machine at ~/.aws/credentials. \n' +
      "4. Navigate into the 'goofys' folder in the manifest.zip. \n" +
      '5. Run the mount.sh script.';
    return readme;
  }

  generateStudyList(downloadKey) {
    let studyListWithS3 = '';
    _.map(downloadKey.files, file => {
      _.map(file.resources, resource => {
        studyListWithS3 += `${file.id}--${resource.replace('arn:aws:s3:::', 's3://')}\n`;
      });
    });
    return studyListWithS3;
  }

  render() {
    const store = [this.props.carryoutStore, this.props.userDownloadKeysStore, this.props.studiesStore];
    const isBusy = isStoreLoading(store) || isStoreReloading(store);

    return isBusy ? this.renderProgress() : this.renderInstructions();
  }

  renderInstructions() {
    const num = value => (
      <Label circular className="mr2">
        {value}
      </Label>
    );

    return (
      <div className="animated fadeIn">
        <div>
          {this.generated ? (
            <div className="ui action input">
              <Input disabled value={this.setName} />
              <Button
                disabled
                size="small"
                color="blue"
                floated="right"
                className="mt1 mr1"
                content="Generate and download manifest zip"
                icon="cloud download"
                labelPosition="right"
              />
            </div>
          ) : this.loading ? (
            <div className="ui action input">
              <Input disabled value={this.setName} />
              <Button
                size="small"
                color="blue"
                floated="right"
                className="mt1 mr1"
                content="Generate and download manifest zip"
                icon="cloud download"
                labelPosition="right"
                loading
              />
            </div>
          ) : (
            <div className="ui action input">
              <Input placeholder="Study set name" value={this.setName} onChange={this.handleChange} />
              <Button
                size="small"
                color="blue"
                floated="right"
                className="mr1"
                content="Generate and download manifest zip"
                icon="cloud download"
                labelPosition="right"
                onClick={this.handleCreateDownloadKey}
              />
            </div>
          )}
        </div>
        <Header as="h3" attached="top" color="grey">
          Usage Instructions
        </Header>
        <Segment attached>
          <div className="flex">
            <div className="pl2">
              <Container fluid text textAlign="left">
                <div className="flex mt2">
                  <div>{num(1)}</div>
                  <div className="flex-auto">
                    Generate and download the manifest zip. The manifest zip contains your download key and should be
                    considered a <b>secret</b>, it <b>should not be shared with others</b>, and it should be{' '}
                    <b>protected</b>. If the manifest.zip is lost, please revoke the download key and regenerate a new
                    one. Download keys generated are only valid for a period of{' '}
                    <u>
                      <b>2 months</b>
                    </u>
                    . <br />
                    If the permissions to any selected study is revoked, the associated download key{' '}
                    <u>
                      <b>will be revoked</b>
                    </u>{' '}
                    regardless of the validity date. <br />
                    <b>
                      Download keys are identified by the access key value in the credentials file in the manifest zip.{' '}
                    </b>
                  </div>
                </div>
                <div className="flex mt2">
                  <div>{num(2)}</div>
                  <div className="flex-auto">Unzip the manifest zip.</div>
                </div>
                <div className="flex mt2">
                  <div>{num(3)}</div>
                  <div className="flex-auto display-linebreak">{this.generateCyberduckReadme()}</div>
                </div>
                <div className="flex mt2">
                  <div>{num(4)}</div>
                  <div className="flex-auto display-linebreak">{this.generateMountReadme()}</div>
                </div>
              </Container>
            </div>
            <div className="flex-auto">&nbsp;</div>
          </div>
          <div style={{ clear: 'both' }} />
        </Segment>
      </div>
    );
  }

  renderProgress() {
    return (
      <Progress percent={100} active color="blue">
        <span className="color-grey">Preparing access information</span>
      </Progress>
    );
  }
}

export default inject('carryoutStore', 'userDownloadKeysStore', 'studiesStore')(observer(Carryout));
