/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { observer } from 'mobx-react';
import { Label } from 'semantic-ui-react';

function UserLabels(props) {
  const { color, className = '', users } = props;

  return (
    <div className={className}>
      {_.map(users, user => (
        <Label key={user.username} color={color} image className="mt1">
          {user.firstName}
          {user.lastName}
          <Label.Detail>
            {user.unknown && `${user.username}??`}
            {!user.unknown && (user.email || user.username)}
          </Label.Detail>
        </Label>
      ))}
    </div>
  );
}

export default observer(UserLabels);
