import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';
import { approveStudyPublish, rejectStudyPublish } from '../../helpers/api';
import { displayError } from '../../helpers/notification';

const AWAITING_REVIEW = 'AWAITING REVIEW';
const SUBMITTED = 'SUBMITTED';
const PENDING = 'PENDING';
const RUNNING = 'RUNNING';
const RUNNABLE = 'RUNNABLE';
const STARTING = 'STARTING';

const FAILED = 'FAILED';
const SUCCEEDED = 'SUCCEEDED';
const REJECTED = 'REJECTED';

const PublishRequest = types
  .model('PublishRequest', {
    id: types.string,
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    studyId: types.string,
    status: types.enumeration('status', [
      AWAITING_REVIEW,
      PENDING,
      RUNNING,
      SUCCEEDED,
      REJECTED,
      FAILED,
      RUNNABLE,
      SUBMITTED,
      STARTING,
    ]),
  })
  .actions(self => ({
    updateStatus(status) {
      self.status = status;
    },
    async approve(requestId, updateRequest) {
      self.status = RUNNING;
      const { comments } = updateRequest;
      try {
        await approveStudyPublish(requestId, { id: requestId, comments });
        self.updateStatus(SUBMITTED);
      } catch (e) {
        self.updateStatus(FAILED);
        displayError(`Error approving publish for '${self.studyId}'`, e);
      }
    },
    async reject(requestId, updateRequest) {
      self.status = RUNNING;
      const { comments } = updateRequest;
      try {
        await rejectStudyPublish(requestId, { id: requestId, comments });
        self.updateStatus(REJECTED);
      } catch (e) {
        self.updateStatus(FAILED);
        displayError(`Error rejecting publish for '${self.studyId}'`, e);
      }
    },
  }))
  .views(self => ({
    pending() {
      return !self.status || self.status === AWAITING_REVIEW;
    },
    running() {
      return (
        self.status === RUNNING || self.status === STARTING || self.status === SUBMITTED || self.status === PENDING
      );
    },
    completed() {
      return self.status === SUCCEEDED;
    },
    rejected() {
      return self.status === REJECTED;
    },
    failed() {
      return self.status === FAILED;
    },
  }));

export default PublishRequest;
