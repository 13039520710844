import React from 'react';
import { Button, Modal, Table, Pagination } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import Form from '../helpers/fields/Form';
import StudyCollectionsCheckbox from './StudyCollectionsCheckbox';

import { getAddBatchToCollectionForm } from '../../models/forms/AddBatchToCollectionForm';

const ITEMS_PER_PAGE = 5;

class DataCollectionModal extends React.Component {
  modalOpen = false;
  activePage = 1;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
      activePage: observable,
    });

    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Add selection to collections
      </Button>
    );
  }

  onBatchUpdate = async form => {
    const formValues = form.values();
    const fullPaths = this.props.files.map(file => file.fullPath);
    await this.props.batchUpdate(fullPaths, formValues.collectionIds);
  };

  renderSelectionTable(files) {
    const activePageFiles = files.slice((this.activePage - 1) * ITEMS_PER_PAGE, this.activePage * ITEMS_PER_PAGE);
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Full Path</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {activePageFiles.map(file => (
            <Table.Row key={file.fullPath}>
              <Table.Cell>{file.fileName}</Table.Cell>
              <Table.Cell>{file.fullPath}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const form = getAddBatchToCollectionForm();
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <Modal.Header as="h3" color="grey">
          Selections
        </Modal.Header>
        <Modal.Content>
          <Form
            form={form}
            onCancel={this.handleFormCancel}
            onSuccess={this.onBatchUpdate}
            onAlternate={this.handleRevokePermission}
            onError={this.handleFormError}
          >
            {({ processing, onSubmit, onCancel }) => {
              return (
                <>
                  <div className="w-100 flex justify-end mt1">
                    {this.props.files.length > ITEMS_PER_PAGE && (
                      <Pagination
                        boundaryRange={0}
                        activePage={this.activePage}
                        onPageChange={(e, data) => {
                          runInAction(() => {
                            this.activePage = data.activePage;
                          });
                        }}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={0}
                        totalPages={Math.ceil(this.props.files.length / ITEMS_PER_PAGE)}
                      />
                    )}
                  </div>
                  {this.renderSelectionTable(this.props.files.toJSON())}
                  <StudyCollectionsCheckbox
                    field={form.$('collectionIds')}
                    options={this.props.collections.map(collection => ({
                      key: collection.collectionId,
                      text: collection.collectionName,
                      value: collection.collectionId,
                      description: collection.description,
                    }))}
                  />
                  <div>
                    <Button
                      onClick={onSubmit}
                      className="ml2 mb3"
                      floated="right"
                      color="blue"
                      icon
                      disabled={processing}
                      type="submit"
                    >
                      Add
                    </Button>
                    <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default observer(DataCollectionModal);
