import _ from 'lodash';

import { createForm } from '../../helpers/form';

const editSecureEnvironmentSecurityForm = {
  cidr: {
    label: 'Whitelisted CIDR',
    placeholder: 'The CIDR range to restrict research workspace access to',
    extra: {
      explain: `<p>For secure workspaces, you are <span class="alert-text">REQUIRED</span> to restrict access to a <span class="alert-text">single IP</span> at any time. To <b>accurately</b> check your device's actual IP:
					<ul>
						<li>For Windows: Open your Command Prompt terminal (search for "cmd") and run <span class="code-text">curl http://checkip.amazonaws.com/</span> </li>
						<li>For MacOS: Open Terminal and run <span class="code-text">curl https://checkip.amazonaws.com/</span> </li>
					</ul>
					The CIDR value to be used: {your_reported_ip}/32 (for e.g. 101.99.88.77/32)</br></br>
					NOTE: Please do NOT go to the URL via a browser to retrieve your IP address as organizational laptops/desktops may be behind a browser proxy which hides your true IP address.
					</p>`,
    },
    rules: 'required|singleCidr',
  },
};

const getEditSecureEnvironmentSecurityForm = defaultValues => {
  return createForm(_.merge(editSecureEnvironmentSecurityForm, defaultValues));
};

export { getEditSecureEnvironmentSecurityForm }; // eslint-disable-line import/prefer-default-export
