import React from 'react';
import _ from 'lodash';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import { swallowError } from '../../helpers/utils';
import { isStoreError } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';

// expected props
// - environment
// - environmentsStore
class EnvironmentCostDetails extends React.Component {
  constructor(props) {
    super(props);
    this.store = props.environmentsStore;
    this.state = { showLoader: true, costs: [] };
  }

  async componentDidMount() {
    swallowError(this.store.load());
    const envCreatedAt = new Date(this.props.environment.createdAt);
    const now = new Date();
    const diffTime = Math.abs(now - envCreatedAt);
    const numberOfDaysBetweenDateCreatedAndToday = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const cost = await this.store.getCost(this.props.environment.id, numberOfDaysBetweenDateCreatedAndToday);
    runInAction(() => {
      this.setState({ costs: cost, showLoader: false });
    });
  }

  render() {
    // Render loading, error, or button
    let content;
    if (isStoreError(this.store)) {
      content = <ErrorBox error={this.store.error} />;
    } else {
      content = this.renderCostTable();
    }

    return content;
  }

  renderCostTable() {
    let costHeadings = [];
    const rows = [];
    this.state.costs.forEach(costItemGivenADate => {
      const cost = costItemGivenADate.cost;
      const headings = Object.keys(cost);
      costHeadings.push(headings);
      const rowValues = {};
      rowValues.date = costItemGivenADate.startDate;
      let total = 0;
      headings.forEach(heading => {
        const amount = cost[heading].amount;
        rowValues[heading] = amount.toFixed(2);
        total += amount;
      });
      rowValues.total = total.toFixed(2);
      rows.push(rowValues);
    });

    costHeadings = _.flatten(costHeadings);
    costHeadings = _.uniq(costHeadings);

    return (
      <>
        <Dimmer active={this.state.showLoader}>
          <Loader />
        </Dimmer>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              {costHeadings.map(header => {
                return <Table.HeaderCell key={header}>{header}</Table.HeaderCell>;
              })}
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map(row => {
              return (
                <Table.Row key={row.date}>
                  <Table.Cell>{row.date}</Table.Cell>
                  {costHeadings.map(header => {
                    return <Table.Cell key={row}>${_.get(row, header, 0)}</Table.Cell>;
                  })}
                  <Table.Cell>${row.total}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default observer(EnvironmentCostDetails);
