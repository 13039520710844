/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';

import { createForm } from '../../helpers/form';

const editStudyFields = {
  // General fields
  id: {
    label: 'ID',
    placeholder: 'A unique ID used to reference the study',
    extra: {
      explain: 'Must be less than 100 characters long and only contain alphanumeric characters, "-", or "_"',
    },
    rules: ['required', 'string', 'between:1,100', 'regex:/^[A-Za-z0-9-_ ]+$/'],
    disabled: true,
  },
  name: {
    label: 'Name',
    placeholder: 'A name for the study',
    rules: ['required', 'string', 'between:1,2048'],
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the study',
    rules: ['string', 'max:8192'],
  },
};

const getEditStudyForm = defaultValues => {
  return createForm(_.merge(editStudyFields, defaultValues));
};

export { getEditStudyForm }; // eslint-disable-line import/prefer-default-export
