import { createForm } from '../../helpers/form';

const addBatchToCollectionFields = {
  // General fields
  collectionIds: {
    label: 'Collections',
    value: [],
  },
};

const getAddBatchToCollectionForm = () => {
  return createForm(addBatchToCollectionFields);
};

export { getAddBatchToCollectionForm }; // eslint-disable-line import/prefer-default-export
