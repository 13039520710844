/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Container, Header, Icon, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { isStoreError, isStoreLoading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import SimpleTable from '../helpers/SimpleTable';
import { withRouter } from '../../helpers/routing';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';

class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getProjectsStore() {
    const store = this.props.projectsStore;
    store.load();
    return store;
  }

  getProjects() {
    const store = this.getProjectsStore();
    return store.list;
  }

  renderMain() {
    const projectsData = this.getProjects();
    // const pageSize = projectsData.length;
    // const pagination = projectsData.length > pageSize;

    return (
      <div>
        <SimpleTable
          headerRenderer={() => {
            return ['Project Name', 'Index Id', 'Description'];
          }}
          rowRenderer={request => {
            return [request.id, request.indexId, request.description];
          }}
          rowData={projectsData}
          keyMethod={request => request.id}
        />
        <br />
      </div>
    );
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleAddProject = () => {
    this.goto('/projects/add');
  };

  renderHeader() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="briefcase" className="align-top" />
          <Header.Content className="left-align">
            Projects
            {this.renderTotal()}
          </Header.Content>
        </Header>
        <Button color="blue" size="medium" basic onClick={this.handleAddProject}>
          Add Project
        </Button>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getProjects().length}</Label>;
  }

  render() {
    const store = this.getProjectsStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderMain();
    }
    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {content}
      </Container>
    );
  }
}

export default inject('awsAccountsStore', 'projectsStore')(withRouter(observer(ProjectsList)));
