/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

const localStorageKeys = {
  // Name of the id token for Data Lake APIs in local storage
  appIdToken: 'appIdToken',
};

export default localStorageKeys;
