/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Menu, Icon, Dropdown } from 'semantic-ui-react';
import { withRouter } from '../helpers/routing';
import { displayError } from '../helpers/notification';
import { branding } from '../helpers/settings';
import UserConfigure from './users/UserConfigure';
import UserAgreement from './users/UserAgreement';

// expected props
// - userStore (via injection)
// edit 2359 - added link for Notebooks
class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    makeObservable(this, {
      handleLogout: action,
      handleContactUs: action,
      handleUserManual: action
    });
  }

  goto = pathname => () => {
	/*
    const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });
	*/

    this.props.navigate(pathname);
  };

  handleLogout = async event => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await this.props.authentication.logout();
      window.location = '/';
    } catch (error) {
      displayError(error);
    }
  };

  handleContactUs = async event => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = 'mailto:raptor_admin@gis.a-star.edu.sg';
  };

  handleUserManual = async event => {
    event.preventDefault();
    event.stopPropagation();
    window.open('https://dik0p9iflrv67.cloudfront.net/RAPTOR+User+Documentation.docx', '_blank');
  };

  handleUserAgreement = async event => {
    event.preventDefault();
    event.stopPropagation();
    window.open('https://dik0p9iflrv67.cloudfront.net/RAPTOR+User+Agreement.pdf', '_blank');
  };
	
  render() {
    return (
      <>
       <UserAgreement userStore={this.props.userStore} usersStore={this.props.usersStore}/>
       { this.renderMenu() }
      </>
    );
  }

  // edited by 2359 - added link for AMI
  // edit 2359 - added link for Notebooks
  // edit chenjqp - shift admin menu to the bottom, remove grouping for now as it interferes with scrolling
  renderMenu() {
    const displayRole = this.props.userStore.user.userRole;
    const isAdmin = this.props.userStore.user.isAdmin;
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = value => _.startsWith(pathname, value);

    const items = [
      { title: 'Dashboard', url: '/dashboard', icon: 'dashboard', show: true, admin: false },
      // { title: 'Auth', url: '/authentication-providers', icon: 'user secret', show: isAdmin },
      { title: 'Users', url: '/users', icon: 'users', show: isAdmin, admin: true },
      { title: 'Accounts', url: '/accounts', icon: 'sitemap', show: isAdmin, admin: true },
      // { title: 'API Keys', url: '/api-keys', icon: 'key', show: true },
      // { title: 'Workflows', url: '/workflows/published', icon: 'fork', show: true },
      // { title: 'Projects', url: '/projects', icon: 'book', show: true },
      { title: 'Studies', url: '/studies', icon: 'book', show: true },
      { title: 'Notebooks', url: '/notebooks', icon: 'newspaper', show: true },
      { title: 'Workspaces', url: '/workspaces/R', icon: 'server', show: true, admin: false },
      { title: 'AMIs', url: '/amis', icon: 'cogs', show: true, admin: false },
      { title: 'Secure Outputs', url: '/secure-outputs', icon: 'file archive', show: true, admin: false },
      // {
      //   title: (
      //     <span>
      //       Workflows
      //       <br />
      //       Templates
      //     </span>
      //   ),
      //   url: '/workflow-templates/published',
      //   icon: 'clipboard outline',
      //   show: isAdmin,
      // },
      // { title: 'Audit Logs', url: '/auditlogs', icon: 'eye', show: isAdmin, admin: true },
      { title: 'AccessRequests', url: '/access-requests', icon: 'key', show: true, admin: false },
      // { title: 'Download Keys', url: '/download-keys', icon: 'key', show: true, admin: false },
      { title: 'UploadAccess', url: '/upload-access', icon: 'key', show: true, admin: false },
      { title: 'Settings', url: '/settings', icon: 'setting', show: isAdmin, admin: true },
      { title: 'PublishRequests', url: '/publish-requests', icon: 'key', show: isAdmin, admin: false },
      // Menu Item Insertion Point (do not change this text, it is being used by hygen cli)
    ];

    return [
      <Menu vertical inverted fixed="left" icon="labeled" key="ml1" style={{ overflowY: 'auto' }}>
        <Menu.Item name=" " style={{ height: '40px' }} />
        {_.map(
          items,
          (item, index) =>
            item.show &&
            !item.admin && (
              <Menu.Item key={index} active={is(item.url)} onClick={is(item.url) ? undefined : this.goto(item.url)}>
                <Icon name={item.icon} size="mini" />
                <span className="fs-7">{item.title}</span>
              </Menu.Item>
            ),
        )}
        {isAdmin &&
          _.map(
            items,
            (item, index) =>
              item.show &&
              item.admin && (
                <Dropdown.Item
                  key={index}
                  active={is(item.url)}
                  onClick={is(item.url) ? undefined : this.goto(item.url)}
                >
                  <Icon name={item.icon} size="mini" />
                  <span className="fs-7">{item.title}</span>
                </Dropdown.Item>
              ),
          )}
        <Menu.Item name=" " style={{ height: '40px' }} />
      </Menu>,

      <Menu vertical inverted fixed="bottom" icon="labeled" key="ml4">
        <Menu.Item name=" " style={{ height: '40px' }}>
          {branding.main.version}
        </Menu.Item>
      </Menu>,

      <Menu inverted color="black" fixed="top" className="box-shadow zindex-1500" key="ml2">
        <Menu.Item className="m0 pt2 pl2 pr2" style={{ height: '30px' }}>
          {/* <Image size="mini" src={logo} className="mr1" style={{ maxHeight: '20px' }}/> */}
          {branding.main.title}
        </Menu.Item>
        <Menu.Menu position="right">
          <UserConfigure
            user={this.props.userStore.cloneUser}
            adminMode={false}
            userStore={this.props.userStore}
            usersStore={this.props.usersStore}
            userRolesStore={this.props.userRolesStore}
            awsAccountsStore={this.props.awsAccountsStore}
            indexesStore={this.props.indexesStore}
          />
          <Menu.Item>
            <Icon name="id badge" /> {displayRole.toUpperCase()}
          </Menu.Item>

          <Menu.Item name="userdocs">
	    <Icon name="file outline" /> 
	    <Dropdown text="User Documents" className='link item'>
              <Dropdown.Menu>
	        <Dropdown.Item onClick={this.handleUserManual}>User Manual</Dropdown.Item>
	        <Dropdown.Item onClick={this.handleUserAgreement}>User Agreement</Dropdown.Item>
	      </Dropdown.Menu>
	    </Dropdown> 
          </Menu.Item>
          <Menu.Item name="contactus" onClick={this.handleContactUs}>
            <Icon name="mail" /> Contact Us
          </Menu.Item>
          <Menu.Item name="logout" onClick={this.handleLogout} />
        </Menu.Menu>
      </Menu>,
      <div
        className="mainLayout fit animated fadeIn"
        style={{
          paddingTop: '40px',
          paddingLeft: '84px',
          paddingBottom: '100px',
        }}
        key="ml3"
      >
        {this.props.children}
      </div>,
    ];
  }
}

export default inject(
  'authentication',
  'usersStore',
  'userStore',
  'awsAccountsStore',
  'userRolesStore',
  'indexesStore',
)(withRouter(observer(MainLayout)));
