/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { Button, Grid, Dropdown, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getEditInstanceForm } from '../../models/forms/EditInstanceForm';
import { displayError, displaySuccess } from '../../helpers/notification';

import Form from '../helpers/fields/Form';

// expected props
// - environment
// - environmentsStore
// - environmentConfigurationsStore
// - userStore (via injection)
class EditInstance extends React.Component {
  selectedSize = '';

  constructor(props) {
    super(props);
    this.environmentId = this.props.environment.id;
    this.currSize = this.props.environment.instanceInfo.size;
    this.state = {
      customEnvironments: [],
      isProcessing: false,
    };
    this.getCustom();
    this.handleChangeInstanceSize = this.handleChangeInstanceSize.bind(this);
    makeObservable(this, {
      handleFormSubmission: action,
      handleFormError: action,
      selectedSize: observable,
    });
	
  }

  get getConfigurationStore() {
    return this.props.environmentConfigurationsStore;
  }

  async getCustom() {
    const store = this.getConfigurationStore;
    const custom = await store.getCustomEnvironments();
    this.setState({ customEnvironments: _.map(custom, item => item) });
  }

  handleChangeInstanceSize(event, data) {
    if (data.value !== this.currSize) {
      runInAction(() => {
        this.selectedSize = data.value;
      });
    } else {
      displayError('Please select a different instance size!');
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    }
  }

  handleFormCancel = form => {
    form.clear();
    runInAction(() => {
      this.selectedSize = '';
    });
    this.props.onClose();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async (event, _form) => {
    event.preventDefault();
    event.stopPropagation();
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      const newSize = this.selectedSize;
      // Edit instance and clear form
      const _updated = await this.props.environmentsStore.editInstance(this.environmentId, newSize);
      displaySuccess('Instance size updated successfully.', 'Success');
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
      if (this.props.onClose && typeof this.props.onClose === 'function') {
        this.props.onClose();
      }
    } catch (error) {
      displayError(error);
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    }
  };

  render() {
    const form = getEditInstanceForm({
      size: { value: this.currSize },
    });
    // const instanceSize = form.$('size');
    const customOptions = _.orderBy(
      this.state.customEnvironments.map(({ InstanceType, _index }) => ({
        key: InstanceType,
        text: InstanceType,
        value: InstanceType,
      })),
      ['text'],
      ['asc'],
    );

    return (
      <Form
        size="large"
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {/* eslint-disable-next-line no-unused-vars */}
        {({ processing, onSubmit, onCancel }) => (
          <>
            <Grid>
              <Grid.Column width={16}>
                <p>
                  You are allowed to changed instance size only when instance is stopped.
                  <br />
                  The change will take effect when you start your instance again.
                </p>

                <Label>
                  Environment:{' '}
                  <Label.Detail>
                    {this.environmentId} - {this.props.environment.name}
                  </Label.Detail>
                </Label>
                <br />
                <Label>
                  Current size: <Label.Detail>{this.currSize}</Label.Detail>
                </Label>
                <br />
                <Label>New size: </Label>
                <Dropdown
                  options={customOptions}
                  search
                  selection
                  disabled={this.state.isProcessing}
                  onChange={this.handleChangeInstanceSize}
                  value={this.selectedSize}
                />

                <Button
                  className="ml2 mb3"
                  floated="right"
                  color="blue"
                  icon
                  disabled={this.state.isProcessing}
                  onClick={this.handleFormSubmission}
                >
                  Edit Instance
                </Button>
                <Button className="mb3" floated="right" onClick={onCancel}>
                  Cancel
                </Button>
              </Grid.Column>
            </Grid>
          </>
        )}
      </Form>
    );
  }
}

export default inject('userStore', 'environmentsStore', 'environmentConfigurationsStore')(observer(EditInstance));
