/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Container, Header, Icon, Label, Message } from 'semantic-ui-react';
import { makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { swallowError } from '../../helpers/utils';

import { isStoreError, isStoreLoading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import SimpleTable from '../helpers/SimpleTable';
import { withRouter } from '../../helpers/routing';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';

// edit 2359 - add support for parallel cluster which needs private subnet
class AwsAccountsList extends React.Component {
  constructor(props) {
    super(props);
    // mapOfUsersBeingEdited: observable,
    // formProcessing: observable,
    makeObservable(this, {});

    this.state = {};
    /* runInAction(() => {
      // An object that keeps track of which user is being edited
      // Each key in the object below has key as user's unique id (<ns>/<username>)
      // and value as flag indicating whether to show the editor for the user
      this.mapOfUsersBeingEdited = {};
      this.formProcessing = false;
    }); */
  }

  componentDidMount() {
    const accountsStore = this.props.accountsStore;
    const awsAccountsStore = this.props.awsAccountsStore;
    swallowError(accountsStore.load());
    swallowError(awsAccountsStore.load());
    accountsStore.startHeartbeat();
    awsAccountsStore.startHeartbeat();
  }

  componentWillUnmount() {
    const accountsStore = this.props.accountsStore;
    const awsAccountsStore = this.props.awsAccountsStore;
    accountsStore.stopHeartbeat();
    awsAccountsStore.stopHeartbeat();
  }

  getAwsAccountsStore() {
    const store = this.props.awsAccountsStore;
    return store;
  }

  getAwsAccounts() {
    const store = this.getAwsAccountsStore();
    return store.list;
  }

  // edit 2359 - add support for parallel cluster which needs private subnet
  renderMain() {
    const awsAccountsData = this.getAwsAccounts();
    // const pageSize = 5;
    // const showPagination = awsAccountsData.length > pageSize;
    const columns = [
      {
        Header: 'Account Name',
        accessor: 'name',
      },
      {
        Header: 'AWS Account ID',
        accessor: 'accountId',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Role ARN',
        accessor: 'roleArn',
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
      },
      {
        Header: 'VPC ID',
        accessor: 'vpcId',
      },
      {
        Header: 'Subnet ID',
        accessor: 'subnetId',
      },
      {
        Header: 'Private Subnet ID',
        accessor: 'privateSubnetId',
      },
      {
        Header: 'Encryption Key Arn',
        accessor: 'encryptionKeyArn',
      },
      {
        Header: 'SSB Workspace VPC ID',
        accessor: 'ssbWorkspaceVpcId',
      },
      {
        Header: 'SSB Workspace Subnet ID',
        accessor: 'ssbWorkspaceSubnetId',
      },
      {
        Header: 'SSB Bastion VPC ID',
        accessor: 'ssbBastionVpcId',
      },
      {
        Header: 'SSB Bastion Subnet ID',
        accessor: 'ssbBastionSubnetId',
      },
      {
        Header: 'SSB Encryption Key Arn',
        accessor: 'ssbEncryptionKeyArn',
      },
      {
        Header: 'SSB Workspace CFN VPC EndpointIP',
        accessor: 'ssbWorkspaceCfnVpcEndpointIp',
      },
      {
        Header: 'SSB Workspace KMS VPC EndpointIP',
        accessor: 'ssbWorkspaceKmsVpcEndpointIp',
      },
      {
        Header: 'SSB Workspace S3 VPC Prefix list',
        accessor: 'ssbWorkspaceS3VpcEndpointPrefixList',
      },
      {
        Header: 'SSB Bastion Cfn VPC Endpoint IP',
        accessor: 'ssbBastionCfnVpcEndpointIp',
      },
      {
        Header: 'SSB Bastion KMS VPC Endpoint IP',
        accessor: 'ssbBastionSsmVpcEndpointIp',
      },
    ];

    return (
      <div>
        <SimpleTable
          headerRenderer={() => {
            return columns.map(col => col.Header);
          }}
          rowRenderer={request => {
            return [
              request.name,
              request.accountId,
              request.description,
              request.roleArn,
              request.externalId,
              request.vpcId,
              request.subnetId,
              request.privateSubnetId,
              request.encryptionKeyArn,
              request.ssbWorkspaceVpcId,
              request.ssbWorkspaceSubnetId,
              request.ssbBastionVpcId,
              request.ssbBastionSubnetId,
              request.ssbEncryptionKeyArn,
              request.ssbWorkspaceCfnVpcEndpointIp,
              request.ssbWorkspaceKmsVpcEndpointIp,
              request.ssbWorkspaceS3VpcEndpointPrefixList,
              request.ssbBastionCfnVpcEndpointIp,
              request.ssbBastionSsmVpcEndpointIp,
            ];
          }}
          rowData={awsAccountsData}
          keyMethod={request => request.name}
        />
      </div>
    );
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleAddAwsAccount = () => {
    this.goto('/aws-accounts/add');
  };

  /* handleCreateAwsAccount = () => {
    this.goto('/aws-accounts/create');
  }; */

  renderHeader() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="amazon" className="align-top" />
          <Header.Content className="left-align">
            AWS Accounts
            {this.renderTotal()}
          </Header.Content>
        </Header>
        {/*
        <Button color="blue" size="medium" basic onClick={this.handleCreateAwsAccount}>
          Create AWS Account
        </Button>
		*/}
        <Button className="ml2" color="blue" size="medium" basic onClick={this.handleAddAwsAccount}>
          Add AWS Account
        </Button>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getAwsAccounts().length}</Label>;
  }

  handleDismiss(id) {
    const accountsStore = this.props.accountsStore;
    accountsStore.removeItem(id);
    this.componentDidMount();
  }

  renderCreatingAccountNotification() {
    const accountsStore = this.props.accountsStore;
    const pendingAccount = accountsStore.listCreatingAccount;
    const errorAccounts = accountsStore.listErrorAccount;
    return (
      <div className="mt3 mb3 animated fadeIn">
        {pendingAccount.map(account => (
          <Message>
            <Icon name="circle notched" loading />
            Trying to create accountID: {account.id}
          </Message>
        ))}
        {errorAccounts.map(account => (
          <Message onDismiss={() => this.handleDismiss(account.id)}>
            <Icon name="times" color="red" />
            Error happended in creating accountID: {account.id}. If the account is created, please contact follow{' '}
            <a
              href="https://aws.amazon.com/blogs/security/aws-organizations-now-supports-self-service-removal-of-accounts-from-an-organization/"
              target="_blank"
              rel="noopener noreferrer"
            >
              instruction
            </a>{' '}
            to remove it. You may close this message after you make sure the account is removed.
          </Message>
        ))}
      </div>
    );
  }

  render() {
    const store = this.getAwsAccountsStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderMain();
    }
    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {this.renderCreatingAccountNotification()}
        {content}
      </Container>
    );
  }
}

export default inject('awsAccountsStore', 'accountsStore')(withRouter(observer(AwsAccountsList)));
