/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';

import { createForm } from '../../helpers/form';

const searchStudyFilesFields = {
  // General fields
  query: {
    placeholder: 'Search for...',
    rules: ['string', 'max:2048'],
  },
  queryType: {
    rules: ['required', { in: ['filename', 'collectionname'] }],
  },
};

const getSearchStudyFilesForm = defaultValues => {
  return createForm(_.merge(searchStudyFilesFields, defaultValues));
};

export { getSearchStudyFilesForm }; // eslint-disable-line import/prefer-default-export
