/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, observable, computed, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Card } from 'semantic-ui-react';
import { gallery, amiGallery, ec2Gallery, secureGallery, emrGallery, amiSecureGallery } from './constants';
import SlidingSections from '../../helpers/SlidingSections';
import SummaryCard from './SummaryCard';
import Details from './Details';

// edited by 2359 - added support for custom AMI
// edit 2359, chenjqp - add support for EMR notebook selection
class Marketplace extends React.Component {
  selection = { offering: undefined };
  section = 'summary';

  constructor(props) {
    super(props);

    makeObservable(this, {
      selection: observable,
      section: observable,
      sectionNumber: computed,
      handleDetailsRequested: action,
      handleBack: action,
    });

    const store = props.environmentsStore;
    this.state = {
      amiId: props.isAmi ? store.selectedAmi.id : null,
    };
  }

  get sectionNumber() {
    return this.section === 'summary' ? 0 : 1;
  }

  getEnvType() {
    const envtype = this.props.envType;
    return typeof envtype === 'undefined' ? 'R' : envtype;
  }

  handleDetailsRequested = offering => {
    this.selection.offering = offering;
    this.section = 'details';
  };

  handleBack = () => {
    this.section = 'summary';
  };

  // edit 2359 - add support for EMR notebook selection
  render() {
    const sectionNum = this.sectionNumber;
    const { files, onSuccess, secureFiles, isSecure, notebooks } = this.props;

    return (
      <SlidingSections section={sectionNum}>
        <Card.Group className="mt1">{this.renderSummaryCards()}</Card.Group>
        <Details
          offering={this.selection.offering}
          onBack={this.handleBack}
          onSuccess={onSuccess}
          files={files}
          secureFiles={secureFiles}
          amiId={this.state.amiId}
          isSecure={isSecure}
          envType={this.getEnvType()}
          notebooks={notebooks}
        />
      </SlidingSections>
    );
  }

  // edit chenjqp - add check for notebooks, and only give emr options if notebooks are selected
  renderSummaryCards() {
    const store = this.props.environmentsStore;
    const ami = store.selectedAmi;
    let envGallery;
    if (this.props.isSecure) {
      if (this.props.isAmi) {
        envGallery = amiSecureGallery;
      } else {
        envGallery = secureGallery;
      }
    } else if (this.props.notebooks && this.props.notebooks.length > 0) {
      // show emr gallery
      envGallery = emrGallery;
    } else if (this.props.isAmi) {
      if (ami && ami.emrCompatible === 'true') {
        envGallery = amiGallery;
      } else if (ami && ami.pclusterCompatible) {
        envGallery = ec2Gallery;
      } else {
        envGallery = amiGallery;
      }
    } else {
      envGallery = gallery;
    }

    // const envGallery = this.props.isSecure ? secureGallery : (this.props.isAmi ? (ami && ami.emrCompatible === 'true' ? amiGallery : ec2Gallery) : gallery);
    return _.map(envGallery, (entry, id) => (
      <SummaryCard key={id} offering={entry} onViewDetails={this.handleDetailsRequested} />
    ));
  }
}

export default inject('environmentsStore')(observer(Marketplace));
