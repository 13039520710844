/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Header, Button, Header as BaseHeader, Modal, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { Study } from '../../models/studies/Study';
import { getRequestAccessForm } from '../../models/forms/RequestAccessForm';
import { displayError, displaySuccess } from '../../helpers/notification';
import { getOptionsFromRules } from '../../helpers/utils';
import { isStoreLoading, isStoreError } from '../../models/BaseStore';
import Form from '../helpers/fields/Form';
import Toggle from '../helpers/fields/Toggle';
import DateField from '../helpers/fields/DateField';
import TextArea from '../helpers/fields/TextArea';
import InputFile from '../helpers/fields/InputFile';
import Radio from '../helpers/fields/Radio';
import StudyCollectionsCheckbox from './StudyCollectionsCheckbox';
import ErrorBox from '../helpers/ErrorBox';

class RequestAccess extends React.Component {
  modalOpen = false;
  requestFile = undefined;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
      requestFile: observable,
      renderOptions: action,
    });

    this.study = props.study;
    this.permissionsStore = props.study.getPermissionsStore();
    this.collectionsStore = props.study.getCollectionsStore();
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      showHideUpload: true,
      showHideMetadata: true,
    };

  }

  componentDidMount() {
    this.collectionsStore.load();
  }

  getCollectionsStore() {
    return this.collectionsStore;
  }
	
  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const { collectionIds, requestFile, uuid, adminComments, ...requestValues } = form.values();

      // don't allow empty collection list if permission type is collection
      if (requestValues.permissionType === 'collection' && collectionIds.length === 0) {
        displayError('Please indicate collections for collection permission type!');
        return;
      }

      // Create study, clear form, and close modal
      await this.permissionsStore.requestPermissions(
        collectionIds.length !== 0
          ? {
              ...requestValues,
              collectionIds,
              id: this.study.id,
            }
          : {
              ...requestValues,
              id: this.study.id,
            },
        this.requestFile,
      );
      this.study.updateAccess(Study.ACCESS_REQUESTED);
      form.clear();
      this.cleanModal();
      displaySuccess('Permission requested.');
    } catch (error) {
      displayError(error);
    }
  };

  /* addDefaultExpiry = () => {
    const today = new Date();
    today.setDate(today.getDate() + 60);
    return today;
  }; */

  render() {
    // Render loading, error, or button
    let content;
    if (isStoreError([this.permissionsStore, this.collectionsStore])) {
      content = <ErrorBox error={this.permissionsStore.error} />;
    } else if (isStoreLoading([this.permissionsStore, this.collectionsStore])) {
      content = (
        <Button disabled>
          <Loader size="mini" />
          Request Access
        </Button>
      );
    } else {
      content = this.renderContent();
    }

    return content;
  }

  renderContent() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Request Access
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderRequestAccessForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Request Access
      </Button>
    );
  }

  handleFileChange = action(event => {
    this.requestFile = event.target.files[0];
  });

  handlePermissionType = action((value, _field) => {
    //console.log(`chosen type: ${value}`);
    if (value === 'collection') {
      this.state.showHideUpload = false;
      this.state.showHideMetadata = false;
    } else {
      this.state.showHideUpload = true;
      this.state.showHideMetadata = true;
    }
  });

  renderOptions(form) {
    // if study is secure, set upload and metadata to default false
    // only study owner should have these rights
    const isSecureStudy = this.study.isSecure;
    const { showHideUpload, showHideMetadata } = this.state;
    if (isSecureStudy) {
      form.$('isUpload').value = false;
      form.$('isMetaData').value = false;
    } else {
      // if hide upload or metadata, collections type is selected
      // set the value to false
      if (!showHideUpload) {
        form.$('isUpload').value = false;
      }

      if (!showHideMetadata) {
        form.$('isMetaData').value = false;
      }
    }

    return (
      <>
        {isSecureStudy ? <></> : showHideUpload && <Toggle field={form.$('isUpload')} />}
        {isSecureStudy ? <></> : showHideMetadata && <Toggle field={form.$('isMetaData')} />}
      </>
    );
  }

  renderRequestAccessForm() {
    const form = getRequestAccessForm();
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    const maxDate = `${today.getFullYear() + 1}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    today.setDate(today.getDate() + 60);
    const afterSixtyDaysDate = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    today.setDate(today.getDate() + 60);
    form.$('expiry').min = formattedDate;
    form.$('expiry').max = maxDate;
    form.$('expiry').value = afterSixtyDaysDate;

    form.$('requestorComments').set('rules', 'required');
    let permissionTypes = getOptionsFromRules(form.$('permissionType').rules);
    const collections = this.getCollectionsStore().list; 
    if (collections.length === 0) {
      permissionTypes = permissionTypes.filter(type => type.value !== 'collection');
    }
    form.$('requestorComments').set('rules', 'required');
    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Header>Study: {this.study.id}</Header>
              <DateField field={form.$('expiry')} />
              <Toggle field={form.$('isRead')} disabled />
              {this.renderOptions(form)}
              <Radio
                radio
                field={form.$('permissionType')}
                options={permissionTypes}
                onChange={this.handlePermissionType}
              />
              {form.$('permissionType').value === 'collection' &&
                collections.length > 0 && (
                  <StudyCollectionsCheckbox
                    field={form.$('collectionIds')}
                    options={collections.map(collection => ({
                      key: collection.collectionId,
                      text: collection.collectionName,
                      value: collection.collectionId,
                      description: collection.description,
                    }))}
                  />
                )}
              <TextArea field={form.$('requestorComments')} />
              <InputFile field={form.$('requestFile')} onChange={this.handleFileChange} />
              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Request Access
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(RequestAccess));
