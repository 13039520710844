/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Header, Segment, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { gotoFn, withRouter } from '../../helpers/routing';
import MarketPlace from './marketplace';

class CreateEnvironment extends React.Component {
  handleCancel = () => {
    const onCancel = this.props.onCancel || _.noop;
    onCancel();
    const goto = gotoFn(this);
    goto('/workspaces');
  };

  getEnvType() {
    return (this.props.match.params || {}).envtype;
  }

  render() {
    return (
      <div className="mt3 ml3 mr3 animated fadeIn">
        <Segment className="pr3 pl3 pb3 pt0">
          <Button floated="right" className="mt3" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Header as="h2" icon textAlign="center" className="mt3" color="grey">
            Create Research Workspace
          </Header>
          <MarketPlace envType={this.getEnvType()} />
        </Segment>
      </div>
    );
  }
}

export default withRouter(observer(CreateEnvironment));
