/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { BaseStore } from '../BaseStore';
import { ClientInformation } from './ClientInformation';

// There are situations in which it is useful for the UI to be able to
// determine the IP address that it has, for example so that it can use that
// IP address in a Security Group rule that later restricts access for a given
// compute environment to the user that launched it. So, this store implements
// a "what is my IP address?" feature.
//
// By default, we use the free db-ip.com service which returns IP address as
// well as geo-location information for that IP address. The service limits
// how many free requests can be made per day from a given IP range, so it is
// not uncommon for this request to fail because the daily limit has been
// exceeded. If you find this service valuable, please consider subscribing.
//
// Some no-cost alternatives to db-ip.com are as follows, though none provides
// the geo-location data:
//
// http://httpbin.org/get
// http://checkip.amazonaws.com
//
// Another alternative that could be implemented is to create a new AWS Lambda
// function which simply retrieves contextual information such as IP address,
// browser user-agent info etc. and returns them to the requester. It would
// probably be wise to secure this API through credentials or a secret token to
// mitigate the risk of unapproved users finding and abusing the API.

const ClientInformationStore = BaseStore.named('ClientInformationStore')
  .props({
    clientInformation: types.optional(ClientInformation, {}),
    heartbeatInterval: -1,
  })
  .actions(self => {
    return {
      async doLoad() {
        try {
          // TODO: if using the paid version, consider using the dbip api
          const res = await fetch('https://api.db-ip.com/v2/free/self');
          const info = await res.json();
          self.runInAction(() => {
            self.clientInformation = ClientInformation.create(info);
          });
        } catch (error) {
          if (!_.isEmpty(error.errorCode)) {
            // if error is ??? then handle it, otherwise
            throw this.bomb.unknown('Error getting client information.', true);
          } // else there was probably an issue with the number of requests for the day on the free account.
        }
      },
    };
  })
  .views(self => ({
    get ipAddress() {
      return self.clientInformation.ipAddress;
    },

    get information() {
      return self.clientInformation;
    },
  }));

function registerModels(globals) {
  globals.clientInformationStore = ClientInformationStore.create({}, globals);
}

export { ClientInformationStore, registerModels };
