/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Segment, Header, Pagination, Icon, Label, Button } from 'semantic-ui-react';
import { niceNumber, plural } from '../../helpers/utils';

import { isStoreLoading, isStoreError, isStoreEmpty } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';
import SecureOutputRow from './SecureOutputRow';

// expected props
// store (SecureOutputsStore)
// category ('mine' | 'pending')
class SecureOutputsTab extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  componentDidMount() {
    this.props.store.load();
  }

  render() {
    let content;
    if (isStoreError(this.props.store)) {
      content = <ErrorBox error={this.props.store.error} />;
    } else if (isStoreLoading(this.props.store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else if (isStoreEmpty(this.props.store)) {
      content = this.renderEmpty();
    } else {
      content = this.renderContent();
    }

    return content;
  }

  renderPagination(store) {
    const pages = store.numOfPages;
    if (pages < 2) return null;
    return (
      <Pagination
        className="mt1"
        activePage={store.activePage}
        totalPages={pages}
        siblingRange={0}
        boundaryRange={1}
        firstItem={null}
        lastItem={null}
        onPageChange={this.handlePageChange}
      />
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No secure outputs
        </Header>
      </Segment>
    );
  }

  handleReset = () => {
    this.props.outputsSelection.clear();
  };

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleNext = () => {
    this.goto('/secure-vetting');
  };

  renderSelection() {
    const selection = this.props.outputsSelection;
    const empty = selection.empty;
    const count = selection.count;
    const whenEmpty = (
      <Label basic color="grey" size="large">
        No secure outputs selected
      </Label>
    );
    const whenNotEmpty = (
      <span>
        <Label basic color="blue" size="large">
          You selected {niceNumber(count)} secure {plural('output', 'outputs', count)}{' '}
        </Label>
        <Button color="grey" icon labelPosition="right" className="ml2" onClick={this.handleReset}>
          Reset
          <Icon name="undo" />
        </Button>
        <Button color="blue" icon labelPosition="right" className="ml2" onClick={this.handleNext}>
          Next
          <Icon name="right arrow" />
        </Button>
      </span>
    );

    return (
      <div className="mt2 float-right">
        {empty && whenEmpty}
        {!empty && whenNotEmpty}
      </div>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderPagination(this.props.store)}
        {this.props.selection && this.renderSelection()}
        {this.props.store.list.map(secureOutput => (
          <Segment clearing raised key={secureOutput.id} className="mb3">
            <SecureOutputRow
              secureOutput={secureOutput}
              secureOutputsStore={this.props.store}
              noPermission={this.props.noPermission}
              category={this.props.category}
            />
          </Segment>
        ))}
      </>
    );
  }
}

export default observer(SecureOutputsTab);
