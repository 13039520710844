import _ from 'lodash';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { Message, Icon, Container } from 'semantic-ui-react';

const container = document.getElementById('root');
const root = createRoot(container);

// Render the AppContainer component
function renderAppContainer(AppContainer, globals) {
  root.render(
    <Provider {...globals}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </Provider>,
  );
}

// Render a progress message
function renderProgress(
  progressContent = (
    <Message.Content>
      <Message.Header>Just one second</Message.Header>
      Great things are now happening, please wait!
    </Message.Content>
  ),
) {
  root.render(
    <Container text className="pt4">
      <Message icon>
        <Icon name="circle notched" loading />
        {progressContent}
      </Message>
    </Container>,
  );
}

// Render an error message
function renderError(err) {
  const error = _.get(err, 'message', 'Unknown error');
  root.render(
    <Container text className="pt4">
      <Message negative>
        <Message.Header>We have a problem</Message.Header>
        <p>{error}</p>
        <p>See if refreshing the browser will resolve your issue</p>
      </Message>
    </Container>,
  );
}

export { renderAppContainer, renderProgress, renderError };
