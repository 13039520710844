/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';
import { validRegions } from '../../helpers/utils';

// edited by leeas - removed space from ID regex rule
// edited by 2359 - added isPublic
// edit 2359 - add api type study
const createStudyFields = {
  // General fields
  id: {
    label: 'ID',
    placeholder: 'A unique ID used to reference the study',
    extra: {
      explain: 'Must be less than 100 characters long and only contain alphanumeric characters, "-", or "_"',
    },
    rules: ['required', 'string', 'between:1,100', 'regex:/^[A-Za-z0-9-_]+$/'],
  },
  name: {
    label: 'Name',
    placeholder: 'A name for the study',
    rules: ['string', 'max:2048'],
  },
  isSecure: {
    label: 'Secure',
    rules: ['required', 'boolean'],
  },
  isPublic: {
    label: 'Make Public',
    rules: ['required', 'boolean'],
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the study',
    rules: ['string', 'max:8192'],
  },
  projectId: {
    label: 'Project ID',
    placeholder: 'The project ID associated with this study',
    rules: ['required', 'string', 'min:1', 'max:100'],
  },
  storageType: {
    label: 'Storage Type',
    placeholder: 'The location where study data is stored',
    rules: ['required', { in: ['S3', 'REST API Endpoint'] }],
  },

  // S3 storage fields
  s3StorageLocation: {
    label: 'S3 Storage Location',
    rules: [{ in: ['Create New', 'Use Existing'] }],
  },
  s3Path: {
    label: 'S3 Path',
    placeholder: 's3://my-bucket/my-prefix',
    extra: {
      explain: 'The path in S3 where study data is located',
    },
    rules: [{ required_if: ['s3StorageLocation', 'Use Existing'] }, 'regex:/^s3://[a-z0-9][-.a-z0-9]*/'],
  },

  // Fsx field only applicable for output study
  fsxStorageSize: {
    label: 'Output Storage Size',
    placeholder: 'The storage size for output study',
    extra: {
      explain: 'The storage capacity of the resource that will hold your output study data.',
    },
    rules: ['integer'],
  },

  // API storage fields
  apiData: {
    label: 'API Data',
    placeholder: 'A description of the study',
    rules: ['string', { required_if: ['storageType', 'REST API Endpoint'] }],
  },

  // RDS storage fields
  rdsRegion: {
    label: 'RDS Region',
    placeholder: 'us-xxxx-n',
    rules: [{ required_if: ['storageType', 'RDS'] }, { in: validRegions() }],
  },
  rdsInstanceResourceId: {
    label: 'RDS Instance Resource ID',
    placeholder: 'db-XXXXXXXXXXXXXXXXXXXXXXXXXX',
    rules: [{ required_if: ['storageType', 'RDS'] }, 'regex:/db-[A-Z0-9]{26}/'],
  },
  rdsUsername: {
    label: 'DB Username',
    placeholder: 'dbuser',
    rules: [{ required_if: ['storageType', 'RDS'] }],
  },
};

const getCreateStudyForm = () => {
  return createForm(createStudyFields);
};

export { getCreateStudyForm }; // eslint-disable-line import/prefer-default-export
