/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */
import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Button, Label, Grid, Header, Tab } from 'semantic-ui-react';

import { niceNumber, plural } from '../../helpers/utils';
import { withRouter } from '../../helpers/routing';
import StudiesTab from './StudiesTab';
import { StudiesStore } from '../../models/studies/StudiesStore';
import { StudyUploadDirStore } from '../../models/studies/StudyUploadDirStore';

// edit 2359 - added API studies type
const studyCategories = {
  ORGANIZATION: 'Organization',
  OPEN_DATA: 'Open Data',
  API_DATA: 'API Data',
};

const DELIMIT = '/';

export { studyCategories };

// edited by leeas -add uploadDirStore
class StudiesTable extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.stores = {
      'My Studies': StudiesStore.create({ loadMethod: 'getMyStudies' }),
      'Accessible Studies': StudiesStore.create({ loadMethod: 'getAccessibleStudies' }),
    };
    const currUser = this.props.userStore.user;
    const unameWithNs = _.join([currUser.ns, currUser.username], DELIMIT);
    // console.log("in StudiesPage: unameWithNs: " + unameWithNs);
    this.uploadDirStore = StudyUploadDirStore.create({ unameWithNs });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /* handlePageChange = (_event, _data) => {
    // TODO: Implement search
    // const activePage = data.activePage - 1;
    // this.props.searchSelection.setPage(activePage);
  };

  goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });

    this.props.history.push(link);
  } */

  render() {
    const categories = [
      { label: 'My Studies', allowCreate: true, category: studyCategories.ORGANIZATION },
      { label: 'Accessible Studies', category: studyCategories.ORGANIZATION },
    ];

    const dataPanes = categories.map(category => {
      const store = this.stores[category.label];
      return {
        menuItem: category.label,
        render: () => (
          <Tab.Pane key={category.label}>
            <StudiesTab
              store={store}
              allowCreate={false}
              category={category.category}
              uploadDirStore={this.uploadDirStore}
            />
          </Tab.Pane>
        ),
      };
    });

    return (
      <div className="p3 animated fadeIn">
        {/* <div dangerouslySetInnerHTML={{__html:store.csv}} /> */}
        <Grid className="mt0">
          <Grid.Column width={16}>
            {/* TODO: Re-implement search */}
            {/* <Search /> */}
            <div className="mt3">
              {this.renderFilesSelection()}
              <div style={{ clear: 'both' }} />
            </div>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                Studies
              </Header>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
            </Segment>
            {this.renderFilesSelection()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderFilesSelection() {
    const selection = this.props.filesSelection;
    const empty = selection.empty;
    const count = selection.count;
    const whenEmpty = (
      <Label basic color="grey" size="large">
        No datasets selected
      </Label>
    );
    const whenNotEmpty = (
      <Label basic color="blue" size="large">
        You selected {niceNumber(count)} {plural('dataset', 'datasets', count)}{' '}
      </Label>
    );

    return (
      <div className="mt2 float-right">
        {empty && whenEmpty}
        {!empty && whenNotEmpty}
        <Button color="blue" icon labelPosition="right" className="ml2" onClick={this.props.handleNext}>
          Next
          <Icon name="right arrow" />
        </Button>
      </div>
    );
  }
}

export default inject('filesSelection', 'userStore')(withRouter(observer(StudiesTable)));
