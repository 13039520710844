/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const addUserFormFields = {
  username: {
    label: 'Username',
    explain: 'Type a username for the user',
    rules: 'required|string|between:3,300|regex:/^[A-Za-z0-9_\\-@\\.]+$/',
  },
  password: {
    label: 'Password',
    placeholder: 'Type default password for the user',
    explain: `The password must be between 4 and 2048 characters long.`,
    rules: 'string|between:4,2048',
  },
  email: {
    label: 'Email',
    placeholder: 'example@example.com',
    explain: 'Type an email for the user',
    rules: 'email|string',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'John',
    explain: "User's first name",
    rules: 'required|string|between:1,100',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Doe',
    explain: "User's last name",
    rules: 'required|string|between:1,100',
  },  
  instituteName: {
    label: 'Institute Name',
    placeholder: 'Genome Institute of Singapore',
    explain: 'Institute name',
    rules: 'required|string|between:1,300',
  },  
  designation: {
    label: 'Designation',
    placeholder: 'Bioinformatics Specialist',
    explain: 'User\'s official designation',
    rules: 'required|string|between:1,150',  
  },
  identityProviderName: {
    label: 'Identity Provider',
    explain: 'Identity Provider for this user',
  },
  projectId: {
    label: 'Project Id',
    explain: 'Select Project for this user',
  },
  indexId: {
    label: 'Index',
    explain: 'Select Index for the project',
  },
  userRole: {
    label: 'UserRole',
    explain: "Select user's role",
  },
  status: {
    label: 'Status',
    explain: 'Active users can log into the Research Portal',
  },
};

function getAddUserFormFields() {
  return addUserFormFields;
}

function getAddUserForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addUserFormFields }, { plugins });
}

export { getAddUserFormFields, getAddUserForm };
