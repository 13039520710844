/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';

const createSecureOutputFields = {
  // General fields
  id: {
    label: 'ID',
    placeholder: 'A unique ID used to reference the secure output',
    extra: {
      explain: 'Must be less than 100 characters long and only contain alphanumeric characters, "-", or "_"',
    },
    rules: ['required', 'string', 'between:1,100', 'regex:/^[A-Za-z0-9-_]+$/'],
  },
  name: {
    label: 'Name',
    placeholder: 'A name for the secure output',
    rules: ['required', 'string', 'max:2048'],
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the study',
    rules: ['required', 'string', 'max:2048'],
  },
  projectId: {
    label: 'Project ID',
    placeholder: 'The project ID associated with this study',
    rules: ['required', 'string', 'min:1', 'max:100'],
  },
  fsxStorageSize: {
    label: 'Output Storage Size',
    placeholder: 'The storage size for output study',
    extra: {
      explain: 'The storage capacity of the resource that will hold your secure output data.',
    },
    rules: ['integer'],
  },
};

const getCreateSecureOutputForm = () => {
  return createForm(createSecureOutputFields);
};

export { getCreateSecureOutputForm }; // eslint-disable-line import/prefer-default-export
