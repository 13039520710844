/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject, Observer } from 'mobx-react';
import { makeObservable, observable, runInAction, action } from 'mobx';
import TimeAgo from 'react-timeago';
import {
  Accordion,
  Breadcrumb,
  Button,
  Container,
  Header,
  Icon,
  Label,
  Popup,
  Reveal,
  Segment,
  Tab,
  Table,
  Input,
  Dimmer,
  Loader,
  Menu,
  Grid,
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import crypto from 'crypto';

import { gotoFn, withRouter } from '../../helpers/routing';
import { displayError, displaySuccess } from '../../helpers/notification';
import { swallowError, returnValidatedUrl } from '../../helpers/utils';
import { isStoreLoading, isStoreReady, isStoreError } from '../../models/BaseStore';
import EnvironmentStatusIcon from './EnvironmentStatusIcon';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import By from '../helpers/By';
import EnvironmentConnectButton from './EnvironmentConnectButton';
import EnvironmentCostDetails from './EnvironmentCostDetails';
import EditEnvironmentSecurityForm from './EditEnvironmentSecurityForm';
import DisplayCollectionPane from './DisplayCollectionPane';
import EnvironmentOutputFilesTable from './EnvironmentOutputFilesTable';

function ErrorInfo({ environment }) {
  // const [visible, setVisible] = React.useState(() => false);
  if (!environment.error) {
    environment.getEnvironmentError();
  }
  return (
    <Segment>
      This research workspace encountered an error <TimeAgo date={environment.updatedAt} />{' '}
      <By user={environment.updatedBy} />. Please contact administrators at raptor_admin@gis.a-star.edu.sg.
      {/* environment.error ? (
        <Accordion>
          <Accordion.Title active={visible} index={0} onClick={() => setVisible(s => !s)}>
            <Icon name="dropdown" />
            Detailed error information
          </Accordion.Title>
          <Accordion.Content active={visible}>
            <p>{environment.error}</p>
          </Accordion.Content>
        </Accordion>
      ) : null */}
    </Segment>
  );
}

// expected props
// - environmentsStore (via injection)
// - location (from react router)
// edit 2359 - add support for parallel cluster
// edit 2359 - add support for EMR-hail
// edit chenjqp - add support for CIDR update
// edit chenjqp = add support for secure emr-hail
class EnvironmentDetailPage extends React.Component {
  windowsPassword = '';
  bastionPassword = '';
  emrPassword = '';
  cidr = '';
  editModeOn = false;
  showHostInformation = true;
  showDataInformation = true;
  showOutputInformation = true;

  constructor(props) {
    super(props);
    /* this.state = {
      costs: null,
    }; */

    makeObservable(this, {
      windowsPassword: observable,
      bastionPassword: observable,
      emrPassword: observable,
      cidr: observable,
      editModeOn: observable,
      showHostInformation: observable,
      showDataInformation: observable,
      showOutputInformation: observable,
      onCidrEdit: action,
      cancelEdit: action,
      updateCidr: action,
    });

    this.handleShowHostInformation = this.handleShowHostInformation.bind(this);
    this.handleShowDataInformation = this.handleShowDataInformation.bind(this);
    this.handleShowOutputInformation = this.handleShowOutputInformation.bind(this);
  }

  componentDidMount() {
    const store = this.getInstanceStore();
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.getInstanceStore();
    store.stopHeartbeat();
  }

  getInstanceStore() {
    const instanceId = this.getInstanceId();
    return this.props.environmentsStore.getEnvironmentStore(instanceId);
  }

  getInstanceId() {
    return (this.props.match.params || {}).instanceId;
  }

  getEnvironment() {
    const store = this.getInstanceStore();
    if (!isStoreReady(store)) return {};
    return store.environment;
  }

  render() {
    const store = this.getInstanceStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder />;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3">
        {this.renderBreadcrumb()}
        {content}
      </Container>
    );
  }

  renderBreadcrumb() {
    const instanceId = this.getInstanceId();
    const goto = gotoFn(this);
    let envType = this.props.envType;
    if (typeof envType === 'undefined') envType = 'R';
    return (
      <Breadcrumb className="block mb3">
        <Breadcrumb.Section link onClick={() => goto(`/workspaces/${envType}`)}>
          Research Workspaces
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>{instanceId}</Breadcrumb.Section>
      </Breadcrumb>
    );
  }

  renderMain() {
    const instance = this.getEnvironment();
    const { id, name, updatedAt, updatedBy, _amiIds } = instance;

    return (
      <>
        <div className="flex mb2">
          <Header as="h3" color="grey" className="mt0 flex-auto ellipsis">
            <span className="flex justify-between">
              <div>
                <Label color="blue" className="ml0 mr1">
                  Research Workspace
                </Label>
                {name} - {id}
              </div>
              <div>
                <EnvironmentStatusIcon environment={instance} />
              </div>
            </span>
            <Header.Subheader className="fs-9 color-grey mt1">
              <div>
                updated <TimeAgo date={updatedAt} /> <By user={updatedBy} />
              </div>
            </Header.Subheader>
          </Header>
        </div>
        <div className="mb3">{this.renderTabs()}</div>
      </>
    );
  }

  renderAmiList() {
    const environment = this.getEnvironment();
    return (
      <Tab.Pane attached={false}>
        Exported AMIs
        <div>{environment.amiIds && environment.amiIds.length && environment.amiIds.map(ami => <div>{ami}</div>)}</div>
      </Tab.Pane>
    );
  }

  renderTabs() {
    const environment = this.getEnvironment();
    if (environment.isCompleted) {
      return this.renderCompletedTabs();
    }
    if (environment.isError) {
      return <ErrorInfo environment={environment} />;
    }
    if (environment.isTerminated) {
      return this.renderTerminateInfo();
    }
    return this.renderPendingInfo();
  }

  // edit 2359 - add support for parallel cluster
  // edit 2359 - add support for EMR-hail, edit chenjqp - add support for CIDR update
  // edit chenjqp - add support for secure-emr-hail
  // edit chenjqp - add a warning label when environment is open to everywhere
  renderCompletedTabs() {
    const environment = this.getEnvironment();
    let panes = [
      {
        menuItem: 'Access Instructions',
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>
              {() => {
                switch (environment.instanceInfo.type) {
                  case 'parallel-cluster':
                  case 'ec2-linux':
                  case 'ec2-alinux2':
                    return this.renderEc2LinuxSecurity();
                  case 'ec2-windows':
                    return this.renderEc2WindowsSecurity();
                  case 'sagemaker':
                    return this.renderSagemakerSecurity();
	          case 'secure-sagemaker':
		    return this.renderSecureSagemakerSecurity();		
		  case 'emr':
                    return this.renderEmrSecurity();
                  case 'secure-ec2-linux':
                  case 'secure-ec2-alinux2':
                    return this.renderSecureEc2LinuxSecurity();
                  case 'secure-ec2-windows':
                    return this.renderEc2WindowsSecurity();
                  case 'secure-vetting':
                    return this.renderSecureVettingEc2Security();
                  case 'emr-hail':
                    return this.renderEmrHailSecurity();
                  case 'secure-emr-hail':
                    return this.renderSecureEmrHailSecurity();
                  default:
                    return (
                      <Segment placeholder>
                        <Header icon className="color-grey">
                          <Icon name="hdd" />
                          Access details
                        </Header>
                      </Segment>
                    );
                }
              }}
            </Observer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Research Workspace Details',
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>{() => this.renderStudyInfo()}</Observer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Staged Output Files',
        render: () => (
          <Tab.Pane basic attached={false}>
            <Observer>{() => this.renderStagedOutputFileTab()}</Observer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Cost Details',
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>{() => this.renderInstanceDetails()}</Observer>
          </Tab.Pane>
        ),
      },
      {
        menuItem:
          environment.instanceInfo.cidr !== '0.0.0.0/0' ? (
            'Security'
          ) : (
            <Menu.Item key="messages">
              Security<Label color="red">!</Label>
            </Menu.Item>
          ),
        render: () => (
          <Tab.Pane attached={false}>
            <Observer>{() => this.renderEditableSecurityDetails()}</Observer>
          </Tab.Pane>
        ),
      },
    ];
	
	if(!environment.isSecure){
		panes.push({
        menuItem: 'Exported AMIs',
        render: () => (
          <Tab.Pane attached={false}>
            Exported AMIs
            <div>
              {environment.amiIds && environment.amiIds.length && environment.amiIds.map(ami => <div>{ami}</div>)}
            </div>
          </Tab.Pane>
        ),
      });
	}

    return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
  }

  renderStagedOutputFileTab() {
    const environment = this.getEnvironment();
    return <EnvironmentOutputFilesTable environment={environment} />;
  }

  // add chenjqp - add support for CIDR update
  /* enableEditMode = () => {
    this.cidr = this.getEnvironment().instanceInfo.cidr;
    this.editModeOn = true;
  }; */

  // add chenjqp - add support for CIDR update
  onCidrEdit = (_event, data) => {
    this.cidr = data.value;
  };

  // add chenjqp - add support for CIDR update
  updateCidr = async (_event, _data) => {
    runInAction(() => {
      this.isProcessing = true;
    });
    try {
      await this.props.environmentsStore.updateSecurity(this.getEnvironment().id, { cidr: this.cidr });
      displaySuccess('Permissions updated successfully.', 'Success');
      runInAction(() => {
        this.isProcessing = false;
        this.editModeOn = false;
      });
    } catch (error) {
      displayError('Update Failed', error);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  };

  // add chenjqp - add support for CIDR update
  cancelEdit = () => {
    this.editModeOn = false;
  };

  // add chenjqp - add support for CIDR update
  // edit chenjqp - add description on IP restriction
  renderEditableSecurityDetails() {
    const environment = this.getEnvironment();
    return (
      <>
        <h3 className="center">Security details</h3>
        <p>
          You are <b>REQUIRED</b> to limit access to your resources to your OWN IP.
        </p>

        <Dimmer.Dimmable dimmed={this.isProcessing}>
          <Dimmer active={this.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>

          <Table celled>
            <Table.Body>
              <Table.Row key="cidr">
                <Table.Cell>CIDR</Table.Cell>
                <Table.Cell>
                  {this.editModeOn ? (
                    <>
                      <Input defaultValue={this.cidr} onChange={this.onCidrEdit} />
                      <Button
                        floated="right"
                        disabled={this.isProcessing}
                        onClick={this.updateCidr}
                        size="mini"
                        color="blue"
                        icon
                      >
                        Submit
                      </Button>
                      <Button floated="right" disabled={this.isProcessing} onClick={this.cancelEdit} size="mini">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Table.Cell>{environment.instanceInfo.cidr}</Table.Cell>
                      <EditEnvironmentSecurityForm
                        environment={environment}
                        environmentsStore={this.props.environmentsStore}
                      />
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Dimmer.Dimmable>
      </>
    );
  }

  renderInstanceDetails() {
    return this.renderCostInfo();
  }

  renderStudyInfo() {
    const environment = this.getEnvironment();
    let envType = this.props.envType;
    if (typeof envType === 'undefined') envType = 'R';
    let content;
    if (environment.instanceInfo.type !== 'secure-vetting') {
      content = (
        <>
          <h3 className="center">Workspace Configuration</h3>
          <h4>Instance size</h4>
          {environment.instanceInfo.size}
          <h4>Study Input(s)</h4>
          <DisplayCollectionPane environment={environment} />
          <h4>Study Output</h4>
          <ul>
            {environment.instanceInfo.outputFiles ? (
              environment.instanceInfo.outputFiles.map(file => {
                return <li>{file}</li>;
              })
            ) : (
              <li>&apos;NA&apos;</li>
            )}
          </ul>
          {environment.isSecure && (
            <>
              <h4>Secure Output</h4>
              <ul>
                <li>{environment.instanceInfo.secureOutput ? environment.instanceInfo.secureOutput : 'NA'}</li>
              </ul>
            </>
          )}
        </>
      );
    } else {
      content = (
        <>
          <h3 className="center">Workspace Configuration</h3>
          <h4>Instance size</h4>
          {environment.instanceInfo.size}
          <h4>Vetting Input</h4>
          <ul>
            {environment.instanceInfo.outputs.map(file => {
              return <li>{file}</li>;
            })}
          </ul>
        </>
      );
    }
    return content;
  }

  renderCostInfo() {
    return (
      <Segment>
        <h2 className="center"> Daily Costs</h2>
        {/* this.renderCostTable() */}
        <EnvironmentCostDetails environment={this.getEnvironment()} environmentsStore={this.props.environmentsStore} />
      </Segment>
    );
  }

  /* renderCostTable() {
    // Convert from mobx obj to normal obj
    const environment = JSON.parse(JSON.stringify(this.getEnvironment()));

    let costHeadings = [];
    const rows = [];
    environment.costs.forEach(costItemGivenADate => {
      const cost = costItemGivenADate.cost;
      const headings = Object.keys(cost);
      costHeadings.push(headings);
      const rowValues = {};
      rowValues.date = costItemGivenADate.startDate;
      let total = 0;
      headings.forEach(heading => {
        const amount = cost[heading].amount;
        rowValues[heading] = amount.toFixed(2);
        total += amount;
      });
      rowValues.total = total.toFixed(2);
      rows.push(rowValues);
    });

    costHeadings = _.flatten(costHeadings);
    costHeadings = _.uniq(costHeadings);

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            {costHeadings.map(header => {
              return <Table.HeaderCell key={header}>{header}</Table.HeaderCell>;
            })}
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map(row => {
            return (
              <Table.Row key={row.date}>
                <Table.Cell>{row.date}</Table.Cell>
                {costHeadings.map(header => {
                  return <Table.Cell key={row}>${_.get(row, header, 0)}</Table.Cell>;
                })}
                <Table.Cell>${row.total}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  } */

  renderTerminateInfo() {
    const environment = this.getEnvironment();
    return (
      <>
        <Segment>
          This research workspace was terminated <TimeAgo date={environment.updatedAt} />{' '}
          <By user={environment.updatedBy} />.
        </Segment>
        {this.renderCostInfo()}
        {this.renderAmiList()}
      </>
    );
  }

  renderPendingInfo() {
    const environment = this.getEnvironment();
    return (
      <Segment>
        This research workspace was started <TimeAgo date={environment.createdAt} /> <By user={environment.createdBy} />
        .
      </Segment>
    );
  }

  renderCopyToClipboard(text) {
    return (
      <Popup
        content="Copy"
        trigger={
          // <CopyToClipboard text={text} style={{ 'margin-left': '4px', cursor: 'pointer' }}>
          <CopyToClipboard className="ml1 mr0" text={text} style={{ cursor: 'pointer' }}>
            <Icon name="copy" />
          </CopyToClipboard>
        }
      />
    );
  }

  handleKeyPairRequest = async event => {
    event.preventDefault();
    event.stopPropagation();

    const environment = this.getEnvironment();
    const keyPair = await environment.getKeyPair();

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', `data:application/octet-stream,${encodeURIComponent(keyPair.privateKey)}`);
    downloadLink.setAttribute('download', `${environment.id}.pem`);
    downloadLink.click();
  };

  handleWindowsPasswordRequest = async event => {
    event.preventDefault();
    runInAction(() => {
      this.windowsPassword = 'loading';
    });

    const environment = this.getEnvironment();
    const [{ privateKey }, { passwordData }] = await Promise.all([
      environment.getKeyPair(),
      environment.getWindowsPassword(),
    ]);

    const password = crypto
      .privateDecrypt(
        { key: privateKey, padding: crypto.constants.RSA_PKCS1_PADDING },
        Buffer.from(passwordData, 'base64'),
      )
      .toString('utf8');

    runInAction(() => {
      this.windowsPassword = password;
    });
  };

  handleBastionPasswordRequest = async event => {
    event.preventDefault();
    runInAction(() => {
      this.bastionPassword = 'loading';
    });

    const environment = this.getEnvironment();
    const encryptedPassword = await environment.getBastionPassword();
    const keypair = await environment.getBastionKeypair();

    const password = crypto
      .privateDecrypt(keypair.privateKey, Buffer.from(encryptedPassword, 'base64'))
      .toString('utf8');

    runInAction(() => {
      this.bastionPassword = password;
    });
  };

  handleShowHostInformation(_event) {
    runInAction(() => {
      this.showHostInformation = !this.showHostInformation;
    });
  }

  handleShowDataInformation(_event) {
    runInAction(() => {
      this.showDataInformation = !this.showDataInformation;
    });
  }

  handleShowOutputInformation(_event) {
    runInAction(() => {
      this.showOutputInformation = !this.showOutputInformation;
    });
  }

  // edited by 2359 - added showing of MasterPublicIP
  renderEc2LinuxSecurity() {
    const environment = this.getEnvironment();
    return (
      <div>
        <Accordion>
          <Accordion.Title active={this.showHostInformation} onClick={this.handleShowHostInformation}>
            <Icon name="dropdown" />
            Host information
          </Accordion.Title>
          <Accordion.Content active={this.showHostInformation}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>Hostname:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.Ec2WorkspaceDnsName || environment.instanceInfo.MasterPublicIP}
                      {this.renderCopyToClipboard(
                        environment.instanceInfo.Ec2WorkspaceDnsName || environment.instanceInfo.MasterPublicIP,
                      )}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Username:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.loginUser || 'ec2-user'}
                      {this.renderCopyToClipboard(environment.instanceInfo.loginUser || 'ec2-user')}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Private Key:</Grid.Column>
                  <Grid.Column width={10}>
                    <Button color="blue" onClick={this.handleKeyPairRequest}>
                      Download SSH Key
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              Connecting to your research workspace depends on the operating system you are connecting from.
              <ul>
                <li>
                  <a href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/putty.html">
                    Connecting from Windows via Putty
                  </a>
                </li>
                <li>
                  <a href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/AccessingInstancesLinux.html">
                    Connecting from MacOS or Linux via SSH
                  </a>
                </li>
              </ul>
              Connect command:
              <Segment>{`ssh -i ${environment.id}.pem ${environment.instanceInfo.loginUser || 'ec2-user'}@${environment
                .instanceInfo.Ec2WorkspaceDnsName || environment.instanceInfo.MasterPublicIP}`}</Segment>
            </Segment>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title active={this.showDataInformation} onClick={this.handleShowDataInformation}>
            <Icon name="dropdown" />
            Locating your data
          </Accordion.Title>
          <Accordion.Content active={this.showDataInformation}>
            <Segment>
              <p>
                Data can be found at the following paths:
                <ul>
                  <li>Input study (read-only): /home/ec2-user/studies</li>
                  <li>Input API study (read-only): /home/ec2-user/api</li>
                  {environment.instanceInfo.outputFiles ? (
                    environment.instanceInfo.outputFiles.map(file => {
                      return <li>Output study (read-write): /mnt/outputstudy/studies/Organization/{file}</li>;
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              </p>
            </Segment>
          </Accordion.Content>
        </Accordion>
        {environment.instanceInfo.outputFiles ? (
          <Accordion>
            <Accordion.Title active={this.showOutputInformation} onClick={this.handleShowOutputInformation}>
              <Icon name="dropdown" />
              Data Output
            </Accordion.Title>
            <Accordion.Content active={this.showOutputInformation}>
              <Segment>
                <p>
                  Please ensure that data and/or scripts that need to be persisted across workspaces and/or egressed are
                  copied into the output study mounted folder.{' '}
                  <b>
                    <font color="red">
                      Any data and/or scripts that are not copied into the output study folder will be lost when
                      workspace is terminated.
                    </font>
                  </b>
                  <br />
                  <br />
                  The output study data is hosted on a FSx HDD. A final export will be performed automatically when the
                  workspace is terminated. You may choose to perform this export manually. To do so, you may run the
                  following command:
                  <Segment>
                    sudo lfs hsm_archive &lt;filename&gt;{' '}
                    {this.renderCopyToClipboard('sudo lfs hsm_archive &lt;filename&gt;')}{' '}
                  </Segment>
                  To check the export status, you may run the following command:
                  <Segment>
                    sudo lfs hsm_state &lt;filename&gt;{' '}
                    {this.renderCopyToClipboard('sudo lfs hsm_state &lt;filename&gt;')}
                  </Segment>
                  A return value of &quot;0x00000009 exists archived&quot; indicates that the file has successfully been
                  exported.
                </p>
                <Label basic color="red">
                  Please note that the export of FSx data only pushes the files into a staging folder and not the actual
                  output study folder. Syncing to the actual output study will only be performed upon workspace
                  termination.
                </Label>
              </Segment>
            </Accordion.Content>
          </Accordion>
        ) : null}
      </div>
    );
  }

  renderEc2WindowsSecurity() {
    const environment = this.getEnvironment();
    const passRevealDisabled = !this.windowsPassword || this.windowsPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };

    return (
      <>
        <p>
          Your Windows workspace can be accessed via a remote desktop client (for e.g. Remote Desktop Connection for
          Windows) with the DNS host name and credentials defined defined below.
        </p>

        <Label className="mx1">
          Host <Label.Detail>{environment.instanceInfo.Ec2WorkspaceDnsName}</Label.Detail>
          {this.renderCopyToClipboard(environment.instanceInfo.Ec2WorkspaceDnsName)}
        </Label>

        <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
          <Reveal.Content visible>
            <Button
              color="blue"
              onClick={this.handleWindowsPasswordRequest}
              loading={this.windowsPassword === 'loading'}
              style={passRevealStyle}
            >
              <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
              {passRevealDisabled ? 'Get ' : 'Show '} Windows Credentials
            </Button>
          </Reveal.Content>
          <Reveal.Content hidden>
            <Segment className="px1 py1" style={passRevealStyle}>
              <Label>
                Username <Label.Detail>Administrator</Label.Detail>
              </Label>
              <br />
              <Label className="mx0">
                Password
                <Label.Detail>{this.windowsPassword}</Label.Detail>
                {this.renderCopyToClipboard(this.windowsPassword)}
              </Label>
            </Segment>
          </Reveal.Content>
        </Reveal>

        <p className="mt2">
          Additional information about connecting via a remote desktop client (for e.g. Remote Desktop Connection for
          Windows) can be found in the documentation below:
          <ul>
            <li>
              <a
                href="https://docs.aws.amazon.com/AWSEC2/latest/WindowsGuide/connecting_to_windows_instance.html#connect-rdp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Connect to Your Windows Instance
              </a>
            </li>
          </ul>
        </p>
      </>
    );
  }

  renderEmrSecurity() {
    return this.renderConnectBtn(
      'To connect to this EMR Jupyter notebook instance simply click the launch button below.',
    );
  }

  renderSagemakerSecurity() {
    return this.renderConnectBtn(
      'To connect to this SageMaker notebook instance simply click the launch button below.',
    );
  }

  renderSecureSagemakerSecurity() {
    const environment = this.getEnvironment();
    const passRevealDisabled = !this.bastionPassword || this.bastionPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };
    return (
      <>
        <Accordion>
          <Accordion.Title active={this.showHostInformation} onClick={this.handleShowHostInformation}>
            <Icon name="dropdown" />
            Host information
          </Accordion.Title>
          <Accordion.Content active={this.showHostInformation}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Hostname:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionDnsName}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionDnsName)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
	        <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Username:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionUser}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionUser)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Default Password:</Grid.Column>
                  <Grid.Column width={10}>
                    <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
                      <Reveal.Content visible>
                        <Button
                          color="blue"
                          onClick={this.handleBastionPasswordRequest}
                          loading={this.bastionPassword === 'loading'}
                          style={passRevealStyle}
                        >
                          <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
                          {passRevealDisabled ? 'Get ' : 'Show '} Windows Credentials
                        </Button>
                      </Reveal.Content>
                      <Reveal.Content hidden>
                        <Segment className="px1 py1" style={passRevealStyle}>
                          <Label className="mx0">
                            {this.bastionPassword}
                            {this.renderCopyToClipboard(this.bastionPassword)}
                          </Label>
                        </Segment>
                      </Reveal.Content>
                    </Reveal>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <p>
                Please access the jumpbox windows host via a remote desktop client (for e.g. Remote Desktop Connection
                for Windows) with the jumpbox hostname and credentials defined above. Instructions to connect to your
                secure Sagemaker notebook can be found in the jumpbox (please refer to the section{' '}
                <b>Locating your data</b>).
              </p>

              <Label basic color="red">
                Please note:
                <ul>
                  <li>if you have changed your password for your jumpbox, this will not be reflected above</li>
                  <li>
                    clipboard redirection has been disabled on the jumpbox (i.e. no copying between your desktop/laptop
                    and jumpbox)
                  </li>
                </ul>
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title active={this.showDataInformation} onClick={this.handleShowDataInformation}>
            <Icon name="dropdown" />
            Locating your data
          </Accordion.Title>
          <Accordion.Content active={this.showDataInformation}>
            <Segment>
              <p>
                The following files are available in your jumpbox:
                <ul>
                  <li>C:/notebook.txt -- Instructions to connect to your Sagemaker notebook</li>
                  <li>It will take a few minutes for the command to run.</li>
	          <li>The link to your Sagemaker notebook can last up to 12 hours. <br /> 
	              Please run the command again to get a new link to your Sagemaker notebook 
	              if you intend to use longer than 12 hours.</li>
                </ul>
              </p>
	      <p>
                Data can be found at the following paths:
                <ul>
                  <li>Input study (read-only): /home/ec2-user/SageMaker/studies</li>
                  <li>
                    Secure output (read-write): /home/ec2-user/SageMaker/secure-outputs/{environment.instanceInfo.secureOutput}{' '}
                  </li>
                </ul>
              </p>
            </Segment>
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Title active={this.showOutputInformation} onClick={this.handleShowOutputInformation}>
            <Icon name="dropdown" />
            Data Output
          </Accordion.Title>
          <Accordion.Content active={this.showOutputInformation}>
            <Segment>
              <p>
                Please ensure that data and/or scripts that need to be persisted across workspaces and/or egressed are
                copied into the secure output mounted folder.{' '}
                <b>
                  <font color="red">
                    Any data and/or scripts that are not copied into the secure output folder will be lost when
                    workspace is terminated.
                  </font>
                </b>
                <br />
                <br />
	      </p> 
	    </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }
    	  
  renderSecureEc2LinuxSecurity() {
    const environment = this.getEnvironment();
    const passRevealDisabled = !this.bastionPassword || this.bastionPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };
    return (
      <>
        <Accordion>
          <Accordion.Title active={this.showHostInformation} onClick={this.handleShowHostInformation}>
            <Icon name="dropdown" />
            Host information
          </Accordion.Title>
          <Accordion.Content active={this.showHostInformation}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Hostname:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionDnsName}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionDnsName)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Username:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionUser}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionUser)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Default Password:</Grid.Column>
                  <Grid.Column width={10}>
                    <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
                      <Reveal.Content visible>
                        <Button
                          color="blue"
                          onClick={this.handleBastionPasswordRequest}
                          loading={this.bastionPassword === 'loading'}
                          style={passRevealStyle}
                        >
                          <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
                          {passRevealDisabled ? 'Get ' : 'Show '} Windows Credentials
                        </Button>
                      </Reveal.Content>
                      <Reveal.Content hidden>
                        <Segment className="px1 py1" style={passRevealStyle}>
                          <Label className="mx0">
                            {this.bastionPassword}
                            {this.renderCopyToClipboard(this.bastionPassword)}
                          </Label>
                        </Segment>
                      </Reveal.Content>
                    </Reveal>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <p>
                Please access the jumpbox windows host via a remote desktop client (for e.g. Remote Desktop Connection
                for Windows) with the jumpbox hostname and credentials defined above. Instructions to connect to your
                secure linux workspace can be found in the jumpbox (please refer to the section{' '}
                <b>Locating your data</b>).
              </p>

              <Label basic color="red">
                Please note:
                <ul>
                  <li>if you have changed your password for your jumpbox, this will not be reflected above</li>
                  <li>
                    jumpbox and secure linux workspace has no internet connectivity (use an AMI to spin up secure
                    workspace for preinstalled packages)
                  </li>
                  <li>
                    clipboard redirection has been disabled on the jumpbox (i.e. no copying between your desktop/laptop
                    and jumpbox)
                  </li>
                </ul>
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title active={this.showDataInformation} onClick={this.handleShowDataInformation}>
            <Icon name="dropdown" />
            Locating your data
          </Accordion.Title>
          <Accordion.Content active={this.showDataInformation}>
            <Segment>
              <p>
                The following files are available in your jumpbox:
                <ul>
                  <li>C:/key.pem -- Private key for your workspace</li>
                  <li>C:/workspace.txt -- Instructions to connect to your workspace</li>
                </ul>
              </p>

              <p>
                Data can be found at the following paths:
                <ul>
                  <li>Input study (read-only): /home/ec2-user/studies</li>
                  <li>
                    Secure output (read-write): /mnt/outputstudy/secure-outputs/{environment.instanceInfo.secureOutput}{' '}
                  </li>
                </ul>
              </p>
            </Segment>
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Title active={this.showOutputInformation} onClick={this.handleShowOutputInformation}>
            <Icon name="dropdown" />
            Data Output
          </Accordion.Title>
          <Accordion.Content active={this.showOutputInformation}>
            <Segment>
              <p>
                Please ensure that data and/or scripts that need to be persisted across workspaces and/or egressed are
                copied into the secure output mounted folder.{' '}
                <b>
                  <font color="red">
                    Any data and/or scripts that are not copied into the secure output folder will be lost when
                    workspace is terminated.
                  </font>
                </b>
                <br />
                <br />
                The secure output data is hosted on a FSx HDD. A final export will be performed automatically when the
                workspace is terminated. You may choose to perform this export manually. To do so, you may run the
                following command:
                <Segment>
                  sudo lfs hsm_archive &lt;filename&gt;{' '}
                  {this.renderCopyToClipboard('sudo lfs hsm_archive &lt;filename&gt;')}{' '}
                </Segment>
                To check the export status, you may run the following command:
                <Segment>
                  sudo lfs hsm_state &lt;filename&gt;{' '}
                  {this.renderCopyToClipboard('sudo lfs hsm_state &lt;filename&gt;')}
                </Segment>
                A return value of &quot;0x00000009 exists archived&quot; indicates that the file has successfully been
                exported.
              </p>
              <Label basic color="red">
                Please note that the export of FSx data only pushes the files into a staging folder and not the actual
                secure output folder. Syncing to the actual secure output will only be performed upon workspace
                termination.
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }

  renderSecureVettingEc2Security() {
    const environment = this.getEnvironment();
    const passRevealDisabled = !this.bastionPassword || this.bastionPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };
    return (
      <>
        <Accordion>
          <Accordion.Title active={this.showHostInformation} onClick={this.handleShowHostInformation}>
            <Icon name="dropdown" />
            Host information
          </Accordion.Title>
          <Accordion.Content active={this.showHostInformation}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>Hostname:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionDnsName}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionDnsName)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Username:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionUser}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionUser)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Default Password:</Grid.Column>
                  <Grid.Column width={10}>
                    <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
                      <Reveal.Content visible>
                        <Button
                          color="blue"
                          onClick={this.handleBastionPasswordRequest}
                          loading={this.bastionPassword === 'loading'}
                          style={passRevealStyle}
                        >
                          <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
                          {passRevealDisabled ? 'Get ' : 'Show '} Windows Credentials
                        </Button>
                      </Reveal.Content>
                      <Reveal.Content hidden>
                        <Segment className="px1 py1" style={passRevealStyle}>
                          <Label className="mx0">
                            {this.bastionPassword}
                            {this.renderCopyToClipboard(this.bastionPassword)}
                          </Label>
                        </Segment>
                      </Reveal.Content>
                    </Reveal>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <p>
                Please access the vetting windows host via a remote desktop client (for e.g. Remote Desktop Connection
                for Windows) with the hostname and credentials defined above.
              </p>

              <Label basic color="red">
                Please note:
                <ul>
                  <li>
                    if you have changed your password for your vetting workspace, this will not be reflected above
                  </li>
                  <li>vetting workspace has no internet connectivity</li>
                  <li>
                    clipboard redirection has been disabled on the vetting workspace (i.e. no copying between your
                    desktop/laptop and vetting workspace)
                  </li>
                </ul>
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title active={this.showDataInformation} onClick={this.handleShowDataInformation}>
            <Icon name="dropdown" />
            Locating your data
          </Accordion.Title>
          <Accordion.Content active={this.showDataInformation}>
            <Segment>
              <p>
                The following files are available in your vetting workspace:
                <ul>
                  <li>C:/info.txt -- Instructions to view secure output data</li>
                </ul>
              </p>

              <p>
                Data can be found at the following URL in a browser:
                <ul>
                  <li>Secure output (read only): https://localhost/studies</li>
                </ul>
              </p>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }

  // edit chenjqp - access details page added for secure-emr-hail
  renderSecureEmrHailSecurity() {
    const environment = this.getEnvironment();
    const passRevealDisabled = !this.bastionPassword || this.bastionPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };
    // const environmentName = environment.name;
    const command = `%sh\ncd /opt/zeppelin/ && find . -name "*.zpln" -exec cp --parents \\\{\\\} /mnt/outputstudy/secure-outputs/${environment.instanceInfo.secureOutput}/ \\\;`;
    return (
      <>
        <Accordion>
          <Accordion.Title active={this.showHostInformation} onClick={this.handleShowHostInformation}>
            <Icon name="dropdown" />
            Host information
          </Accordion.Title>
          <Accordion.Content active={this.showHostInformation}>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Hostname:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionDnsName}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionDnsName)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Username:</Grid.Column>
                  <Grid.Column width={10}>
                    <Label.Detail>
                      {environment.instanceInfo.BastionUser}
                      {this.renderCopyToClipboard(environment.instanceInfo.BastionUser)}
                    </Label.Detail>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5}>Jumpbox Default Password:</Grid.Column>
                  <Grid.Column width={10}>
                    <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
                      <Reveal.Content visible>
                        <Button
                          color="blue"
                          onClick={this.handleBastionPasswordRequest}
                          loading={this.bastionPassword === 'loading'}
                          style={passRevealStyle}
                        >
                          <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
                          {passRevealDisabled ? 'Get ' : 'Show '} Windows Credentials
                        </Button>
                      </Reveal.Content>
                      <Reveal.Content hidden>
                        <Segment className="px1 py1" style={passRevealStyle}>
                          <Label className="mx0">
                            {this.bastionPassword}
                            {this.renderCopyToClipboard(this.bastionPassword)}
                          </Label>
                        </Segment>
                      </Reveal.Content>
                    </Reveal>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <p>
                Please access the jumpbox windows host via a remote desktop client (for e.g. Remote Desktop Connection
                for Windows) with the jumpbox hostname and credentials defined above. Instructions to connect to your
                secure linux workspace can be found in the jumpbox (please refer to the section{' '}
                <b>Locating your data</b>).
              </p>

              <Label basic color="red">
                Please note:
                <ul>
                  <li>if you have changed your password for your jumpbox, this will not be reflected above</li>
                  <li>jumpbox and secure EMR workspace has no internet connectivity</li>
                  <li>
                    clipboard redirection has been disabled on the jumpbox (i.e. no copying between your desktop/laptop
                    and jumpbox)
                  </li>
                </ul>
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title active={this.showDataInformation} onClick={this.handleShowDataInformation}>
            <Icon name="dropdown" />
            Locating your data
          </Accordion.Title>
          <Accordion.Content active={this.showDataInformation}>
            <Segment>
              <p>
                The following files are available in your jumpbox:
                <ul>
                  <li>C:/workspace.txt -- Instructions to connect to your workspace</li>
                </ul>
              </p>

              <p>
                Data can be found at the following paths:
                <ul>
                  <li>Input study (read-only): /home/hadoop/studies</li>
                  <li>
                    Secure output (read-write): /mnt/outputstudy/secure-outputs/{environment.instanceInfo.secureOutput}{' '}
                  </li>
                </ul>
              </p>

              <p>
                Notebooks created within the workspace can only be exported with secure outputs. You may then resuse
                your notebook across environments that have access to the secure output. To do so, run the following in
                one of your notes in Zeppelin:
                <Segment className="px1 py1">
                  {command.split('\n').map(item => (
                    <>
                      {item}
                      <br />
                    </>
                  ))}
                </Segment>
              </p>
            </Segment>
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Title active={this.showOutputInformation} onClick={this.handleShowOutputInformation}>
            <Icon name="dropdown" />
            Data Output
          </Accordion.Title>
          <Accordion.Content active={this.showOutputInformation}>
            <Segment>
              <p>
                Please ensure that data and/or scripts that need to be persisted across workspaces and/or egressed are
                copied into the secure output mounted folder.{' '}
                <b>
                  <font color="red">
                    Any data and/or scripts that are not copied into the secure output folder will be lost when
                    workspace is terminated.
                  </font>
                </b>
                <br />
                <br />
                The secure output data is hosted on a FSx HDD. A final export will be performed automatically when the
                workspace is terminated. You may choose to perform this export manually. To do so, you may run the
                following command:
                <Segment>
                  sudo lfs hsm_archive &lt;filename&gt;{' '}
                  {this.renderCopyToClipboard('sudo lfs hsm_archive &lt;filename&gt;')}{' '}
                </Segment>
                To check the export status, you may run the following command:
                <Segment>
                  sudo lfs hsm_state &lt;filename&gt;{' '}
                  {this.renderCopyToClipboard('sudo lfs hsm_state &lt;filename&gt;')}
                </Segment>
                A return value of &quot;0x00000009 exists archived&quot; indicates that the file has successfully been
                exported.
              </p>
              <Label basic color="red">
                Please note that the export of FSx data only pushes the files into a staging folder and not the actual
                secure output folder. Syncing to the actual secure output will only be performed upon workspace
                termination.
              </Label>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }

  renderConnectBtn(msg) {
    const environment = this.getEnvironment();

    return (
      <div>
        <p>{msg}</p>
        <EnvironmentConnectButton as={Button} environment={environment} color="green">
          {environment.fetchingUrl ? (
            <>
              Connecting
              <Icon loading name="spinner" size="small" className="ml1 mr1" />
            </>
          ) : (
            <>Connect</>
          )}
        </EnvironmentConnectButton>
      </div>
    );
  }

  // add chenjqp - add EMR login credentials
  handleEmrPasswordRequest = async event => {
    event.preventDefault();
    runInAction(() => {
      this.emrPassword = 'loading';
    });

    const environment = this.getEnvironment();
    const encryptedPassword = await environment.getEmrPassword();
    const keypair = await environment.getKeyPair();

    const password = crypto
      .privateDecrypt(keypair.privateKey, Buffer.from(encryptedPassword, 'base64'))
      .toString('utf8');

    runInAction(() => {
      this.emrPassword = password;
    });
  };

  // add 2359 - add support for EMR-hail, edit chenjqp - clearer instructions
  // edit chenjqp - add EMR login credentials, edit description
  renderEmrHailSecurity() {
    const passRevealDisabled = !this.emrPassword || this.emrPassword === 'loading';
    const passRevealStyle = {
      width: '27em',
      height: '5em',
    };

    const environment = this.getEnvironment();
    const environmentName = environment.name;
    const environmentNotebook = environment.instanceInfo.workspaceNotebook;
    const folder = `${environmentName}-Notebook-${environmentNotebook}`;
    const command = `%sh\ncd /opt/zeppelin/ && find . -type d -not -path '*/${folder}*' -print -exec mkdir -p '/home/hadoop/notebooks/${folder}/{}' \\\; ;\ncd /opt/zeppelin/ && find . -type f -not -path '*/${folder}*' -print -exec cp '{}' '/home/hadoop/notebooks/${folder}/{}' \\\; ;`;
    return (
      <div>
        <p>
          Your workspace can be accessed by the Zeppelin URL{' '}
          <a target="_blank" href={returnValidatedUrl(environment.instanceInfo.ZeppelinUrl)} rel="noreferrer">
            here
          </a>{' '}
          and the Spark URL{' '}
          <a target="_blank" href={returnValidatedUrl(environment.instanceInfo.SparkUrl)} rel="noreferrer">
            here
          </a>
          . Please use the credentials below to login.
        </p>

        <p>
          <Reveal className="mt1" animated="move" disabled={passRevealDisabled}>
            <Reveal.Content visible>
              <Button
                color="blue"
                onClick={this.handleEmrPasswordRequest}
                loading={this.emrPassword === 'loading'}
                style={passRevealStyle}
              >
                <Icon name={passRevealDisabled ? 'lock' : 'unlock'} />
                {passRevealDisabled ? 'Get ' : 'Show '} Credentials
              </Button>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Segment className="px1 py1" style={passRevealStyle}>
                <Label>
                  Username <Label.Detail>{environment.instanceInfo.EmrUsername}</Label.Detail>
                  {this.renderCopyToClipboard(environment.instanceInfo.EmrUsername)}
                </Label>
                <br />
                <Label className="mx0">
                  Password <Label.Detail content={this.emrPassword} />
                  {this.renderCopyToClipboard(this.emrPassword)}
                </Label>
              </Segment>
            </Reveal.Content>
          </Reveal>
        </p>

        <p>
          Please note that access to the URLs are restricted by IP. This can be configured in the &apos;Security&apos;
          tab of your workspace. Only single IPs (or CIDRs ending with /32) are accepted. In the event you are unable to
          fix your address to a single IP, you may choose to enter 0.0.0.0/0 to allow access from anywhere. However, do
          take note of the security implications of doing so.
        </p>

        <p>
          Please note that Notebooks are created by default for each EMR workspace spun up. However, you are required to
          make an <b>explicit save</b> to retain all the changes you&apos;ve done to your notebook in Zeppelin if you
          wish to use them again in the future. To do so, run the following in one of your notes in Zeppelin:
          <Segment className="px1 py1">
            {command.split('\n').map(item => (
              <>
                {item}
                <br />
              </>
            ))}
            {this.renderCopyToClipboard(command)}
          </Segment>
        </p>

        <p>
          Study data can be accessed in the EMR via the path /home/hadoop/studies. API study data can be accessed in the
          EMR via the path /home/hadoop/api.
        </p>
      </div>
    );
  }

  /* renderEnvironmentStagingDetails() {
    const environment = this.getEnvironment();
  } */
}

export default inject('environmentsStore')(withRouter(observer(EnvironmentDetailPage)));
