/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const addUserApplicationFormFields = {
  email: {
    label: 'Username',
    placeholder: 'Type email address as username for the user',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'Your first name',
    explain: 'Your current default first name is ',
    rules: 'required|string',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Your last name',
    explain: 'Your current default last name is ',
    rules: 'required|string',
  },
  applyReason: {
    label: 'Describe Your Research',
    explain: 'Please tell us why you are requesting access to the Research Portal',
    placeholder: 'Why are you requesting access?',
    rules: 'required|string',
  },
};

function getAddUserApplicationFormFields() {
  return addUserApplicationFormFields;
}

function getAddUserApplicationForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addUserApplicationFormFields }, { plugins });
}

export { getAddUserApplicationFormFields, getAddUserApplicationForm };
