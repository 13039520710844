/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getEgressTaskStudySelectForm } from '../../models/forms/CreateSecureOutputEgressTaskForm';
import { displayError, displaySuccess } from '../../helpers/notification';
import { StudiesStore } from '../../models/studies/StudiesStore';

import Form from '../helpers/fields/Form';
import Dropdown from '../helpers/fields/DropDown';
import Input from '../helpers/fields/Input';

class EgressToStudy extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.secureOutput = props.secureOutput;
    this.store = props.secureOutput.getEgressTasksStore();
    this.studiesStore = StudiesStore.create({ loadMethod: 'getMyStudies' });
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  componentDidMount() {
    this.studiesStore.load();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const formValues = form.values();
      const task = await this.store.egressToStudy(
        this.secureOutput.id,
        formValues.secureOutputPrefix ? formValues.secureOutputPrefix : '',
        formValues.destinationStudyId,
        formValues.destinationStudyPrefix,
      );
      this.store.addTask(task);

      form.clear();
      this.cleanModal();

      displaySuccess('Copy to study requested.');
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Egress study select
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderStudySelectForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Copy To Study
      </Button>
    );
  }

  renderStudySelectForm() {
    const form = getEgressTaskStudySelectForm({ secureOutputId: this.secureOutput.id });
    const regularStudiesList = this.studiesStore.list
      .filter(study => !study.isSecure)
      .filter(study => study.available)
      .filter(study => !study.deleted)
      .filter(study => study.category !== 'API Data')
      .map(study => ({ key: study.id, value: study.id, text: study.name, icon: 'folder' }));
    const secureStudiesList = this.studiesStore.list
      .filter(study => study.isSecure)
      .filter(study => study.available)
      .filter(study => !study.deleted)
      .map(study => ({ key: study.id, value: study.id, text: study.name, icon: 'lock', label: 'secure' }));
    const studiesList = regularStudiesList.concat(secureStudiesList);

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('secureOutputPrefix')} />
              <Dropdown
                field={form.$('destinationStudyId')}
                options={studiesList}
                search
                fluid
                placeholder="please select study"
                selection
              />
              <Input field={form.$('destinationStudyPrefix')} />

              <p>
                <b>Examples</b>
                <br />
                To copy whole secure output to a prefix (folder_dest) in destination study: <br />
                - Source prefix folder: &lt;empty&gt; <br />
                - Destination Study Prefix: folder_dest <br />
                <br />
                To copy subfolder (output_folder_01) in secure output to a prefix (folder_dest) in destination study:{' '}
                <br />
                - Source prefix folder: output_folder_01 <br />
                - Destination Study Prefix: folder_dest/output_folder_01 <br />
              </p>

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Submit egress
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(EgressToStudy));
