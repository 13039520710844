/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { observable } from 'mobx';

import registerModels from './helpers/register-models';
import postRegister from './helpers/post-register';

const globals = observable({});

const initializeGlobals = () => {
  const globalsHolder = {
    disposers: {},
    intervalIds: {},
  };

  registerModels(globalsHolder);
  postRegister(globalsHolder);

  Object.assign(globals, globalsHolder); // this is to ensure that it is the same globals reference whether initializeGlobals is called or not
  return globalsHolder;
};

export { globals, initializeGlobals };
