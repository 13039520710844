/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { BaseStore } from '../BaseStore';
import { listAuditLogs } from '../../helpers/api';
import AuditLogEvent from './AuditLogEvent';

// ==================================================================
// AuditStore
// ==================================================================
const AuditStore = BaseStore.named('AuditStore')
  .props({
    currentPage: 1,
    pageSize: 25,
    actor: types.maybe(types.string),
    total: 0,
    visibleRecords: types.optional(types.array(AuditLogEvent), []),
    loadedRecords: types.optional(types.array(AuditLogEvent), []),
    progress: 0,
    startTime: types.maybe(types.string),
    endTime: types.maybe(types.string),
    messageSearch: types.maybe(types.string),
  })

  .actions(self => {
    // save the base implementation of clear
    const superCleanup = self.cleanup;

    return {
      async doLoad({ page = 1, pageSize = 25, reload = true, startTime, endTime, messageSearch } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;
        self.startTime = startTime;
        self.endTime = endTime;
        self.messageSearch = messageSearch;

        if (reload) {
          self.runInAction(() => {
            self.currentPage = 1;
            self.total = 0;
            self.loadedRecords = [];
            self.visibleRecords = [];
            self.progress = 0;
          });
        }

        const startTimeParam = startTime ? new Date(startTime).getTime() : Date.now();
        const endTimeParam = (() => {
          if (endTime) {
            return new Date(endTime).getTime();
          }
          if (startTimeParam) {
            return startTimeParam - 1000 * 60 * 60 * 24;
          }
          // This will be invalid.
          return undefined;
        })();

        const whereParam =
          messageSearch && messageSearch.trim()
            ? messageSearch
                .trim()
                .split(' ')
                .map(queryTerm => ({ field: 'message', operator: 'LIKE', queryTerm }))
            : undefined;

        self.progress = 0;

        if (reload) {
          self.visibleRecordsRendered = false;

          // eslint-disable-next-line no-await-in-loop
          const response = await listAuditLogs({
            startTime: startTimeParam,
            endTime: endTimeParam,
            where: whereParam,
          });
          self.runInAction(() => {
            self.total += response.length;
            self.loadedRecords.push(...response);
            // update progress bar
            self.progress = 100;
          });
        }

        self.runInAction(() => {
          // slice the records to show the current page
          self.visibleRecords.replace(
            _.cloneDeep(
              self.loadedRecords.slice(self.pageSize * (self.currentPage - 1), self.pageSize * self.currentPage),
            ),
          );
        });
      },

      cleanup: () => {
        superCleanup();
        self.currentPage = 1;
        self.total = 0;
        self.loadedRecords = [];
        self.visibleRecords = [];
        self.progress = 0;
      },
    };
  })

  .views(self => ({
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
    get empty() {
      return self.loadedRecords.length === 0;
    },
  }));

function registerModels(globals) {
  globals.auditStore = AuditStore.create({}, globals);
}

export { AuditStore, registerModels };
