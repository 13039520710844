/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

import { BaseStore } from '../BaseStore';
import { listExecutionAuditLogs, putAuditLogComment } from '../../helpers/api';
import AuditLogEvent from './AuditLogEvent';

// ==================================================================
// WorkflowExecutionAuditStore
// ==================================================================
const WorkflowExecutionAuditStore = BaseStore.named('WorkflowExecutionAuditStore')
  .props({
    currentPage: 1,
    pageSize: 50,
    total: 0,
    records: types.optional(types.array(AuditLogEvent), []),
    progress: 0,
  })

  .actions(self => {
    return {
      async doLoad({ page = 1, workflowId, executionId, startTime } = {}) {
        self.currentPage = page;
        self.total = 0;
        self.records.clear();
        self.startTime = startTime;
        const response = await listExecutionAuditLogs({
          workflowId,
          executionId,
          startTime: self.startTime,
          endTime: Date.now(),
        });
        self.runInAction(() => {
          self.total += response.events.length;
          self.records.push(...response.events);
        });
      },
      addAuditLogComment: async (workflowId, executionId, comment) => {
        await putAuditLogComment(workflowId, executionId, comment);
        const response = await listExecutionAuditLogs({
          workflowId,
          executionId,
          startTime: self.startTime,
          endTime: Date.now(),
        });
        self.runInAction(() => {
          self.total += response.events.length + 1;
          self.records.push(...response.events);
        });
      },
      clear: () => {
        self.currentPage = 1;
        self.total = 0;
        self.records = [];
      },
    };
  })

  .views(self => ({
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
    get empty() {
      return self.records.length === 0;
    },
  }));

function registerModels(globals) {
  globals.workflowExecutionAuditStore = WorkflowExecutionAuditStore.create({}, globals);
}

export { WorkflowExecutionAuditStore, registerModels };
