/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { displayError } from '../../helpers/notification';

class AmiSelectModal extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.state = {
      selected: '',
    };
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  onSelect = e => {
    this.setState({ selected: e.target.value });
  };

  handleFormCancel = () => {
    this.cleanModal();
  };

  handleFormSubmission = () => {
    try {
      if (!this.state.selected) {
        displayError('Please select a type of instance.', 'Selection Missing');
      }
      this.props.onSelectComputeType(this.state.selected);
      this.cleanModal();
    } catch (error) {
      // displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Create Research Workspace
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderComputeRequestForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      color: 'blue',
      size: 'medium',
    };
    return (
      <Button
        {...props}
        basic
        disabled={this.props.isDisabled}
        onClick={action(() => {
          this.props.onSelectComputeType('ec2');
        })}
      >
        Create Research Workspace
      </Button>
    );
  }

  renderComputeRequestForm() {
    return (
      <div>
        <BaseHeader>Create Instance from AMI: {this.props.amiId}</BaseHeader>
        <br />
        <input type="radio" name="test" value="ec2" onClick={this.onSelect} />
        Create an EC2 Instance from selected AMI <br />
        <br />
        <input type="radio" name="test" value="emr" onClick={this.onSelect} />
        Create an EMR cluster using selected AMI
        <br />
        <Button className="ml2 mb3" floated="right" color="blue" onClick={this.handleFormSubmission}>
          Select
        </Button>
        <Button className="mb3" floated="right" onClick={this.handleFormCancel}>
          Cancel
        </Button>
      </div>
    );
  }
}

export default inject('userStore')(observer(AmiSelectModal));
