/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Amplify } from 'aws-amplify';
import CookieStorage from './helpers/cookie-storage';
import { cookieDomain } from './helpers/settings';

export const cookieStorage = new CookieStorage({
    domain: cookieDomain,
    expires: 1,
    path: '/',
    secure: cookieDomain !== "localhost",
    sameSite: 'lax',	
});
 

function initializeAmplify({ awsRegion, userPoolId, userPoolWebClientId }) {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: awsRegion,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - customized storage object
      storage: cookieStorage,
      
    },
  });
  return Amplify;
}

export default initializeAmplify;
