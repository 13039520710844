/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';
import { disableAmi, deleteAmi, enableAmi, updateAmi, publishAmi, approveAmiPublish, rejectAmiPublish } from '../../helpers/api';
import { displayError } from '../../helpers/notification';

// ==================================================================
// AMI Permissions
// ==================================================================
const PENDING = 'Pending';
const UNKNOWN = 'Unknown';
const AVAILABLE = 'Available';
const FAILED = 'FAILED';
const DRAFT = 'Draft';
const PROCESSING = 'processing';
const APPROVED = 'Approved';
const REJECTED = 'Rejected';

const AmiPermission = types
  .model('AmiPermission', {
    id: types.identifier,
    permissions: types.array(UserIdentifier),
    createdAt: '',
    createdBy: UserIdentifier,
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    amiId: types.maybeNull(types.string),
    emrCompatible: types.maybeNull(types.string),
    pclusterCompatible: types.maybeNull(types.boolean),
    environmentId: types.maybeNull(types.string),
    amiName: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    status: types.maybe(types.enumeration('status', [PENDING, UNKNOWN, AVAILABLE, FAILED])),
    amiStatus:  types.maybe(types.enumeration('status', [DRAFT, PENDING, PROCESSING, APPROVED, REJECTED, FAILED])),
    disabled: types.maybeNull(types.boolean),
    deleted: types.maybeNull(types.boolean)
  })
  .actions(self => ({
        setAmi(rawAmi) {
                // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
                // the appropriate logic

                // Preserve the value of the fetchingUrl
                const fetchingUrl = self.fetchingUrl;
                applySnapshot(self, rawAmi);
                self.fetchingUrl = fetchingUrl;
        },
	
        updateStatus(status) {
                self.status = status;
        },	  
	updateDisabled(disabled) {
		self.disabled = disabled;
	},
	updateDeleted(deleted){
		self.deleted = deleted;
	},
	updateAmiStatus(amiStatus) {
	        self.amiStatus = amiStatus;
	},
	async updateAmi(body){
		const updated = await updateAmi(self.id, body);
		applySnapshot(self, updated);
		return updated;
	},  
	async disableAmi(){
		await disableAmi(self.id);
		self.updateDisabled(true);	
	},
	async enableAmi(){
		await enableAmi(self.id);
		self.updateDisabled(false);	
	},
	async deleteAmi(){
		await deleteAmi(self.id);
		self.updateDeleted(true);		
	},
	async publishAmi() {
	        await publishAmi(self.id);
		self.updateAmiStatus(PENDING);
	},
	async approve(amiId, updateRequest) {
                self.updateAmiStatus(PROCESSING);
                const { comments } = updateRequest;
                try {
                   await approveAmiPublish(amiId, { id: amiId, comments });
	           self.updateAmiStatus(APPROVED);		
                } catch (e) {
                   self.updateAmiStatus(FAILED);
                   displayError(`Error approving publish for '${self.id}'`, e);
                }
        },
        async reject(amiId, updateRequest) {
               self.updateAmiStatus(PROCESSING);
               const { comments } = updateRequest;
               try {
                  await rejectAmiPublish(amiId, { id: amiId, comments });     
                  self.updateAmiStatus(REJECTED);
               } catch (e) {
                  self.updateAmiStatus(FAILED);
                  displayError(`Error rejecting publish for '${self.id}'`, e);
               }
        },

  }))
  .views(self => ({
    get isEmrCompatible() {
      return self.emrCompatible && self.emrCompatible.toLowerCase() === "true"
    },

    get isPclusterCompatible() {
      return self.pclusterCompatible || false;
    },

    get isDisabled() {
	  return self.disabled || false;
    },

    draft() {
      return !self.amiStatus || self.amiStatus === DRAFT;
    },
    pending() {
      return self.amiStatus === PENDING || false; 
    },

    running() {
      // not required for AMI Publish	    
      return false;
    },
    completed() {
      // not required for AMI Publish	    
      return false;
    },
    rejected() {
      return self.amiStatus === REJECTED || false;
    },
    failed() {
      return self.amiStatus === FAILED || false;
    },
	
  }));

export { AmiPermission };
