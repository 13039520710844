/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Icon, Step } from 'semantic-ui-react';
import { gotoFn } from '../../../helpers/routing';
import StudySelector from './StudySelector';
import CreateEnvironmentFromAmiForm from '../CreateEnvironmentFromAmiForm';
import Ready from './Ready';

class AmiWizardPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const envConfigStore = this.props.environmentConfigurationsStore;
    envConfigStore.load();
  }

  onSuccess = () => {
    const goto = gotoFn(this);
    goto(`/workspaces`);
  };

  handleNext = () => {
    const wizard = this.props.amiWizard;
    wizard.setCurrentStep(3);
  };

  reset = () => {
    const wizard = this.props.amiWizard;
    wizard.setCurrentStep(2);
  };

  render() {
    const wizard = this.props.amiWizard;
    return (
      <div className="p3 animated fadeIn">
        <Step.Group widths={3}>
          <Step>
            <Icon name="search" color="blue" />
            <Step.Content>
              <Step.Title>Find &amp; Select</Step.Title>
              <Step.Description>Search and select the AMI&apos; to use.</Step.Description>
            </Step.Content>
          </Step>
          <Step active={wizard.currentStep === 2}>
            <Icon name="wordpress forms" />
            <Step.Content>
              <Step.Title>Select Studies</Step.Title>
              <Step.Description>Select from a list of studies you have access to.</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled={wizard.currentStep < 3} active={wizard.currentStep === 3}>
            <Icon name="unlock" />
            <Step.Content>
              <Step.Title>Launch Workspace</Step.Title>
              <Step.Description>Fill in the details and launch workspace.</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        {wizard.currentStep === 2 && <StudySelector handleNext={this.handleNext} />}
        {wizard.currentStep === 3 && <Ready />}
        {wizard.currentStep === 4 && (
          <CreateEnvironmentFromAmiForm reset={this.reset} files={wizard.getFiles()} onSuccess={this.onSuccess} />
        )}
      </div>
    );
  }
}

export default inject('amiWizard', 'environmentConfigurationsStore')(observer(AmiWizardPage));
