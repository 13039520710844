// added by leeas

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import {
  getUploadRequestDirs,
  getUploadDirsByStudy,
  createUploadRequest,
  updateUploadRequest,
  revokeUploadRequest,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import StudyUploadDir from './StudyUploadDir';

// ==================================================================
// StudyUploadDirStore
// ==================================================================

const StudyUploadDirStore = BaseStore.named('StudyUploadDirStore')
  .props({
    unameWithNs: '',
    studyId: '',
    uploadDirs: types.array(StudyUploadDir),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad(unameWithNs) {
        self.unameWithNs = unameWithNs;
        await self.searchByUnameWithNs(self.unameWithNs);
      },

      cleanup: () => {
        superCleanup();
      },

      getUploadDir: studyId => {
        const result = _.find(self.uploadDirs, ['studyId', studyId]);
        return result;
      },

      searchByUnameWithNs: async unameWithNs => {
        self.unameWithNs = unameWithNs;
        const result = await getUploadRequestDirs(unameWithNs, 'Y');
        if (!self.uploadDirs || !_.isEqual(self.uploadDirs, result.uploadDirs)) {
          self.runInAction(() => {
            self.uploadDirs = result.uploadDirs;
          });
        }
        return self.uploadDirs;
      },

      searchByStudy: async (studyId, unameWithNs, studyAdminUnameWithNs = '') => {
        self.studyId = studyId;

        const result = await getUploadDirsByStudy(studyId, studyAdminUnameWithNs);
        if (typeof unameWithNs !== 'undefined' && unameWithNs !== null) {
          result.uploadDirs = _.filter(result.uploadDirs, dir => dir.unameWithNs === unameWithNs);
        }
        if (!self.uploadDirs || !_.isEqual(self.uploadDirs, result.uploadDirs)) {
          self.runInAction(() => {
            self.uploadDirs = result.uploadDirs;
          });
        }
        return self.uploadDirs;
      },

      createOrSaveUploadDir: async uploadRequest => {
        let result;
        const entry = self.getUploadDir(uploadRequest.studyId);
        if (!entry) {
          result = await createUploadRequest(uploadRequest);
        } else {
          result = await updateUploadRequest(uploadRequest);
        }
        return result;
      },

      revokeRequest: async (unameWithNs, uploaddir, studyId, studyAdminUnameWithNs) => {
        const entry = self.getUploadDir(studyId);
        if (entry) {
          const revokeRequest = { unameWithNs, uploaddir, studyId };
          const result = await revokeUploadRequest(revokeRequest);
          if (typeof studyAdminUnameWithNs !== 'undefined' && studyAdminUnameWithNs !== null) {
            await self.load(studyAdminUnameWithNs);
          } else {
            await self.load(unameWithNs);
          }
          return result;
        }
        throw new Error(`User does not have upload access to "${studyId}"`);
      },
    };
  })
  .views(self => ({
    get empty() {
      return self.uploadDirs.length === 0;
    },

    get total() {
      return self.uploadDirs.length;
    },

    get list() {
      const result = [];
      self.uploadDirs.forEach(dir => result.push(dir));
      return result;
    },
  }));

function registerModels(globals) {
  globals.studyUploadDirStore = StudyUploadDirStore.create({}, globals);
}

export { StudyUploadDirStore, registerModels };
