/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';

const triggerWorkflowFields = [
  {
    name: 'workflowInput',
    label: 'Workflow Input',
    placeholder: 'Provide a JSON input',
    extra: {
      explain: `This is an advance operation. You will need to provide an input in the form of a json object.`,
    },
    rules: 'string',
  },
];

function getTriggerWorkflowForm() {
  return createForm(triggerWorkflowFields);
}

export default getTriggerWorkflowForm;
