/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getEnv, types } from 'mobx-state-tree';

import { BaseStore, isStoreReady } from '../BaseStore';
import DownloadKeysStore from './DownloadKeysStore';

const UserDownloadKeysStore = BaseStore.named('UserDownloadKeysStore')
  .props({
    // key = userIdentifierStr and value = ApiKeysStore for that user
    userDownloadKeysStores: types.optional(types.map(DownloadKeysStore), {}),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      async doLoad() {
        const userStore = getEnv(self).userStore;
        if (!isStoreReady(userStore)) {
          // Load current user information, if not loaded already
          await userStore.load();
        }

        const currentUser = userStore.user;
        const currentUserDownloadKeyStore = DownloadKeysStore.create({ userIdentifierStr: currentUser.id });
        if (!isStoreReady(currentUserDownloadKeyStore)) {
          // Load API keys for the current user
          await currentUserDownloadKeyStore.load();
        }

        self.runInAction(() => {
          // The put call below will automatically use the id from currentUserApiKeyStore
          // (as it is marked "types.identifier") and add that as a key in the map and
          // store the object as value against it
          self.userDownloadKeysStores.put(currentUserDownloadKeyStore);
        });
      },
      getDownloadKeysStore: (userIdentifierStr, userIdentifier) => {
        let entry = self.userDownloadKeysStores.get(userIdentifierStr);
        if (!entry) {
          self.userDownloadKeysStores.put(DownloadKeysStore.create({ userIdentifierStr, userIdentifier }));
          entry = self.userDownloadKeysStores.get(userIdentifierStr);
        }
        return entry;
      },
      getCurrentUserDownloadKeysStore: () => {
        const userStore = getEnv(self).userStore;
        const currentUser = userStore.user;
        return self.getDownloadKeysStore(currentUser.id, currentUser.identifier);
      },
      cleanup: () => {
        self.user = undefined;
        superCleanup();
      },
    };
  })
  .views(self => ({
    get empty() {
      return self.userDownloadKeysStores.size === 0;
    },
  }));

function registerModels(globals) {
  globals.userDownloadKeysStore = UserDownloadKeysStore.create({}, globals);
}

export { UserDownloadKeysStore, registerModels };
