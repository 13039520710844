/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { listStudyFiles, updateFolderCollections, addCollectiontoBatchFolders } from '../../helpers/api';
import { BaseStore } from '../BaseStore';

// ==================================================================
// StudyFile
// ==================================================================
const StudyFile = types.model('StudyFile', {
  fileType: types.string,
  fileName: types.string,
  size: types.optional(types.integer, 0),
  lastModified: types.optional(types.Date, new Date()),
  fullPath: types.maybeNull(types.string),
  collections: types.array(types.string),
  //  parentCollections: types.maybeNull(types.array(types.array(types.string)))
});

// ==================================================================
// SelectedFile
// ==================================================================
const SelectedFile = types.model({
  fileName: types.string,
  fullPath: types.string,
});
// ==================================================================
// StudyFiles
// ==================================================================
const StudyFilesStore = BaseStore.named('StudyFilesStore')
  .props({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    progress: 0,
    studyId: '',
    selectedPrefix: '',
    parentPrefix: '',
    queryType: 'filename',
    query: '',
    isSearchOn: false,
    selectedFiles: types.optional(types.array(SelectedFile), []),
    files: types.array(StudyFile),
    visibleRecords: types.optional(types.array(StudyFile), []),
    tickPeriod: 5 * 1000, // 5 seconds
    parentCollections: types.optional(types.array(types.array(types.string)), []),
  })

  .actions(self => {
    // save the base implementation of clear
    const superCleanup = self.cleanup;

    return {
      async doLoad({
        page = 1,
        pageSize = 10,
        reload = false,
        selectedPrefix,
        queryType,
        query,
        parentCollections,
      } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;
        self.selectedPrefix = typeof selectedPrefix !== 'undefined' && selectedPrefix !== '' ? selectedPrefix : '';
        // console.log(`in doLoad: ${self.studyId} prefix: ${self.selectedPrefix}`);

        if (typeof query !== 'undefined' && query !== '') {
          self.query = query;
          self.queryType = queryType;
          self.isSearchOn = true;
        } else if (query === '') {
          self.query = query;
          self.queryType = queryType;
          self.isSearchOn = false;
        }

        if (reload) {
          // Retrieve files
          self.progress = 0;
          self.total = 0;
          self.currentPage = 1;
          self.visibleRecords = [];

          let result = {};
          let fullDirList = {};

          // retrieve everything in current prefix
          fullDirList = await listStudyFiles(self.studyId, self.selectedPrefix);
          const mergedFiles = fullDirList.files;
          result = fullDirList;

          // retrieve folders with collection tags
          /*
          if (self.isSearchOn && self.selectedPrefix === '') {
            result = await listStudyFilesWithTagsAndSearch(self.studyId, self.queryType, self.query);
          } else {
            result = await listStudyFilesWithTags(self.studyId, self.selectedPrefix);
          }

		  // merge full folder listing with collection tags
		  const mergedFiles = [];
		  if(fullDirList.files){
			  fullDirList.files.map(file => {
				const collection = result.files.find(cFile => cFile.fileName === file.fileName);
				if(collection){
					mergedFiles.push(collection);
				}else{
					mergedFiles.push(file);
				}
			  })
		  } */

          // let result = await listStudyFiles(self.studyId, self.selectedPrefix);
          // Determine which files were added or removed
          /* const comparator = (fileA, fileB) => fileA.filename === fileB.filename;
            const removed = _.differenceWith(self.files, files, comparator);
            const added = _.differenceWith(files, self.files, comparator);

            // Only update store when needed to avoid unnecessary re-rendering
            if (removed.length !== 0 || added.length !== 0) { */
          // Sort files by name and cast lastModified as Date()
          if (result === null || !Object.prototype.hasOwnProperty.call(result, 'files')) {
            self.runInAction(() => {
              self.files = [];
            });
          } else {
            const files = mergedFiles
              .sort((fileA, fileB) => fileA.fileType.localeCompare(fileB.fileType))
              .map(file => {
                if (file.fileType === 'file') {
                  return { ...file, lastModified: new Date(file.lastModified) };
                }
                return { ...file };
              });

            // Update store
            self.runInAction(() => {
              self.parentPrefix = result.parentPrefix;
              self.files.replace(files);
              self.total = self.files.length;
              const num = self.currentPage * self.pageSize;
              self.visibleRecords.replace(_.cloneDeep(self.files.slice(num - self.pageSize, num)));
              self.progress = 100;
              self.parentCollections = parentCollections;
            });
          }
        } else {
          self.runInAction(() => {
            self.progress = 0;
            const num = self.currentPage * self.pageSize;
            self.visibleRecords.replace(_.cloneDeep(self.files.slice(num - self.pageSize, num)));
            self.progress = 100;
          });
        }
      },

      async updateCollections(fullPath, collections) {
        const result = await updateFolderCollections(self.studyId, fullPath, collections);
        self.runInAction(() => {
          self.files.replace(
            self.files.map(file => {
              if (file.fullPath === result.fullPath) {
                return { ...file, collections: result.collections };
              }
              return file;
            }),
          );
          const num = self.currentPage * self.pageSize;
          self.visibleRecords.replace(_.cloneDeep(self.files.slice(num - self.pageSize, num)));
        });
      },

      async updateBatchCollections(fullPaths, collection) {
        const result = await addCollectiontoBatchFolders(self.studyId, fullPaths, collection);
        return result;
      },

      updateSelectedFiles({ fileName, fullPath }) {
        if (_.some(self.selectedFiles, file => file.fullPath === fullPath)) {
          self.selectedFiles = _.filter(self.selectedFiles, file => file.fullPath !== fullPath);
        } else {
          self.selectedFiles = _.concat(self.selectedFiles, { fileName, fullPath });
        }
      },

      unselectAllFiles() {
        self.selectedFiles = [];
      },

      clearSearch() {
        self.query = '';
        self.queryType = 'filename';
        self.isSearchOn = false;
      },

      cleanup: () => {
        self.files.clear();
        superCleanup();
        self.clearSearch();
        self.currentPage = 1;
        self.total = 0;
        self.visibleRecords = [];
        self.selectedFiles = [];
        self.progress = 0;
        self.parentCollections = [];
      },

      empty() {
        self.files.clear();
      },
    };
  })
  .views(self => ({
    get isEmpty() {
      return self.files.length === 0;
    },
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use study.getFilesStore()
export { StudyFilesStore }; // eslint-disable-line import/prefer-default-export
