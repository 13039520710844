/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, Card, Radio, Dropdown, Header } from 'semantic-ui-react';
import { getCreateEnvironmentAmiForm } from '../../models/forms/CreateEnvironmentAmiForm';
import { isStoreError, isStoreLoading, isStoreReloading } from '../../models/BaseStore';
import Form from '../helpers/fields/Form';
import { displayError } from '../../helpers/notification';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import Input from '../helpers/fields/Input';
import { withRouter } from '../../helpers/routing';
import { swallowError } from '../../helpers/utils';
import TextArea from '../helpers/fields/TextArea';
import CreateStudy from '../studies/CreateStudy';
import { studyCategories } from '../studies/StudiesPage';
import { StudiesStore } from '../../models/studies/StudiesStore';

// expected props
// - userStore (via injection)
// edit 2359 - add support for parallel cluster
class CreateEnvironmentFromAmiForm extends React.Component {
  studiesStores = {};
  outputStudies = [];
  outputStudySelected = '';
  outputStudyCreated = '';
  outputStudyTypeSelect = '';

  constructor(props) {
    super(props);
    // environmentSelect: observable,
    makeObservable(this, {
      handleFormSubmission: action,
      handleFormError: action,
      getStudiesStore: action,
      handleCreateStudy: action,
      handleOutputStudyTypeSelection: action,
      handleOutputStudySelect: action,
      renderOutputStudy: action,
      studiesStores: observable,
      outputStudies: observable,
      outputStudySelected: observable,
      outputStudyCreated: observable,
      outputStudyTypeSelect: observable,
    });

    this.currUser = this.props.userStore.user;
    this.state = {
      projectId: '',
    };

    runInAction(() => {
      // this.environment = {};
      // this.environmentSelect = '';
      this.instanceType = '';
      this.oldOutputStudySelected = '';
    });
    this.form = this.getForm();
    this.category = studyCategories.ORGANIZATION;
    this.getStudiesStore();
    this.getUserStudiesStore();
  }

  get getStore() {
    return this.props.environmentsStore;
  }

  get getUserStore() {
    return this.props.userStore;
  }

  get getConfigurationStore() {
    return this.props.environmentConfigurationsStore;
  }

  get getClientInformationStore() {
    return this.props.clientInformationStore;
  }

  getStudiesStore() {
    if (!this.studiesStores[this.category]) {
      this.studiesStores[this.category] = StudiesStore.create({ category: this.category });
    }
    this.currStudiesStore = this.studiesStores[this.category];
    swallowError(this.studiesStores[this.category].load());
  }

  getUserStudiesStore() {
    if (!this.userStudiesStore) {
      this.userStudiesStore = StudiesStore.create({ loadMethod: 'getMyStudies' });
    }
    swallowError(this.userStudiesStore.load());
  }

  get getSettingsStore() {
    return this.props.settingsStore;
  }

  isStoreError() {
    return isStoreError(this.getConfigurationStore);
  }

  isStoreLoading() {
    return isStoreLoading(this.getConfigurationStore) || isStoreReloading(this.getConfigurationStore);
  }

  /* isStoreReady() {
    return isStoreReady(this.getConfigurationStore);
  } */

  // edit 2359 - add support for parallel cluster
  componentDidMount() {
    swallowError(this.getConfigurationStore.load());
    swallowError(this.getClientInformationStore.load());
    swallowError(this.getSettingsStore.load());
    const store = this.getStore;
    const instanceType = store.getSelectedCompute();
    runInAction(() => {
      this.instanceType = instanceType;
    });
  }

  getForm = () => {
    const form = [];
    return getCreateEnvironmentAmiForm(form);
  };

  handleFormError = (_form, _errors) => {
    // We don't need to do anything here
  };

  // edit 2359 - add support for parallel cluster
  handleFormSubmission = async form => {
    const values = form.values();
    const store = this.getStore;
    const instanceType = store.getSelectedCompute();
    const config = {};
    if (instanceType === 'emr') {
      const environment = this.getConfigurationStore.getConfiguration(4);
      Object.assign(config, environment.emrConfiguration);
    }
    if (instanceType !== 'parallel-cluster') {
      delete values.maxCount;
      delete values.minCount;
    }
    if (instanceType !== 'ami-parallel-cluster') {
      delete values.amiOs;
    }
    const { files } = this.props;
    const fileMounts = files ? { files: _.map(this.props.files.toJSON().files, file => file.id) } : [];
    const outputFiles =
      this.outputStudyCreated !== ''
        ? _.filter(
            this.outputStudies.map(({ id }) => id),
            study => !_.isNull(study),
          )
        : this.outputStudies;
    const outputFileMounts = this.outputStudies.length ? { outputFiles } : {};

    let size;
    let type;
    switch (instanceType) {
      case 'ec2':
        size = 'r5.2xlarge';
        type = 'ami-ec2';
        break;
      case 'emr':
        size = 'm5.xlarge';
        type = 'ami-emr';
        break;
      case 'parallel-cluster':
        size = 'r5.2xlarge';
        type = 'ami-parallel-cluster';
        break;
      default:
        size = 'r5.2xlarge';
        type = 'ami-ec2';
        break;
    }

    Object.assign(values.instanceInfo, {
      config,
      ...fileMounts,
      ...outputFileMounts,
      size,
      type,
    });
    const indexId = this.props.projectsStore.getProject(this.state.projectId).indexId;
    try {
      await store.createEnvironmentFromAmi({
        ...values,
        projectId: this.state.projectId,
        indexId,
        amiId: store.getSelectedAmi().amiId,
      });
      form.clear();
      this.props.reset();
      this.props.onSuccess();
    } catch (error) {
      displayError(error);
    }
  };

  handleFormCancel = form => {
    form.clear();
    this.props.onCancel();
  };

  handleProjectIdSelection = (e, { value }) => this.setState({ projectId: value });

  handleCreateStudy = study => {
    this.outputStudies.push(study);
    this.outputStudyCreated = study.id;
  };

  // edit 2359 - add support for parallel cluster
  render() {
    const form = this.form;
    const name = form.$('name');
    const description = form.$('description');
    const cidr = form.$('instanceInfo.cidr');
    const maxCount = form.$('maxCount');
    const minCount = form.$('minCount');
    // eslint-disable-next-line no-shadow
    cidr.value = this.createCidr();

    const userStore = this.getUserStore;
    // TODO: Make maxPrice come from user role and make it admin configurable by role
    const projects = userStore.projectIdDropdown;

    if (this.isStoreError()) {
      const error = _.assign({}, this.getConfigurationStore.error);
      return <ErrorBox error={error} className="p0" />;
    }
    if (this.isStoreLoading()) {
      return <ProgressPlaceHolder />;
    }

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {/* eslint-disable-next-line no-unused-vars */}
        {({ processing, onSubmit, onCancel }) => (
          <>
            <Input field={name} disabled={processing} />
            <Input field={cidr} disabled={processing} />
            <Header className="mr3 mt0" as="h4" color="grey">
              Project ID
            </Header>
            <Dropdown
              options={projects}
              fluid
              placeholder="please select Project ID"
              selection
              onChange={this.handleProjectIdSelection}
            />
            <div className="mb4" />

            <Header className="mr3 mt0" as="h4" color="grey">
              Workspace output studies
            </Header>
            {this.renderOutputStudySelection()}

            {this.instanceType === 'parallel-cluster' ? (
              <>
                <div className="mb4" />
                <Input type="number" field={minCount} disabled={processing} />
                <div className="mb4" />
                <Input type="number" field={maxCount} disabled={processing} />
              </>
            ) : null}
            <div className="mb4" />

            <TextArea field={description} disabled={processing} />
            <div className="mt3">
              <Button floated="right" color="blue" icon disabled={processing} className="ml2" type="submit">
                Create Research Workspace
              </Button>
              <Button floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Form>
    );
  }

  createCidr() {
    const store = this.getClientInformationStore;
    if (_.isEmpty(store.ipAddress)) {
      return '';
    }
    return `${store.ipAddress}/32`;
  }

  /* handleCardSelection = (event, { value, processing }) => {
    const environment = this.getConfigurationStore.getConfiguration(value);
    const selectedSize = environment.size;
    if (processing !== 'false') return;
    runInAction(() => {
      this.environmentSelect = value;
    });
    const form = this.form;
    const size = form.$('instanceInfo.size');
    size.value = selectedSize;
    size.validate();
  }; */

  /* renderEnvironmentCard({ id, label, size, description, properties }, index, processing) {
    const environment = this.getConfigurationStore.getConfiguration(id);
    const disabledClass = processing ? 'disabled' : '';
    return (
      <Card key={index} onClick={this.handleCardSelection} value={id} processing={processing.toString()}>
        <Card.Content>
          <Card.Header className="center">{label}</Card.Header>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Table basic="very" size="small">
            <Table.Body>
              {properties.map((property, propertyIndex) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.Row key={propertyIndex} textAlign="center">
                    <Table.Cell>{property.key}</Table.Cell>
                    <Table.Cell>{property.value}</Table.Cell>
                  </Table.Row>
                );
              })}
              <Table.Row textAlign="center">
                <Table.Cell>{environment.isEmrCluster ? 'Maximum price per day' : 'Price per day'}</Table.Cell>
                <Table.Cell>
                  {environment.isLoadingPrice ? 'Loading...' : `$${environment.totalPrice.toFixed(2)}`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra className="center">
          <Radio
            className={disabledClass}
            key={index}
            checked={this.environmentSelect === id}
            value={size}
            name="size"
          />
        </Card.Content>
      </Card>
    );
  } */

  renderOutputStudySelection() {
    const listMyOutputStudies = this.userStudiesStore.list
      .filter(
        outputStudy =>
          outputStudy.isOutput &&
          !outputStudy.isSharing &&
          outputStudy.outputAvailable() &&
          this.userStudiesStore.isStudyOwner(outputStudy.id, this.currUser.username, this.currUser.ns),
      )
      .map(outputStudy => ({
        key: outputStudy.id,
        text: outputStudy.name,
        value: outputStudy.id,
      }));

    return (
      <Card.Group className="mb1" centered>
        <Card key="1" onClick={this.handleOutputStudyTypeSelection} value="new" processing="false">
          <Card.Content>
            <Card.Header className="center">Create New</Card.Header>
            <Card.Description>Create a new output study to hold your analysis output data</Card.Description>
          </Card.Content>
          <Card.Content extra>
            {this.outputStudyCreated === '' && (
              <CreateStudy
                category={this.category}
                studiesStore={this.currStudiesStore}
                buttonProps={{
                  size: 'mini',
                  compact: true,
                  color: 'blue',
                }}
                onCreate={this.handleCreateStudy}
                isSecure={false}
                disableSecure
                isOutputStudy
                settingsStore={this.getSettingsStore()}
              />
            )}
            {this.outputStudyCreated !== '' && <>{this.outputStudyCreated}</>}
          </Card.Content>
          <Card.Content extra className="center">
            <Radio key="1" checked={this.outputStudyTypeSelect === 'new'} value="custom" name="outputStudyType" />
          </Card.Content>
        </Card>
        <Card key="2" onClick={this.handleOutputStudyTypeSelection} value="existing" processing="false">
          <Card.Content>
            <Card.Header className="center">Use Existing</Card.Header>
            <Card.Description>
              Use an existing output study. The following conditions must be met:
              <ol>
                <li>Output study must not be in use in another workspace</li>
                <li>Study owner must not enable sharing for the output study</li>
              </ol>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Dropdown
              options={listMyOutputStudies}
              search
              selection
              clearable
              disabled={this.outputStudyTypeSelect !== 'existing'}
              onChange={(e, data) => this.handleOutputStudySelect(e, data)}
              value={this.outputStudySelected}
            />
          </Card.Content>
          <Card.Content extra className="center">
            <Radio
              key="2"
              checked={this.outputStudyTypeSelect === 'existing'}
              value="existing"
              name="outputStudyType"
            />
          </Card.Content>
        </Card>
      </Card.Group>
    );
  }

  handleOutputStudyTypeSelection = (event, { value, _processing }) => {
    runInAction(() => {
      this.outputStudyTypeSelect = value;
    });
  };

  handleOutputStudySelect(e, data) {
    e.preventDefault();
    e.stopPropagation();
    this.oldOutputStudySelected = this.outputStudySelected;
    this.outputStudySelected = data.value;
    this.outputStudies = _.remove(this.outputStudies, this.oldOutputStudySelected);
    this.outputStudies.push(this.outputStudySelected);
  }
}

export default inject(
  'environmentsStore',
  'environmentConfigurationsStore',
  'userStore',
  'projectsStore',
  'awsAccountsStore',
  'indexesStore',
  'clientInformationStore',
  'settingsStore',
)(withRouter(observer(CreateEnvironmentFromAmiForm)));
