/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { observer } from 'mobx-react';

// The tests prop takes an array of functions that may return a component.
// The tests are evaluated in order and the first component is returned.
// If no tests are truthy the fallback component is returned.
const ComponentSwitch = ({ tests, fallback = null }) =>
  tests.reduce((result, aTest) => result || aTest(), null) || fallback;

export default observer(ComponentSwitch);
