/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import FileSaver from 'file-saver';
import { displayError } from '../../helpers/notification';
import { getEditAccessForm } from '../../models/forms/EditAccessForm';
import { getOptionsFromRules } from '../../helpers/utils';
import Form from '../helpers/fields/Form';
import Toggle from '../helpers/fields/Toggle';
import DateField from '../helpers/fields/DateField';
import TextArea from '../helpers/fields/TextArea';
import Radio from '../helpers/fields/Radio';
import Header from '../helpers/fields/Header';
import StudyCollectionsCheckbox from '../studies/StudyCollectionsCheckbox';
import ByWithProfile from '../helpers/ByWithProfile';

class EditAccessRequest extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });
    this.accessRequest = props.accessRequest;
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      isProcessing: false,
    };
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleRevokePermission = async () => {
    this.setState({ isProcessing: true });
    const updateRequest = { ...this.accessRequest };
    await this.props.onRevokeAccess(this.props.studyId, updateRequest);
    this.setState({ isProcessing: false });
  };

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const requestValues = form.values();

      // don't allow empty collection list if permission type is collection
      if (requestValues.permissionType === 'collection' && requestValues.collectionIds.length === 0) {
        displayError('Please indicate collections for collection permission type!');
        return;
      }

      const updateRequest = { ...this.accessRequest, ...requestValues };
      if (requestValues.permissionType === 'study') {
        delete updateRequest.collectionIds;
      }
      if (requestValues.permissionType === 'collection') {
        updateRequest.isMetadata = false;
        updateRequest.isUpload = false;
      }
      await this.props.onUpdateAccess(this.props.studyId, updateRequest);
      form.clear();
      this.cleanModal();
    } catch (error) {
      // displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>
          <div className="mt2 animated fadeIn">
            <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
              Review Permissions
            </BaseHeader>
            <div className="mx3 animated fadeIn">{this.renderReviewAccessRequestForm()}</div>
            {!this.props.isRequest && (
              <Button onClick={this.handleRevokePermission} className="mb3" floated="right" color="red">
                Revoke
              </Button>
            )}
          </div>
        </Dimmer.Dimmable>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Update Permissions
      </Button>
    );
  }

  handleFileDownload = async event => {
    event.preventDefault();
    event.stopPropagation();

    // retrieve temporary link to download file
    const url = await this.props.accessRequest.downloadRequestFile(this.props.studyId, this.props.accessRequest.uuid);

    // redirect to download file link
    FileSaver.saveAs(url, this.props.accessRequest.requestFile.split('/').pop());
  };

  // edit chenjqp - remove isUpload and isMetadata for reviewing access request to secure studies
  renderReviewAccessRequestForm() {
    const form = getEditAccessForm();
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${`0${today.getDate()}`.slice(-2)}`;
    const maxDate = `${today.getFullYear() + 1}-${today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1}-${`0${today.getDate()}`.slice(-2)}`;
    form.$('isRead').value = this.accessRequest.isRead;
    form.$('isUpload').value = this.accessRequest.isUpload;
    form.$('isMetaData').value = this.accessRequest.isMetaData;
    form.$('expiry').min = formattedDate;
    form.$('expiry').max = maxDate;
    form.$('permissionType').value = this.accessRequest.permissionType
      ? this.accessRequest.permissionType
      : this.accessRequest.collectionIds
      ? 'collection'
      : 'study';
    form.$('collectionIds').value = this.accessRequest.collectionIds ? this.accessRequest.collectionIds : [];
    if (this.accessRequest.expiry) {
      form.$('expiry').value = this.accessRequest.expiry;
    }
    if (this.accessRequest.requestorComments) {
      form.$('requestorComments').value = this.accessRequest.requestorComments;
    } else {
      // for old requests that did not have requestor comments
      form.$('requestorComments').value = '-';
    }
    if (this.accessRequest.adminComments) form.$('adminComments').value = this.accessRequest.adminComments;
    if (this.accessRequest.uuid) form.$('uuid').value = this.accessRequest.uuid;
    if (this.accessRequest.requestFile) form.$('requestFile').value = this.accessRequest.requestFile;

    form.$('requestorComments').set('rules', 'required');
    let permissionTypes = getOptionsFromRules(form.$('permissionType').rules);
    if (this.props.collections.length === 0) {
      permissionTypes = permissionTypes.filter(type => type.value !== 'collection');
    }

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onAlternate={this.handleRevokePermission}
        onError={this.handleFormError}
      >
        {({ processing, onSubmit, onCancel }) => {
          return (
            <>
              <Header field={{id: 'study', label:'Study'}}/>
			  <>{this.props.studyId}</>
              <Header field={{id: 'requestor', label:'Requestor'}}/>
			  <ByWithProfile user={this.props.usersStore.asUserObjectByNsUsername(this.accessRequest.principalIdentifier.ns, this.accessRequest.principalIdentifier.username)}/>
              <hr />
			  
			  <DateField field={form.$('expiry')} />
			  <Toggle field={form.$('isRead')} disabled />
			  {!this.props.isSecure && form.$('permissionType').value === 'study' && (
				<Toggle field={form.$('isUpload')} />
			  )}
			  {!this.props.isSecure && form.$('permissionType').value === 'study' && (
				<Toggle field={form.$('isMetaData')} />
			  )}
			  <Radio radio field={form.$('permissionType')} options={permissionTypes} />
			  {form.$('permissionType').value === 'collection' && this.props.collections.length > 0 && (
				<StudyCollectionsCheckbox
				  field={form.$('collectionIds')}
				  options={this.props.collections.map(collection => ({
					key: collection.collectionId,
					text: collection.collectionName,
					value: collection.collectionId,
					description: collection.description,
				  }))}
				/>
			  )}
			  <TextArea field={form.$('requestorComments')} disabled />
			  {this.accessRequest.requestFile && (
				<div className="mb4">
				  <Button type="button" onClick={this.handleFileDownload}>
					Download attached file
				  </Button>
				  <br />
				</div>
			  )}
              <TextArea field={form.$('adminComments')} disabled/>
              <Button
                onClick={onSubmit}
                className="ml2 mb3"
                floated="right"
                color="blue"
                icon
                disabled={processing}
                type="submit"
              >
                Update Permissions
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore', 'usersStore')(observer(EditAccessRequest));
