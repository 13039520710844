/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Container, Label, Button } from 'semantic-ui-react';
import { gotoFn, withRouter } from '../../helpers/routing';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import { swallowError } from '../../helpers/utils';
import AmiCard from './AmiCard';
import AmiSelectModal from './AmiSelectModal';

// expected props
// - environmentsStore (via injection)
// - location (from react router)
class AmisList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
		onDisable: action,
		onDelete: action
	});

    this.state = {
      selected: null,
      selectedAmi: null,
      list: [],
      isEmpty: false,
    };
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
    store.startHeartbeat();
    this.updateAmis();
    const envConfigStore = this.props.environmentConfigurationsStore;
    envConfigStore.load();
  }

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  async updateAmis() {
    const store = this.getStore();
    let list = store.amilist;
    if (!list.length) {
      list = await store.getAmis();
    }
    this.setState({ list, isEmpty: list.length === 0 });
  }

  getStore() {
    return this.props.environmentsStore;
  }

  updatePermissions = async (amiId, permissions) => {
    const store = this.getStore();
    await store.updateAmiPermissions(amiId, permissions);
  };

  handleCreateEnvironment = type => {
    const store = this.getStore();
    store.setSelectedAmi(this.state.selectedAmi);
    store.setComputeType(type);
    const goto = gotoFn(this);
    goto(`/amis/wizard`);
  };

  render() {
    const { list, isEmpty } = this.state;

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderTitle()}
        {list.length === 0 && !isEmpty ? <ProgressPlaceHolder segmentCount={3} /> : this.renderMain(list)}
      </Container>
    );
  }

  /* renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No AMIs
        </Header>
      </Segment>
    );
  } */

  renderTitle() {
    const isAmin = this.props.userStore.user.isAdmin;
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="server" className="align-top" />
          <Header.Content className="left-align">AMIs {this.renderTotal()}</Header.Content>
          {!isAmin ? (
            <div className="h4 regular">In order to share your AMIs, please contact the administrator.</div>
          ) : (
            ''
          )}
        </Header>
        <AmiSelectModal
          isEmrCompatible={
            !!(
              this.state.selectedAmi &&
              this.state.selectedAmi.emrCompatible &&
              this.state.selectedAmi.emrCompatible === 'true'
            )
          }
          isDisabled={this.state.selected === null}
          onSelectComputeType={this.handleCreateEnvironment}
        />
        {isAmin && (
          <Button color="blue" size="medium" basic onClick={this.handleImportAMI}>
            Import AMI
          </Button>
        )}
      </div>
    );
  }

  renderTotal() {
    const store = this.getStore();
    const list = store.amilist && store.amilist.length ? store.amilist : this.state.list;
    if (!list.length) return null;

    return <Label circular>{list.length}</Label>;
  }

  // onDisable
  // check that the disabled AMI is not selected
  // deselect if it was
  onDisable = action((amiId) =>{
	  if(this.state.selected === amiId){
		  this.setState({selected:  null, selectedAmi: null});
	  }
  })
  
  // onDelete
  // check that the deleted AMI is not selected
  // deselect if it was
  onDelete = action((amiId) =>{
	  if(this.state.selected === amiId){
		  this.setState({selected:  null, selectedAmi: null});
	  }
  })

  // onPublish
  // check that the published AMI is not selected
  // deselect if it was
  onPublish = action((amiId) =>{
          if(this.state.selected === amiId){
                  this.setState({selected:  null, selectedAmi: null});
          }
  })

  
  renderMain(list) {
    return this.state.isEmpty ? (
      'You do not have any AMIs yet.'
    ) : (
      <div>
        {_.map(list, item => (
          <Segment className="p3 mb2  cursor-pointer" clearing key={item.id} data-instance={item.id}>
            <AmiCard
              ami={item}
              onSelect={() => this.setState({ selected: item.amiId, selectedAmi: item })}
              selected={this.state.selected}
              updateAmiPermissions={this.updatePermissions}
              isAdmin={this.props.userStore.user.isAdmin}
			  onDisable={this.onDisable}
			  onDelete={this.onDelete}
		          onPublish={this.onPublish}
            />
          </Segment>
        ))}
      </div>
    );
  }

  handleImportAMI = () => {
    const goto = gotoFn(this);
    goto(`/amis/import`);
  };
}

export default inject(
  'environmentsStore',
  'environmentConfigurationsStore',
  'userStore',
)(withRouter(observer(AmisList)));
