/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { Button, Dimmer, Header, List, Loader, Dropdown, Segment } from 'semantic-ui-react';
import _ from 'lodash';

import { getImportAMIFormFields } from '../../models/forms/ImportAMIForm';
import { displayError } from '../../helpers/notification';
import { withRouter } from '../../helpers/routing';
import validate from '../../models/forms/Validate';

const amiTypeOption = [
  {
    text: 'Another Region',
    value: 'anotherRegion',
  },
  {
    text: 'Community',
    value: 'community',
  },
];

const defaultAMITypeOption = amiTypeOption[0];
class ImportAMI extends React.Component {
  formProcessing = false;
  validationErrors = new Map();
  ami = {};

  constructor(props) {
    super(props);
    makeObservable(this, {
      formProcessing: observable,
      ami: observable,
      validationErrors: observable,
    });

    this.state = {
      amiType: defaultAMITypeOption.value,
      isEmrCompatible: false,
      isPclusterCompatible: false,
    };

    // this.form = getImportAMIForm();
    this.importAMIFormFields = getImportAMIFormFields();
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  render() {
    return (
      <div className="mt2 animated fadeIn">
        <Header as="h2" icon textAlign="center" className="mt3" color="grey">
          Import AMI
        </Header>
        <div className="mt3 ml3 mr3 animated fadeIn">{this.renderImportAMIForm()}</div>
      </div>
    );
  }

  // eslint-disable-next-line react/no-unused-state
  // handleRoleChange = (e, { value }) => this.setState({ role: value });

  // eslint-disable-next-line react/no-unused-state
  // handleStatusChange = (e, { value }) => this.setState({ status: value });

  handleAMIType = (e, { value }) => {
    this.setState({
      amiType: value,
    });
  };

  renderAMITypeSelection() {
    return (
      <Dropdown
        options={amiTypeOption}
        fluid
        selection
        defaultValue={defaultAMITypeOption.value}
        onChange={this.handleAMIType}
      />
    );
  }

  renderImportAMIForm() {
    const processing = this.formProcessing;
    const fields = this.importAMIFormFields;
    const toEditableInput = (attributeName, type = 'text') => {
      const handleChange = action(event => {
        event.preventDefault();
        this.ami[attributeName] = event.target.value;
      });

      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.ami[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
          />
        </div>
      );
    };

    const emrCompatibleOnChange = action(event => {
      event.preventDefault();
      if (event.target.checked === true) {
        this.setState({ isEmrCompatible: true, isPclusterCompatible: false });
      } else {
        this.setState({ isEmrCompatible: false });
      }
    });

    const pcCompatibleOnChange = action(event => {
      event.preventDefault();
      if (event.target.checked === true) {
        this.setState({ isEmrCompatible: false, isPclusterCompatible: true });
      } else {
        this.setState({ isPclusterCompatible: false });
      }
    });

    return (
      <Segment basic className="ui fluid form">
        <Dimmer active={processing} inverted>
          <Loader inverted>Checking</Loader>
        </Dimmer>
        {this.renderField('amiType')}
        {this.renderAMITypeSelection()}
        <div className="mb4" />
        {this.renderField('imageId', toEditableInput('imageId'))}
        <div className="mb4" />
        {this.state.amiType === defaultAMITypeOption.value ? (
          <>
            {this.renderField('region', toEditableInput('region'))}
            <div className="mb4" />
          </>
        ) : null}
        {this.renderField('name', toEditableInput('name'))}
        <div className="mb4" />
        {this.renderField('description', toEditableInput('description'))}
        <div className="mb4" />
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="isEmrCompatible"
            checked={this.state.isEmrCompatible}
            onChange={e => emrCompatibleOnChange(e)}
          />
          <label>EMR Compatible</label>
        </div>
        <div className="mb4" />
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="isPclusterCompatible"
            checked={this.state.isPclusterCompatible}
            onChange={e => pcCompatibleOnChange(e)}
          />
          <label>Parallel Cluster Compatible</label>
        </div>
        {this.renderButtons()}
      </Segment>
    );
  }

  renderButtons() {
    const processing = this.formProcessing;
    return (
      <div className="mt3">
        <Button floated="right" color="blue" icon disabled={processing} className="ml2" onClick={this.handleSubmit}>
          Import
        </Button>
        <Button floated="right" disabled={processing} onClick={this.handleCancel}>
          Cancel
        </Button>
      </div>
    );
  }

  renderField(name, component) {
    const fields = this.importAMIFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    const fieldErrors = this.validationErrors.get(name);
    const hasError = !_.isEmpty(fieldErrors);

    return (
      <div>
        <Header className="mr3 mt0" as="h2" color="grey">
          {label}
        </Header>
        {hasExplain && <div className="mb2">{explain}</div>}
        <div className={`ui big field input block m0 ${hasError ? 'error' : ''}`}>{component}</div>
        {hasError && (
          <div className="ui pointing red basic label">
            <List>
              {_.map(fieldErrors, fieldError => (
                <List.Item key={name}>
                  <List.Content>{fieldError}</List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }

  handleCancel = action(event => {
    event.preventDefault();
    event.stopPropagation();
    this.formProcessing = false;
    this.setState({ isEmrCompatible: false, isPclusterCompatible: false });
    this.goto('/amis');
  });

  handleSubmit = action(async () => {
    this.formProcessing = true;
    try {
      // Perform client side validations first
      this.ami.amiType = this.state.amiType;
      this.ami.emrCompatible = this.state.isEmrCompatible;
      this.ami.pclusterCompatible = this.state.isPclusterCompatible;
      const validationResult = await validate(this.ami, this.importAMIFormFields);
      // if there are any client side validation errors then do not attempt to make API call
      if (validationResult.fails()) {
        runInAction(() => {
          this.validationErrors = validationResult.errors;
          this.formProcessing = false;
        });
      } else {
        // There are no client side validation errors so ask the store to add user (which will make API call to server to add the user)
        await this.props.environmentsStore.importAmi(this.ami);
        runInAction(() => {
          this.formProcessing = false;
        });
        this.goto('/amis');
      }
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
  });

  /* getStore() {
    return this.props.usersStore;
  } */
}

export default inject('usersStore', 'environmentsStore')(withRouter(observer(ImportAMI)));
