/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { getEnv, types } from 'mobx-state-tree';

import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';
import initializeAmplify, { cookieStorage as storage } from '../../init-amplify';
import { getFragmentParam, removeFragmentParams } from '../../helpers/utils';
import { setIdToken } from '../../helpers/api';

import localStorageKeys from '../constants/local-storage-keys';
import { boom } from '../../helpers/errors';
//import initializeAmplify from '../../init-amplify';
import { awsRegion } from '../../helpers/settings';

const isTokenExpired = token => {
  const now = Date.now();
  const decoded = jwtDecode(token);
  const expiresAt = _.get(decoded, 'exp', 0) * 1000;
  return expiresAt < now;
};

function removeTokensFromUrl() {
  const newUrl = removeFragmentParams(document.location, [
    'id_token',
    'access_token',
    'token_type',
    'expires_in',
    'state',
  ]);
  window.history.replaceState({}, document.title, newUrl);
}

async function getIdTokenFromAmplify() {
  try {
    // The Auth.currentSession() line below throws "No current user" error when user has not signed in yet
    // Also the Auth.currentSession() works only in case of direct Cognito User Pool auth.
    // It throws exception in case of federated login via SAML or other social IdPs
    const currentSession = await Auth.currentSession();
    return _.get(currentSession, 'idToken.jwtToken');
  } catch (e) {
    return console.error(e);
  }
}

// ==================================================================
// Login model
// ==================================================================
const Authentication = types
  .model('Authentication', {
    processing: false,
    selectedAuthenticationProviderId: '',
  })
  .actions(self => ({
    runInAction(fn) {
      return fn();
    },

    // this method is called by the Cleaner
    cleanup() {
      if (self.selectedAuthenticationProvider) {
        // give selected authentication provider a chance to do its own cleanup
        self.selectedAuthenticationProvider.cleanup();
      }
      self.clearTokens();
    },

    clearTokens() {
      _.forEach(localStorageKeys, keyValue => storage.removeItem(keyValue));
    },

    setSelectedAuthenticationProviderId(authenticationProviderId) {
      self.selectedAuthenticationProviderId = authenticationProviderId;

      if (self.isCognitoUserPool) {
        // If the Cognito User Pool authentication provider is selected then initialize amplify library for using it with Cognito
        initializeAmplify({
          awsRegion,
          userPoolId: self.selectedAuthenticationProvider.userPoolId,
          userPoolWebClientId: self.selectedAuthenticationProvider.clientId,
        });
      }
    },

    async getActiveIdToken() {
      // The id token would be in URL in case of SAML redirection.
      // The name of the token param is "id_token" in that case (instead of "appIdToken"), if the token is
      // issued by Cognito.
      // Also the id_token is returned via URL fragment i.e, with # instead of query param something like
      // https://web.site.url/#id_token=blabla instead of
      // https://web.site.url?idToken=blabla
      // TODO: Make the retrieval of id token from query string param or fragment param (or any other mechanism)
      // dynamic based on the authentication provider. Without that, the following code will only work for
      // any auth providers that set id token either in local storage as "appIdToken" or deliver to us
      // via URL fragment parameter as "id_token".
      // This code will NOT work for auth providers issuing id token and delivering via any other mechanism.
      let idToken = getFragmentParam(document.location, 'id_token');
      if (idToken) {
        await self.saveIdToken(idToken);
        removeTokensFromUrl(); // we remove the idToken from the url for a good security measure
      }

      if (!idToken && self.isCognitoUserPool) {
        // attempt to get token from amplify only if the selected authentication provider is cognito
        idToken = await getIdTokenFromAmplify();
      }

      if (!idToken) {
        idToken = storage.getItem(localStorageKeys.appIdToken);
      }

      let activeIdToken;
      if (idToken && !isTokenExpired(idToken)) {
        activeIdToken = idToken;
      }
      return activeIdToken;
    },

    async getActiveIdTokenAndDecodedToken() {
      const idToken = await self.getActiveIdToken();
      const decodedIdToken = idToken && jwtDecode(idToken);
      return {
        idToken,
        decodedIdToken,
      };
    },

    async saveIdToken(idToken) {
      storage.setItem(localStorageKeys.appIdToken, idToken);
      const decodedIdToken = idToken && jwtDecode(idToken);
      setIdToken(idToken, decodedIdToken);
    },

    async login({ username, password }) {
      if (self.shouldCollectUserNamePassword) {
        const result = await self.selectedAuthenticationProvider.login({
          username,
          password,
          authenticationProviderId: self.selectedAuthenticationProviderId,
        });
        const { idToken } = result || {};
        if (_.isEmpty(idToken)) {
          throw boom.incorrectImplementation(
            `There is a problem with the implementation of the server side code. The id token is not returned.`,
          );
        }

        await self.saveIdToken(idToken);

        const app = getEnv(self).app;
        await app.start();
      } else {
        // If we do no need to collect credentials from the user then just call login method of the selected authentication provider without any arguments
        // The selected auth provider will then take care of rest of the login flow (such as redirecting to other identity provider etc)
        await self.selectedAuthenticationProvider.login();
      }
    },
    async logout() {
      if (self.isCognitoUserPool) {
        // If the Cognito User Pool authentication provider is selected then call amplify's Logout to logout from Cognito as well
        await Auth.signOut({ global: true }).catch(_err => {
          Auth.signOut();
        });
      }
      const cleaner = getEnv(self).cleaner;
      return cleaner.cleanup();
    },
  }))
  .views(self => ({
    get isCognitoUserPool() {
      return self.selectedAuthenticationProvider.type === 'cognito_user_pool';
    },
    get selectedAuthenticationProvider() {
      const authenticationProviderPublicConfigsStore = getEnv(self).authenticationProviderPublicConfigsStore;
      return authenticationProviderPublicConfigsStore.toAuthenticationProviderFromId(
        self.selectedAuthenticationProviderId,
      );
    },

    get shouldCollectUserNamePassword() {
      const selectedAuthenticationProvider = self.selectedAuthenticationProvider;
      return selectedAuthenticationProvider && selectedAuthenticationProvider.credentialHandlingType === 'submit';
    },
  }));

function registerModels(globals) {
  globals.authentication = Authentication.create({}, globals);
}

export { Authentication, registerModels };
