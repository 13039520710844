import React from 'react';
import { observer, inject } from 'mobx-react';
import { Checkbox, Label } from 'semantic-ui-react';

const DataCollectionPane = inject('filesSelection')(
  observer(({ study, handlePrefixSelection, getStudyCollections, filesSelection }) => {
    const [collections, setCollections] = React.useState([]);

    React.useEffect(() => {
      const fetchCollection = async () => {
        try {
          const result = await getStudyCollections(study.id);
          setCollections(result.collections);
        } catch (err) {
          console.log(err);
        }
      };
      fetchCollection();
    }, []);

    const handleCollectionSelection = collection => {
      handlePrefixSelection(study, collection);
    };

    return (
      <>
        {collections.length > 0 ? (
          collections.map(prefix => (
            <Label>
              <Checkbox
                className="mr1"
                onChange={() => handleCollectionSelection(prefix.collectionId)}
                checked={filesSelection.hasPrefix(study.id, prefix.collectionId)}
              />
              {prefix.collectionName}
            </Label>
          ))
        ) : (
          <div>No collection found.</div>
        )}
      </>
    );

    /*
    return (
      <Segment>
        <Header as="h3">Collection list</Header>
        <div className="nowrap max-height-300-px overflow-auto">
          <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Collection Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {collections.length > 0 ? (
                collections.map(prefix => (
                  <Table.Row key={prefix.collectionId}>
                    <Table.Cell collapsing>
                      <Checkbox
                        className="mr1"
                        onChange={() => handleCollectionSelection(prefix.collectionId)}
                        checked={filesSelection.hasPrefix(study.id, prefix.collectionId)}
                      />
                    </Table.Cell>
                    <Table.Cell>{prefix.collectionName}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <div>No collection found.</div>
              )}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    );
*/
  }),
);

export default DataCollectionPane;
