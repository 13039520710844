/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, action, computed } from 'mobx';
import { Segment, Icon, Divider, Header } from 'semantic-ui-react';

import ConfigTable from '../../../configuration/ConfigTable';
import ConfigurationEditor from '../../../configuration/ConfigurationEditor';
import ConfigurationReview from '../../../configuration/ConfigurationReview';

// expected props
// - stepEditor - a WorkflowStepEditor or a WorkflowTemplateStepEditor model instance (via props)
// - onSave - called when the configuration is saved (via props)
// - className (via props)
class WorkflowCommonStepConfigEditor extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      editing: computed,
      handleEditOn: action,
      handleEditOff: action,
      handleSave: action,
    });
  }

  getStepEditor() {
    return this.props.stepEditor;
  }

  getStep() {
    return this.getStepEditor().step;
  }

  get editing() {
    return this.getStepEditor().configEdit;
  }

  handleEditOn = event => {
    event.preventDefault();
    event.stopPropagation();

    const stepEditorModel = this.getStepEditor();
    stepEditorModel.setConfigEdit(true);
  };

  handleEditOff = () => {
    const stepEditorModel = this.getStepEditor();
    stepEditorModel.setConfigEdit(false);
  };

  handleSave = async configs => {
    const onSave = this.props.onSave || _.noop;
    const stepEditorModel = this.getStepEditor();

    await onSave(configs);
    stepEditorModel.setConfigEdit(false);
  };

  render() {
    const editing = this.editing;
    const step = this.getStep();
    const configRows = step.configSummaryRows || [];
    const hasConfigRows = configRows.length > 0;
    const canEdit = !editing && hasConfigRows;

    return (
      <div className={this.props.className}>
        {!editing && (
          <div className="flex animated fadeIn">
            <div className="flex-auto">
              <Icon name="cog" className="mr1 color-grey" />
              <b>Configuration</b>
            </div>
            {canEdit && (
              <div className="pl1 pr0" onClick={this.handleEditOn}>
                <Icon name="edit" color="grey" className="cursor-pointer" />
              </div>
            )}
          </div>
        )}
        <Divider className="mt1 mb2" />
        {editing && this.renderConfigEditingContent()}
        {!editing && this.renderConfigContent()}
      </div>
    );
  }

  renderConfigContent() {
    const step = this.getStep();
    const configRows = step.configSummaryRows || [];
    const hasConfigRows = configRows.length > 0;

    return (
      <>
        {hasConfigRows && (
          <Segment padded className="animated fadeIn">
            <ConfigTable rows={configRows} />
          </Segment>
        )}
        {!hasConfigRows && <div>No configuration entries are available</div>}
      </>
    );
  }

  renderConfigEditingContent() {
    const model = this.getStepEditor().configurationEditor;
    const review = model.review;

    if (review) {
      return (
        <>
          <Header textAlign="center" as="h2" color="grey" className="mt1 mb3">
            Review Configuration Changes
          </Header>
          <ConfigurationReview model={model} onCancel={this.handleEditOff} onSave={this.handleSave} dimmer={false} />
        </>
      );
    }

    return (
      <>
        <Header textAlign="center" as="h2" color="grey" className="mt1 mb3">
          Change Configuration
        </Header>
        <ConfigurationEditor model={model} onCancel={this.handleEditOff} />
      </>
    );
  }
}

export default inject()(observer(WorkflowCommonStepConfigEditor));
