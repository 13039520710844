import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { displayError, displaySuccess } from '../../helpers/notification';
import { getEditEnvironmentSecurityForm } from '../../models/forms/EditEnvironmentSecurityForm';
import { getEditSecureEnvironmentSecurityForm } from '../../models/forms/EditSecureEnvironmentSecurityForm';

import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';

// expected props
// - environmentsStore
// - environment
class EditEnvironmentSecurityForm extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      await this.props.environmentsStore.updateSecurity(this.props.environment.id, form.values());
      displaySuccess('Security settings updated successfully.', 'Success');

      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Edit Security Settings
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderEditForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    return (
      <Button
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Edit
      </Button>
    );
  }

  renderEditForm() {
    const form = this.props.environment.isSecure
      ? getEditSecureEnvironmentSecurityForm({
          cidr: { value: this.props.environment.instanceInfo.cidr },
        })
      : getEditEnvironmentSecurityForm({
          cidr: { value: this.props.environment.instanceInfo.cidr },
        });

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('cidr')} />

              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Update
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default observer(EditEnvironmentSecurityForm);
