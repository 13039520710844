/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// edited by 2359 - added handling of public study and different grant permissions
import React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Dimmer, Dropdown, Loader, Icon, Table } from 'semantic-ui-react';

import { displayError, displaySuccess } from '../../helpers/notification';
import { swallowError } from '../../helpers/utils';
import SimpleTable from '../helpers/SimpleTable';
import { getIdentifierObjFromId } from '../../models/users/User';
import { isStoreError, isStoreLoading, isStoreNew } from '../../models/BaseStore';
import StudyOwnerTable from './StudyOwnerTable'; // added by leeas
import EditAccessRequest from '../access-requests/EditAccessRequest';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import Toggle from '../helpers/fields/Toggle';
import ErrorBox from '../helpers/ErrorBox';
import UserLabels from '../helpers/UserLabels';

// expected props
// - study
// - userStore (via injection)
// - usersStore (via injection)
class StudyPermissionsTable extends React.Component {
  editModeOn = false;
  isProcessing = false;
  selectedUserIds = {};

  constructor(props) {
    super(props);
    makeObservable(this, {
      editModeOn: observable,
      isProcessing: observable,
      selectedUserIds: observable,

      enableEditMode: action,
      resetForm: action,
    });

    this.permissionsStore = props.study.getPermissionsStore();
    this.study = props.study;
    this.currUser = props.userStore.user;
    this.usersStore = props.usersStore;
    // this.permissions = [];
    this.state = {
      isPublic: '',
    };
    this.resetForm();
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    this.permissionsStore.startHeartbeat();
    this.props.study.getDataCollections();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  enableEditMode = () => {
    // Set users who currently have permission to the study as the selected users
    this.permissionsStore.studyPermissions.userTypes.forEach(userType => {
      this.selectedUserIds[userType] = this.permissionsStore.studyPermissions[`${userType}Users`].map(user => user.id);
    });
    this.setState({ isPublic: this.permissionsStore.studyPermissions.isPublic });
    // Show edit dropdowns via observable
    this.editModeOn = true;
  };

  resetForm = () => {
    this.editModeOn = false;
    this.isProcessing = false;
    this.selectedUserIds = {};
  };

  handleUpdatePermissions = async (studyId, permissions) => {
    runInAction(() => {
      this.isProcessing = true;
    });
    permissions.id = this.props.study.id;
    // Perform update
    try {
      await this.permissionsStore.updatePermissions(permissions);
      displaySuccess('Permissions updated successfully.', 'Success');
      this.resetForm();
    } catch (error) {
      displayError('Update Failed', error);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  };

  handleRevokePermissions = async (studyId, permissions) => {
    runInAction(() => {
      this.isProcessing = true;
    });
    permissions.id = this.props.study.id;
    // Perform update
    try {
      await this.permissionsStore.revokePermissions(permissions);
      displaySuccess('Update Succeeded');
      this.resetForm();
    } catch (error) {
      displayError('Update Failed', error);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  };

  submitUpdate = async () => {
    runInAction(() => {
      this.isProcessing = true;
    });

    // Convert user ID strings back into user objects
    const selectedUsers = {};
    this.permissionsStore.studyPermissions.userTypes.forEach(type => {
      selectedUsers[type] = this.selectedUserIds[type].map(getIdentifierObjFromId);
    });

    // Perform update
    try {
      await this.permissionsStore.update({}, this.state.isPublic.toString(), selectedUsers);
      displaySuccess('Update Succeeded');
      this.resetForm();
    } catch (error) {
      displayError('Update Failed', error);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  };

  handleUpdatePublic = val => {
    this.setState({ isPublic: val });
  };

  renderUsersDropdown(userType) {
    const dropdownOnChange = action((_event, data) => {
      this.selectedUserIds[userType] = data.value;
    });

    return (
      <Dropdown
        selection
        fluid
        multiple
        search
        options={this.usersStore.asDropDownOptions()}
        value={this.selectedUserIds[userType]}
        placeholder="Select users"
        onChange={dropdownOnChange}
      />
    );
  }

  render() {
    // Render loading, error, or permissions table
    let content;
    if (isStoreError(this.permissionsStore)) {
      content = <ErrorBox error={this.permissionsStore.error} />;
    } else if (isStoreLoading(this.permissionsStore) || isStoreNew(this.permissionsStore)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    const studyPermissions = this.permissionsStore.studyPermissions;
    const isEditable =
      studyPermissions.adminUsers.some(
        adminUser => adminUser.ns === this.currUser.ns && adminUser.username === this.currUser.username,
      ) && !this.props.study.deleted;
    const userPermissions = studyPermissions.permissions ? studyPermissions.permissions.slice() : [];
    return (
      <Dimmer.Dimmable dimmed={this.isProcessing}>
        <Dimmer active={this.isProcessing} inverted>
          <Loader size="big" />
        </Dimmer>

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Permission Level</Table.HeaderCell>
              <Table.HeaderCell>
                Users
                {isEditable && !this.editModeOn && (
                  <Icon name="pencil" className="ml1 cursor-pointer" color="grey" onClick={this.enableEditMode} />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!this.props.study.isSecure && (
              <Table.Row key="isPublic">
                <Table.Cell style={{ textTransform: 'capitalize' }}>Make Public</Table.Cell>
                {!this.editModeOn && (
                  <Table.Cell style={{ textTransform: 'capitalize' }}>{studyPermissions.isPublic}</Table.Cell>
                )}
                {this.editModeOn && (
                  <Table.Cell>
                    <Toggle
                      field={{
                        value: this.state.isPublic,
                        sync: this.handleUpdatePublic,
                        validate: () => {},
                      }}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            )}
            {this.permissionsStore.studyPermissions.userTypes.map(userType =>
              userType === 'admin' ? (
                <Table.Row key={userType}>
                  <Table.Cell style={{ textTransform: 'capitalize' }}>{userType}</Table.Cell>
                  <Table.Cell>
                    {this.editModeOn ? (
                      this.renderUsersDropdown(userType)
                    ) : (
                      <UserLabels users={this.usersStore.asUserObjects(studyPermissions[`${userType}Users`])} />
                    )}
                  </Table.Cell>
                </Table.Row>
              ) : null,
            )}
          </Table.Body>
        </Table>
        {this.editModeOn && (
          <>
            <Button
              floated="right"
              disabled={this.isProcessing}
              onClick={this.submitUpdate}
              size="mini"
              color="blue"
              icon
            >
              Submit
            </Button>

            <Button floated="right" disabled={this.isProcessing} onClick={this.resetForm} size="mini">
              Cancel
            </Button>
          </>
        )}
        {userPermissions.length > 0 && (
          <SimpleTable
            headerRenderer={() => {
              return ['User', 'Read', 'Upload File', 'Edit Metadata', 'Expiry', ''];
            }}
            rowRenderer={permit => {
              return [
                permit.principalIdentifier.username,
                permit.isRead ? 'Yes' : 'No',
                permit.isUpload ? 'Yes' : 'No',
                permit.isMetaData ? 'Yes' : 'No',
                permit.expiry || 'None',
                this.renderReviewRequest(permit),
              ];
            }}
            rowData={userPermissions}
            keyMethod={request => request.username}
          />
        )}
        <StudyOwnerTable study={this.study} permissionsStore={this.permissionsStore} />
      </Dimmer.Dimmable>
    );
  }

  renderReviewRequest(request) {
    return (
      <EditAccessRequest
        accessRequest={request}
        onUpdateAccess={this.handleUpdatePermissions}
        studyId={this.props.study.id}
        onRevokeAccess={this.handleRevokePermissions}
        isSecure={this.props.study.isSecure}
        collections={this.props.study.collections}
      />
    );
  }
}

export default inject('userStore', 'usersStore')(observer(StudyPermissionsTable));
