/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types, applySnapshot } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';
import { uiEventBus } from '../SessionStore';
import { InstanceInfo } from './InstanceInfo';
import {
  getEnvironmentKeypair,
  getEnvironmentNotebookUrl,
  getEnvironmentPasswordData,
  getEnvironmentBastionPasswordData,
  getEnvironmentBastionKeypair,
  getEnvironmentEmrPasswordData,
} from '../../helpers/api';
import { EnvironmentOutputFilesStore } from './EnvironmentOutputFilesStore';

// ==================================================================
// Environment
// ==================================================================
const serviceCost = types.model({
  amount: types.number,
  unit: types.string,
});

const environmentCost = types.model({
  startDate: types.string,
  cost: types.map(serviceCost),
});

// edited by 2359 - added support for AMI and ability to start or stop the env
const Environment = types
  .model('Environment', {
    id: types.identifier,
    rev: types.maybe(types.number),
    description: '',
    amiIds: types.maybeNull(types.array(types.string)),
    createdFromAmi: types.maybeNull(types.string),
    instanceInfo: types.optional(InstanceInfo, {}),
    name: '',
    status: '',
    indexId: '',
    projectId: '',
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
    costs: types.optional(types.array(environmentCost), []),
    fetchingUrl: types.optional(types.boolean, false),
    error: types.maybeNull(types.string),
    isSecure: types.optional(types.boolean, false),
    filesStore: types.maybe(EnvironmentOutputFilesStore),
  })
  .actions(self => ({
    setEnvironment(rawEnvironment) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic

      // Preserve the value of the fetchingUrl
      const fetchingUrl = self.fetchingUrl;
      applySnapshot(self, rawEnvironment);
      self.fetchingUrl = fetchingUrl;
    },

    async getEnvironmentNotebookUrl() {
      self.setFetchingUrl(true);
      return getEnvironmentNotebookUrl(self.id);
    },

    setFetchingUrl(value) {
      self.fetchingUrl = value;
    },

    markAsTerminating() {
      self.status = 'TERMINATING';
    },

    markAsStopping() {
      self.status = 'STOPPING';
    },

    markAsStarting() {
      self.status = 'STARTING';
    },

    async getKeyPair() {
      return getEnvironmentKeypair(self.id, `${self.id}.pem`);
    },

    async getWindowsPassword() {
      const [passwordData] = await Promise.all([self.getKeyPair(), getEnvironmentPasswordData(self.id)]);
      return passwordData;
    },

    async getBastionKeypair() {
      return getEnvironmentBastionKeypair(self.id);
    },

    async getBastionPassword() {
      const { passwordData } = await getEnvironmentBastionPasswordData(self.id);
      return passwordData;
    },

    // add chenjqp - add EMR login credentials
    async getEmrPassword() {
      const { passwordData } = await getEnvironmentEmrPasswordData(self.id);
      return passwordData;
    },

    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = EnvironmentOutputFilesStore.create({ environmentId: self.id });
      }
      return self.filesStore;
    },
  }))

  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // add view methods here
    get isTerminated() {
      return _.includes(['TERMINATING', 'TERMINATED', 'TERMINATING_FAILED'], this.status);
    },

    get isCompleted() {
      return _.includes(['COMPLETED'], this.status);
    },

    get isStopped() {
      return _.includes(['STOPPED'], this.status);
    },

    get isStopFailed() {
      return _.includes(['STOPPING_FAILED'], this.status);
    },

    get isStartFailed() {
      return _.includes(['STARTING_FAILED'], this.status);
    },

    get isPending() {
      return _.includes(['PENDING'], this.status);
    },

    get isError() {
      return _.includes(['FAILED'], this.status);
    },

    getEnvironmentError() {
      return this.error;
    },
  }));

function registerModels() {
  uiEventBus.listenTo('environmentDeleted', {
    id: 'Environment',
    listener: async event => {
      // event will be the environment object
      event.markAsTerminating();
    },
  });
  uiEventBus.listenTo('environmentStopped', {
    id: 'Environment',
    listener: async event => {
      // event will be the environment object
      event.markAsStopping();
    },
  });
  uiEventBus.listenTo('environmentStarted', {
    id: 'Environment',
    listener: async event => {
      // event will be the environment object
      event.markAsStarting();
    },
  });
}

export { Environment, registerModels };
