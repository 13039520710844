/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Table, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import AddTagModal from './AddTagModal';
import EditTagModal from './EditTagModal';
import TagsEditorLine from './TagsEditorLine';
import ConfirmationModal from '../ConfirmationModal';
import { displayError, displaySuccess } from '../../../helpers/notification';

// edited by 2359 - modified to allow add tag if isEditable
class TagEditor extends React.Component {
  constructor(props) {
    super(props);
    this.isEditable = (props.study.adminAccess() || props.study.isMetaData) && !props.study.deleted;
    this.componentStore = observable({
      openDialog: false,
      openEditDialog: false,
      openRemoveConfirmation: false,
      editTag: '',
    });
  }

  componentDidMount() {
    this.props.tagSet.load();
  }

  handleRemoveTag(tag) {
    this.tagToRemove = tag;
    runInAction(() => {
      this.componentStore.openRemoveConfirmation = true;
    });
  }

  async handleConfirmRemove() {
    this.closeConfirmationDialog();
    try {
      await this.props.tagSet.removeTag(this.tagToRemove);
      displaySuccess('Tag successfully removed', 'Removed');
    } catch (e) {
      displayError('Error Removing Tag', e);
    }
  }

  closeDialog() {
    runInAction(() => {
      this.componentStore.openDialog = false;
    });
  }

  closeEditDialog() {
    runInAction(() => {
      this.componentStore.openEditDialog = false;
    });
  }

  closeConfirmationDialog() {
    runInAction(() => {
      this.componentStore.openRemoveConfirmation = false;
    });
  }

  handleEditTag(tag, _key, _values) {
    runInAction(() => {
      this.componentStore.editTag = tag;
      this.componentStore.openEditDialog = true;
    });
  }

  render() {
    const tagSet = this.props.tagSet;
    const renderTags = () => {
      if (tagSet.loading) {
        return (
          <Table.Row>
            <Table.Cell colSpan={3}>Loading...</Table.Cell>
          </Table.Row>
        );
      }
      if (!tagSet.tags || tagSet.tags.length === 0) {
        return (
          <Table.Row>
            <Table.Cell>There are no metatags available for this study.</Table.Cell>
          </Table.Row>
        );
      }
      return tagSet.tags.map((tag, _index) => {
        let metaEditable = this.isEditable;
        if (tag.key === 'study' || tag.key === 'owner') {
          metaEditable = false;
        }
        // console.log("tag: " + JSON.stringify(tag) + " index: " + JSON.stringify(index) + " metaEditable: " + metaEditable);
        return (
          // eslint-disable-next-line react/no-array-index-key,no-shadow
          <TagsEditorLine
            key={tag}
            tag={tag}
            onRemove={val => this.handleRemoveTag(val)}
            onEdit={val => this.handleEditTag(val)}
            admin={this.props.admin}
            isEditable={metaEditable}
          />
        );
      });
    };

    return (
      <>
        <Table>
          <Table.Body>
            {renderTags()}
            <Table.Row>
              <Table.Cell colSpan={3}>
                {this.isEditable && (
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      runInAction(() => {
                        this.componentStore.openDialog = true;
                      });
                    }}
                  >
                    Add Tag
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <AddTagModal
          tagSet={tagSet}
          open={this.componentStore.openDialog}
          onCancel={() => this.closeDialog()}
          onSuccess={() => this.closeDialog()}
        />
        <EditTagModal
          tagSet={tagSet}
          editTag={this.componentStore.editTag}
          open={this.componentStore.openEditDialog}
          onCancel={() => this.closeEditDialog()}
          onSuccess={() => this.closeEditDialog()}
        />
        <ConfirmationModal
          open={this.componentStore.openRemoveConfirmation}
          header="Remove Tag"
          confirmLabel="Remove Tag"
          message="Are you sure you want to remove this tag?"
          onConfirm={() => {
            this.handleConfirmRemove();
          }}
          onCancel={() => {
            this.closeConfirmationDialog();
          }}
        />
      </>
    );
  }
}

export default observer(TagEditor);
