/* eslint-disable import/prefer-default-export */
import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Study Publish Request
// ==================================================================

const StudyPublishRequest = types
  .model('StudyPublishRequest', {
    id: types.identifier,
    comments: types.maybe(types.string),
    studyId: types.string,
    status: types.string,
    createdAt: '',
    createdBy: UserIdentifier,
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
  })
  .views(self => ({
    isOngoing() {
      return (
        self.status === 'AWAITING REVIEW' ||
        self.status === 'RUNNING' ||
        self.status === 'STARTING' ||
        self.status === 'RUNNABLE' ||
        self.status === 'SUBMITTED' ||
        self.status === 'PENDING'
      );
    },
    isSuccessful() {
      return self.status === 'SUCCEEDED';
    },
  }));

export { StudyPublishRequest };
