/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, action, runInAction } from 'mobx';
import TimeAgo from 'react-timeago';
import { Header, Icon, Segment, Message, Table } from 'semantic-ui-react';

import { gotoFn, withRouter } from '../../../helpers/routing';
import { isStoreEmpty, isStoreNotEmpty } from '../../../models/BaseStore';
import Stores from '../../../models/Stores';
import ErrorBox from '../../helpers/ErrorBox';
import ProgressPlaceHolder from '../../workflow-common/ProgressPlaceholder';

// expected props
// - workflowsStore (via injection)
// - userDisplayName (via injection)
// - location (from react router)
class WorkflowPublishedList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      handleWorkflowClick: action,
    });

    runInAction(() => {
      this.stores = new Stores([this.getStore()]);
    });
  }

  componentDidMount() {
    this.getStores().load({ forceLoad: true });
    const store = this.getStore();
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  getStores() {
    return this.stores;
  }

  getStore() {
    return this.props.workflowsStore;
  }

  getUserDisplayNameService() {
    return this.props.userDisplayName;
  }

  handleWorkflowClick = event => {
    event.preventDefault();
    event.stopPropagation();

    // see https://reactjs.org/docs/events.html and https://github.com/facebook/react/issues/5733
    const workflowId = event.currentTarget.dataset.workflow;
    const version = event.currentTarget.dataset.version;
    const goto = gotoFn(this);

    goto(`/workflows/published/id/${workflowId}/v/${version}`);
  };

  render() {
    const stores = this.getStores();
    const store = this.getStore();
    let content = null;

    if (stores.hasError) {
      content = <ErrorBox error={stores.error} className="p0" />;
    } else if (stores.loading) {
      content = <ProgressPlaceHolder />;
    } else if (stores.ready && isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (stores.ready && isStoreNotEmpty(store)) {
      content = this.renderTable();
    } else {
      content = null;
    }

    return (
      <div className="mb4">
        {this.renderTitle()}
        {content}
      </div>
    );
  }

  renderEmpty() {
    const show = this.showCreateDraftWizard;
    if (show) return null;
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="fork" />
          No workflows
          <Header.Subheader>
            To create a workflow, start by creating a draft and then publish the draft.
          </Header.Subheader>
        </Header>
      </Segment>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="fork" className="align-top" />
          <Header.Content className="left-align">Workflows</Header.Content>
        </Header>
      </div>
    );
  }

  renderTable() {
    const store = this.getStore();
    const list = store.list;

    return (
      <Table selectable padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Workflow</Table.HeaderCell>
            <Table.HeaderCell>Updated</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{_.map(list, workflow => this.renderWorkflowRow(workflow))}</Table.Body>
      </Table>
    );
  }

  renderWorkflowRow(workflow) {
    const latest = workflow.latest;
    if (!latest)
      return (
        <Table.Row>
          <Table.Cell colSpan="2">
            <Message warning>
              <p>Workflow &quot;{workflow.id}&quot; does not have any version!</p>
            </Message>
          </Table.Cell>
        </Table.Row>
      );

    const { id, v, title, updatedAt, updatedBy } = latest;
    const displayNameService = this.getUserDisplayNameService();
    const isSystem = displayNameService.isSystem(updatedBy);
    const by = () => (isSystem ? '' : <span>{displayNameService.getDisplayName(updatedBy)}</span>);

    return (
      <Table.Row
        className="cursor-pointer"
        key={workflow.id}
        data-workflow={workflow.id}
        data-version={workflow.latest.v}
        onClick={this.handleWorkflowClick}
      >
        <Table.Cell>
          <Header as="h4" color="grey">
            {title}
            <Header.Subheader className="fs-9">
              <div>
                <span className="ellipsis breakout color-grey">
                  {id} v{v}
                </span>
              </div>
            </Header.Subheader>
          </Header>
        </Table.Cell>
        <Table.Cell collapsing>
          <div>{by()}</div>
          <TimeAgo date={updatedAt} />
        </Table.Cell>
      </Table.Row>
    );
  }

  /* renderDescription(latest) {
    return (
      <>
        <div className="overflow-hidden height-60-px">
          <div dangerouslySetInnerHTML={{ __html: purify.sanitize(latest.descHtml) }} />
        </div>
        <img src={whiteGradient} alt="" className="absolute height-50-px" style={{ bottom: '0', maxWidth: '100%' }} />
      </>
    );
  } */
}

export default inject('workflowsStore', 'userDisplayName')(withRouter(observer(WorkflowPublishedList)));
