/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook access permissions
import React from 'react';
import { Button, Header as BaseHeader, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getNotebookRequestAccessForm } from '../../models/forms/NotebookRequestAccessForm';
import Form from '../helpers/fields/Form';
import Toggle from '../helpers/fields/Toggle';
import DateField from '../helpers/fields/DateField';
import TextArea from '../helpers/fields/TextArea';
import { displayError } from '../../helpers/notification';

class ReviewNotebookAccessRequest extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.accessRequest = props.accessRequest;
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
    this.state = {
      isProcessing: false,
    };
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleRevokePermission = async () => {
    this.setState({ isProcessing: true });
    const updateRequest = { ...this.accessRequest };
    await this.props.onRevokeAccess(this.props.studyId, updateRequest);
    this.setState({ isProcessing: false });
  };

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const requestValues = form.values();
      const updateRequest = { ...this.accessRequest, ...requestValues };
      await this.props.onGrantAccess(this.props.studyId, updateRequest);
      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <Dimmer.Dimmable dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer>
          <div className="mt2 animated fadeIn">
            <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
              {this.props.isRequest ? 'Review Request' : 'Review Permissions'}
            </BaseHeader>
            <div className="mx3 animated fadeIn">{this.renderReviewAccessRequestForm()}</div>
            {!this.props.isRequest && (
              <Button onClick={this.handleRevokePermission} className="mb3" floated="right" color="red">
                Revoke
              </Button>
            )}
          </div>
        </Dimmer.Dimmable>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      color: this.props.isRequest ? (this.props.reviewType === 'approve' ? 'blue' : 'red') : 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        {this.props.isRequest
          ? this.props.reviewType === 'approve'
            ? 'Approve Request'
            : 'Deny Request'
          : 'Review Permissions'}
      </Button>
    );
  }

  renderReviewAccessRequestForm() {
    const form = getNotebookRequestAccessForm();
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    form.$('isRead').value = this.accessRequest.isRead;
    form.$('expiry').min = formattedDate;
    if (this.accessRequest.expiry) {
      form.$('expiry').value = this.accessRequest.expiry;
    }
    if (this.accessRequest.requestorComments) form.$('requestorComments').value = this.accessRequest.requestorComments;
    if (this.accessRequest.adminComments) form.$('adminComments').value = this.accessRequest.adminComments;

    if (this.props.isRequest) {
      form.$('adminComments').set('rules', 'required');
    }
    // form.$('expiryDate').value = this.accessRequest.expiryDate || Date.now();
    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onAlternate={this.handleRevokePermission}
        onError={this.handleFormError}
      >
        {({ processing, onSubmit, onCancel }) => {
          return (
            <>
              <BaseHeader>Notebook: {this.props.studyId}</BaseHeader>
              {(!this.props.isRequest || this.props.reviewType === 'approve') && (
                <>
                  <DateField field={form.$('expiry')} />
                  <Toggle field={form.$('isRead')} disabled />
                  {this.props.isRequest && <TextArea field={form.$('requestorComments')} disabled />}
                </>
              )}
              {this.props.isRequest && <TextArea field={form.$('adminComments')} />}
              <Button
                onClick={onSubmit}
                className="ml2 mb3"
                floated="right"
                color="blue"
                icon
                disabled={processing}
                type="submit"
              >
                {this.props.isRequest
                  ? this.props.reviewType === 'approve'
                    ? 'Approve Request'
                    : 'Deny Request'
                  : 'Update Permissions'}
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(ReviewNotebookAccessRequest));
