/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { listRequestsForStudy, requestPublish } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import { StudyPublishRequest } from './StudyPublishRequest';

const StudyPublishRequestsStore = BaseStore.named('StudyPublishRequestsStore')
  .props({
    studyId: types.identifier,
    requests: types.array(StudyPublishRequest),
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const newRequests = await listRequestsForStudy(self.studyId);
        if (!self.requests || !_.isEqual(self.requests, newRequests)) {
          self.runInAction(() => {
            self.requests = newRequests;
          });
        }
      },

      requestPublish: async study => {
        await requestPublish(study);
        await self.load();
      },

      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({
    hasOngoing() {
      self.requests.some(request => {
        if (request.isOngoing()) {
          return true;
        }
        return false;
      });
    },

    hasSuccessful() {
      self.requests.some(request => {
        if (request.isSuccessful()) {
          return true;
        }
        return false;
      });
    },
  }));

export { StudyPublishRequestsStore };
