/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { Button, Dimmer, Header, List, Loader, Container, Segment, Message } from 'semantic-ui-react';
import _ from 'lodash';
import { withRouter } from '../helpers/routing';

import { getAddUserApplicationFormFields } from '../models/forms/AddUserApplicationForm';
import { displayError } from '../helpers/notification';
import validate from '../models/forms/Validate';

class UserApplication extends React.Component {
  formProcessing = false;
  validationErrors = new Map();
  user = {};

  constructor(props) {
    super(props);
    makeObservable(this, {
      formProcessing: observable,
      user: observable,
      validationErrors: observable,
    });

    this.state = {};

    // this.form = getAddUserApplicationForm();
    this.addUserApplicationFormFields = getAddUserApplicationFormFields();
    this.currentUser = this.props.userStore.cloneUser;
  }

  render() {
    let content = null;
    if (this.currentUser.status === 'pending') {
      content = this.renderFormSubmittedMessage();
    } else {
      content = this.renderAddUserPage();
    }
    return content;
  }

  renderAddUserPage() {
    return (
      <div className="mt2 animated fadeIn">
        <Header as="h2" icon textAlign="center" className="mt3" color="grey">
          Research Portal Application
        </Header>
        <div className="mt3 ml3 mr3 animated fadeIn">{this.renderAddUserForm()}</div>
      </div>
    );
  }

  renderFormSubmittedMessage() {
    return (
      <Container text className="pt4">
        <Message icon>
          <Message.Content>
            <Message.Header>We have received your application</Message.Header>
            You will not have access to the portal until an administrator reviews and approves your application.
          </Message.Content>
        </Message>
      </Container>
    );
  }

  /* renderIndexIdSelection() {
    const indexes = this.props.indexesStore.dropdownOptions;
    return <Dropdown options={indexes} fluid multiple selection onChange={this.handleIndexId} />;
  } */

  renderAddUserForm() {
    const processing = this.formProcessing;
    const fields = this.addUserApplicationFormFields;
    const toEditableInput = (attributeName, type = 'text') => {
      const handleChange = action(event => {
        event.preventDefault();
        this.user[attributeName] = event.target.value;
      });
      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.user[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
          />
        </div>
      );
    };

    return (
      <Container>
        <Segment basic className="ui fluid form">
          <Dimmer active={processing} inverted>
            <Loader inverted>Updating</Loader>
          </Dimmer>
          {this.renderField('email')}
          <span>Your portal username is: {this.currentUser.username}</span>
          <div className="mb4" />
          {this.renderField('firstName', toEditableInput('firstName', 'firstName'))}
          <div className="mb4" />
          {this.renderField('lastName', toEditableInput('lastName', 'lastName'))}
          <div className="mb4" />
          {this.renderField('applyReason', toEditableInput('applyReason', 'applyReason'))}
          <div className="mb4" />
          {this.renderButtons()}
        </Segment>
      </Container>
    );
  }

  renderButtons() {
    const processing = this.formProcessing;
    return (
      <div className="mt3">
        <Button floated="right" color="blue" icon disabled={processing} className="ml2" onClick={this.handleSubmit}>
          Submit Application
        </Button>
      </div>
    );
  }

  renderField(name, component) {
    const fields = this.addUserApplicationFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    const fieldErrors = this.validationErrors.get(name);
    const hasError = !_.isEmpty(fieldErrors);

    return (
      <div>
        <Header className="mr3 mt0" as="h2" color="grey">
          {label}
        </Header>
        {hasExplain && (
          <div className="mb2">
            {explain}
            <span>{this.currentUser[name]}</span>
          </div>
        )}
        <div className={`ui big field input block m0 ${hasError ? 'error' : ''}`}>{component}</div>
        {hasError && (
          <div className="ui pointing red basic label">
            <List>
              {_.map(fieldErrors, fieldError => (
                <List.Item key={name}>
                  <List.Content>{fieldError}</List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }

  handleSubmit = action(async () => {
    this.formProcessing = true;
    try {
      // Perform client side validations first
      const validationResult = await validate(this.user, this.addUserApplicationFormFields);
      // if there are any client side validation errors then do not attempt to make API call
      if (validationResult.fails()) {
        runInAction(() => {
          this.validationErrors = validationResult.errors;
          this.formProcessing = false;
        });
      } else {
        // There are no client side validation errors so ask the store to add user (which will make API call to server to add the user)

        this.currentUser.firstName = this.user.firstName;
        this.currentUser.lastName = this.user.lastName;
        this.currentUser.applyReason = this.user.applyReason;
        this.currentUser.status = 'pending';
        const updatedUser = await this.getStore().updateUserApplication(this.currentUser);
        this.currentUser.status = updatedUser.status;
        runInAction(() => {
          this.formProcessing = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
  });

  getStore() {
    return this.props.usersStore;
  }
}

export default inject(
  'userStore',
  'usersStore',
  'userRolesStore',
  'awsAccountsStore',
  'indexesStore',
)(withRouter(observer(UserApplication)));
