/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - request access for notebooks
import React from 'react';
import { Header, Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { Notebook } from '../../models/notebooks/Notebook';
import { getNotebookRequestAccessForm } from '../../models/forms/NotebookRequestAccessForm';
import { displayError, displaySuccess } from '../../helpers/notification';
import Form from '../helpers/fields/Form';
import Toggle from '../helpers/fields/Toggle';
import DateField from '../helpers/fields/DateField';
import TextArea from '../helpers/fields/TextArea';

class RequestAccess extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    this.notebook = props.notebook;
    this.permissionsStore = props.notebook.getPermissionsStore();
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const { adminComments, ...requestValues } = form.values();

      // Create notebook, clear form, and close modal
      await this.permissionsStore.requestPermissions({
        ...requestValues,
        id: this.notebook.id,
      });
      this.notebook.updateAccess(Notebook.ACCESS_REQUESTED);
      form.clear();
      this.cleanModal();
      displaySuccess('Permission requested.');
    } catch (error) {
      displayError(error);
    }
  };

  /* addDefaultExpiry = () => {
    const today = new Date();
    today.setDate(today.getDate() + 60);
    return today;
  }; */

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Request Access
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderRequestAccessForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Request Access
      </Button>
    );
  }

  renderRequestAccessForm() {
    const form = getNotebookRequestAccessForm();
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    const maxDate = `${today.getFullYear() + 1}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    today.setDate(today.getDate() + 60);
    const afterSixtyDaysDate = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }-${`0${today.getDate()}`.slice(-2)}`;
    form.$('expiry').min = formattedDate;
    form.$('expiry').max = maxDate;
    form.$('expiry').value = afterSixtyDaysDate;
    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Header>Notebook: {this.notebook.id}</Header>
              <DateField field={form.$('expiry')} />
              <Toggle field={form.$('isRead')} disabled />
              <TextArea field={form.$('requestorComments')} />
              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Request Access
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(RequestAccess));
