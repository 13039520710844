import React from 'react';
import { Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';

import ConfirmationModal from '../helpers/ConfirmationModal';
import { displayError, displaySuccess } from '../../helpers/notification';
import { swallowError } from '../../helpers/utils';
import { isStoreError, isStoreLoading, isStoreNew } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';

// expected props
// - study
class DeleteStudy extends React.Component {
  constructor(props) {
    super(props);
    this.permissionsStore = props.study.getPermissionsStore();
    this.componentStore = observable({
      showDeleteModal: false,
      showLoader: false,
    });
  }

  componentDidMount() {
    swallowError(this.permissionsStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.permissionsStore.stopHeartbeat();
  }

  async handleDeleteStudy() {
    runInAction(() => {
      this.componentStore.showDeleteModal = false;
      this.componentStore.showLoader = true;
    });
    try {
      await this.props.study.deleteData();
      displaySuccess('The study and data associated has been marked for deletion.', 'Success');
    } catch (e) {
      displayError(e);
    }
    runInAction(() => {
      this.componentStore.showLoader = false;
    });
  }

  generateExistingPermissionText() {
    const userPermissions = this.permissionsStore.studyPermissions.permissions;
    if (userPermissions.length > 0) {
      return (
        <p>
          The following users still have access to this study:{' '}
          <ul>
            {userPermissions.map(user => {
              return (
                <li>
                  {user.principalIdentifier.username} (Expires: {user.expiry})
                </li>
              );
            })}{' '}
          </ul>{' '}
          It is <b>recommended</b> to revoke their permissions before proceeding with deletion.
        </p>
      );
    }
    return null;
  }

  renderModal() {
    const userPermissionText = this.generateExistingPermissionText();
    const message = (
      <Modal.Description>
        {this.props.study.category === 'API Data' ? (
          <p>
            Please note that deleting the study ({this.props.study.id}) only marks it as &apos;deleted&apos;. It will no
            longer be usable for future workspaces. This is an <b style={{ color: 'red' }}>irreversible</b> action.
            Users&apos; current workspaces with access to the data will hold the latest API csv data prior to deletion.
          </p>
        ) : (
          <p>
            Please note that deleting the study ({this.props.study.id}) only marks its data for deletion. The study
            itself will not be removed but will be marked as &apos;deleted&apos; and not usable for future workspaces.
            This is an <b style={{ color: 'red' }}>irreversible</b> action. Data marked for deletion will be
            inaccessible within 24 hours.{' '}
          </p>
        )}

        {userPermissionText}
        <p>Are you sure you want to proceed?</p>
      </Modal.Description>
    );
    return (
      <ConfirmationModal
        open={this.componentStore.showDeleteModal}
        header="Delete Study"
        message={message}
        onConfirm={() => {
          this.handleDeleteStudy();
        }}
        onCancel={() => {
          runInAction(() => {
            this.componentStore.showDeleteModal = false;
          });
        }}
      />
    );
  }

  render() {
    // Render loading, error, or button
    let content;
    if (isStoreError(this.permissionsStore)) {
      content = <ErrorBox error={this.permissionsStore.error} />;
    } else if (isStoreLoading(this.permissionsStore) || isStoreNew(this.permissionsStore)) {
      content = this.renderLoadingButton();
    } else {
      content = this.renderButton();
    }

    return content;
  }

  renderLoadingButton() {
    return (
      <Dropdown.Item floated="right" size="mini" compact="true" color="red" loading="true">
        Delete Study
      </Dropdown.Item>
    );
  }

  renderButton() {
    return (
      <>
        <Dimmer active={this.componentStore.showLoader}>
          <Loader />
        </Dimmer>
        <Dropdown.Item
          onClick={() => {
            runInAction(() => {
              this.componentStore.showDeleteModal = true;
            });
          }}
        >
          Delete Study
        </Dropdown.Item>

        {this.renderModal()}
      </>
    );
  }
}

export default observer(DeleteStudy);
