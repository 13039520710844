/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - notebook entry display
import { inject } from 'mobx-react';
import { makeObservable } from 'mobx';
import { Header, Checkbox, Tab, Grid, Label } from 'semantic-ui-react';
import Dotdotdot from 'react-dotdotdot';
import TimeAgo from 'react-timeago';

import RowBase from './RowBase';
import RequestAccess from './RequestAccess';
// import UploadNotebookFiles from './UploadNotebookFiles';
import TagEditor from '../helpers/tag-set/TagEditor';
import NotebookPermissionsTable from './NotebookPermissionsTable';
import ComponentSwitch from '../helpers/ComponentSwitch';
import ByWithProfile from '../helpers/ByWithProfile';

class NotebookRow extends RowBase {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  handleFileSelection = notebook => {
    const canAdd = notebook.adminAccess() || notebook.readAccess();
    if (canAdd) {
      const selection = this.props.notebooksSelection;
      if (selection.hasNotebook(notebook.id)) {
        selection.deleteNotebook(notebook.id);
      } else {
        const { id, name } = notebook;
        selection.setNotebook({ id, name });
      }
    }
  };

  getTabPanes(notebook) {
    // const rdsArns = notebook.resources
    //  .filter(resource => resource.arn.startsWith('arn:aws:rds-db:'))
    //  .map(resource => resource.arn);

    const panes = [
      {
        menuItem: 'Description',
        render: () => (
          <Tab.Pane basic attached={false}>
            <div className="ml1">
              <Header as="h2">Attributes</Header>

              <div className="ml2">
                <b>Project ID</b>: {notebook.projectId}
              </div>
              <div className="ml2">
                <b>Workspace ID</b>: {notebook.workspaceId}
              </div>
              <br />
            </div>
          </Tab.Pane>
        ),
      },
    ];

    // Add the Permissions tab if this is an Organization level notebook
    if (notebook.adminAccess()) {
      panes.push({
        menuItem: 'Permissions',
        render: () => (
          <Tab.Pane basic attached={false}>
            <NotebookPermissionsTable notebook={notebook} />
          </Tab.Pane>
        ),
      });
    }

    return panes;
  }

  getData() {
    return this.props.notebook;
  }

  renderDescriptionPane() {
    const data = this.getData();
    return <Dotdotdot clamp={3}>{data.description}</Dotdotdot>;
  }

  renderMetadataPane(notebook) {
    return <TagEditor tagSet={this.getTagSet()} notebook={notebook} admin={this.getData().adminAccess()} />;
  }

  renderHeader(notebook) {
    const selection = this.props.notebooksSelection;
    const isSelected = selection.hasNotebook(notebook.id);
    const by = () => <span className="ml1"><ByWithProfile by user={this.props.usersStore.asUserObjectByNsUsername(notebook.createdBy.ns, notebook.createdBy.username)}/> </span>;

    return (
      <Header as="h2" color="grey" className="mt0">
        <Grid>
          <Grid.Column  width={12}  onClick={() => this.handleFileSelection(notebook)}>
			      <table>
				      <tr>
					      <td>{(notebook.adminAccess() || notebook.readAccess()) && <Checkbox className="mr1" checked={isSelected} />}</td>
				      	<td><span className="font-size-small-9">{notebook.name} (ID: {notebook.id})</span>
			              <Header.Subheader className="fs-9">
			                created <TimeAgo date={notebook.createdAt} /> {by()}
			              </Header.Subheader>
					      </td>
				      </tr>
			      </table>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            {this.renderNotebookActions(notebook)}
          </Grid.Column>
        </Grid>
      </Header>
    );
  }

  renderNotebookActions(notebook) {
    return (
      <div>
        <ComponentSwitch
          tests={[
            () => notebook.noAccess() && <RequestAccess notebook={notebook} onRequestAccess={() => {}} />,
            () => notebook.accessRequested() && <Label>Access Requested</Label>,
          ]}
          fallback={null}
        />
      </div>
    );
  }
}

// RowBase is already an observer

export default inject('notebooksSelection', 'usersStore', 'tagSetsStore')(NotebookRow);
