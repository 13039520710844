/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
// edit 2359 - add api study type
import {
  getStudies,
  createStudy,
  editStudy,
  searchStudies,
  getMyStudies,
  getAccessibleStudies,
  getOrganizationStudies,
  getOpenDataStudies,
  isStudyAdmin,
  isStudyOwner,
  updateStudyApiData,
  upgradeStudyToSecure,
  getStudyCollections,
} from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import { Study } from './Study';
import { StudyStore } from './StudyStore';

const apiLoadMethods = { getMyStudies, getAccessibleStudies, getOrganizationStudies, getOpenDataStudies };

// ==================================================================
// StudiesStore
// ==================================================================
// edit 2359 - add api study type
const StudiesStore = BaseStore.named('StudiesStore')
  .props({
    category: '',
    studies: types.optional(types.map(Study), {}),
    studyStores: types.optional(types.map(StudyStore), {}),
    tickPeriod: 900 * 1000, // 15 minutes
    loadMethod: types.maybeNull(types.string, 'getStudies'),
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let studies;
        if (self.loadMethod) {
          studies = await apiLoadMethods[self.loadMethod]();
        } else {
          studies = await getStudies(self.category);
        }
        // We try to preserve existing studies data and merge the new data instead
        // We could have used self.studies.replace(), but it will do clear() then merge()
        self.runInAction(() => {
          consolidateToMap(self.studies, studies, (exiting, newItem) => {
            exiting.setStudy(newItem);
          });
        });
      },

      addStudy(rawStudy) {
        const id = rawStudy.id;
        const previous = self.studies.get(id);

        if (!previous) {
          self.studies.put(rawStudy);
        } else {
          previous.setStudy(rawStudy);
        }
        return self.studies.get(id);
      },

      getStudyStore: studyId => {
        let entry = self.studyStores.get(studyId);
        if (!entry) {
          // Lazily create the store
          self.studyStores.set(studyId, StudyStore.create({ studyId }));
          entry = self.studyStores.get(studyId);
        }

        return entry;
      },

      async isStudyAdmin(studyId, username) {
        const result = await isStudyAdmin(studyId, username);
        return result;
      },

      async isStudyOwner(studyId, username, ns) {
        const result = await isStudyOwner(studyId, username, ns);
        return result;
      },

      async createStudy(study, createResourceLocation) {
        const result = await createStudy(study, createResourceLocation);
        self.runInAction(() => {
          self.addStudy(result);
        });
        return self.getStudy(result.id);
      },

      async editStudy(study, body) {
        const result = await editStudy(study, body);
        result.access = 'admin'; // only admin will be able to edit
        self.runInAction(() => {
          self.addStudy(result);
        });
        return self.getStudy(result.id);
      },

      // add 2359 - add api study type
      async updateStudyApiData(study) {
        await updateStudyApiData(study);
        return true;
      },

      // @criteria = [
      //   { key: 'location', value: 'sydney' },
      //   { key: 'location', value: 'brisbane' },
      //   { key: 'age', value: '45-50' },
      // ]
      async search(criteria) {
        const results = await searchStudies(criteria);
        return results.map(this.addStudy);
      },

      cleanup: () => {
        self.studies.clear();
        superCleanup();
      },

      async upgradeToSecure(study) {
        const result = await upgradeStudyToSecure(study);
        self.runInAction(() => {
          self.addStudy(result);
        });
        return self.getStudy(result.id);
      },

      async getCollections(studyId, permission) {
        const result = await getStudyCollections(studyId, permission);
        return result;
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.studies.size === 0;
    },

    get total() {
      return self.studies.size;
    },

    get list() {
      const result = [];
      self.studies.forEach(study => result.push(study));

      return _.reverse(_.sortBy(result, ['createdAt', 'name']));
    },

    get numOfPages() {
      // TODO: implement pagination for when we have more data
      return 1;
    },

    hasStudy(id) {
      return self.studies.has(id);
    },

    getStudy(id) {
      return self.studies.get(id);
    },
  }));

function registerModels(globals) {
  globals.studiesStore = StudiesStore.create({}, globals);
}

export { StudiesStore, registerModels };
