
import _ from 'lodash';

import { createForm } from '../../helpers/form';

const editAmiFields = {
  // General fields
  name: {
    label: 'Name',
    placeholder: 'A name to identify your AMI',
    extra: {
      explain: 'Must be between 3 and 128 characters long, alphanumeric and only underscore or dash',
    },
    rules: ['required', 'string', 'between:3,128', 'regex:/^[A-Za-z0-9-_]+$/'],
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the AMI',
    rules: ['string', 'max:8192'],
  }
};

const getEditAmiForm = (defaultValues) => {
  return createForm(_.merge(editAmiFields, defaultValues));
};

export { getEditAmiForm }; // eslint-disable-line import/prefer-default-export
