/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { authenticate, config } from '../../helpers/api';
import { isAbsoluteUrl, getQueryParam, removeQueryParams, addQueryParams } from '../../helpers/utils';
import { boom } from '../../helpers/errors';
import { websiteUrl } from '../../helpers/settings';

function toAbsoluteUrl(uri) {
  return isAbsoluteUrl(uri) ? uri : `${config.apiPath}/${uri}`;
}

// TODO: Remove this temp adjustment method. See comments in "absoluteSignInUrl" getter below for more details.
function adjustRedirectUri(uri) {
  const redirectUri = getQueryParam(uri, ['redirect_uri']);
  // if the current uri contains 'redirect_uri' param and if it is not the same as websiteUrl then adjust it
  // This is required during local development. For other envs, redirectUri and websiteUrl will be same.
  let adjustedUri = uri;
  if (redirectUri !== websiteUrl) {
    adjustedUri = removeQueryParams(uri, ['redirect_uri']);
    adjustedUri = addQueryParams(adjustedUri, { redirect_uri: window.location });
  }
  return adjustedUri;
}

const AuthenticationProviderPublicConfig = types
  .model('AuthenticationProviderPublicConfig', {
    id: '',
    title: types.identifier,
    type: '',
    credentialHandlingType: '',
    signInUri: '',
    signOutUri: '',
    userPoolId: '',
    clientId: '',
  })
  .actions(self => ({
    cleanup() {
      // No-op for now
    },

    login: async ({ username, password } = {}) => {
      const handleException = err => {
        const code = _.get(err, 'code');
        const isBoom = _.get(err, 'isBoom');
        if (code === 'badRequest') throw boom.badRequest(err, err.message);
        if (isBoom) throw err;
        throw boom.apiError(err, 'Something went wrong while trying to contact the server.');
      };

      try {
        if (self.credentialHandlingType === 'submit') {
          // if the selectedAuthenticationProvider requires credentials to be submitted
          // then submit the username/password to the specified URL
          const authenticationProviderId = self.id;
          return await authenticate(self.absoluteSignInUrl, username, password, authenticationProviderId);
        }
        if (self.credentialHandlingType === 'redirect') {
          // if the selectedAuthenticationProvider requires us to redirect to identity provider
          // instead of collecting credentials from user (for example, in case of SAML)
          // just redirect to the specified url
          window.location = self.absoluteSignInUrl;
        }
      } catch (err) {
        handleException(err);
      }
      return undefined;
    },
  }))
  .views(self => ({
    get absoluteSignInUrl() {
      // The "signInUri" below contains redirectUrl that comes from server and points back to the actual websiteUrl
      // (even on local machines during local development)
      // TODO: Temp code: Adjust redirectUrl for local development.
      //  This will go away once we switch to the idea of "provider registry". Currently, the provider configs are retrieved
      //  from a central "AuthenticationProviderConfigService" on the server side and the providers do not get a chance to adjust "signInUri"
      //  (or any other config variables) before returning them during local development, once we move to "provider registry" the registry will
      //  pick appropriate auth provider impl and give it a chance to adjust variables or create derived variables
      return adjustRedirectUri(toAbsoluteUrl(self.signInUri));
    },
    get absoluteSignOutUrl() {
      return toAbsoluteUrl(self.signOutUri);
    },
  }));

export default AuthenticationProviderPublicConfig;
