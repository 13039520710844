/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Container, Header, Icon, Label } from 'semantic-ui-react';
import { makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { isStoreError, isStoreLoading } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import SimpleTable from '../helpers/SimpleTable';
import { withRouter } from '../../helpers/routing';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';

class RolesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // mapOfUsersBeingEdited: observable,
    // formProcessing: observable,
    makeObservable(this, {});

    /* runInAction(() => {
      // An object that keeps track of which user is being edited
      // Each key in the object below has key as user's unique id (<ns>/<username>)
      // and value as flag indicating whether to show the editor for the user
      this.mapOfUsersBeingEdited = {};
      this.formProcessing = false;
    }); */
  }

  getUserRolesStore() {
    const store = this.props.userRolesStore;
    return store;
  }

  getUserRoles() {
    const store = this.getUserRolesStore();
    return store.list;
  }

  renderMain() {
    const userRolesData = this.getUserRoles();
    // const pageSize = userRolesData.length;
    // const showPagination = userRolesData.length > pageSize;

    return (
      <div>
        <SimpleTable
          headerRenderer={() => {
            return ['User Role Name', 'Description', 'User Type'];
          }}
          rowRenderer={request => {
            return [request.id, request.description, request.userType];
          }}
          rowData={userRolesData}
          keyMethod={request => request.id}
        />
      </div>
    );
    /* return (
      <div>
        <ReactTable
          data={userRolesData}
          showPagination={showPagination}
          defaultPageSize={pageSize}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) => {
            const columnValue = String(row[filter.id]).toLowerCase();
            const filterValue = filter.value.toLowerCase();
            return columnValue.indexOf(filterValue) >= 0;
          }}
          columns={[
            {
              Header: 'User Role Name',
              accessor: 'id',
            },
            {
              Header: 'Description',
              accessor: 'description',
            },
            {
              Header: 'User Type',
              accessor: 'userType',
            },
          ]}
        />
        <br />
      </div>
    ); */
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleAddUserRole = () => {
    this.goto('/user-roles/add');
  };

  renderHeader() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="id badge" className="align-top" />
          <Header.Content className="left-align">
            User Roles
            {this.renderTotal()}
          </Header.Content>
        </Header>
        <Button color="blue" size="medium" basic onClick={this.handleAddUserRole}>
          Add User Role
        </Button>
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getUserRoles().length}</Label>;
  }

  render() {
    const store = this.getUserRolesStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderMain();
    }
    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {content}
      </Container>
    );
  }
}

export default inject('userRolesStore')(withRouter(observer(RolesList)));
