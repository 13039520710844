/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, getEnv } from 'mobx-state-tree';

import { niceNumber } from '../../helpers/utils';

const AmiWizard = types
  .model('AmiWizard', {
    currentStep: 2,
    accountConnectionStatus: 'notConnected',
  })
  .actions(self => ({
    setCurrentStep: step => {
      self.currentStep = step;
    },
    setAmiId: id => {
      self.amiId = id;
    },
    setAccountConnectionStatus: status => {
      self.accountConnectionStatus = status;
    },
    getFiles: () => {
      return getEnv(self).filesSelection;
    },
    clear: () => {
      self.currentStep = 2;
    },
  }))
  .views(self => ({
    get empty() {
      const selection = getEnv(self).filesSelection;
      return selection.count === 0;
    },
    get info() {
      // const amiId = self.amiId;
      const selection = getEnv(self).filesSelection;
      const count = selection.count;
      const studiesCount = selection.studiesCount;
      const approvedStudiesCount = selection.studiesCountByStatus('approved');
      const notApprovedStudiesCount = selection.studiesCountByNotStatus('approved');
      const approved = selection.countByStatus('approved');
      const hasSecureStudies = selection.hasSecureStudies();
      const notApproved =
        selection.countByStatus('denied') + selection.countByStatus('expired') + selection.countByStatus('');
      const isPartial = approved !== count;
      const hasApiStudies = selection.hasApiStudies();
      const isConflict = hasApiStudies && hasSecureStudies;

      return {
        currentStep: self.currentStep,
        studies: {
          all: {
            nice: niceNumber(studiesCount),
            count: studiesCount,
            word: studiesCount === 1 ? 'study' : 'studies',
          },
          approved: {
            nice: niceNumber(approvedStudiesCount),
            count: approvedStudiesCount,
            word: approvedStudiesCount === 1 ? 'study' : 'studies',
          },
          notApproved: {
            nice: niceNumber(notApprovedStudiesCount),
            count: notApprovedStudiesCount,
            word: notApprovedStudiesCount === 1 ? 'study' : 'studies',
          },
        },
        selection: {
          approved: {
            nice: niceNumber(approved),
            count: approved,
            word: approved === 1 ? 'dataset' : 'datasets',
          },
          notApproved: {
            nice: niceNumber(notApproved),
            count: notApproved,
            word: notApproved === 1 ? 'dataset' : 'datasets',
          },
        },
        partial: isPartial,
        conflict: isConflict,
        hasSecureStudies,
      };
    },
  }));

function registerModels(globals) {
  globals.amiWizard = AmiWizard.create({}, globals);
}

export { AmiWizard, registerModels };
