/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';

import { createForm } from '../../helpers/form';

const createEgressTaskFields = {
  // General fields
  secureOutputPrefix: {
    label: 'Source prefix folder',
    placeholder: 'Folder for source (Leave empty to transfer whole secure output)',
    rules: ['string', 'max:2048'],
  },
  destinationStudyId: {
    label: 'Destination Study ID',
    placeholder: 'A unique ID used to reference the study',
    rules: ['required', 'string', 'between:1,100', 'regex:/^[A-Za-z0-9-_]+$/'],
  },
  destinationStudyPrefix: {
    label: 'Destination Study Prefix',
    placeholder: 'The destination folder within the study',
    rules: ['required', 'string', 'max:2048'],
  },
};

const getEgressTaskStudySelectForm = defaultValues => {
  return createForm(_.merge(createEgressTaskFields, defaultValues));
};

export { getEgressTaskStudySelectForm }; // eslint-disable-line import/prefer-default-export
