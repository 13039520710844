/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header, Icon, Modal, Input, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { displayError, displaySuccess } from '../../../helpers/notification';

class EditTagModal extends React.Component {
  constructor() {
    super();
    this.componentStore = observable({
      key: '',
      options: [],
      values: [],
      rev: '',
      processing: false,
    });
  }

  componentDidUpdate(prevProps) {
    runInAction(() => {
      if (this.props.editTag !== prevProps.editTag) {
        this.componentStore.values = this.props.editTag.values;
        this.componentStore.key = this.props.editTag.key;
        this.componentStore.rev = this.props.editTag.rev;
        this.setOptions(this.props.editTag.values);
      }
    });
  }

  setOptions(values) {
    runInAction(() => {
      this.componentStore.options = values.map(value => {
        return { key: value, text: value, value };
      });
      this.componentStore.values = values;
    });
  }

  render() {
    const processing = this.componentStore.processing;
    const disableEditButton = processing || this.componentStore.key === '' || this.componentStore.values.length === 0;
    return (
      <Modal open={this.props.open} size="tiny" onClose={this.props.onCancel} closeOnDimmerClick={!processing}>
        <Header content="Edit Tag" />
        <Modal.Content>
          <Input
            disabled={processing}
            value={this.componentStore.key}
            className="mb3"
            onChange={(event, data) => {
              runInAction(() => {
                this.componentStore.key = data.value;
              });
            }}
            placeholder="Key"
            fluid
          />
          <Dropdown
            disabled={processing}
            className="mb3"
            placeholder="Values"
            fluid
            multiple
            selection
            allowAdditions
            search
            value={this.componentStore.values}
            options={this.componentStore.options}
            onChange={(e, { value }) => {
              this.setOptions(value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={processing}
            onClick={() => {
              this.reset();
              if (this.props.onCancel && typeof this.props.onCancel === 'function') {
                this.props.onCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button loading={processing} disabled={disableEditButton} color="blue" onClick={() => this.handleEdit()}>
            <Icon name="pencil" /> Edit Tag
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  reset() {
    runInAction(() => {
      this.componentStore.key = '';
      this.componentStore.options = [];
      this.componentStore.values = [];
      this.componentStore.processing = false;
    });
  }

  async handleEdit() {
    runInAction(() => {
      this.componentStore.processing = true;
    });

    const key = this.componentStore.key;
    const values = this.componentStore.values;
    if (key === '' || values.length === 0) {
      displayError('Invalid input, please ensure both the key and values have been defined');
    }

    try {
      const store = this.props.tagSet;
      await store.editTag({ id: this.props.editTag.id, key: key.toLowerCase(), values, rev: this.componentStore.rev });
      displaySuccess('The tag has been edited.', 'Edited');
      this.reset();
      if (this.props.onSuccess && typeof this.props.onSuccess === 'function') {
        this.props.onSuccess();
      }
    } catch (error) {
      displayError(error);
      runInAction(() => {
        this.componentStore.processing = false;
      });
    }
  }
}

export default observer(EditTagModal);
