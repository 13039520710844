/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { Button, Header, Modal } from 'semantic-ui-react';

// expected props
// - open, whether the dialog should be visible or not.
// - onConfirm, handler method for the confirm case.
// - onCancel, handler method for the cancel case.
// - header (default to empty string)
// - message (default to empty string)
// - confirmLabel (default to 'Confirm')
// - cancelLabel (default to 'Cancel')
function ConfirmationModal({
  open,
  header = '',
  message = '',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onCancel,
  onConfirm,
}) {
  return (
    <Modal open={open} size="tiny" onClose={onCancel} closeOnDimmerClick>
      <Header content={header} />
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{cancelLabel}</Button>
        <Button color="blue" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ConfirmationModal;
