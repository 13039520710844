/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';

import { createForm } from '../../helpers/form';

const editInstanceFields = {
  // General fields
  size: {
    label: 'Size',
    placeholder: 'New size for the research workspace',
    rules: ['required', 'string', 'between:1,2048'],
  },
};

const getEditInstanceForm = defaultValues => {
  return createForm(_.merge(editInstanceFields, defaultValues));
};

export { getEditInstanceForm }; // eslint-disable-line import/prefer-default-export
