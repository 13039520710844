// added by leeas

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const searchUploadDirReqFormFields = {
  username: {
    label: 'Username',
    placeholder: 'A unique name used to reference the user',
    rules: ['string', 'between:3,300', 'regex:/^[A-Za-z0-9_\\-@\\.]+$/'],
  },
  studyId: {
    label: 'Study ID',
    placeholder: 'A unique ID used to reference the study',
    rules: ['string', 'between:1,100', 'regex:/^[A-Za-z0-9-_ ]+$/'],
  },
};

function getSearchUploadDirReqFormFields() {
  return searchUploadDirReqFormFields;
}

function getSearchUploadDirReqForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: searchUploadDirReqFormFields }, { plugins });
}

export { getSearchUploadDirReqForm, getSearchUploadDirReqFormFields };
