/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// Secure Output Permissions
// ==================================================================
const SecureOutputEgressTask = types
  .model('SecureOutputEgressTask', {
    taskArn: types.identifier,
    secureOutputId: types.maybeNull(types.string),
    secureOutputPrefix: types.maybeNull(types.string),
    destinationStudyId: types.maybeNull(types.string),
    destinationStudyPrefix: types.maybeNull(types.string),
    createdAt: '',
    createdBy: UserIdentifier,
    updatedAt: '',
    status: types.maybeNull(types.string),
  })
  .actions(self => ({
    setEgressTask(task) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, task);
    },
  }))
  .views(_self => ({
    get userTypes() {
      return ['admin', 'readonly'];
    },
  }));

export { SecureOutputEgressTask };
