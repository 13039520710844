/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';

// TODO: Improve file model
// const File2 = types.model('File2', {
//   name: '',
//   size: types.optional(types.number, 0),
// });

// TODO this should have been named 'Run'
const Output = types.model('Output', {
  id: types.identifier,
  name: '',
  description: '',
});

// TODO this should have been named 'RunsSelection'
const OutputsSelection = types
  .model('OutputsSelection', {
    outputs: types.optional(types.map(Output), {}),
  })
  .actions(self => ({
    setOutput(output) {
      self.outputs.set(output.id, output);
    },
    deleteOutput(id) {
      self.outputs.delete(id);
    },
    clear() {
      self.outputs.clear();
    },
    setOutputs(outputsMapSnapshot) {
      applySnapshot(self.outputs, outputsMapSnapshot);
    },
  }))
  .views(self => ({
    hasOutput(id) {
      return self.outputs.has(id);
    },
    get empty() {
      return self.outputs.size === 0;
    },
    get count() {
      return self.outputs.size;
    },
    get list() {
      const result = [];
      self.outputs.forEach((value, _key) => result.push(value.id));
      return result;
    },
  }));

function registerModels(globals) {
  globals.outputsSelection = OutputsSelection.create({}, globals);
}

export { OutputsSelection, registerModels };
