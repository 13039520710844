/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const searchAuditLogsFormFields = {
  startTime: {
    label: 'Start Date/Time',
    placeholder: 'Start Date/Time',
    extra: {
      explain: 'Date/time must be in ISO format',
    },
    rules: 'regex:/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/',
  },
  endTime: {
    label: 'End Date/Time',
    placeholder: 'End Date/Time',
    extra: {
      explain: 'Date/time must be in ISO format',
    },
    rules: 'regex:/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/',
  },
  messageSearch: {
    label: 'Search the log message',
    placeholder: 'Message contents',
    extra: {
      explain: 'Plain text search of the message field.',
    },
    rules: 'regex:/^[A-Za-z0-9-_/ ]+$',
  },
};

function getSearchAuditLogsFormFields() {
  return searchAuditLogsFormFields;
}

function getSearchAuditLogsForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: searchAuditLogsFormFields }, { plugins });
}

export { getSearchAuditLogsForm, getSearchAuditLogsFormFields };
