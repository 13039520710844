/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable, action, runInAction, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Header,
  Icon,
  Segment,
  Container,
  Label,
  Button,
  Dropdown,
  Checkbox,
  Grid,
  Accordion,
} from 'semantic-ui-react';
import { gotoFn, withRouter } from '../../helpers/routing';
import { isStoreLoading, isStoreEmpty, isStoreNotEmpty, isStoreError } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import EnvironmentCard from './EnvironmentCard';

// expected props
// - environmentsStore (via injection)
// - location (from react router)
// edited  by 2359 - added envConfigStore
const envDbTypes = {
  'Research Workspace': 'R',
  'Vetting Workspace': 'V',
};

export { envDbTypes };

class EnvironmentsList extends React.Component {
  showTerminated = false;
  showErrored = true;
  showFilterSettings = false;
  statusFilters = [];

  constructor(props) {
    super(props);
    makeObservable(this, {
      showTerminated: observable,
      showErrored: observable,
      showFilterSettings: observable,
      statusFilters: observable,
      handleDetailClick: action,
    });

    this.store = this.props.environmentsStore;
    this.envStore = this.props.store;
    runInAction(() => {
      this.store.setEnvType(this.getEnvDbType());
    });
    this.updateStatusFilters = this.updateStatusFilters.bind(this);
    this.handleShowTerminatedToggle = this.handleShowTerminatedToggle.bind(this);
    this.handleShowErroredToggle = this.handleShowErroredToggle.bind(this);
    this.handleShowFilterSettings = this.handleShowFilterSettings.bind(this);
  }

  componentDidMount() {
    const store = this.getStore();
    // swallowError(store.load());
    store.startHeartbeat();
    const envConfigStore = this.props.environmentConfigurationsStore;
    envConfigStore.load();
    const envStore = this.getEnvStore();
    envStore.load();
    runInAction(() => {
      this.statusFilters = [];
    });
  }

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  getStore() {
    return this.store;
  }

  getEnvStore() {
    return this.envStore;
  }

  getEnvType() {
    return this.props.envType;
  }

  getEnvDbType() {
    const envtype = this.getEnvType();
    return envDbTypes[envtype];
  }

  handleDetailClick = event => {
    event.preventDefault();
    event.stopPropagation();

    // see https://reactjs.org/docs/events.html and https://github.com/facebook/react/issues/5733
    const instanceId = event.currentTarget.dataset.instance;
    const goto = gotoFn(this);
    goto(`/workspaces/id/${instanceId}`);
  };

  handleCreateEnvironment = event => {
    event.preventDefault();
    event.stopPropagation();

    const goto = gotoFn(this);
    const envtype = this.getEnvDbType();
    goto(`/workspaces/create/${envtype}`);
  };

  render() {
    const store = this.getEnvStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder segmentCount={3} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderEmpty() {
    const envtype = this.getEnvType();
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          No {envtype}
          {this.props.allowCreate && (
            <Header.Subheader>
              To create a {envtype}, click Create {envtype}.
            </Header.Subheader>
          )}
        </Header>
      </Segment>
    );
  }

  updateStatusFilters(event, data) {
    runInAction(() => {
      this.statusFilters = data.value;
    });
  }

  handleShowTerminatedToggle(_event) {
    runInAction(() => {
      this.showTerminated = !this.showTerminated;
    });
  }

  handleShowErroredToggle(_event) {
    runInAction(() => {
      this.showErrored = !this.showErrored;
    });
  }

  handleShowFilterSettings(_event) {
    runInAction(() => {
      this.showFilterSettings = !this.showFilterSettings;
    });
  }

  getStatusFilters() {
    return [
      { key: 'PENDING', text: 'STARTING', value: 'PENDING' },
      { key: 'STOPPING', text: 'STOPPING', value: 'STOPPING' },
      { key: 'STOPPED', text: 'STOPPED', value: 'STOPPED' },
      { key: 'COMPLETED', text: 'READY', value: 'COMPLETED' },
      { key: 'FAILED', text: 'ERROR', value: 'FAILED' },
      { key: 'TERMINATED', text: 'TERMINATED', value: 'TERMINATED' },
    ];
  }

  renderTitle() {
    return (
      <>
        <div className="mb3 flex">
          <Header as="h3" className="color-grey mt1 mb0 flex-auto">
            <Icon name="server" className="align-top" />
            <Header.Content className="left-align">
              {this.getEnvType()} {this.renderTotal()}
            </Header.Content>
          </Header>
          {this.props.allowCreate && (
            <Button color="blue" size="medium" basic onClick={this.handleCreateEnvironment}>
              Create {this.getEnvType()}
            </Button>
          )}
        </div>
        <Accordion>
          <Accordion.Title active={this.showFilterSettings} onClick={this.handleShowFilterSettings}>
            <Icon name="dropdown" />
            Filter settings
          </Accordion.Title>
          <Accordion.Content active={this.showFilterSettings}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>Filter status: </Grid.Column>
                <Grid.Column width={10}>
                  <Dropdown
                    placeholder="Filter status"
                    fluid
                    multiple
                    selection
                    clearable
                    icon="filter"
                    options={this.getStatusFilters()}
                    onChange={this.updateStatusFilters}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>Show: </Grid.Column>
                <Grid.Column width={10}>
                  <Checkbox
                    label="Terminated"
                    slider
                    checked={this.showTerminated}
                    onChange={this.handleShowTerminatedToggle}
                  />
                  <Checkbox label="Error" slider checked={this.showErrored} onChange={this.handleShowErroredToggle} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>
        <hr />
      </>
    );
  }

  renderTotal() {
    const store = this.getEnvStore();
    if (isStoreError(store) || isStoreLoading(store)) return null;

    return <Label circular>{store.total}</Label>;
  }

  renderMain() {
    const store = this.getEnvStore();
    let list = store.list;
    if (this.statusFilters && this.statusFilters.toJSON().length > 0)
      list = list.filter(env => this.statusFilters.toJSON().includes(env.status));

    if (!this.showTerminated) {
      list = list.filter(env => env.status !== 'TERMINATED');
    }
    if (!this.showErrored) {
      list = list.filter(env => env.status !== 'FAILED');
    }

    return (
      <div>
        {_.map(list, item => (
          <Segment
            className="p3 mb2 cursor-pointer"
            clearing
            key={item.id}
            data-instance={item.id}
            onClick={this.handleDetailClick}
          >
            <EnvironmentCard environment={item} environmentsStore={this.getStore()} />
          </Segment>
        ))}
      </div>
    );
  }
}

export default inject('environmentsStore', 'environmentConfigurationsStore')(withRouter(observer(EnvironmentsList)));
