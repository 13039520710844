// adapted from https://gist.github.com/KristofferEriksson/9f7c3bd928953c3853f7807b3565ec99
import { useEffect, useState } from 'react';

const timeout = 1200000; // 20 mins in ms
const events = [
  "mousemove",
  "keydown",
  "click",
  "scroll",
];

// onIdle - handler method for idle case
function IdleTimer({ onIdle })  {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
     let timer = null;

     const resetTimer = () => { 
       if (timer)	     
           clearTimeout(timer);
       setIsIdle(false);
       timer = setTimeout(() => {
	       console.log("time up!!!!"); 
	       setIsIdle(true);
	       if (onIdle && typeof onIdle === 'function') {
                   onIdle();
               }
       }, timeout);
     };

     // initialize timer	  
     resetTimer();

     // Event handler to reset the timer on user activity
     events.forEach((event) => window.addEventListener(event, resetTimer));

     // Cleanup function
     return () => {
	if (timer)
	    clearTimeout(timer);
	events.forEach((event) => window.removeEventListener(event, resetTimer));
     }
  }, [timeout, events]);

  return isIdle;	
};

export default IdleTimer;
