/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types, applySnapshot } from 'mobx-state-tree';

// ==================================================================
// Tag
// ==================================================================
const Tag = types
  .model('Tag', {
    id: types.identifier,
    key: types.string,
    values: types.optional(types.array(types.string), []),
    rev: types.maybe(types.number),
  })
  .actions(self => ({
    setTagSet(rawTagSet) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawTagSet);
    },
    setKey(key) {
      self.key = key;
    },
    setValues(values) {
      self.values.replace(values);
    },
  }));

export default Tag;
