/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const addIndexFormFields = {
  id: {
    label: 'Cost Centre ID',
    placeholder: 'Type id for this cost centre',
    rules: 'required|string|between:1,300|regex:/^[A-Za-z0-9-_]+$/',
  },
  awsAccountId: {
    label: 'AWS Account ID',
  },
  description: {
    label: 'Description',
    placeholder: 'Type description for this cost centre',
    rules: 'string|between:1,3000',
  },
};

function getAddIndexFormFields() {
  return addIndexFormFields;
}

function getAddIndexForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addIndexFormFields }, { plugins });
}

export { getAddIndexFormFields, getAddIndexForm };
