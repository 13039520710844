/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getParent } from 'mobx-state-tree';

import { getAccount } from '../../helpers/api';
import { BaseStore } from '../BaseStore';

// ==================================================================
// AccountStore
// ==================================================================
const AccountStore = BaseStore.named('AccountStore')
  .props({
    accountId: '',
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const parent = getParent(self, 2);
        const rawAccount = await getAccount(self.accountId);
        parent.addAccount(rawAccount);
        return undefined;
      },

      cleanup: () => {
        superCleanup();
      },
    };
  })

  .views(self => ({
    get account() {
      const parent = getParent(self, 2);
      const w = parent.getAccount(self.accountId);
      return w;
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use accountsStore.getAccountStore()
// eslint-disable-next-line import/prefer-default-export
export { AccountStore };
