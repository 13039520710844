/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Header as BaseHeader, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { getCreateCollectionForm } from '../../models/forms/CreateCollectionForm';
import { displayError } from '../../helpers/notification';

import Form from '../helpers/fields/Form';
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';

class CreateCollection extends React.Component {
  modalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      modalOpen: observable,
    });

    // console.log(props.study);
    this.store = props.study.getCollectionsStore();
    // this.error = false;
    this.cleanModal = this.cleanModal.bind(this);
    this.cleanModal();
  }

  cleanModal() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const values = form.values();
      // console.log(values);
      await this.store.create(values);

      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.cleanModal}>
        <div className="mt2 animated fadeIn">
          <BaseHeader as="h3" icon textAlign="center" className="mt3" color="grey">
            Create Collection
          </BaseHeader>
          <div className="mx3 animated fadeIn">{this.renderForm()}</div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
      className: 'mb2',
    };
    return (
      <Button
        {...props}
        onClick={action(() => {
          this.modalOpen = true;
        })}
        type="button"
      >
        Create Collection
      </Button>
    );
  }

  // edit 2359 - add api study type
  renderForm() {
    const form = getCreateCollectionForm();

    return (
      <Form
        form={form}
        onCancel={this.handleFormCancel}
        onSuccess={this.handleFormSubmission}
        onError={this.handleFormError}
      >
        {({ processing, _onSubmit, onCancel }) => {
          return (
            <>
              <Input field={form.$('collectionName')} />
              <TextArea field={form.$('description')} />
              <Button className="ml2 mb3" floated="right" color="blue" icon disabled={processing} type="submit">
                Create Collection
              </Button>
              <Button className="mb3" floated="right" disabled={processing} onClick={onCancel}>
                Cancel
              </Button>
            </>
          );
        }}
      </Form>
    );
  }
}

export default inject('userStore')(observer(CreateCollection));
