/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { observer } from 'mobx-react';
import { Input } from 'semantic-ui-react';
import c from 'classnames';

import Header from './Header';
import Description from './Description';
import ErrorPointer from './ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - fluid (via props), default to true
// - disabled (via props), default to false
// - autoFocus (via props), default to false
const Component = observer(
  ({ field, size = 'small', disabled = false, type = 'date', className = 'mb2', autoFocus = false }) => {
    const { error = '' } = field;
    const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
    const isDisabled = field.disabled || disabled;
    const disabledClass = isDisabled ? 'disabled' : '';
    const errorClass = hasError ? 'error' : '';
    const attrs = {
      size,
      disabled: isDisabled,
      error: hasError,
      ..._.omit(field.bind(), ['label']),
      autoFocus,
      type,
      min: field.min || '',
      max: field.max || '',
    };

    return (
      <div className={c(className, errorClass, disabledClass)}>
        <Header field={field} />
        <Description field={field} />
        <Input className="field" {...attrs} />
        <ErrorPointer field={field} />
      </div>
    );
  },
);

export default Component;
