/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';

import WorkflowPublishedTemplatesList from './published/WorkflowPublishedTemplatesList';
import WorkflowTemplateDraftsList from './drafts/WorkflowTemplateDraftsList';

// expected props
// - location (from react router)
class WorkflowTemplatesList extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container className="mt3">
        <WorkflowPublishedTemplatesList />
        <WorkflowTemplateDraftsList />
      </Container>
    );
  }
}

export default inject()(withRouter(observer(WorkflowTemplatesList)));
