/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';
import FileUploadGroup from './FileUploadGroup';

const FileUploadsStore = types
  .model('FileUploadsStore', {
    fileUploadGroups: types.map(FileUploadGroup),
  })
  .actions(self => ({
    getFileUploadGroup(resourceId) {
      let group = self.fileUploadGroups.get(resourceId);
      if (!group) {
        group = FileUploadGroup.create({ resourceId, state: 'PENDING' });
        self.fileUploadGroups.put(group);
      }
      return group;
    },
    resetFileUploadGroup(resourceId) {
      const group = FileUploadGroup.create({ resourceId, state: 'PENDING' });
      self.fileUploadGroups.put(group);
    },
  }));

function registerModels(globals) {
  globals.fileUploadsStore = FileUploadsStore.create({}, globals);
}

export { FileUploadsStore, registerModels };
