/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getParent } from 'mobx-state-tree';
import { getEnvironment, downloadBootstrapNotes } from '../../helpers/api';
import { BaseStore } from '../BaseStore';

// ==================================================================
// EnvironmentStore
// ==================================================================
// edit 2359 - add support for EMR-hail
const EnvironmentStore = BaseStore.named('EnvironmentStore')
  .props({
    environmentId: '',
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const parent = getParent(self, 2);
        const rawEnvironment = await getEnvironment(self.environmentId);
        // const envCreatedAt = new Date(rawEnvironment.createdAt);
        // const now = new Date();
        // const diffTime = Math.abs(now - envCreatedAt);
        // const numberOfDaysBetweenDateCreatedAndToday = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        /*
        try {
          const environmentCost = await getEnvironmentCost(
            self.environmentId,
            Math.min(30, numberOfDaysBetweenDateCreatedAndToday),
          );
          rawEnvironment.costs = environmentCost;
        } catch (error) {
          displayWarning('Error encountered retrieving cost data', error);
        }
		*/

        parent.addEnvironment(rawEnvironment);
      },

      downloadNotes: async environmentId => {
        const result = await downloadBootstrapNotes(environmentId);
        return result;
      },

      cleanup: () => {
        superCleanup();
      },
    };
  })

  .views(self => ({
    get environment() {
      const parent = getParent(self, 2);
      const w = parent.getEnvironment(self.environmentId);
      return w;
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use environmentsStore.getEnvironmentStore()
// eslint-disable-next-line import/prefer-default-export
export { EnvironmentStore };
