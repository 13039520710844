/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getEnv, types } from 'mobx-state-tree';
import _ from 'lodash';
import { BaseStore } from '../BaseStore';
import { getAuthenticationProviderPublicConfigs } from '../../helpers/api';
import AuthenticationProviderPublicConfig from './AuthenticationProviderPublicConfig';

const AuthenticationProviderPublicConfigsStore = BaseStore.named('AuthenticationProviderPublicConfigsStore')
  .props({
    authenticationProviderPublicConfigs: types.optional(types.array(AuthenticationProviderPublicConfig), []),
  })
  .actions(self => ({
    async doLoad() {
      const authenticationProviderPublicConfigs = await getAuthenticationProviderPublicConfigs();
      self.runInAction(() => {
        self.authenticationProviderPublicConfigs = authenticationProviderPublicConfigs;
        if (self.authenticationProviderPublicConfigs && !_.isEmpty(self.authenticationProviderPublicConfigs)) {
          const authentication = getEnv(self).authentication;
          authentication.setSelectedAuthenticationProviderId(self.authenticationProviderPublicConfigs[0].id);
        }
      });
    },
  }))
  .views(self => ({
    get authenticationProviderOptions() {
      if (self.authenticationProviderPublicConfigs && !_.isEmpty(self.authenticationProviderPublicConfigs)) {
        const authProviderOptions = _.map(self.authenticationProviderPublicConfigs, config => ({
          key: config.id,
          text: config.title,
          value: config.id,
        }));
        return authProviderOptions;
      }
      return [];
    },

    toAuthenticationProviderFromId(authenticationProviderId) {
      if (self.authenticationProviderPublicConfigs && !_.isEmpty(self.authenticationProviderPublicConfigs)) {
        return _.find(self.authenticationProviderPublicConfigs, { id: authenticationProviderId });
      }
      return undefined;
    },
  }));
function registerModels(globals) {
  globals.authenticationProviderPublicConfigsStore = AuthenticationProviderPublicConfigsStore.create({}, globals);
}

export { AuthenticationProviderPublicConfigsStore, registerModels };
