/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import {
  getStudyCollections,
  createStudyCollection,
  addToStudyCollection,
  addBatchToStudyCollection,
  removeFromStudyCollection,
} from '../../helpers/api';
import { BaseStore } from '../BaseStore';

const StudyCollectionsStore = BaseStore.named('StudyCollectionsStore')
  .props({
    studyId: types.identifier,
    collections: types.optional(
      types.array(
        types.union(
          types.model({
            collectionName: types.string,
            collectionId: types.string,
            description: types.optional(types.string, ''),
          }),
          types.string,
        ),
      ),
      [],
    ),
    tickPeriod: 300 * 1000, // 5 minutes
    permission: types.string,
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const result = await getStudyCollections(self.studyId, self.permission);
        if (!self.collections || !_.isEqual(self.collections, result.collections)) {
          self.runInAction(() => {
            self.collections = (result == null ? []: result.collections);
          });
        }
      },
      addCollection(rawCollection) {
        const id = rawCollection.collectionId;
        const previous = self.collections.find(collection => collection.collectionId === id);

        if (!previous) {
          self.collections.push(rawCollection);
        } else {
          const newCollections = self.collections.filter(collection => collection.collectionId !== id);
          newCollections.push(rawCollection);
          self.runInAction(() => {
            self.collections = newCollections;
          });
        }
        return self.collections.find(collection => collection.collectionId === id);
      },
      create: async body => {
        const collection = await createStudyCollection(self.studyId, body);
        self.runInAction(() => {
          self.addCollection(collection);
        });
        return collection;
      },
      addToCollection: async (collectionId, fullPath, fileType) => {
        const collectionTag = await addToStudyCollection(self.studyId, collectionId, fullPath, fileType);
        return collectionTag;
      },
      addBatchToCollections: async (fullPaths, collectionIds) => {
        const files = [];
        fullPaths.forEach(path => {
          collectionIds.forEach(collectionId => {
            files.push({ fullPath: path, collectionId, fileType: 'dir' });
          });
        });
        const collectionTag = await addBatchToStudyCollection(self.studyId, files);
        return collectionTag;
      },
      removeFromCollection: async (collectionId, fullPath, fileType) => {
        const result = await removeFromStudyCollection(self.studyId, collectionId, fullPath, fileType);
        return result;
      },
      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({
    get list() {
      return self.collections;
    },
  }));

export { StudyCollectionsStore };
