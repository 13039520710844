/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const importAMIFormFields = {
  amiType: {
    label: 'AMI Type',
    placeholder: 'AMI Type',
    rules: 'required|string',
  },
  name: {
    label: 'Name',
    placeholder: 'AMI Name',
    rules: 'required|string|between:3,255',
  },
  imageId: {
    label: 'Id',
    placeholder: 'AMI Name',
    rules: 'required|string|between:1,255',
  },
  region: {
    label: 'Region',
    placeholder: 'AMI Region',
    rules: 'required_if:amiType,anotherRegion|string|between:1,100',
  },
  description: {
    label: 'Description',
    placeholder: 'AMI Description',
    rules: 'string|between:1,2048',
  },
};

function getImportAMIFormFields() {
  return importAMIFormFields;
}

function getImportAMIForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: importAMIFormFields }, { plugins });
}

export { getImportAMIFormFields, getImportAMIForm };
