/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable max-classes-per-file */
import { Table } from 'semantic-ui-react';

// expected props
// - headerRenderer (via props), A method that returns an array that will be rendered into the header cells
// - rowRenderer (via props), A method that returns an array that will be rendered into the row's cells
// - keyMethod (via props), A method that returns a unique identifier for that particular row
// - rowData (via props), the data to be rendered

// Example usage
/*
<SimpleTable
  headerRenderer={() => {
    return ['Exhibit ID', 'Offence', 'Case', 'Strike Force', 'Created', 'File Count', ''];
  }}
  rowRenderer={exhibit => {
    const createdAt = moment(exhibit.createdAt).format('DD/MM/YYYY HH:mm');
    return [
      exhibit.friendlyId,
      exhibit.offence,
      exhibit.case,
      exhibit.strikeForce,
      createdAt,
      exhibit.fileCount,
      this.renderExhibitActions(exhibit),
    ];
  }}
  rowData={exhibits}
  keyMethod={exhibit => exhibit.id}
/>
*/

function SimpleTable({ rowData, keyMethod, rowRenderer, headerRenderer }) {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {headerRenderer().map((cell, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <Table.HeaderCell key={index}>{cell}</Table.HeaderCell>;
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rowData.map((row, index) => {
          return <TableRow key={keyMethod(row)} data={row} rowIndex={index} rowRenderer={rowRenderer} />;
        })}
      </Table.Body>
    </Table>
  );
}

function TableRow({ rowRenderer, data, rowIndex }) {
  return (
    <Table.Row>
      {rowRenderer(data, rowIndex).map((cell, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Table.Cell key={index}>{cell}</Table.Cell>;
      })}
    </Table.Row>
  );
}

export default SimpleTable;
