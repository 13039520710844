/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Step, Button, Grid } from 'semantic-ui-react';

import Ready from './Ready';
import { withRouter } from '../../../helpers/routing';

class SearchWizardPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleBack = () => {
    this.goto('/studies');
  };

  render() {
    const wizard = this.props.searchWizard;

    return (
      <div className="p3 animated fadeIn">
        <Step.Group widths={3}>
          <Step>
            <Icon name="search" color="blue" />
            <Step.Content>
              <Step.Title>Find &amp; Select</Step.Title>
              <Step.Description>Search and select the studies&apos; runs</Step.Description>
            </Step.Content>
          </Step>
          <Step active={wizard.currentStep === 2}>
            <Icon name="wordpress forms" />
            <Step.Content>
              <Step.Title>Confirm Selection</Step.Title>
              <Step.Description>Confirm your selection or request access</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled={wizard.currentStep < 3} active={wizard.currentStep === 3}>
            <Icon name="unlock" />
            <Step.Content>
              <Step.Title>Get Access</Step.Title>
              <Step.Description>Receive access instructions</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Grid className="mt0">
          <Grid.Column width={16}>
            {/* TODO: Re-implement search */}
            {/* <Search /> */}
            <div className="mt3">
              {this.renderBack()}
              <div style={{ clear: 'both' }} />
            </div>
            <Segment raised className="mt3">
              <Ready location={this.props.location} navigate={this.props.navigate} />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderBack() {
    return (
      <Button color="grey" icon labelPosition="right" className="ml2" onClick={this.handleBack}>
        Back
        <Icon name="undo" />
      </Button>
    );
  }
}

export default inject('searchWizard')(withRouter(observer(SearchWizardPage)));
