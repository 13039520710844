/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getSnapshot, types } from 'mobx-state-tree';

// edited by 2359 - changed fields to maybeNull types
// add 2359 - add support for EMR-hail workspaces
// edit chenjqp - add EMR login credentials
const InstanceInfo = types
  .model('InstanceInfo', {
    Ec2WorkspaceDnsName: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    JupyterUrl: types.maybeNull(types.string),
    notebookInstanceName: types.maybeNull(types.string),
    MasterPublicIP: types.maybeNull(types.string),
    loginUser: types.maybeNull(types.string),
    BastionDnsName: types.maybeNull(types.string),
    BastionUser: types.maybeNull(types.string),
    EC2WorkspacePrivateIp: types.maybeNull(types.string),
    files: types.maybeNull(types.array(types.string)),
    outputFiles: types.maybeNull(types.array(types.string)),
    secureOutput: types.maybeNull(types.string),
    outputs: types.maybeNull(types.array(types.string)),
    ZeppelinUrl: types.maybeNull(types.string),
    SparkUrl: types.maybeNull(types.string),
    Ec2WorkspacePublicIp: types.maybeNull(types.string),
    EMRMasterDNS: types.maybeNull(types.string),
    cidr: types.maybeNull(types.string),
    workspaceNotebook: types.maybeNull(types.string),
    EmrUsername: types.maybeNull(types.string),
    studyCollections: types.maybeNull(
      types.array(types.model({ studyId: types.string, collectionIds: types.maybeNull(types.array(types.string)) })),
    ),
  })
  .views(self => ({
    get id() {
      return self.identifierStr;
    },
    get identifier() {
      return self;
    },
    get identifierStr() {
      return JSON.stringify(getSnapshot(self));
    },
  }));

// eslint-disable-next-line import/prefer-default-export
export { InstanceInfo };
