/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const addProjectFormFields = {
  id: {
    label: 'Project ID',
    placeholder: 'Type id for this project',
    rules: 'required|string|between:1,300|regex:/^[A-Za-z0-9-_]+$/',
  },
  indexId: {
    label: 'Cost Centre ID',
  },
  description: {
    label: 'Description',
    placeholder: 'Type description for this project',
    rules: 'string|between:1,3000',
  },
};

function getAddProjectFormFields() {
  return addProjectFormFields;
}

function getAddProjectForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addProjectFormFields }, { plugins });
}

export { getAddProjectFormFields, getAddProjectForm };
