/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Grid, Header, Tab } from 'semantic-ui-react';

import { withRouter } from '../../helpers/routing';
import SecureOutputsTab from './SecureOutputsTab';
import { SecureOutputsStore } from '../../models/secure-outputs/SecureOutputsStore';
import SecureOutputsAbout from './SecureOutputsAbout';

// const DELIMIT = '/';

class SecureOutputsList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});

    this.stores = {
      'My Secure Outputs': SecureOutputsStore.create({ loadMethod: 'getMySecureOutputs' }),
      'Egress Requests': SecureOutputsStore.create({ loadMethod: 'getEgressRequestedSecureOutputs' }),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // handlePageChange = (_event, _data) => {};

  /* goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });

    this.props.history.push(link);
  } */

  render() {
    const categories = [
      { label: 'My Secure Outputs', category: 'my-secure-output', noPermission: true }, // requested, pending, approved, rejected
      { label: 'Egress Requests', category: 'egress-requests', selection: true }, // pending my action, approved by me, rejected by me
    ];

    const dataPanes = categories.map(category => {
      const store = this.stores[category.label];
      return {
        menuItem: category.label,
        render: () => (
          <Tab.Pane key={category.label}>
            <SecureOutputsTab
              store={store}
              category={category.category}
              noPermission={category.noPermission}
              navigate={this.props.navigate}
              location={this.props.location}
              selection={category.selection}
              outputsSelection={this.props.outputsSelection}
            />
          </Tab.Pane>
        ),
      };
    });

    return (
      <div className="p3 animated fadeIn">
        <Grid className="mt0">
          <Grid.Column width={16}>
            <Segment raised className="mt3">
              <div className="mb3 flex">
                <Header as="h3" className="color-grey mt1 mb0 flex-auto">
                  <Icon name="file archive" className="align-top" />
                  <Header.Content className="left-align">Secure Outputs</Header.Content>
                </Header>
                What&apos;s this?
                <SecureOutputsAbout />
              </div>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* renderOutputsSelection() {
    const selection = this.props.outputsSelection;
    const empty = selection.empty;
    const count = selection.count;
    const whenEmpty = (
      <Label basic color="grey" size="large">
        No secure outputs selected
      </Label>
    );
    const whenNotEmpty = (
      <span>
        <Label basic color="blue" size="large">
          You selected {niceNumber(count)} secure {plural('output', 'outputs', count)}{' '}
        </Label>
        <Button color="grey" icon labelPosition="right" className="ml2" onClick={this.handleReset}>
          Reset
          <Icon name="undo" />
        </Button>
        <Button color="blue" icon labelPosition="right" className="ml2" onClick={this.handleNext}>
          Next
          <Icon name="right arrow" />
        </Button>
      </span>
    );

    return (
      <div className="mt2 float-right">
        {empty && whenEmpty}
        {!empty && whenNotEmpty}
      </div>
    );
  } */
}

export default inject('outputsSelection', 'userStore')(withRouter(observer(SecureOutputsList)));
