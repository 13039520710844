/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable } from 'mobx';
import c from 'classnames';
import { withRouter } from '../../helpers/routing';

// expected props
// - user (via props)
// - userDisplayName (via injection)
// - className (via props)
class By extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  get user() {
    return this.props.user;
  }

  get userDisplayNameService() {
    return this.props.userDisplayName;
  }

  render() {
    const user = this.user;
    const displayNameService = this.userDisplayNameService;
    const isSystem = displayNameService.isSystem(user);
    return isSystem ? (
      ''
    ) : (
      <span className={c(this.props.className)}>by {displayNameService.getDisplayName(user)}</span>
    );
  }
}

export default inject('userDisplayName')(withRouter(observer(By)));
