/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, Card, Radio, Table, Dropdown, Header, Input as CustomInput } from 'semantic-ui-react';
import { withRouter } from '../../helpers/routing';
import { getCreateEnvironmentForm } from '../../models/forms/CreateEnvironmentForm';
import { getCreateSecureEnvironmentForm } from '../../models/forms/CreateSecureEnvironmentForm';
import { isStoreError, isStoreLoading, isStoreReloading } from '../../models/BaseStore';
import Form from '../helpers/fields/Form';
import { displayError } from '../../helpers/notification';
import ErrorBox from '../helpers/ErrorBox';
import ProgressPlaceHolder from '../helpers/BasicProgressPlaceholder';
import Input from '../helpers/fields/Input';
import CustomDropDown from '../helpers/fields/DropDown';
import ErrorPointer from '../helpers/fields/ErrorPointer';
import { swallowError } from '../../helpers/utils';
import TextArea from '../helpers/fields/TextArea';
import CreateStudy from '../studies/CreateStudy';
import CreateSecureOutput from '../secure-outputs/CreateSecureOutput';
import { studyCategories } from '../studies/StudiesPage';
import { StudiesStore } from '../../models/studies/StudiesStore';
import { SecureOutputsStore } from '../../models/secure-outputs/SecureOutputsStore';
import SecureOutputsAbout from '../secure-outputs/SecureOutputsAbout';
import upload from '../../helpers/xhr-upload';
import EnvironmentPrompt from './EnvironmentPrompt';

// edited by 2359 - added handling of custom instance type
// edit 2359 - add support for parallel cluster
// edit 2359 - add support for EMR-hail and notebook selections, initial custom bootstrap scripts and initial custom ports
// expected props
// - userStore (via injection)
class CreateEnvironmentForm extends React.Component {
  environmentSelect = '';
  studiesStores = {};
  outputStudies = [];
  secureOutputSelected = '';
  secureOutputCreated = '';
  secureOutputTypeSelect = '';
  outputStudySelected = '';
  outputStudyCreated = '';
  outputStudyTypeSelect = 'none';

  constructor(props) {
    super(props);
    makeObservable(this, {
      handleFormSubmission: action,
      handleFormError: action,
      getStudiesStore: action,
      handleCreateStudy: action,
      handleCreateSecureOutput: action,
      handleSecureOutputTypeSelection: action,
      handleSecureOutputSelect: action,
      handleOutputStudyTypeSelection: action,
      handleOutputStudySelect: action,
      environmentSelect: observable,
      studiesStores: observable,
      outputStudies: observable,
      secureOutputSelected: observable,
      secureOutputCreated: observable,
      secureOutputTypeSelect: observable,
      outputStudySelected: observable,
      outputStudyCreated: observable,
      outputStudyTypeSelect: observable,
    });

    this.currUser = this.props.userStore.user;
    this.state = {
      maxWorkerNodes: 10,
      diskSizeListInGb: [],
      projectId: '',
      customEnvironments: [],
      customTypes: ['Custom', 'Custom - Spot', 'Custom - On Demand'],
      customDescriptions: {
        Custom: 'Create custom environment with an instance type of your choice.',
        'Custom - Spot':
          'Create a custom research workspace by choosing an instance type.  This research workspace uses spot pricing for worker nodes with a maximum price of the on demand price.',
        'Custom - On Demand':
          'Create a custom research workspace by choosing an instance type. This research workspace uses spot pricing for worker nodes with a maximum price of 1.3x the spot history price.',
      },
      customEmrConfiguration: {
        workerInstanceSize: null,
        workerInstanceCount: null,
        workerInstanceOnDemandPrice: null,
        diskSizeGb: 10,
      },
      pclusterOsList: [],
      isPromptOpen: false,
      customSagemakerEnvs: [],	    
    };

    runInAction(() => {
      // this.environment = {};
      this.secureOutputsStore = null;
      this.bootstrapFiles = [];
      this.oldOutputStudySelected = '';
    });
    this.form = this.getForm(this.props.isSecure);
    this.category = studyCategories.ORGANIZATION;
    this.getStudiesStore();
    this.getSecureOutputsStore();
    this.getUserStudiesStore();
    this.getCustom();
    this.getCustomSagemaker();	  
  }

  componentDidMount() {
    swallowError(this.getConfigurationStore.load());
    swallowError(this.getClientInformationStore.load());
    swallowError(this.getSettingsStore.load());
    this.getMaxNodesSetting();
    this.getDiskSizeListInGbSetting();
    this.getPclusterOsSetting();
  }

  async getMaxNodesSetting() {
    const maxWorkerNodesSetting = await this.getSettingsStore.getSetting('maxWorkerNodes');
    if (maxWorkerNodesSetting) {
      this.setState({ maxWorkerNodes: maxWorkerNodesSetting.value });
    }
  }

  async getDiskSizeListInGbSetting() {
    const diskSizeListInGbSetting = await this.getSettingsStore.getSetting('diskSizeListInGb');
    const arr = diskSizeListInGbSetting.value.split(',');
    const diskSizeListInGb = [];
    Object.values(arr).forEach(size => {
      diskSizeListInGb.push({ key: size, value: size, text: `${size}GB` });
    });
    this.setState({ diskSizeListInGb });
  }

  async getPclusterOsSetting() {
    const pclusterOsSetting = await this.getSettingsStore.getSetting('pclusterOs');
    const arr = pclusterOsSetting.value.split(',');
    const pclusterOsList = [];
    Object.values(arr).forEach(allowedOs => {
      pclusterOsList.push({ key: allowedOs, value: allowedOs, text: allowedOs });
    });
    this.setState({ pclusterOsList });
  }

  get getStore() {
    return this.props.environmentsStore;
  }

  get getUserStore() {
    return this.props.userStore;
  }

  get getConfigurationStore() {
    return this.props.environmentConfigurationsStore;
  }

  get getClientInformationStore() {
    return this.props.clientInformationStore;
  }

  get getSettingsStore() {
    return this.props.settingsStore;
  }

  async getCustom() {
    const store = this.getConfigurationStore;
    const custom = await store.getCustomEnvironments();
    this.setState({ customEnvironments: _.map(custom, item => item) });
  }

  async getCustomSagemaker() {
    const store = this.getConfigurationStore;
    const smEnvs = await store.getSagemakerEnvironments();
    this.setState({ customSagemakerEnvs: _.map(smEnvs, item => item) });
  }
	

  getStudiesStore() {
    if (!this.studiesStores[this.category]) {
      this.studiesStores[this.category] = StudiesStore.create({ category: this.category });
    }
    this.currStudiesStore = this.studiesStores[this.category];
    swallowError(this.studiesStores[this.category].load());
  }

  getUserStudiesStore() {
    if (!this.userStudiesStore) {
      this.userStudiesStore = StudiesStore.create({ loadMethod: 'getMyStudies' });
    }
    swallowError(this.userStudiesStore.load());
  }

  getSecureOutputsStore() {
    if (!this.secureOutputsStore) {
      this.secureOutputsStore = SecureOutputsStore.create({ loadMethod: 'getMySecureOutputs' });
    }
    swallowError(this.secureOutputsStore.load());
  }

  isStoreError() {
    return isStoreError(this.getConfigurationStore);
  }

  isStoreLoading() {
    return isStoreLoading(this.getConfigurationStore) || isStoreReloading(this.getConfigurationStore);
  }

  /* isStoreReady() {
    return isStoreReady(this.getConfigurationStore);
  } */

  onPromptDeny = () => {
    this.setState({ isPromptOpen: false });
  };

  onPromptAccept = () => {
    const form = this.form;
    this.setState({ isPromptOpen: false });
    this.handleFormSubmission(form, true);
  };

  getForm = isSecure => {
    const form = [];
    if (!isSecure) return getCreateEnvironmentForm(form);
    return getCreateSecureEnvironmentForm(form);
  };

  handleFormError = (_form, _errors) => {
    // We don't need to do anything here
  };

  // edit 2359 - add support for EMR-hail and notebook selections, initial custom bootstrap scripts and initial custom ports
  // edit chenjqp - add support for secure emr-hail
  handleFormSubmission = async (form, ignoreSanityCheck = false) => {
    const values = form.values();

    const store = this.getStore;
    const config = {};
    if (!this.state.customTypes.includes(this.environmentSelect)) {
      const environment = this.getConfigurationStore.getConfiguration(this.environmentSelect);
      if (environment.isEmrCluster) {
        Object.assign(config, environment.emrConfiguration);
      }
      if (!environment.isOnDemandPricing) {
        Object.assign(config, { spotBidPrice: environment.spotBidPrice });
      }
    } else if (
      this.props.offering.type === 'emr' ||
      this.props.offering.type === 'emr-hail' ||
      this.props.offering.type === 'secure-emr-hail'
    ) {
      const emrConfiguration = this.state.customEmrConfiguration;
      if (
        this.environmentSelect === 'Custom - Spot' ||
        this.props.offering.type === 'emr-hail' ||
        this.props.offering.type === 'secure-emr-hail'
      ) {
        const spotBidPrice = await this.getConfigurationStore.getCustomSpotBidPrice(
          emrConfiguration.workerInstanceSize,
        );
        Object.assign(config, { spotBidPrice });
      }
      const onDemandPrice = await this.getConfigurationStore.getCustomOnDemandPrice(
        emrConfiguration.workerInstanceSize,
      );
      emrConfiguration.workerInstanceOnDemandPrice = onDemandPrice;
      Object.assign(config, emrConfiguration);
    }
    const { files, outputs, notebooks } = this.props;
    const studyfiles = [];
    const studyCollectionfiles = [];
    // console.log(files);
    if (files && files.length) {
      files.forEach(item => {
        const file = item.toJSON();
        if (file.prefix && file.prefix.length) {
          studyfiles.push(file.id);
          studyCollectionfiles.push({ studyId: file.id, collectionIds: file.prefix });
        } else {
          studyfiles.push(file.id);
          studyCollectionfiles.push({ studyId: file.id, collectionIds: [] });
        }
      });
    }

    const fileMounts = files ? { files: studyfiles, studyCollections: studyCollectionfiles } : {};
    const notebookMounts = notebooks ? { notebooks } : {};
    const outputMounts = outputs ? { outputs } : {};
	
	// determine output study to be used for workspace
	let outputFileMounts = {};
	if(this.outputStudyTypeSelect === 'none'){
		outputFileMounts = {};
	}else if (this.outputStudyTypeSelect === 'new'){
		outputFileMounts = {outputFiles: [this.outputStudyCreated]};
	}else if (this.outputStudyTypeSelect === 'existing'){
		outputFileMounts = {outputFiles: this.outputStudies};
	}

    Object.assign(values.instanceInfo, {
      config,
      ...fileMounts,
      ...outputFileMounts,
      ...outputMounts,
      ...notebookMounts,
    });
    const indexId = this.props.projectsStore.getProject(this.state.projectId).indexId;
    const envType = this.props.envType;
    if (this.bootstrapFiles.length) {
      const filenames = _.map(this.bootstrapFiles, file => file.name);
      values.bootstrapFiles = filenames;
    }
    let environment;
    try {
      if (this.props.offering.type !== 'ami-parallel-cluster') {
        delete values.amiOs;
      }

      if (this.props.offering.type === 'parallel-cluster' || this.props.offering.type === 'ami-parallel-cluster') {
        values.minCount = Number(values.minCount);
        values.maxCount = Number(values.maxCount);
      } else {
        delete values.minCount;
        delete values.maxCount;
      }

      if (this.props.amiId) {
        delete values.instanceInfo.rootEbs;
        if (this.props.isSecure) {
          environment = await store.createSecureEnvironment({
            ...values,
            projectId: this.state.projectId,
            ignoreSanityCheck,
            indexId,
            envType,
            amiId: this.props.amiId,
            isSecure: true,
          });
        } else {
          environment = await store.createEnvironmentFromAmi({
            ...values,
            projectId: this.state.projectId,
            ignoreSanityCheck,
            indexId,
            envType,
            amiId: this.props.amiId,
          });
        }
      } else if (this.props.isSecure) {
        environment = await store.createSecureEnvironment({
          ...values,
          projectId: this.state.projectId,
          ignoreSanityCheck,
          indexId,
          envType,
          isSecure: true,
        });
      } else {
        environment = await store.createEnvironment({
          ...values,
          projectId: this.state.projectId,
          ignoreSanityCheck,
          indexId,
          envType,
        });
      }

      if (this.bootstrapFiles.length) {
        const bsfiles = _.map(this.bootstrapFiles, file => file.name);
        const presignResult = await store.uploadBootstrapFiles(bsfiles, environment.id);
        let uploadRequest;
        _.forEach(this.bootstrapFiles, async file => {
          uploadRequest = presignResult[file.name];
          const uploadHandle = upload(file, uploadRequest.url, uploadRequest.fields);
          try {
            await uploadHandle.done;
          } catch (error) {
            const errMessage = 'Error encounted uploading file';
            console.error(`${errMessage}:`, error);
            throw new Error(errMessage);
          }
        });
      }

      form.clear();
      this.props.onSuccess();
    } catch (error) {
      if (
        error.code &&
        error.message &&
        error.code === 'badRequest' &&
        error.message === 'Warning: sanity check failed, some collections include empty folder'
      ) {
        this.setState({ isPromptOpen: true });
      } else {
        displayError(error);
        // This should be where we can check if the error code or message indicates faults in selected collections
        // then prompt the users if they want to proceed with the faulty collections or not
      }
    }
  };

  handleFormCancel = form => {
    form.clear();
    this.props.onCancel();
  };

  handleProjectIdSelection = (e, { value }) => this.setState({ projectId: value });

  handleCreateStudy = study => {
    this.outputStudies.push(study);
    this.outputStudyCreated = study.id;
  };

  handleCreateSecureOutput = secureOutput => {
    const form = this.form;
    const secureOutputForm = form.$('instanceInfo.secureOutput');
    secureOutputForm.value = secureOutput.id;
    secureOutputForm.validate();
    this.secureOutputCreated = secureOutput.id;
  };

  handleSecureOutputSelect(e, data) {
    e.preventDefault();
    e.stopPropagation();
    const form = this.form;
    const secureOutput = form.$('instanceInfo.secureOutput');
    secureOutput.value = data.value;
    secureOutput.validate();
    this.secureOutputSelected = data.value;
  }

  handleOutputStudySelect(e, data) {
    e.preventDefault();
    e.stopPropagation();
    this.oldOutputStudySelected = this.outputStudySelected;
    this.outputStudySelected = data.value;
    this.outputStudies = _.remove(this.outputStudies, this.oldOutputStudySelected);
    this.outputStudies.push(this.outputStudySelected);
  }

  // edit 2359 - add support for initial custom bootstrap scripts
  /* handleBootstrapFiles = e => {
    runInAction(() => {
      this.bootstrapFiles = e.target.files;
    });
  }; */

  // edit 2359 - add support for parallel cluster
  // edit 2359 - add support for EMR-hail and notebook selections, initial custom bootstrap scripts and initial custom ports
  // edit chenjqp - add support for secure emr-hail
  // edit chenjqp - remove default cidr for emr
  render() {
    const emrConfiguration = this.state.customEmrConfiguration;
    const { maxWorkerNodes } = this.state;
    const { offering } = this.props;
    const form = this.form;
    const name = form.$('name');
    const description = form.$('description');
    const maxCount = form.$('maxCount');
    const minCount = form.$('minCount');
    const type = form.$('instanceInfo.type');
    type.value = offering.type;
    type.validate();
    const size = form.$('instanceInfo.size');
    const cidr = form.$('instanceInfo.cidr');
    const rootDiskSize = form.$('instanceInfo.rootEbs.size');
    if (!this.props.amiId && offering.type !== 'secure-vetting') {
      rootDiskSize.set('rules', 'required');
    }
    const secureOutput = form.$('instanceInfo.secureOutput');
    if (this.props.isSecure && offering.type !== 'secure-vetting') {
      secureOutput.set('rules', 'required');
    }

    // added by leeas
    const amiOs = form.$('amiOs');
    if (this.props.amiId && offering.type === 'ami-parallel-cluster') {
      amiOs.set('rules', 'required');
      maxCount.set('rules', 'required');
      minCount.set('rules', 'required');
    }

    // const ports = form.$('ports');
    const configs = this.getConfigurationStore.list.filter(config => config.type === offering.type);
    // eslint-disable-next-line no-shadow
    const defaultCidr = configs.reduce((result, { defaultCidr }) => result || defaultCidr, false);
    // edit chenjqp - if creating emr, create cidr as default since emr will not work with 0.0.0.0/0
    /*
    if(offering.type === 'emr' || offering.type === 'emr-hail' || offering.type === 'secure-emr-hail'){
        cidr.value = this.createCidr();
    }
    */

    const typeErrorClass = !_.isEmpty(type.error) ? 'error' : '';
    const sizeErrorClass = !_.isEmpty(size.error) ? 'error' : '';
    const cidrErrorClass = !_.isEmpty(cidr.error) ? 'error' : '';

    const userStore = this.getUserStore;
    // TODO: Make maxPrice come from user role and make it admin configurable by role
    const maxPrice = Infinity;
    const projects = userStore.projectIdDropdown;
    const btnLabel = offering.type === 'secure-vetting' ? 'Create Vetting Workspace' : 'Create Research Workspace';
    if (this.isStoreError()) {
      const error = _.assign({}, this.getConfigurationStore.error);
      return <ErrorBox error={error} className="p0" />;
    }
    if (this.isStoreLoading()) {
      return <ProgressPlaceHolder />;
    }

    return (
      <>
        <Form
          form={form}
          onCancel={this.handleFormCancel}
          onSuccess={this.handleFormSubmission}
          onError={this.handleFormError}
        >
          {/* eslint-disable-next-line no-unused-vars */}
          {({ processing, onSubmit, onCancel }) => (
            <>
              <Input field={name} disabled={processing} />
              {!defaultCidr && <Input field={cidr} disabled={processing} />}
              <Header className="mr3 mt0" as="h4" color="grey">
                Project ID
              </Header>
              <Dropdown
                options={projects}
                fluid
                placeholder="please select Project ID"
                selection
                onChange={this.handleProjectIdSelection}
              />
              <div className="mb4" />
              <Card.Group className={`${typeErrorClass} ${sizeErrorClass} ${cidrErrorClass} mb1`} centered>
                {configs
                  .filter(config => config.price <= maxPrice)
                  .map((config, index) => this.renderEnvironmentCard(config, index, processing))}
                {(offering.type === 'secure-ec2-linux' ||
                  offering.type === 'ec2-linux' ||
                  offering.type === 'secure-ec2-alinux2' ||
                  offering.type === 'ec2-alinux2') &&
                  this.renderCustomCard(configs.length)}
		{(offering.type === 'secure-sagemaker') && 
		  this.renderSagemakerCustomCard(configs.length)}  
                {/* commented out because EMR does not support all instance sizes
				(offering.type === 'emr-hail') && this.renderCustomCard(configs.length, 'Custom - Spot')}
                {(offering.type === 'emr-hail') && this.renderCustomCard(configs.length + 1, 'Custom - On Demand') 
				*/}
                <ErrorPointer field={type} />
              </Card.Group>
              {parseInt(emrConfiguration.workerInstanceCount, 10) > maxWorkerNodes && (
                <ErrorBox error={`The maximum number of worker nodes allowed are ${maxWorkerNodes}`} className="p0" />
              )}
              {!this.props.amiId && offering.type !== 'secure-vetting' && (
                <CustomDropDown
                  field={rootDiskSize}
                  options={this.state.diskSizeListInGb}
                  fluid
                  placeholder="please select root disk size"
                  selection
                />
              )}
              {(this.props.isSecure === undefined || !this.props.isSecure) && (
                <>
                  {/*
                {offering.type === 'emr-hail' && <Input field={ports} disabled={processing} />}
                
                <Header className="mr3 mt0 my4" as="h4" color="grey">
                  Bootstrap Actions
                </Header>
                <CustomInput className="field" onChange={(e) => this.handleBootstrapFiles(e)} disabled={processing} type="file" multiple />
                */}

                  <Header className="mr3 mt0 my4" as="h4" color="grey">
                    Workspace output studies
                  </Header>
                  {this.renderOutputStudySelection()}
                </>
              )}
              {/*
               * IF user is creating a secure workspace
               * THEN
               * - show create secure output option
               *
               */

              this.props.isSecure && offering.type !== 'secure-vetting' && (
                <>
                  <Header className="mr3 mt0 my4" as="h4" color="grey">
                    Workspace secure output <SecureOutputsAbout />
                  </Header>
                  {this.renderSecureOutputSelection(secureOutput)}
                </>
              )}
              {offering.type === 'parallel-cluster' || offering.type === 'ami-parallel-cluster' ? (
                <>
                  {this.props.amiId && (
                    <CustomDropDown
                      field={amiOs}
                      options={this.state.pclusterOsList}
                      fluid
                      placeholder="please select pcluster OS that match your AMI OS"
                      selection
                    />
                  )}
                  <div className="mb4" />
                  <Input type="number" field={minCount} disabled={processing} />
                  <div className="mb4" />
                  <Input type="number" field={maxCount} disabled={processing} />
                </>
              ) : null}
              <div className="mb4" />
              <TextArea field={description} disabled={processing} />
              <div className="mt3">
                <Button floated="right" color="blue" icon disabled={processing} className="ml2" type="submit">
                  {btnLabel}
                </Button>
                <Button floated="right" disabled={processing} onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
        <EnvironmentPrompt
          isPromptOpen={this.state.isPromptOpen}
          onPromptDeny={this.onPromptDeny}
          onPromptAccept={this.onPromptAccept}
        />
      </>
    );
  }

  renderOutputStudySelection() {
    const listMyOutputStudies = this.userStudiesStore.list
      .filter(
        outputStudy =>
          outputStudy.isOutput &&
          !outputStudy.isSharing &&
          outputStudy.outputAvailable() &&
          this.userStudiesStore.isStudyOwner(outputStudy.id, this.currUser.username, this.currUser.ns),
      )
      .map(outputStudy => ({
        key: outputStudy.id,
        text: outputStudy.name,
        value: outputStudy.id,
      }));

    return (
      <Card.Group className="mb1" centered>
        <Card key="0" onClick={this.handleOutputStudyTypeSelection} value="none" processing="false">
          <Card.Content>
            <Card.Header className="center">NONE</Card.Header>
            <Card.Description>No output study to be used</Card.Description>
          </Card.Content>
          <Card.Content extra className="center">
            <Radio key="0" checked={this.outputStudyTypeSelect === 'none'} value="none" name="outputStudyType" />
          </Card.Content>
        </Card>	  
        <Card key="1" onClick={this.handleOutputStudyTypeSelection} value="new" processing="false">
          <Card.Content>
            <Card.Header className="center">Create New</Card.Header>
            <Card.Description>Create a new output study to hold your analysis output data</Card.Description>
          </Card.Content>
          <Card.Content extra>
            {this.outputStudyCreated === '' && (
              <CreateStudy
                category={this.category}
                studiesStore={this.currStudiesStore}
                buttonProps={{
                  size: 'mini',
                  compact: true,
                  color: 'blue',
                }}
                onCreate={this.handleCreateStudy}
                isSecure={false}
                disableSecure
                isOutputStudy
                settingsStore={this.getSettingsStore}
              />
            )}
            {this.outputStudyCreated !== '' && <>{this.outputStudyCreated}</>}
          </Card.Content>
          <Card.Content extra className="center">
            <Radio key="1" checked={this.outputStudyTypeSelect === 'new'} value="custom" name="outputStudyType" />
          </Card.Content>
        </Card>
        <Card key="2" onClick={this.handleOutputStudyTypeSelection} value="existing" processing="false">
          <Card.Content>
            <Card.Header className="center">Use Existing</Card.Header>
            <Card.Description>
              Use an existing output study. The following conditions must be met:
              <ol>
                <li>Output study must not be in use in another workspace</li>
                <li>Study owner must not enable sharing for the output study</li>
              </ol>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Dropdown
              options={listMyOutputStudies}
              search
              selection
              clearable
              disabled={this.outputStudyTypeSelect !== 'existing'}
              onChange={(e, data) => this.handleOutputStudySelect(e, data)}
              value={this.outputStudySelected}
            />
          </Card.Content>
          <Card.Content extra className="center">
            <Radio
              key="2"
              checked={this.outputStudyTypeSelect === 'existing'}
              value="existing"
              name="outputStudyType"
            />
          </Card.Content>
        </Card>
      </Card.Group>
    );
  }

  handleOutputStudyTypeSelection = (event, { value, _processing }) => {
    runInAction(() => {
      this.outputStudyTypeSelect = value;
    });
  };

  handleSecureOutputTypeSelection = (event, { value, _processing }) => {
    runInAction(() => {
      this.secureOutputTypeSelect = value;
    });
  };

  renderSecureOutputSelection() {
    const { offering } = this.props;	  
    const listMySecureOutputs = this.secureOutputsStore.list
      .filter(
        secureOutput =>
          !(
            (secureOutput.inUse !== null && secureOutput.inUse) ||
            (secureOutput.egressRequested !== null && secureOutput.egressRequested) ||
            secureOutput.isDataDeleted() ||
            secureOutput.isPending()
          ),
      )
      .filter(secureOutput => _.isEqual(secureOutput.parentStudy.slice().sort(), this.props.secureFiles.slice().sort()))
      .map(secureOutput => ({
        key: secureOutput.id,
        text: secureOutput.name,
        value: secureOutput.id,
      }));
      const isSagemaker = (offering.type === 'secure-sagemaker');	  

    return (
      <Card.Group className="mb1" centered>
        <Card key="1" onClick={this.handleSecureOutputTypeSelection} value="new" processing="false">
          <Card.Content>
            <Card.Header className="center">Create New</Card.Header>
            <Card.Description>Create a new secure output to hold all your data</Card.Description>
          </Card.Content>
          <Card.Content extra>
            {this.secureOutputCreated === '' && (
              <CreateSecureOutput
                secureOutputsStore={this.props.secureOutputsStore}
		isSagemaker={isSagemaker}    
                buttonProps={{
                  size: 'mini',
                  compact: true,
                  color: 'blue',
                  disabled: this.secureOutputTypeSelect !== 'new',
                }}
                parentStudies={this.props.secureFiles}
                onCreate={this.handleCreateSecureOutput}
                settingsStore={this.getSettingsStore}
              />
            )}
            {this.secureOutputCreated !== '' && <>{this.secureOutputCreated}</>}
          </Card.Content>
          <Card.Content extra className="center">
            <Radio key="1" checked={this.secureOutputTypeSelect === 'new'} value="custom" name="secureOutputType" />
          </Card.Content>
        </Card>
        <Card key="2" onClick={this.handleSecureOutputTypeSelection} value="existing" processing="false">
          <Card.Content>
            <Card.Header className="center">Use Existing</Card.Header>
            <Card.Description>
              Use an existing secure output. The following conditions must be met:
              <ol>
                <li>Secure output must not be in use in another workspace</li>
                <li>Studies used for the secure output must be the same as when it was created</li>
                <li>Egress must not have been requested/approved/rejected.</li>
              </ol>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Dropdown
              options={listMySecureOutputs}
              search
              selection
              clearable
              disabled={this.secureOutputTypeSelect !== 'existing'}
              onChange={(e, data) => this.handleSecureOutputSelect(e, data)}
              value={this.secureOutputSelected}
            />
          </Card.Content>
          <Card.Content extra className="center">
            <Radio
              key="2"
              checked={this.secureOutputTypeSelect === 'existing'}
              value="existing"
              name="secureOutputType"
            />
          </Card.Content>
        </Card>
      </Card.Group>
    );
  }

  /* createCidr() {
    const store = this.getClientInformationStore;
    if (_.isEmpty(store.ipAddress)) {
      return '';
    }
    return `${store.ipAddress}/32`;
  } */

  resetCustomConfig() {
    const emrConfiguration = {
      workerInstanceSize: '',
      workerInstanceCount: null,
      workerInstanceOnDemandPrice: null,
      diskSizeGb: 10,
    };
    this.setState({ customEmrConfiguration: emrConfiguration }); // , customSize: null - to put back if not unused
  }

  // edit 2359 - add support for EMR-hail
  // edit chenjqp - add support for secure emr-hail
  handleCardSelection = (event, { value, processing }) => {
    if (value !== this.environmentSelect) {
      this.resetCustomConfig();
    }
    if (processing !== 'false') return;
    runInAction(() => {
      this.environmentSelect = value;
    });
    if (this.state.customTypes.includes(value)) {
      /* if (
        (value === 'Custom - Spot' && this.props.offering.type === 'emr') ||
        this.props.offering.type === 'emr-hail' ||
        this.props.offering.type === 'secure-emr-hail'
      ) {
        this.setState({ customIsSpot: true });
      } */
      return;
    }
    const environment = this.getConfigurationStore.getConfiguration(value);
    const selectedSize = environment.size;
    const form = this.form;
    const size = form.$('instanceInfo.size');
    size.value = selectedSize;
    size.validate();
  };

  handleChangeCustomParams = (event, param) => {
    event.preventDefault();
    event.stopPropagation();
    const emrConfiguration = this.state.customEmrConfiguration;
    const form = this.form;
    const size = form.$('instanceInfo.size');
    switch (param) {
      case 'masterInstanceType':
        size.value = event.target.innerText;
        emrConfiguration.workerInstanceSize = event.target.innerText;
        break;
      case 'workerInstanceCount':
        emrConfiguration.workerInstanceCount = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ customEmrConfiguration: emrConfiguration });
  };

  // edit 2359 - add support for EMR-hail
  // edit chenjqp - add support for secure emr-hail
  renderCustomCard(index, pricingType = null) {
    const emrConfiguration = this.state.customEmrConfiguration;
    const value = pricingType || `Custom`;
    const selected = value === this.environmentSelect;
    const current = this.state.customEnvironments.find(
      ({ InstanceType }) => InstanceType === emrConfiguration.workerInstanceSize,
    );
    const customOptions = _.orderBy(
      this.state.customEnvironments.map(({ InstanceType, _index }) => ({
        key: InstanceType,
        text: InstanceType,
        value: InstanceType,
      })),
      ['text'],
      ['asc'],
    );
    return (
      <Card key={index} onClick={this.handleCardSelection} value={pricingType || `Custom`} processing="false">
        <Card.Content>
          <Card.Header className="center">{`${pricingType || 'Custom'}`}</Card.Header>
          <Card.Description>{this.state.customDescriptions[`${pricingType || `Custom`}`]}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Table basic="very" size="small">
            <Table.Body>
              <Table.Row key="size" textAlign="center">
                <Table.Cell>Size</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    options={customOptions}
                    disabled={!selected}
                    search
                    selection
                    onChange={e => this.handleChangeCustomParams(e, 'masterInstanceType')}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row key="vCPU" textAlign="center">
                <Table.Cell>vCPU</Table.Cell>
                <Table.Cell>{selected && current ? current.VCpuInfo.DefaultVCpus : '--'}</Table.Cell>
              </Table.Row>
              <Table.Row key="memory" textAlign="center">
                <Table.Cell>Memory (GiB)</Table.Cell>
                <Table.Cell>{selected && current ? current.MemoryInfo.SizeInMiB / 953.674 : '--'}</Table.Cell>
              </Table.Row>
              {(this.props.offering.type === 'emr' ||
                this.props.offering.type === 'emr-hail' ||
                this.props.offering.type === 'secure-emr-hail') && (
                <Table.Row key="nodes" textAlign="center">
                  <Table.Cell>Worker nodes</Table.Cell>
                  <Table.Cell>
                    <CustomInput
                      disabled={!selected}
                      type="text"
                      onChange={e => this.handleChangeCustomParams(e, 'workerInstanceCount')}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra className="center">
          <Radio key={index} checked={this.environmentSelect === value} value="custom" name="size" />
        </Card.Content>
      </Card>
    );
  }

  renderSagemakerCustomCard(index, pricingType = null) {
    const emrConfiguration = this.state.customEmrConfiguration;
    const value = pricingType || `Custom`;
    const selected = value === this.environmentSelect;
     const current = this.state.customSagemakerEnvs.find(
      ({ instanceType }) => instanceType === emrConfiguration.workerInstanceSize,
    );
    const customSmOptions = _.orderBy(
      this.state.customSagemakerEnvs.map(({ instanceType, _index }) => ({
        key: instanceType,
        text: instanceType,
        value: instanceType,
      })),
      ['text'],
      ['asc'],
    );
    return (
      <Card key={index} onClick={this.handleCardSelection} value={pricingType || `Custom`} processing="false">
        <Card.Content>
          <Card.Header className="center">{`${pricingType || 'Custom'}`}</Card.Header>
          <Card.Description>{this.state.customDescriptions[`${pricingType || `Custom`}`]}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Table basic="very" size="small">
            <Table.Body>
              <Table.Row key="size" textAlign="center">
                <Table.Cell>Size</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    options={customSmOptions}
                    disabled={!selected}
                    search
                    selection
                    onChange={e => this.handleChangeCustomParams(e, 'masterInstanceType')}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row key="vCPU" textAlign="center">
                <Table.Cell>vCPU</Table.Cell>
                <Table.Cell>{selected && current ? current.vCpu : '--'}</Table.Cell>
              </Table.Row>
              <Table.Row key="memory" textAlign="center">
                <Table.Cell>Memory</Table.Cell>
                <Table.Cell>{selected && current ? current.memory : '--'}</Table.Cell>
              </Table.Row>
	      <Table.Row key="price" textAlign="center">
                <Table.Cell>Price per day</Table.Cell>
                <Table.Cell>{selected && current ? (current.unit === 'Hrs' ? Number(current.pricePerUnit * 24).toFixed(2) : Number(current.pricePerUnit).toFixed(2)) : '--'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra className="center">
          <Radio key={index} checked={this.environmentSelect === value} value="custom" name="size" />
        </Card.Content>
      </Card>
    );
	  
  }

  renderEnvironmentCard({ id, label, size, description, properties }, index, processing) {
    const environment = this.getConfigurationStore.getConfiguration(id);
    const disabledClass = processing ? 'disabled' : '';
    return (
      <Card key={index} onClick={this.handleCardSelection} value={id} processing={processing.toString()}>
        <Card.Content>
          <Card.Header className="center">{label}</Card.Header>
          <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Table basic="very" size="small">
            <Table.Body>
              {properties.map((property, propertyIndex) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.Row key={propertyIndex} textAlign="center">
                    <Table.Cell>{property.key}</Table.Cell>
                    <Table.Cell>{property.value}</Table.Cell>
                  </Table.Row>
                );
              })}
              <Table.Row textAlign="center">
                <Table.Cell>{environment.isEmrCluster ? 'Maximum price per day' : 'Price per day'}</Table.Cell>
                <Table.Cell>
                  {environment.isLoadingPrice ? 'Loading...' : `$${environment.totalPrice.toFixed(2)}`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Content>
        <Card.Content extra className="center">
          <Radio
            className={disabledClass}
            key={index}
            checked={this.environmentSelect === id}
            value={size}
            name="size"
          />
        </Card.Content>
      </Card>
    );
  }
}

export default inject(
  'environmentsStore',
  'environmentConfigurationsStore',
  'userStore',
  'projectsStore',
  'awsAccountsStore',
  'indexesStore',
  'clientInformationStore',
  'settingsStore',
  'secureOutputsStore',
)(withRouter(observer(CreateEnvironmentForm)));
