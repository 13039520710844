/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { SecureOutput } from '../../models/secure-outputs/SecureOutput';
import { displayError, displaySuccess } from '../../helpers/notification';

class RequestEgress extends React.Component {
  constructor(props) {
    super(props);
    this.secureOutput = props.secureOutput;
    this.permissionsStore = props.secureOutput.getPermissionsStore();
  }

  requestEgress = async () => {
    try {
      await this.permissionsStore.requestEgress(this.secureOutput);
      this.secureOutput.updateStatus(SecureOutput.PENDING_OWNER);

      displaySuccess('Egress requested.');
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    const props = this.props.buttonProps || {
      floated: 'right',
      size: 'mini',
      compact: true,
      color: 'blue',
    };
    return (
      <Button {...props} onClick={this.requestEgress}>
        Freeze and egress
      </Button>
    );
  }
}

export default inject('userStore')(observer(RequestEgress));
