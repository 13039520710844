/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import {
  getMySecureOutputs,
  getEgressRequestedSecureOutputs,
  getSecureOutputs,
  createSecureOutput,
  deleteSecureOutputData,
} from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import { SecureOutput } from './SecureOutput';
import { SecureOutputStore } from './SecureOutputStore';

const apiLoadMethods = { getMySecureOutputs, getEgressRequestedSecureOutputs };

// ==================================================================
// SecureOutputsStore
// ==================================================================
const SecureOutputsStore = BaseStore.named('SecureoutputsStore')
  .props({
    category: '',
    secureOutputs: types.optional(types.map(SecureOutput), {}),
    studyStores: types.optional(types.map(SecureOutputStore), {}),
    tickPeriod: 900 * 1000, // 15 minutes
    loadMethod: types.maybeNull(types.string, 'getMySecureOutputs'),
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let secureOutputs;
        if (self.loadMethod) {
          secureOutputs = await apiLoadMethods[self.loadMethod]();
        } else {
          secureOutputs = await getSecureOutputs(self.category);
        }

        self.runInAction(() => {
          consolidateToMap(self.secureOutputs, secureOutputs, (exiting, newItem) => {
            exiting.setSecureOutput(newItem);
          });
        });
      },

      addSecureOutput(rawSecureOutput) {
        const id = rawSecureOutput.id;
        const previous = self.secureOutputs.get(id);

        if (!previous) {
          self.secureOutputs.put(rawSecureOutput);
        } else {
          previous.setSecureOutput(rawSecureOutput);
        }
        return self.secureOutputs.get(id);
      },

      async createSecureOutput(secureOutput) {
        const result = await createSecureOutput(secureOutput);
        self.runInAction(() => {
          self.addSecureOutput(result);
        });
        return self.getSecureOutput(result.id);
      },

      async deleteData(secureOutput) {
        const result = await deleteSecureOutputData(secureOutput);
        self.runInAction(() => {
          self.addSecureOutput(result);
        });
        return self.getSecureOutput(result.id);
      },

      cleanup: () => {
        self.secureOutputs.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.secureOutputs.size === 0;
    },

    get total() {
      return self.secureOutputs.size;
    },

    get list() {
      const result = [];
      self.secureOutputs.forEach(secureOutput => result.push(secureOutput));

      return _.reverse(_.sortBy(result, ['createdAt', 'name']));
    },

    get numOfPages() {
      // TODO: implement pagination for when we have more data
      return 1;
    },

    getSecureOutput(id) {
      return self.secureOutputs.get(id);
    },
  }));

function registerModels(globals) {
  globals.secureOutputsStore = SecureOutputsStore.create({}, globals);
}

export { SecureOutputsStore, registerModels };
