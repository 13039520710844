/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import _ from 'lodash';
import React from 'react';
import { makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';

import InputEntryRenderer from './InputEntryRenderer';

// expected props
// - form (via props)
// - inputEntries (via props) (these are the input manifest input entries)
// - processing (via props) (default to false)
class InputEntriesRenderer extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
  }

  getForm() {
    return this.props.form;
  }

  getInputEntries() {
    return this.props.inputEntries;
  }

  render() {
    const processing = this.props.processing || false;
    const form = this.getForm();
    // entry is an object of this shape:
    // { name: 'id',  type: 'string/yesNo,..', label, children: [ <optional> ], .. }
    const entries = this.getInputEntries();
    return (
      <>
        {_.map(entries, entry => (
          <InputEntryRenderer key={entry.name} form={form} inputEntry={entry} processing={processing} />
        ))}
      </>
    );
  }
}

export default inject()(observer(InputEntriesRenderer));
