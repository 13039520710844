/**
 * Added chenjqp
 */

import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import InfoPopup from '../helpers/InfoPopup';

class SecureStudiesAbout extends React.Component {
  render() {
    const secureStudiesHelpContent = (
      <Grid centered>
        <Grid.Column textAlign="center">
          <Header as="h4">What&apos;s this?</Header>
          <p>
            Secure studies are studies where owners have tighter control over data flow. Data within secure studies are
            locked down to within RAPTOR workspaces and original/derived data are not allowed to be copied/exported
            unless approved by the study owner.
          </p>
          <Header as="h4">Who can upload to secure studies?</Header>
          <p>Only the study owner and admins are able to do so.</p>
          <Header as="h4">How will users access the data?</Header>
          <p>
            A user must first request for access to the study (only read access can be requested). Once access has been
            granted, users can create a research workspace with a mix of regular and secure studies. For workspace
            output, a secure output must be created/selected (please refer to the &apos;Secure Output&apos; tab for more
            information). The resulting workspace is a secure workspace, wrapped in a bastion Windows machine that acts
            as a virtual desktop which has exclusive access to the underlying machine. For security, all clipboard and
            file copy redirects are disabled in the bastion, which prevents data leaks of the secure study data from the
            bastion. Access instructions to the bastion will be available in the &apos;Security&apos; tab of the
            environment details in RAPTOR.
          </p>
        </Grid.Column>
      </Grid>
    );

    return <InfoPopup message={secureStudiesHelpContent} />;
  }
}

export default inject('userStore')(observer(SecureStudiesAbout));
