/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

/* eslint-disable import/prefer-default-export */
import { types, applySnapshot } from 'mobx-state-tree';

// ==================================================================
// Study Permissions
// ==================================================================
const CustomInstanceType = types
  .model('CustomInstanceType', {
    MemoryInfo: types.model({ SizeInMiB: types.integer }),
    VCpuInfo: types.model({ DefaultVCpus: types.integer }),
    InstanceType: types.string,
  })
  .actions(self => ({
    setCustomInstanceType(rawInstance) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic

      // Preserve the value of the fetchingUrl
      const fetchingUrl = self.fetchingUrl;
      applySnapshot(self, rawInstance);
      self.fetchingUrl = fetchingUrl;
    },
  }));

export { CustomInstanceType };
