/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeObservable, action, observable } from 'mobx';
import { Accordion, Icon, Divider } from 'semantic-ui-react';
import purify from 'dompurify';
import { withRouter } from '../../helpers/routing';

import PropertySection from './PropertySection';
import ConfigSection from './ConfigSection';

// expected props
// - step - an instance of WorkflowTemplateStep model (via props)
// - location (from react router)
class WorkflowTemplateStep extends React.Component {
  expanded = false;

  constructor(props) {
    super(props);
    makeObservable(this, {
      expanded: observable,
      handleOnExpand: action,
    });
  }

  getWorkflowTemplateStep() {
    return this.props.step;
  }

  /* getStepTemplate() {
    return this.getWorkflowTemplateStep().stepTemplate;
  } */

  handleOnExpand = () => {
    this.expanded = !this.expanded;
  };

  render() {
    const step = this.getWorkflowTemplateStep();
    const expanded = this.expanded;
    return (
      <Accordion className="flex-auto overflow-hidden pr1">
        <Accordion.Title active={expanded} index={0} onClick={this.handleOnExpand}>
          <div className="flex mt1 mb1">
            <Icon name="dropdown" className="inline-block" style={{ marginTop: '3px' }} />
            <div className="ellipsis flex-auto">{step.derivedTitle || step.title}</div>
            <div className="ellipsis pl1 pr1 breakout fs-9 color-grey">
              {step.templateId} v{step.templateVer}
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content active={expanded} className="animated fadeIn pt3 pl2 pr2 pb2 mb2">
          {this.renderDescriptionSection(step)}
          {this.renderConfigurationSection(step)}
          {this.renderPropertiesSection(step)}
        </Accordion.Content>
      </Accordion>
    );
  }

  renderDescriptionSection(step) {
    /* eslint-disable react/no-danger */
    return (
      <>
        <Icon name="file alternate outline" className="mr1 color-grey" />
        <b>Description</b>
        <Divider className="mt1" />
        <div className="mb4" dangerouslySetInnerHTML={{ __html: purify.sanitize(step.descHtml) }} />
      </>
    );
    /* eslint-enable react/no-danger */
  }

  renderConfigurationSection(step) {
    return (
      <div className="mb4">
        <Icon name="cog" className="mr1 color-grey" />
        <b>Configuration</b>
        <Divider className="mt1 mb3" />
        <ConfigSection model={step} />
      </div>
    );
  }

  renderPropertiesSection(step) {
    return (
      <>
        <Icon name="list alternate outline" className="mr1 color-grey" />
        <b>Properties</b>
        <Divider className="mt1 mb3" />
        <PropertySection model={step} />
      </>
    );
  }
}

export default inject()(withRouter(observer(WorkflowTemplateStep)));
