/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { getEnv } from 'mobx-state-tree';

import _ from 'lodash';
import { setIdToken } from '../helpers/api';
import { BaseStore } from './BaseStore';
import { displayError, displayWarning } from '../helpers/notification';

const Startup = BaseStore.named('Startup').actions(self => ({
  doLoad: async () => {
    // The logic:
    // - ask the authentication model to clear any expired tokens
    // - ask the authentication model for the id token
    //   - if not found, it is either expired or does not exists, in both cases, we will consider this a clean start
    //   - if found,
    //      - set the token on the api object
    //      - ask the userStore to load the user
    //      - if we get an error, display the error and clean everything, very much starting over
    //      - if we don't get an error, then
    //        - finally, setup any preferences store (TODO - future enhancement), then set app.setUserAuthenticated(true);
    //        - and we are done! yay!

    const cleaner = getEnv(self).cleaner;
    const userStore = getEnv(self).userStore;
    const usersStore = getEnv(self).usersStore;
    const userRolesStore = getEnv(self).userRolesStore;
    const awsAccountsStore = getEnv(self).awsAccountsStore;
    const indexesStore = getEnv(self).indexesStore;
    const projectsStore = getEnv(self).projectsStore;
    const app = getEnv(self).app;
    const authentication = getEnv(self).authentication;
    const authenticationProviderPublicConfigsStore = getEnv(self).authenticationProviderPublicConfigsStore;

    await authenticationProviderPublicConfigsStore.load();

    const { idToken, decodedIdToken } = await authentication.getActiveIdTokenAndDecodedToken();
    if (_.isEmpty(idToken)) {
      // either the token expired or this is clean start
      return cleaner.cleanup();
    }

    setIdToken(idToken, decodedIdToken);
    // Set selected authentication provider. This is used during logout
    authentication.setSelectedAuthenticationProviderId(decodedIdToken.iss);

    try {
      await userStore.load();

      // Validate if user is registered
      if (userStore.user.status === 'active') {
        app.setUserRegistered(true);
      }

      const isRootUser = userStore.user.isRootUser;
      if (isRootUser) {
        displayWarning('You have logged in as root user. Logging in as root user is discouraged.');
      }
    } catch (error) {
      displayError('Encountered a problem trying to get the user information of the logged in user', error);
      return cleaner.cleanup();
    }

    self.runInAction(() => {
      // const user = userStore.user;
      app.setUserAuthenticated(true);
    });

    // TODO: Load these stores as needed instead of on startup
    if (userStore.user.status === 'active') {
      await Promise.all([
        usersStore.load(),
        awsAccountsStore.load(),
        userRolesStore.load(),
        indexesStore.load(),
        projectsStore.load(),
      ]);
    }

    return undefined;
  },
}));

export default Startup;
