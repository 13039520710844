/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */
import _ from 'lodash';
import React from 'react';
import { makeObservable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Step, Button, Label, Grid, Header, Tab } from 'semantic-ui-react';

import { niceNumber, plural } from '../../helpers/utils';
import { withRouter } from '../../helpers/routing';
import StudiesTab from './StudiesTab';
import SearchStudiesTab from './SearchStudiesTab';
import { StudiesStore } from '../../models/studies/StudiesStore';
import { StudyUploadDirStore } from '../../models/studies/StudyUploadDirStore';

// edit 2359 - added API studies type
const studyCategories = {
  ORGANIZATION: 'Organization',
  API_DATA: 'API Data',
};

const DELIMIT = '/';

export { studyCategories };

// edited by 2359 - add handling of AMI
// edited by leeas - add uploadDirStore
// edit 2359 - add support for EMR notebook selection
class StudiesList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      handleNext: action,
    });

    this.stores = {
      'My Studies': StudiesStore.create({ loadMethod: 'getMyStudies' }),
      'Accessible Studies': StudiesStore.create({ loadMethod: 'getAccessibleStudies' }),
      'GIS Studies': StudiesStore.create({ loadMethod: 'getOrganizationStudies' }),
    };
    const ami = props.location.search.includes('ami=true') || false;
    this.state = { ami };
    const currUser = this.props.userStore.user;
    const unameWithNs = _.join([currUser.ns, currUser.username], DELIMIT);
    this.uploadDirStore = StudyUploadDirStore.create({ unameWithNs });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /* handlePageChange = (_event, _data) => {
    // TODO: Implement search
    // const activePage = data.activePage - 1;
    // this.props.searchSelection.setPage(activePage);
  }; */

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleNext = () => {
    // if (this.state.ami) {
    // }
    this.goto('/studies/wizard');
  };

  handleReset = () => {
    this.props.filesSelection.clear();
  };

  render() {
    const { ami } = this.state;
    const categories = [
      { label: 'My Studies', allowCreate: true, allowCreateSecure: true, category: studyCategories.ORGANIZATION },
      { label: 'Accessible Studies', category: studyCategories.ORGANIZATION },
      !ami && { label: 'GIS Studies', category: studyCategories.ORGANIZATION, noPermission: true }
    ];

    const dataPanes = categories.map(category => {
      const store = this.stores[category.label];
      return {
        menuItem: category.label,
        render: () => (
          <Tab.Pane key={category.label}>
            <StudiesTab
              store={store}
              noPermission={category.noPermission}
              allowCreate={ami ? false : category.allowCreate}
              allowCreateSecure={category.allowCreateSecure}
              category={category.category}
              uploadDirStore={this.uploadDirStore}
            />
          </Tab.Pane>
        ),
      };
    });

    if (!this.state.ami) {
      dataPanes.push({
        menuItem: 'Search Studies',
        render: () => (
          <Tab.Pane>
            <SearchStudiesTab uploadDirStore={this.uploadDirStore} />
          </Tab.Pane>
        ),
      });
    }

    return (
      <div className="p3 animated fadeIn">
        {/* <div dangerouslySetInnerHTML={{__html:store.csv}} /> */}
        <Step.Group widths={3}>
          <Step active>
            <Icon name="search" color="blue" />
            <Step.Content>
              <Step.Title>Find &amp; Select</Step.Title>
              <Step.Description>Search and select the studies&rsquo; runs</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled>
            <Icon name="wordpress forms" />
            <Step.Content>
              <Step.Title>Confirm Selection</Step.Title>
              <Step.Description>Confirm your selection or request access</Step.Description>
            </Step.Content>
          </Step>
          <Step disabled>
            <Icon name="unlock" />
            <Step.Content>
              <Step.Title>Get Access</Step.Title>
              <Step.Description>Receive access instructions</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        <Grid className="mt0">
          <Grid.Column width={16}>
            {/* TODO: Re-implement search */}
            {/* <Search /> */}
            <div className="mt3">
              {this.renderFilesSelection()}
              <div style={{ clear: 'both' }} />
            </div>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                Studies
              </Header>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
            </Segment>
            {this.renderFilesSelection()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  // edit 2359 - add support for EMR notebook selection
  renderFilesSelection() {
    const selection = this.props.filesSelection;
    const empty = selection.empty;
    const count = selection.count;
    const notebooks = this.props.notebooksSelection;
    const notebooksEmpty = notebooks.empty;
    const notebooksCount = notebooks.count;
    const whenEmpty = (
      <Label basic color="grey" size="large">
        No datasets selected
      </Label>
    );
    const whenNotEmpty = (
      <span>
        <Label basic color="blue" size="large">
          You selected {niceNumber(count)} {plural('dataset', 'datasets', count)}{' '}
        </Label>
        <Button color="grey" icon labelPosition="right" className="ml2" onClick={this.handleReset}>
          Reset
          <Icon name="undo" />
        </Button>
        <Button color="blue" icon labelPosition="right" className="ml2" onClick={this.handleNext}>
          Next
          <Icon name="right arrow" />
        </Button>
      </span>
    );

    return (
      <div className="mt2 float-right">
        {!notebooksEmpty && (
          <Label basic color="blue" size="large">
            You selected {niceNumber(notebooksCount)} {plural('notebook', 'notebooks', count)}{' '}
          </Label>
        )}
        {empty && whenEmpty}
        {!empty && whenNotEmpty}
      </div>
    );
  }
}

export default inject('notebooksSelection', 'filesSelection', 'userStore')(withRouter(observer(StudiesList)));
