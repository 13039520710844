/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// added by leeas
import _ from 'lodash';
import React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown, Icon, Table } from 'semantic-ui-react';

import { displayError, displaySuccess } from '../../helpers/notification';
import { isStoreError, isStoreLoading, isStoreNew } from '../../models/BaseStore';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import ErrorBox from '../helpers/ErrorBox';
import UserLabels from '../helpers/UserLabels';

// expected props
// - study
// - userStore (via injection)
// - usersStore (via injection)
const DELIMIT = '/';
const encode = (username, ns) => `${ns}/${username}`;

class StudyOwnerTable extends React.Component {
  editModeOn = false;
  isProcessing = false;
  selecteduser = '';

  constructor(props) {
    super(props);
    makeObservable(this, {
      editModeOn: observable,
      isProcessing: observable,
      selecteduser: observable,
      enableEditMode: action,
      resetForm: action,
    });

    // this.userType = 'admin';
    this.study = props.study;
    this.permissionsStore = props.permissionsStore;
    this.currUser = props.userStore.user;
    this.usersStore = props.usersStore;
    this.owner = this.permissionsStore.studyPermissions.ownerWithNs;
    // console.log("owner: "+ JSON.stringify(this.owner));
    runInAction(() => {
      this.selecteduser = this.owner;
    });
    this.resetForm();
  }

  enableEditMode = () => {
    runInAction(() => {
      // Set current owner
      this.selecteduser = this.owner;
      // Show edit dropdowns via observable
      this.editModeOn = true;
    });
  };

  resetForm = () => {
    runInAction(() => {
      this.editModeOn = false;
      this.selecteduser = this.owner;
      this.isProcessing = false;
    });
  };

  submitUpdate = async () => {
    runInAction(() => {
      this.isProcessing = true;
    });

    const newowner = this.selecteduser;

    // Perform update
    try {
      const updateRequest = { studyId: this.study.id, newowner };
      await this.permissionsStore.updateOwner(updateRequest);
      displaySuccess('Update Owner Succeeded');
      this.owner = this.permissionsStore.studyPermissions.ownerWithNs;
      this.resetForm();
    } catch (error) {
      displayError('Update Owner Failed', error);
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  };

  decode(selecteduser) {
    const ind = selecteduser.lastIndexOf(DELIMIT);
    const ns = selecteduser.substring(0, ind);
    const username = selecteduser.substring(ind + DELIMIT.length);
    return this.usersStore.asUserObject({ username, ns });
  }

  render() {
    // Render loading, error, or permissions table
    let content;
    if (isStoreError(this.permissionsStore)) {
      content = <ErrorBox error={this.permissionsStore.error} />;
    } else if (isStoreLoading(this.permissionsStore) || isStoreNew(this.permissionsStore)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    // const studyPermissions = this.permissionsStore.studyPermissions;
    // only allow owner or system admin user to change owner
    const isEditable =
      (this.owner === encode(this.currUser.username, this.currUser.ns) || this.currUser.isAdmin) &&
      !this.props.study.deleted;
    const selecteduser = this.decode(this.selecteduser);
    return (
      <>
        {/* <Dimmer.Dimmable dimmed={this.isProcessing}>
          <Dimmer active={this.isProcessing} inverted>
            <Loader size="big" />
          </Dimmer> */}

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Owner
                {isEditable && !this.editModeOn && (
                  <Icon name="pencil" className="ml1 cursor-pointer" color="grey" onClick={this.enableEditMode} />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key="owner">
              <Table.Cell>
                {this.editModeOn ? (
                  this.renderUsersDropdown()
                ) : (
                  <UserLabels users={this.usersStore.asUserObjects([selecteduser])} />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {this.editModeOn && (
          <>
            <Button
              floated="right"
              disabled={this.isProcessing}
              onClick={this.submitUpdate}
              size="mini"
              color="blue"
              icon
            >
              Update
            </Button>

            <Button floated="right" disabled={this.isProcessing} onClick={this.resetForm} size="mini">
              Cancel
            </Button>
          </>
        )}
        {/* </Dimmer.Dimmable> */}
      </>
    );
  }

  renderUsersDropdown() {
    const dropdownOnChange = action((_event, data) => {
      runInAction(() => {
        this.selecteduser = data.value;
      });
    });
    const selectedVal = _.isString(this.selecteduser) ? this.selecteduser : JSON.stringify(this.selecteduser);
    return (
      <Dropdown
        selection
        fluid
        search
        options={this.usersStore.asEncodedDropDownOptions()}
        value={selectedVal}
        placeholder="Select owner"
        onChange={dropdownOnChange}
      />
    );
  }
}

export default inject('userStore', 'usersStore')(observer(StudyOwnerTable));
