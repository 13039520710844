/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Button, Container, Header, Icon, Label, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { swallowError } from '../../helpers/utils';
import { displayWarning } from '../../helpers/notification';

import { isStoreError, isStoreLoading, isStoreReady } from '../../models/BaseStore';
import ErrorBox from '../helpers/ErrorBox';
import { withRouter } from '../../helpers/routing';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import UsersListTable from './UsersListTable';

class UsersList extends React.Component {
  formProcessing = false;
  // this.mapOfUsersBeingEdited = {};

  constructor(props) {
    super(props);
    // mapOfUsersBeingEdited: observable,
    makeObservable(this, {
      formProcessing: observable,
    });

    this.notifyNonRootUers = true;
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      selectedRole: '',
      // eslint-disable-next-line react/no-unused-state
      projectId: [],
      // eslint-disable-next-line react/no-unused-state
      identityProviderName: '',
      // eslint-disable-next-line react/no-unused-state
      isIdentityProviderNameChanged: false,
      // eslint-disable-next-line react/no-unused-state
      unchangedIdentityProviderName: '',
    };
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.getStore();
    store.stopHeartbeat();
  }

  getStore() {
    return this.props.usersStore;
  }

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  renderHeader() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="users" className="align-top" />
          <Header.Content className="left-align">
            Users
            {this.renderTotal()}
          </Header.Content>
        </Header>
        <Button color="blue" size="medium" basic onClick={this.handleAddUser}>
          {' '}
          Add User{' '}
        </Button>
      </div>
    );
  }

  renderTotal() {
    const store = this.getStore();
    if (isStoreError(store) || isStoreLoading(store)) return null;
    const nonRootUsers = store.nonRootUsers;
    const count = nonRootUsers.length;

    return <Label circular>{count}</Label>;
  }

  renderMain() {
    return this.renderUsers();
  }

  /* getAwsAccountOptions() {
    const accountStore = this.props.awsAccountsStore;
    return accountStore.dropdownOptions;
  } */

  renderUsers() {
    // Read "this.mapOfUsersBeingEdited" in the "render" method here
    // The usersBeingEditedMap is then used in the ReactTable
    // If we directly use this.mapOfUsersBeingEdited in the ReactTable's cell method, MobX does not
    // realize that it is being used in the outer component's "render" method's scope
    // Due to this, MobX does not re-render the component when observable state changes.
    // To make this work correctly, we need to access "this.mapOfUsersBeingEdited" out side of ReactTable once

    const store = this.getStore();
    const usersList = store.list;
    // const pageSize = usersList.length;
    // const showPagination = usersList.length > pageSize;
    const processing = this.formProcessing;

    return (
      // TODO: add api token stats and active flag here in the table
      <Segment basic className="p0">
        <Dimmer active={processing} inverted>
          <Loader inverted>Updating</Loader>
        </Dimmer>
        <UsersListTable props={this.props} usersList={usersList} />
        {/*
        <ReactTable
          data={usersList}
          defaultSorted={[{ id: 'lastName', desc: true }]}
          showPagination={showPagination}
          defaultPageSize={pageSize}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) => {
            const columnValue = String(row[filter.id]).toLowerCase();
            const filterValue = filter.value.toLowerCase();
            return columnValue.indexOf(filterValue) >= 0;
          }}
          columns={[
            {
              Header: 'Name',
              accessor: 'username',
              width: 200,
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 200,
            },
            {
              Header: 'Identity Provider',
              accessor: 'identityProviderName',
              Cell: row => {
                const user = row.original;
                return user.identityProviderName || 'internal';
              },
            },
            {
              Header: 'Role',
              accessor: 'userRole',
              width: 100,
              style: { whiteSpace: 'unset' },
              Cell: row => {
                const user = row.original;
                return user.userRole || 'N/A';
              },
            },
            {
              Header: 'Project',
              style: { whiteSpace: 'unset' },
              Cell: row => {
                const user = row.original;
                return user.projectId.join(', ') || '<<none>>';
              },
            },
            {
              Header: 'Status',
              accessor: 'isActive',
              width: 100,
              Cell: row => {
                const user = row.original;
                let lable = null;
                if (user.status === 'active') {
                  lable = (
                    <span>
                      <Label color="green">
                        <i className="check circle outline icon" />
                        Active
                      </Label>
                    </span>
                  );
                } else if (user.status === 'inactive') {
                  lable = (
                    <span>
                      <Label color="red">
                        <i className="circle icon" />
                        Inactive
                      </Label>
                    </span>
                  );
                } else {
                  lable = (
                    <span>
                      <Label color="orange">
                        <i className="exclamation icon" />
                        Pending
                      </Label>
                    </span>
                  );
                }
                return lable;
              },
              filterMethod: (filter, row) => {
                if (row._original.status.indexOf(filter.value.toLowerCase()) >= 0) {
                  return true;
                }
                return false;
              },
            },
            {
              Header: '',
              filterable: false,
              Cell: cell => {
                const user = cell.original;
                return (
                  <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <span>
                      <Popup
                        content="View User Detail"
                        trigger={
                          <UserConfigure
                            user={_.clone(user)}
                            adminMode
                            userStore={this.props.userStore}
                            usersStore={this.props.usersStore}
                            userRolesStore={this.props.userRolesStore}
                            awsAccountsStore={this.props.awsAccountsStore}
                            projectsStore={this.props.projectsStore}
                          />
                        }
                      />
                    </span>
                  </div>
                );
              },
            },
          ]}
        />
        */}
      </Segment>
    );
  }

  render() {
    const store = this.getStore();
    let content = null;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else if (isStoreReady(store)) {
      if (!store.hasNonRootUsers) {
        if (this.notifyNonRootUers) {
          this.notifyNonRootUers = false;
          displayWarning(
            'Please add users in the Data Lake. May need to configure authentication provider in the Auth tab at left. Then login as a regular non-root User.',
          );
        }
      }
      content = this.renderMain();
    }

    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {content}
      </Container>
    );
  }

  handleAddUser = () => {
    this.goto('/users/add');
  };

  /* handleAddAuthenticationProvider = () => {
    this.goto('/authentication-providers');
  }; */
}

export default inject(
  'userStore',
  'usersStore',
  'userRolesStore',
  'awsAccountsStore',
  'projectsStore',
)(withRouter(observer(UsersList)));
