/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - add support for EMR-hail notebook access requests
import { createForm } from '../../helpers/form';

const requestAccessFields = {
  // General fields
  isRead: {
    label: 'Read',
    value: true,
    rules: ['required', 'boolean'],
  },
  expiry: {
    label: 'Expiry Date',
    value: '',
    rules: ['required', 'string'],
  },
  requestorComments: {
    label: 'Requestor Comments',
    value: '',
    rules: 'string|between:1,3000',
  },
  adminComments: {
    label: 'Admin/Owner Comments',
    value: '',
    rules: 'string|between:1,3000',
  },
};

const getNotebookRequestAccessForm = () => {
  return createForm(requestAccessFields);
};

export { getNotebookRequestAccessForm }; // eslint-disable-line import/prefer-default-export
