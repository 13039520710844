/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

// add 2359 - display of notebooks for individual categories
import React from 'react';
import { makeObservable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Icon, Button, Label, Grid, Header, Tab } from 'semantic-ui-react';

import { niceNumber, plural } from '../../helpers/utils';
import { withRouter } from '../../helpers/routing';
import NotebooksTab from './NotebooksTab';
import { NotebooksStore } from '../../models/notebooks/NotebooksStore';

class NotebooksList extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {
      handleNext: action,
    });

    this.stores = {
      'My Notebooks': NotebooksStore.create({ loadMethod: 'getMyNotebooks' }),
      'Accessible Notebooks': NotebooksStore.create({ loadMethod: 'getAccessibleNotebooks' }),
      'GIS Notebooks': NotebooksStore.create({ loadMethod: 'getOrganizationNotebooks' }),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /* handlePageChange = (_event, _data) => {}; */

  goto(pathname) {
    // const location = this.props.location;
    // const link = createLink({ location, pathname });
    this.props.navigate(pathname);
  }

  handleNext = () => {
    this.goto('/studies');
  };

  handleReset = () => {
    this.props.notebooksSelection.clear();
  };

  render() {
    const categories = [
      { label: 'My Notebooks', allowCreate: false, category: 'MY_NOTEBOOKS' },
      { label: 'Accessible Notebooks', category: 'ACCESSIBLE_NOTEBOOKS' },
      { label: 'GIS Notebooks', category: 'GIS_NOTEBOOKS' },
    ];

    const dataPanes = categories.map(category => {
      const store = this.stores[category.label];
      return {
        menuItem: category.label,
        render: () => (
          <Tab.Pane key={category.label}>
            <NotebooksTab store={store} category={category.category} />
          </Tab.Pane>
        ),
      };
    });

    return (
      <div className="p3 animated fadeIn">
        {/* <div dangerouslySetInnerHTML={{__html:store.csv}} /> */}

        <Grid className="mt0">
          <Grid.Column width={16}>
            <div className="mt3">
              {this.renderFilesSelection()}
              <div style={{ clear: 'both' }} />
            </div>
            <Segment raised className="mt3">
              <Header as="h2" color="grey" className="mt1">
                Notebooks
              </Header>
              <Label basic color="red">
                Important notice:
                <ul>
                  <li>
                    Please contact RAPTOR administrators at raptor_admin@gis.a-star.edu.sg if you would like to import
                    notebooks.
                  </li>
                </ul>
              </Label>
              <Tab className="mt2" menu={{ attached: true, tabular: true }} panes={dataPanes} />
            </Segment>
            {this.renderFilesSelection()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderFilesSelection() {
    const selection = this.props.notebooksSelection;
    const empty = selection.empty;
    const count = selection.count;
    const whenEmpty = (
      <Label basic color="grey" size="large">
        No notebooks selected
      </Label>
    );
    const whenNotEmpty = (
      <span>
        <Label basic color="blue" size="large">
          You selected {niceNumber(count)} {plural('notebook', 'notebooks', count)}{' '}
        </Label>
        <Button color="grey" icon labelPosition="right" className="ml2" onClick={this.handleReset}>
          Reset
          <Icon name="undo" />
        </Button>
        <Button color="blue" icon labelPosition="right" className="ml2" onClick={this.handleNext}>
          Next
          <Icon name="right arrow" />
        </Button>
      </span>
    );

    return (
      <div className="mt2 float-right">
        {empty && whenEmpty}
        {!empty && whenNotEmpty}
      </div>
    );
  }
}

export default inject('notebooksSelection', 'userStore')(withRouter(observer(NotebooksList)));
