/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

import { sessionStore, uiEventBus } from '../../../models/SessionStore';

const WorkflowCommonUIState = types
  .model('WorkflowCommonUIState', {
    versionNumber: -1,
    mainTabIndex: 0,
  })
  .actions(self => ({
    setVersionNumber(v) {
      self.versionNumber = v;
    },
    setMainTabIndex(index) {
      self.mainTabIndex = index;
    },
  }));

function getUIState(idSuffix) {
  const id = `WorkflowCommonUIState-${idSuffix}`;
  const entry = sessionStore.map.get(id) || WorkflowCommonUIState.create();

  sessionStore.map.set(id, entry);
  return entry;
}

uiEventBus.listenTo('workflowTemplatePublished', {
  id: 'WorkflowCommonUIState',
  listener: async event => {
    sessionStore.removeStartsWith(`WorkflowCommonUIState-${event.id}`);
  },
});

uiEventBus.listenTo('workflowPublished', {
  id: 'WorkflowCommonUIState',
  listener: async event => {
    sessionStore.removeStartsWith(`WorkflowCommonUIState-${event.id}`);
  },
});

export default getUIState;
