/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';
import { searchTagSetsByTargetType } from '../../helpers/api';
import { BaseStore } from '../BaseStore';
import TagSet from './TagSet';

// ==================================================================
// TagSetsStore
// ==================================================================

const DELIMIT = '||||';

const TagSetsStore = BaseStore.named('TagSetsStore')
  .props({
    tagSets: types.map(TagSet),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    return {
      getTagSet: (targetType, targetId) => {
        const id = targetType + DELIMIT + targetId;
        let entry = self.tagSets.get(id);
        if (!entry) {
          self.tagSets.set(id, TagSet.create({ id }));
          entry = self.tagSets.get(id);
        }
        return entry;
      },

      searchByTargetType: async (targetType, criteria) => {
        const result = await searchTagSetsByTargetType(targetType, criteria);
        return result;
      },
    };
  });

function registerModels(globals) {
  globals.tagSetsStore = TagSetsStore.create({}, globals);
}

export { TagSetsStore, registerModels };
