/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const createAwsAccountFormFields = {
  accountName: {
    label: 'Account Name',
    placeholder: 'Type the name of this account',
    rules: 'required|string|between:1,100',
  },
  accountEmail: {
    label: 'AWS Account Email',
    placeholder: 'Type AWS account email',
    rules: 'required|string|email',
  },
  masterRoleArn: {
    label: 'Master Role Arn',
    placeholder: 'Type configured Role ARN of master account of the Organization',
    rules: 'required|string|between:10,300',
  },
  externalId: {
    label: 'External ID',
    placeholder: 'Type external ID for this AWS account',
    rules: 'required|string|between:1,300',
  },
  description: {
    label: 'Description',
    placeholder: 'Type description for this AWS account',
    rules: 'required|string',
  },
};

function getCreateAwsAccountFormFields() {
  return createAwsAccountFormFields;
}

function getCreateAwsAccountForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: createAwsAccountFormFields }, { plugins });
}

export { getCreateAwsAccountFormFields, getCreateAwsAccountForm };
