/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';
import { observable, runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Button, Segment, Label, Header, Input, Grid } from 'semantic-ui-react';
import { niceNumber } from '../../helpers/utils';
import BasicProgressPlaceholder from '../helpers/BasicProgressPlaceholder';
import StudyRow from './StudyRow';
import { displayError } from '../../helpers/notification';

// edited by leeas - pass uploadDirStore to StudyRow
class SearchStudiesTab extends React.Component {
  constructor(props) {
    super(props);
    this.componentStore = observable({
      loading: false,
      results: [],
      searchValue: '',
    });
  }

  // @param searchString = "location = syd or location = male or another tag = asdf"
  // @returns
  // [
  //   { key: 'location', value: 'syd' },
  //   { key: 'location', value: 'bane' },
  //   { key: 'another tag', value: 'asdf' },
  // ]
  constructSearchQuery(searchString) {
    if (searchString !== '') {
      // add chenjqp - bugfix to display no results when no search string is inserted
      try {
        return searchString.split(' and ').map(criterion => {
          const parts = _.trim(criterion).split('=');
          return {
            key: _.trim(parts[0]).toLowerCase(),
            value: _.trim(parts[1]),
          };
        });
      } catch (e) {
        displayError('The search query you entered is invalid, please adjust and try again');
        return [];
      }
    } else {
      displayError('No search query!');
      return [];
    }
  }

  async handleSearchClick() {
    const searchCriteria = this.constructSearchQuery(this.componentStore.searchValue);
    if (searchCriteria.length === []) {
      return;
    }
    runInAction(() => {
      this.componentStore.loading = true;
    });
    const results = await this.props.studiesStore.search(searchCriteria);
    runInAction(() => {
      this.componentStore.loading = false;
      this.componentStore.results = results;
    });
  }

  async handleSelectAll() {
    runInAction(() => {
      const selection = this.props.filesSelection;
      this.componentStore.results.forEach(res => {
        const { id, name, description } = res;
        selection.setFile({ id, name, description });
      });
    });
  }

  render() {
    return (
      <>
        {this.renderSearchBar()}
        {this.renderContent()}
      </>
    );
  }

  renderSearchBar() {
    return (
      <Grid columns="equal" className="mb1">
        <Grid.Column width={12}>
          <Input
            fluid
            placeholder="location = singapore and gender = male and age-group = 35-45"
            onChange={(event, data) => {
              runInAction(() => {
                this.componentStore.searchValue = data.value;
              });
            }}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Button
            fluid
            onClick={() => {
              this.handleSearchClick();
            }}
          >
            Search
          </Button>
        </Grid.Column>
      </Grid>
    );
  }

  renderContent() {
    if (this.componentStore.loading) {
      return <BasicProgressPlaceholder segmentCount={3} />;
    }
    if (this.componentStore.results.length === 0) {
      return (
        <Segment textAlign="center" secondary>
          No Results
        </Segment>
      );
    }
    return this.renderResults();
  }

  renderResults() {
    const results = this.componentStore.results;
    return (
      <>
        {this.renderTotal({ count: results.length, label: 'Search Results:' })}
        {results.map(study => (
          <Segment clearing raised key={study.id} className="mb3">
            <StudyRow study={study} uploadDirStore={this.props.uploadDirStore} />
          </Segment>
        ))}
      </>
    );
  }

  renderTotal({ count, label }) {
    const niceCount = niceNumber(count);
    return (
      <Header as="h2" color="grey" className="mt2 mb0">
        <Grid>
          <Grid.Column width={12}>
            {label}{' '}
            <Label circular size="huge">
              {niceCount}
            </Label>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              fluid
              onClick={() => {
                this.handleSelectAll();
              }}
            >
              Select All
            </Button>
          </Grid.Column>
        </Grid>
      </Header>
    );
  }
}

export default inject('studiesStore', 'filesSelection')(observer(SearchStudiesTab));
