import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { listEnvironmentOutputFiles } from '../../helpers/api';
import { BaseStore } from '../BaseStore';

// ==================================================================
// SecureOutputFile
// ==================================================================
const EnvironmentOutputFile = types.model('EnvironmentOutputFile', {
  fileType: types.string,
  fileName: types.string,
  size: types.optional(types.integer, 0),
  lastModified: types.optional(types.Date, new Date()),
});

// ==================================================================
// StudyFiles
// ==================================================================
const EnvironmentOutputFilesStore = BaseStore.named('EnvironmentOutputFilesStore')
  .props({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    progress: 0,
    environmentId: '',
    selectedPrefix: '',
    parentPrefix: '',
    queryType: 'filename',
    query: '',
    files: types.array(EnvironmentOutputFile),
    visibleRecords: types.optional(types.array(EnvironmentOutputFile), []),
    tickPeriod: 5 * 1000, // 5 seconds
  })

  .actions(self => {
    // save the base implementation of clear
    const superCleanup = self.cleanup;

    return {
      async doLoad({ page = 1, pageSize = 10, reload = false, selectedPrefix, queryType, query } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;
        self.selectedPrefix = typeof selectedPrefix !== 'undefined' && selectedPrefix !== '' ? selectedPrefix : '';
        // console.log(`in doLoad: ${self.secureOutputId} prefix: ${self.selectedPrefix}`);

        if (typeof query !== 'undefined' && query !== '') {
          self.query = query;
          self.queryType = queryType;
        } else if (query === '') {
          self.query = query;
          self.queryType = queryType;
        }

        if (reload) {
          // Retrieve files
          self.progress = 0;
          self.total = 0;
          self.currentPage = 1;
          self.visibleRecords = [];

          let result = {};
          let fullDirList = {};

          // retrieve everything in current prefix
          fullDirList = await listEnvironmentOutputFiles(self.environmentId, self.selectedPrefix);
          const mergedFiles = fullDirList.files;
          result = fullDirList;

          // Sort files by name and cast lastModified as Date()
          if (result === null || !Object.prototype.hasOwnProperty.call(result, 'files')) {
            self.runInAction(() => {
              self.files = [];
            });
          } else {
            const files = mergedFiles
              .sort((fileA, fileB) => fileA.fileType.localeCompare(fileB.fileType))
              .map(file => {
                if (file.fileType === 'file') {
                  return { ...file, lastModified: new Date(file.lastModified) };
                }
                return { ...file };
              });

            // Update store
            self.runInAction(() => {
              self.parentPrefix = result.parentPrefix;
              self.files.replace(files);
              self.total = self.files.length;
              const num = self.currentPage * self.pageSize;
              self.visibleRecords.replace(_.cloneDeep(self.files.slice(num - self.pageSize, num)));
              self.progress = 100;
            });
          }
        } else {
          self.runInAction(() => {
            self.progress = 0;
            const num = self.currentPage * self.pageSize;
            self.visibleRecords.replace(_.cloneDeep(self.files.slice(num - self.pageSize, num)));
            self.progress = 100;
          });
        }
      },

      cleanup: () => {
        self.files.clear();
        superCleanup();
        self.clearSearch();
        self.currentPage = 1;
        self.total = 0;
        self.visibleRecords = [];
        self.progress = 0;
      },

      empty() {
        self.files.clear();
      },
    };
  })
  .views(self => ({
    get isEmpty() {
      return self.files.length === 0;
    },
    get totalPages() {
      return self.total === 0 ? 1 : Math.ceil(self.total / self.pageSize);
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access to an instance
//       use study.getFilesStore()
export { EnvironmentOutputFilesStore }; // eslint-disable-line import/prefer-default-export
