/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

import UserIdentifier from '../users/UserIdentifier';

// ==================================================================
// AwsAccounts
// ==================================================================
// edit 2359 - add support for parallel cluster which needs private subnet
const AwsAccount = types
  .model('AwsAccounts', {
    id: types.identifier,
    rev: types.maybe(types.number),
    name: '',
    description: '',
    accountId: '',
    externalId: '',
    roleArn: '',
    vpcId: '',
    subnetId: '',
    privateSubnetId: '',
    encryptionKeyArn: '',
    ssbWorkspaceVpcId: '',
    ssbWorkspaceSubnetId: '',
    ssbBastionVpcId: '',
    ssbBastionSubnetId: '',
    ssbEncryptionKeyArn: '',
    ssbWorkspaceCfnVpcEndpointIp: '',
    ssbWorkspaceS3VpcEndpointPrefixList: '',
    ssbWorkspaceKmsVpcEndpointIp: '',
    ssbBastionCfnVpcEndpointIp: '',
    ssbBastionSsmVpcEndpointIp: '',
    createdAt: '',
    createdBy: types.optional(UserIdentifier, {}),
    updatedAt: '',
    updatedBy: types.optional(UserIdentifier, {}),
  })
  .actions(self => ({
    setAwsAccounts(rawAwsAccounts) {
      self.id = rawAwsAccounts.id;
      self.rev = rawAwsAccounts.rev || self.rev || 0;
      self.name = rawAwsAccounts.name || self.name || '';
      self.description = rawAwsAccounts.description || self.description;
      self.accountId = rawAwsAccounts.accountId || rawAwsAccounts.accountId;
      self.externalId = rawAwsAccounts.externalId || self.externalId;
      self.roleArn = rawAwsAccounts.roleArn || self.roleArn;
      self.vpcId = rawAwsAccounts.vpcId || self.vpcId;
      self.subnetId = rawAwsAccounts.subnetId || self.subnetId;
      self.privateSubnetId = rawAwsAccounts.privateSubnetId || self.privateSubnetId;
      self.encryptionKeyArn = rawAwsAccounts.encryptionKeyArn || self.encryptionKeyArn;
      self.ssbWorkspaceVpcId = rawAwsAccounts.ssbWorkspaceVpcId || self.ssbWorkspaceVpcId;
      self.ssbWorkspaceSubnetId = rawAwsAccounts.ssbWorkspaceSubnetId || self.ssbWorkspaceSubnetId;
      self.ssbBastionVpcId = rawAwsAccounts.ssbBastionVpcId || self.ssbBastionVpcId;
      self.ssbBastionSubnetId = rawAwsAccounts.ssbBastionSubnetId || self.ssbBastionSubnetId;
      self.ssbEncryptionKeyArn = rawAwsAccounts.ssbEncryptionKeyArn || self.ssbEncryptionKeyArn;
      self.ssbWorkspaceCfnVpcEndpointIp =
        rawAwsAccounts.ssbWorkspaceCfnVpcEndpointIp || self.ssbWorkspaceCfnVpcEndpointIp;
      self.ssbWorkspaceS3VpcEndpointPrefixList =
        rawAwsAccounts.ssbWorkspaceS3VpcEndpointPrefixList || self.ssbWorkspaceS3VpcEndpointPrefixList;
      self.ssbWorkspaceKmsVpcEndpointIp =
        rawAwsAccounts.ssbWorkspaceKmsVpcEndpointIp || self.ssbWorkspaceKmsVpcEndpointIp;
      self.ssbBastionCfnVpcEndpointIp = rawAwsAccounts.ssbBastionCfnVpcEndpointIp || self.ssbBastionCfnVpcEndpointIp;
      self.ssbBastionSsmVpcEndpointIp = rawAwsAccounts.ssbBastionSsmVpcEndpointIp || self.ssbBastionSsmVpcEndpointIp;
      self.createdAt = rawAwsAccounts.createdAt || self.createdAt;
      self.updatedAt = rawAwsAccounts.updatedAt || self.updatedAt;
      self.createdBy = rawAwsAccounts.createdBy || self.createdBy;
      self.updatedBy = rawAwsAccounts.updatedBy || self.updatedBy;
      // we don't update the other fields because they are being populated by a separate store
    },
  }))

  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // add view methods here
  }));

// eslint-disable-next-line import/prefer-default-export
export { AwsAccount };
