/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import { Tab, Segment, Container } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../helpers/routing';
import ErrorBox from '../helpers/ErrorBox';
import AwsAccountsList from './AwsAccountsList';
import IndexesList from './IndexesList';
import ProjectsList from '../projects/ProjectsList';

const panes = [
  { menuItem: 'Projects', render: () => <ProjectsList /> },
  { menuItem: 'Cost Centres', render: () => <IndexesList /> },
  { menuItem: 'AWS Accounts', render: () => <AwsAccountsList /> },
];

// eslint-disable-next-line react/prefer-stateless-function
class Accounts extends React.Component {
  render() {
    let content;
    const isAdmin = this.props.userStore.user.isAdmin;
    // console.log("username: " + this.props.userStore.user.username + " isAdmin: " + isAdmin);
    if (isAdmin) {
      content = (
        <Container className="mt3 animated fadeIn">
          <Segment basic className="p0">
            <Tab panes={panes} />
          </Segment>
        </Container>
      );
    } else {
      content = <ErrorBox error="You are not authorized to perform this operation" />;
    }
    return content;
  }
}

export default inject(
  'userStore',
  'userRolesStore',
  'indexesStore',
  'awsAccountsStore',
)(withRouter(observer(Accounts)));
