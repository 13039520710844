/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { createForm } from '../../helpers/form';

// edited by 2359 - added maxCPU and minCPU
// edit 2359 - add support for parallel cluster
// edit 2359 - add support for custom ports, and change message for cidr to include note for non-EMR workspaces
const formFields = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Type a name for this research workspace',
    rules: 'required|string|between:3,300',
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'The description of this research workspace',
    rules: 'required|string|between:3,2048',
  },
  {
    name: 'instanceInfo',
    fields: [
      {
        name: 'type',
        label: 'Research Workspace Type',
        placeholder: 'The type of the research workspace',
        rules: 'required',
      },
      {
        name: 'size',
        label: 'Research Workspace Size',
        placeholder: 'The size of the research workspace',
        rules: 'required',
      },
      {
        name: 'cidr',
        label: 'Whitelisted CIDR',
        extra: {
          explain: `<p>This research workspace will only be reachable from this CIDR. This is limited to a CIDR with a single IP address (<ip>/32).</p>
					<p>To <b>accurately</b> check your device's actual IP:
					<ul>
						<li>For Windows: Open your Command Prompt terminal (search for "cmd") and run <span class="code-text">curl http://checkip.amazonaws.com/</span> </li>
						<li>For MacOS: Open Terminal and run <span class="code-text">curl https://checkip.amazonaws.com/</span> </li>
					</ul>
					The CIDR value to be used: {your_reported_ip}/32 (for e.g. 101.99.88.77/32)</br></br>
					NOTE: Please do NOT go to the URL via a browser to retrieve your IP address as organizational laptops/desktops may be behind a browser proxy.
					</p>`,
        },
        placeholder: 'The CIDR to restrict research workspace access to',
        rules: 'required|singleCidr',
      },
      {
        name: 'rootEbs',
        fields: [
          {
            name: 'size',
            label: 'Root Volume Size',
          },
        ],
      },
      {
        name: 'secureOutput',
        label: 'Secure Output',
        placeholder: 'The secure output used for this workspace',
      },
    ],
  },
  {
    name: 'amiOs',
    label: 'Parallel Cluster OS',
    placeholder: 'The OS for the Parallel Cluster',
    rules: 'required_if:instanceInfo.type,ami-parallel-cluster|string',
  },
  {
    name: 'maxCount',
    label: 'Maximum Cluster Size',
    placeholder: '',
    extra: {
      explain: `Maximum number of compute instances to spin up for cluster`,
    },
    rules: 'required_if:instanceInfo.type,parallel-cluster|integer|max:100',
  },
  {
    name: 'minCount',
    label: 'Minimum Cluster Size',
    placeholder: '',
    extra: {
      explain: `Minimum number of compute instances to spin up for cluster`,
    },
    rules: 'required_if:instanceInfo.type,parallel-cluster|integer|min:1',
  },
  {
    name: 'ports',
    label: 'Ports',
    placeholder: 'Enter comma separated integer values of tcp ports to be exposed.',
    rules: 'regex:/^[0-9]+(,[0-9]+)*$/',
  },
];

function getCreateEnvironmentForm(additionalFields = []) {
  return createForm(formFields.concat(additionalFields));
}

// eslint-disable-next-line import/prefer-default-export
export { getCreateEnvironmentForm };
