/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';
import _ from 'lodash';

const DownloadKey = types
  .model('DownloadKey', {
    id: types.identifier,
    ns: '',
    username: '',
    updatedAt: '',
    status: '',
    createdAt: '',
    expiryTime: 0,
    name: '',
    files: types.optional(
      types.array(types.model('F', { id: types.identifier, resources: types.optional(types.array(types.string), []) })),
      [],
    ),
    accessKey: '',
    expiryDate: '',
    key: types.optional(types.string, ''),
  })
  .views(self => ({
    get effectiveStatus() {
      if (self.status !== 'active') {
        // if status it not active then the effective status is same as status (such as "revoked")
        return self.status;
      }
      // if status is active then make sure it is not expired
      if (self.expiryTime > 0 && _.now() > self.expiryTime) {
        return 'expired';
      }
      return self.status;
    },
  }));

export default DownloadKey;
