/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import { types } from 'mobx-state-tree';

import { getStudyAccessRequests } from '../../helpers/api';
import { consolidateToMap } from '../../helpers/utils';
import { BaseStore } from '../BaseStore';
import AccessRequests from './AccessRequests';

// ==================================================================
// AccessRequestsStore
// ==================================================================
const AccessRequestsStore = BaseStore.named('AccessRequestsStore')
  .props({
    accessRequests: types.optional(types.map(AccessRequests), {}),
    tickPeriod: 900 * 1000, // 15 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      doLoad: async () => {
        const accessRequests = await getStudyAccessRequests();
        self.runInAction(() => {
          consolidateToMap(self.accessRequests, accessRequests, (existing, newItem) => {
            existing.setAccessRequest(newItem);
          });
        });
      },
      cleanup: () => {
        self.accessRequests.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.accessRequests.size === 0;
    },

    get total() {
      return self.accessRequests.size;
    },

    get list() {
      const result = [];
      self.accessRequests.forEach(study => result.push(study));
      return result;
    },

    hasAccessRequest(id) {
      return self.accessRequests.has(id);
    },

    getAccessRequest(id) {
      return self.accessRequests.get(id);
    },
  }));

function registerModels(globals) {
  globals.accessRequestsStore = AccessRequestsStore.create({}, globals);
}

export { AccessRequestsStore, registerModels };
