/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const addUserRoleFormFields = {
  id: {
    label: 'User Role name',
    placeholder: 'Type name for this user role',
    rules: 'required|string|regex:^[A-Za-z0-9-_ ]+$',
  },
  description: {
    label: 'Description',
    placeholder: 'Type description for this user role',
    rules: 'string|between:1,3000',
  },
  type: {
    label: 'User Type',
    value: 'INTERNAL',
  },
};

function getAddUserRoleFormFields() {
  return addUserRoleFormFields;
}

function getAddUserRoleForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: addUserRoleFormFields }, { plugins });
}

export { getAddUserRoleFormFields, getAddUserRoleForm };
